import { Component } from '@angular/core';

@Component({
  selector: 'app-header-center',
  templateUrl: './app-header-center.component.html',
  styleUrls: ['./app-header-center.component.scss']
})
export class HeaderCenterComponent {
  headerLogoHeight = 50;
  constructor() {
  }

}
