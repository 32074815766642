<div class="studetnDetail">
    <div class="title">
        <div class="homeBtn">
            <app-button [content]="'< Back to Classroom Materials'" (click)="backToClassManagement()"></app-button>
        </div>
        <div class="heading">{{selectedClass.name}} Students</div>
    </div>
    <div class="addStudent">
        <app-button [isDisabled]="selectedClass?.readOnly || false" [content]="'Add New Student'" [btnClasses]="'blue'" [iconPath]="addStudentIcon" (click)="AddStudentIcon()"></app-button>
        <form class="addStudentContainer" *ngIf="isAddStudentExpanded" [formGroup]="addStudentFormGroup" autocomplete="off" #formDirective="ngForm">
            <mat-form-field appearance="fill" class="item">
                <mat-label>Student ID/Code:</mat-label>
                <input matInput placeholder="" formControlName="studentIdControl">
            </mat-form-field>

            <mat-form-field appearance="fill" class="item">
                <mat-label>Student First Name (Optional):</mat-label>
                <input matInput placeholder="" formControlName="studentFirstNameControl">
            </mat-form-field>
            <mat-form-field appearance="fill" class="item">
                <mat-label>Student Last Name (Optional):</mat-label>
                <input matInput placeholder="" formControlName="studentLastNameControl">
            </mat-form-field>
            <div class="flexBreak"></div>
            <mat-form-field appearance="fill" class="item">
                <mat-label>Reading Level:</mat-label>
                <mat-select formControlName="studentLevelControl">
                    <mat-option value="BR">Beginning Reader</mat-option>
                    <mat-option value="L1_INIT">Level 1</mat-option>
                    <mat-option value="L2_INIT">Level 2</mat-option>
                    <mat-option value="L3_INIT">Level 3 and Up</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="item">
                <mat-progress-bar *ngIf="isAddStudentInProgress" mode="indeterminate"></mat-progress-bar>
                <app-button *ngIf="!isAddStudentInProgress" [content]="'Add and Save Student'" [btnClasses]="'blue'" (click)="onAddStudent(formDirective)"></app-button>
            </div>
        </form>
    </div>
    <div class="mat-elevation-z4">
        <div class="tableInfo">
            <div class="heading">Students:</div>
            <div>
                <img src="./assets/images/icons/assessment-notification-icon.svg" alt="icon" class="icon" matTooltip="You have students in need of assessment. Click the 'Student Assessment' button to see more details." matTooltipPosition="above" matTooltipClass="tool-tip-info red"
                    #tooltip="matTooltip" (click)="tooltip.toggle()" />
                <app-button [content]="'Student Assessment >'" [btnClasses]="'blue'" (click)="goToAssessmentApp()"></app-button>
            </div>

        </div>
        <table mat-table [dataSource]="studentList" class="table">
            <!-- ID Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> Student ID </th>
                <td mat-cell *matCellDef="let student"> {{student.studentID}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Student Name </th>
                <td mat-cell *matCellDef="let student"> {{ (student.firstName || '') + ' ' + (student.lastName || '') }} </td>
            </ng-container>

            <!-- Level Column -->
            <ng-container matColumnDef="level">
                <th mat-header-cell *matHeaderCellDef class="level"> Reading Level </th>
                <td mat-cell *matCellDef="let student" class="level"> {{studentLevels[student.level]?.name}} </td>
            </ng-container>

            <!-- Share Column -->
            <ng-container matColumnDef="share">
                <th mat-header-cell *matHeaderCellDef> Shared </th>
                <td mat-cell *matCellDef="let student">
                    <span *ngIf="student.sharedUsers.length">
                        <img src="./assets/images/icons/shareIcon.svg" alt="icon" class="icon" [matTooltip]="student.sharedUserInfo" matTooltipPosition="right" matTooltipClass="tool-tip-info"/>
                    </span>
                </td>
            </ng-container>

            <!-- Edit Column -->
            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let student" class="editBtns">
                    <app-button [isDisabled]="student?.readOnly || false" (click)="editStudent(student)" [content]="'Edit'" [btnClasses]="'blue conceptBtn'"></app-button>
                    <mat-checkbox [color]="'primary'" class="checkbox" [(ngModel)]="student.selected"></mat-checkbox>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
        </table>
        <div class="studentEditBtns">
            <app-button [isDisabled]="selectedClass?.readOnly || false" [content]="'Transfer Selected'" [btnClasses]="'blue'" (click)="transferStudent()"></app-button>
            <!-- <app-button [isDisabled]="selectedClass?.readOnly || false" [content]="'Share Selected'" [btnClasses]="'blue'" (click)="shareStudent()"></app-button> -->
        </div>
    </div>
</div>