import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators, FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ClassData } from "src/app/classes/classData";
import { Levels } from "src/app/classes/enums";
import { Student } from "src/app/classes/student";
import { User } from "src/app/classes/user";
import { NavbarService } from "src/app/layout/app-header/navbar.service";
import { environment } from "src/environments/environment";
import { CoreService } from "../../core.service";
import { AddInviteStudentDialog } from "../dialog-box/add-edit-invite-student/add-invite-student.component";
import { EditStudentDialog } from "../dialog-box/edit-student/edit-student.component";
import { ReportSelectionDialog } from "../dialog-box/report-selection/report-selection.component";
import { TransferStudentDialog } from "../dialog-box/transfer-student/transfer-student.component";
import * as Driver from 'driver.js';
import { StudentResponseDto } from "src/app/classes/studentResponseDto";
import { LevelForUI } from "src/app/classes/enums";
@Component({
    selector: 'student-assessment',
    templateUrl: './student-assessment.component.html',
    styleUrls: ['./student-assessment.component.scss']
})
export class StudentAssessmentComponent implements OnDestroy, OnInit {
    selectedClass!: ClassData;
    userData!: User | null;
    private _subscription: Subscription[] = [];
    studentList!: Student[];
    studentListFiltered!: Student[] | null;
    displayColumns!: string[];
    studentLevels = Levels;
    searchStudentControl!: UntypedFormControl;
    isGetStudentLoading = false;
    leftHeaderButtons: { title: string, event: string }[] = [{
        title: 'Back to Home',
        event: 'homeButton'
    }];
    driver!: Driver;
    // rightHeaderButtons: { title: string, event: string }[] = [{
    //     title: 'Class Reports',
    //     event: 'classReportBtn'
    // }];

    functonList: { [name: string]: Function } = {
        homeButton: () => this.gotoToHome()
    }

    addIconPath = './assets/images/icons/plus_icon.svg';
    closeIconPath = './assets/images/icons/close_icon.svg';
    addStudentIcon = this.addIconPath;
    isAddStudentExpanded = false;
    isAddStudentInProgress = false;

    studentIdControl!: FormControl<StudentResponseDto | string | null>;
    studentFirstNameControl!: UntypedFormControl;
    studentLastNameControl!: UntypedFormControl;
    studentLevelControl!: UntypedFormControl;
    studentLevelFromClass!: string;
    addStudentFormGroup!: UntypedFormGroup;
    isTourOn: boolean = false;

    sortType: 'none' | 'asc' | 'dsc' = 'none';

    getStudentApiRunning = true;
    studentsDaList: StudentResponseDto[] | null = [];
    studentsDaFliterList: StudentResponseDto[] = [];
    studentData!: StudentResponseDto | undefined;
    canShareStudent = false;
    canAddStudent = false;
    constructor(private coreService: CoreService,
        private router: Router,
        private navbarService: NavbarService,
        public dialog: MatDialog,
        private ref: ChangeDetectorRef) {
        if (!this.coreService.selectedClass) {
            this.router.navigate(['dashboard']);
            return;
        }
        this.selectedClass = this.coreService.selectedClass;
        this.userData = this.coreService.userData;
        let shareStudentRule = this.userData?.propertiesByRule && this.userData.propertiesByRule['TEACHER_SHARE'];
        this.canShareStudent = shareStudentRule ? JSON.parse(shareStudentRule.value) : false;

        let addStudentRule = this.userData?.propertiesByRule && this.userData.propertiesByRule['TEACHER_STUDENT_CREATE'];
        this.canAddStudent = addStudentRule ? JSON.parse(addStudentRule.value) : true;

        this.navbarService.updateLeftHeaderBtn(this.leftHeaderButtons);
        // this.navbarService.updateRightHeaderBtn(this.rightHeaderButtons);
        this.navbarService.updateRightHeaderBtn(null);

        const navSub = this.navbarService.headerBtnClicked$.subscribe({
            next: action => this.onNavigationServiceActionCalled(action)
        });

        this._subscription.push(navSub);

        this.displayColumns = ['student', 'edit', 'assessLevel', 'assessDate', 'AssessScore', 'assess', 'share', 'select'];
        // this.displayColumns = ['id', 'name', 'level', 'assessLevel', 'assessDate', 'AssessScore', 'recommendation', 'edit', 'select'];
        this.studentLevelFromClass = LevelForUI(this.selectedClass.level);
        this.studentIdControl = new FormControl<StudentResponseDto | string | null>(null, Validators.required);
        this.studentFirstNameControl = new UntypedFormControl('');
        this.studentLastNameControl = new UntypedFormControl('');
        this.studentLevelControl = new UntypedFormControl(this.studentLevelFromClass, Validators.required);
        this.addStudentFormGroup = new UntypedFormGroup({
            studentIdControl: this.studentIdControl,
            studentFirstNameControl: this.studentFirstNameControl,
            studentLastNameControl: this.studentLastNameControl,
            studentLevelControl: this.studentLevelControl
        });
        this.searchStudentControl = new UntypedFormControl('');
        let descChangeSub = this.searchStudentControl.valueChanges.subscribe({
            next: (value: string) => {
                this.filterStudentByName();
            }
        });
        this._subscription.push(descChangeSub);

        // this.userData?.districtManaged && this.getStudentFromDA();

    }

    ngOnInit(): void {
        this.getStudentList();
        this.studentIdControl.valueChanges.subscribe({
            next: value => {
                if (!this.studentsDaList?.length) {
                    // this.studentIdControl.setValue(value, { emitEvent: false });
                    return;
                }
                if (value && (typeof value === 'object')) {
                    this.studentData = value;
                    this.studentIdControl.setValue(this.studentData?.studentId, { emitEvent: false });
                    this.studentFirstNameControl.setValue(this.studentData?.firstName);
                    this.studentFirstNameControl.disable();
                    this.studentLastNameControl.setValue(this.studentData?.lastName);
                    this.studentLastNameControl.disable();
                    this.studentLevelControl.setValue(LevelForUI(this.studentData?.level));
                    this.studentLevelControl.disable();
                } else if (value?.trim()) {
                    let trimedValue: string = <string>value.trim();
                    this.studentsDaFliterList = this.studentsDaList.filter(stu => {
                        return stu.studentId.toLowerCase().trim().indexOf(trimedValue.toLowerCase()) > -1;
                    });
                    this.clearStudentData();
                } else {
                    this.studentIdControl.setValue('', { emitEvent: false });
                    this.studentsDaFliterList = [...this.studentsDaList];
                    this.clearStudentData();
                }
            }
        });
    }

    onNavigationServiceActionCalled(action: string) {
        if (this.functonList[action]) {
            this.functonList[action]();
        }
    }

    ngOnDestroy(): void {
        this._subscription.forEach(sub => sub?.unsubscribe());
    }

    clearStudentData() {
        this.studentData = undefined;
        this.studentIdControl.markAsTouched();
        this.studentFirstNameControl.enable();
        this.studentLastNameControl.enable();
        this.studentLevelControl.enable();
    }

    getStudentList() {
        this.searchStudentControl.setValue('');
        this.isGetStudentLoading = true;
        let studentSub = this.coreService.getStudents(this.userData?.ssoReadOnly ? this.userData?.id || '' : '').subscribe({
            next: resp => {
                this.studentList = resp.map(stu => {
                    stu.selected = false;
                    if (stu.sharedUsers.length) {
                        stu.sharedUserInfo = 'Shared with ' + stu.sharedUsers.map(usr => usr.user?.fullName || usr.email).join(', ');
                    }
                    return stu;
                });
                this.studentListFiltered = [...this.studentList];
                this.isGetStudentLoading = false;
                if (this.userData?.districtManaged && this.canAddStudent && !this.selectedClass.readOnly) {
                    this.getStudentFromDA();
                } else {
                    this.getStudentApiRunning = false;
                }
                this.ref.detectChanges();
            },
            error: err => {
                console.log(err);
                this.isGetStudentLoading = false;
                this.getStudentApiRunning = false;
                this.ref.detectChanges();
                this.coreService.openSnackBar("Error while getting Students Data", 3000, "warn", "end", "bottom");
            }
        });
        this._subscription.push(studentSub);
    }

    getStudentFromDA() {
        this.getStudentApiRunning = true;
        let apiSub = this.coreService.getStudentFromDA().subscribe({
            next: resp => {
                this.studentsDaList = resp.sort((a,b) => a.studentId.localeCompare(b.studentId));
                this.studentsDaFliterList = [...this.studentsDaList];
                this.getStudentApiRunning = false;
                this.clearStudentData();
            }, 
            error: err => {
                this.getStudentApiRunning = false;
                this.studentsDaList = null;
                this.coreService.openSnackBar("Error while getting DA Students Data", 3000, "warn", "end", "bottom");
            }
        });
        this._subscription.push(apiSub);
    }

    gotoToHome() {
        this.router.navigate(['/dashboard']);
    }

    backToStudentManagement() {
        this.router.navigate(['/student']);
    }

    backToClassManagement() {
        this.router.navigate(['class']);
    }

    goToAssessmentApp(student: Student) {
        if (this.isTourOn) {
            return;
        }
        if (this.userData?.ssoToken) {
            let readOnlyStudent = '&readOnly=true';
            this.coreService.assessmentApp?.close();
            let url = environment.assessmentAppUrl + '/?sessionId=' + this.userData?.ssoToken + '&classId=' + this.selectedClass.guid + '&studentId=' + student.guid;
            if (this.userData.ssoReadOnly) {
                // by default all DA login to Assessment are Readonly
                url += "&teacherId=" + this.userData.id + "&teacherName=" + this.userData.teacherName;
            } else {
                url += student.readOnly ? readOnlyStudent : '';
            }
            this.coreService.assessmentApp = window.open(url
                , "assessmentAppIog1");
        } else {
            alert("User Token not Found");
        }
    }

    openNoStudentSelectedSnackBar() {
        this.coreService.openSnackBar("Please select atleast one Student", 3000, "warn", "end", "bottom");
    }

    shareStudent() {
        if (this.isTourOn) {
            return;
        }
        let selectedStudent = this.studentListFiltered?.filter(stu => stu.selected);
        if (!(selectedStudent && selectedStudent.length)) {
            this.openNoStudentSelectedSnackBar();
            return;
        }
        let shareStudentDialog = this.dialog.open(AddInviteStudentDialog, {
            data: {
                studentList: selectedStudent
            },
            disableClose: true,
            panelClass: ['dialogOverflow']
        });

        let sharedStudentDialogSub = shareStudentDialog.afterClosed().subscribe({
            next: resp => {
                if (resp) {
                    this.isGetStudentLoading = true;
                    this.studentListFiltered = null;
                    this.ref.detectChanges();
                    this.getStudentList();
                }
            }
        });
        this._subscription.push(sharedStudentDialogSub);
    }

    studentSelected(event: any) {
        this.ref.detectChanges();
    }

    AddStudentIcon() {
        if (this.isTourOn || this.selectedClass?.readOnly) {
            return;
        }
        this.addStudentIcon = this.addStudentIcon === this.closeIconPath ? this.addIconPath : this.closeIconPath;
        this.isAddStudentExpanded = !this.isAddStudentExpanded;

    }

    onAddStudent() {
        if (this.isTourOn) {
            return;
        }
        this.addStudentFormGroup.markAllAsTouched();
        if (this.addStudentFormGroup.invalid) {
            return;
        }
        if(this.userData?.districtManaged && this.studentData) {
            this.linkStudentToClass();
            return;
        }
        let studentIdFormated = (<string> this.studentIdControl.value).trim();
        studentIdFormated = studentIdFormated.replace(/  +/g,' ');
        this.studentIdControl.setValue(studentIdFormated, {emitEvent: false});
        let isStudentIdDuplicate = this.studentsDaList?.find(stu => (stu.studentId.toLowerCase() === (<string>(this.studentIdControl.value ||'')).toLowerCase()) && stu.id !== this.studentData?.id);
        if (isStudentIdDuplicate) {
            this.coreService.openSnackBar("Student ID already exists, update Student ID value or edit existing Student", 3000, "warn", "end", "bottom");
            return;
        }
        this.isAddStudentInProgress = true;
        let newStudent = new Student(<string> this.studentIdControl.value, this.studentFirstNameControl.value, this.studentLastNameControl.value, this.studentLevelControl.value);
        let addApiSub = this.coreService.saveStudent(newStudent.studentDto(this.coreService.userData?.id || '')).subscribe({
            next: resp => {
                this.coreService.openSnackBar("Student Added", 3000, "primary", "end", "bottom");
                this.addStudentFormGroup.reset();
                // formDirective.resetForm();
                this.studentLevelControl.setValue(this.studentLevelFromClass);
                this.isAddStudentInProgress = false;
                this.getStudentList();
            },
            error: err => {
                console.log(err);
                let errorMessage = 'Some Error Occured, please try later';
                if(err.message) {
                    try {
                        let errorResp = JSON.parse(err.message);
                        if(errorResp['message'] === 'StudentId exists') {
                            errorMessage = 'Student ID already exists, please add different ID';
                        }
                    } catch (ex) {
                        console.log(ex);
                    }
                }
                this.coreService.openSnackBar(errorMessage, 3000, "warn", "end", "bottom");
                this.isAddStudentInProgress = false;
            }
        });
        this._subscription.push(addApiSub);
    }

    linkStudentToClass() {
        this.isAddStudentInProgress = true;
        let apiSub = this.coreService.linkStudent(this.studentData?.id || '').subscribe({
            next: resp => {
                this.coreService.openSnackBar("Student assigned to this class", 3000, "primary", "end", "bottom");
                this.addStudentFormGroup.reset();
                this.studentLevelControl.setValue(this.studentLevelFromClass);
                this.getStudentList();
                this.isAddStudentInProgress = false;
            },
            error: err => {
                this.isAddStudentInProgress = false;
                this.coreService.openSnackBar("Error while linking to class", 3000, "warn", "end", "bottom");
            }
        });
        this._subscription.push(apiSub);
    }

    @HostListener('window:message', ['$event'])
    onAssessmentAppClose(event: any) {
        if (event.origin === environment.assessmentAppUrl && event.data === 'tabClose') {
            this.isGetStudentLoading = true;
            this.studentListFiltered = null;
            this.ref.detectChanges();
            setTimeout(() => {
                this.getStudentList();
            }, 1000);
        }
    }

    transferStudent() {
        if (this.isTourOn) {
            return;
        }
        let selectedStudent = this.studentList.filter(stu => stu.selected);
        if (!(selectedStudent && selectedStudent.length)) {
            this.openNoStudentSelectedSnackBar();
            return;
        }

        let transferDialog = this.dialog.open(TransferStudentDialog, {
            data: {
                studentList: selectedStudent
            },
            disableClose: true,
            panelClass: ['dialogOverflow']
        });
        let transferDialogSub = transferDialog.afterClosed().subscribe({
            next: data => {
                if (data) {
                    this.getStudentList();
                }
            }
        });
        this._subscription.push(transferDialogSub);

    }

    editStudent(student: Student) {
        if (this.isTourOn) {
            return;
        }
        let editDialogSub = this.dialog.open(EditStudentDialog, {
            data: {
                student
            },
            disableClose: true,
            panelClass: ['dialogOverflow']
        }).afterClosed().subscribe({
            next: resp => {
                if (resp) {
                    this.getStudentList();
                }
            }
        });

        this._subscription.push(editDialogSub);
    }

    selectAllStudent(selectAll: boolean) {
        this.studentListFiltered?.forEach(stu => stu.selected = selectAll);
    }

    goToAssessmentRepotingApp() {
        if (this.isTourOn) {
            return;
        }
        this.dialog.open(ReportSelectionDialog);
     }

     openTourDialog(event: any) {
         let studentDescription = this.userData?.ssoReadOnly ?
             '• Search for students by name or ID <br/> • Use the View button to view assessments' :
             '• Search for students by name or ID <br/> • Use <img src="./assets/images/icons/edit_black.svg" style="vertical-align: sub;height: 20px;" alt="icon" /> to update student information or to remove them from your class <br/> • Use the Assess button to begin or continue assessments <br/> • Use <input type="checkbox" checked> to select students for sharing or transferring';
        event.stopPropagation();
        this.driver = new Driver({
            allowClose: false,
            onReset: (Element) => {
                this.isTourOn = false;
            }
        });
        this.driver.defineSteps([
            {
                element: '#addStudentBtn',
                popover: {
                    className: 'popover-class',
                    title: 'Add Student to class',
                    description: 'Input student ID, name and reading level',
                    showButtons: true,
                    closeBtnText: 'Close',
                    nextBtnText: 'Next',
                    prevBtnText: 'Previous',
                }
            },
            {
                element: '#studentListTable',
                popover: {
                    className: 'popover-class',
                    title: 'Student list',
                    description: studentDescription,
                    showButtons: true,
                    closeBtnText: 'Close',
                    nextBtnText: 'Next',
                    prevBtnText: 'Previous',
                }
            },
            {
                element: '#transferBtn',
                popover: {
                    className: 'popover-class',
                    title: 'Transfer Students',
                    description: 'Transfer selected students to another class',
                    showButtons: true,
                    closeBtnText: 'Close',
                    nextBtnText: 'Next',
                    prevBtnText: 'Previous',
                    position: 'left-center'
                }
            },
            {
                element: '#shareBtn',
                popover: {
                    className: 'popover-class',
                    title: 'Share Students',
                    description: 'Share selected students with another educator to access within this class',
                    showButtons: true,
                    closeBtnText: 'Close',
                    nextBtnText: 'Next',
                    prevBtnText: 'Previous',
                    position: 'left-center'
                }
            },
            {
                element: '#metricsBtn',
                popover: {
                    className: 'popover-class',
                    title: 'Analyze Metrics & Reporting',
                    description: 'Generate reports to track class progress',
                    showButtons: true,
                    closeBtnText: 'Close',
                    nextBtnText: 'Next',
                    prevBtnText: 'Previous',
                }
            },
        ]);
        this.driver.start();
        this.isTourOn = true;
    }

    filterStudentByName() {
        if (this.isTourOn) {
            return;
        }
        if (!this.studentList?.length) {
            return;
        }
        let value = this.searchStudentControl.value;
        if (!value.trim()) {
            this.studentListFiltered = [...this.studentList];
            this.searchStudentControl.setValue('', {emitEvent: false});
            return;
        }
        this.studentListFiltered = [...this.studentList.filter(stu => {
            if (stu.studentID?.trim().toLowerCase().indexOf(value.trim().toLowerCase()) >= 0 ||
                stu.firstName?.trim().toLowerCase().indexOf(value.trim().toLowerCase()) >= 0 ||
                stu.lastName?.trim().toLowerCase().indexOf(value.trim().toLowerCase()) >= 0 ||
                ((stu.firstName?.trim() || '').concat(' ', (stu.lastName?.trim() || '')).toLowerCase().indexOf(value.trim().toLowerCase()) >= 0)
                ) {
                return true
            }
            return false;
        })];
    }

    sortStudentByScore() {
        if (this.isTourOn) {
            return;
        }
        this.filterStudentByName();
        if (this.sortType === 'none') {
            this.sortType = 'asc';
            this.studentListFiltered?.sort((a,b) => (a.avgScore || 0) - (b.avgScore || 0));
        } else if (this.sortType === 'asc') {
            this.sortType = 'dsc';
            this.studentListFiltered?.sort((a,b) => (b.avgScore || 0) - (a.avgScore || 0));
        } else {
            this.sortType = 'none';
        }
    }
}