export enum TeacherAcceptStatus {
    INVALID_STATUS,
    APPROVED,
    REJECTED,
    INVITE_SENT,
    INVALID_EMAIL,
    READY_TO_INVITE,
}

export const Grades: { [key: string]: { value: number, name: string, key: string } } = {
    KINDERGARTEN: {
        value: 1,
        name: 'Kindergarten',
        key: 'KINDERGARTEN'
    },
    FIRST_GRADE: {
        value: 2,
        name: '1st Grade',
        key: 'FIRST_GRADE'
    },
    SECOND_GRADE: {
        value: 3,
        name: '2nd Grade',
        key: 'SECOND_GRADE'
    },
    THIRD_GRADE: {
        value: 4,
        name: '3rd Grade',
        key: 'THIRD_GRADE'
    },
    FOURTH_UP_GRADE: {
        value: 5,
        name: '4th and up',
        key: 'FOURTH_UP_GRADE'
    }
}

export const Levels: { [key: string]: { value: number, name: string, uiName: string, key: string } } = {
    BR: {
        value: 10,
        name: 'Beginning Reader',
        uiName: 'Beginning Reader',
        key: 'BR',
    },
    BR_R: {
        value: 20,
        name: 'Beginning Reader_R',
        uiName: 'Beginning Reader',
        key: 'BR_R',
    },
    L1_INIT: {
        value: 30,
        name: 'Level 1',
        uiName: 'Level1 Initial',
        key: 'L1_INIT',
    },
    L1_INIT_R: {
        value: 40,
        name: 'Level 1_R',
        uiName: 'Level1 Initial',
        key: 'L1_INIT_R',
    },
    L1_MID: {
        value: 50,
        name: 'Level 1_MID',
        uiName: 'Level1 Midterm',
        key: 'L1_MID',
    },
    L1_MID_R: {
        value: 60,
        name: 'Level 1_MID_R',
        uiName: 'Level1 Midterm',
        key: 'L1_MID_R',
    },
    L1_FINAL: {
        value: 70,
        name: 'Level 1_Final',
        uiName: 'Level1 Final',
        key: 'L1_FINAL',
    },
    L1_FINAL_R: {
        value: 80,
        name: 'Level 1_Final_R',
        uiName: 'Level1 Final',
        key: 'L1_FINAL_R',
    },
    BL: {
        value: 90,
        name: 'BL',
        uiName: 'BL',
        key: 'BL',
    },
    BL_R: {
        value: 100,
        name: 'BL_R',
        uiName: 'BL',
        key: 'BL_R',
    },
    DONE: {
        value: 110,
        name: 'Done',
        uiName: 'Done',
        key: 'DONE',
    },
    DONE_BL: {
        value: 120,
        name: 'Done_BL',
        uiName: 'Done',
        key: 'DONE_BL',
    },
    L2_INIT: {
        value: 130,
        name: 'Level 2',
        uiName: 'Level2 Initial',
        key: 'L2_INIT',
    },
    L2_INIT_R: {
        value: 140,
        name: 'Level 2_R',
        uiName: 'Level2 Initial',
        key: 'L2_INIT_R',
    },
    L2_MID: {
        value: 150,
        name: 'Level 2_MID',
        uiName: 'Level2 Midterm',
        key: 'L2_MID',
    },
    L2_MID_R: {
        value: 160,
        name: 'Level 2_MID_R',
        uiName: 'Level2 Midterm',
        key: 'L2_MID_R',
    },
    L2_FINAL: {
        value: 170,
        name: 'Level 2_FINAL',
        uiName: 'Level2 Final',
        key: 'L2_FINAL',
    },
    L2_FINAL_R: {
        value: 180,
        name: 'Level 2_FINAL_R',
        uiName: 'Level2 Final',
        key: 'L2_FINAL_R',
    },
    L3_INIT: {
        value: 190,
        name: 'Level 3',
        uiName: 'Level 3',
        key: 'L3_INIT',
    },
}

export function LevelForUI(level: string): string {
    switch (level) {
        case 'BR': {
            return 'BR';
        }
        case 'L1_INIT':
        case 'L1_INIT_R':
        case 'L1_MID':
        case 'L1_MID_R':
        case 'L1_FINAL':
        case 'L1_FINAL_R':
            return 'L1_INIT';
        case 'L2_INIT':
        case 'L2_INIT_R':
        case 'L2_MID':
        case 'L2_MID_R':
        case 'L2_FINAL':
        case 'L2_FINAL_R':
        case 'BL':
            return 'L2_INIT';
        case 'L3_INIT':
            return 'L3_INIT';
        default:
            return 'L1_INIT';
    }

}