import { Component, Inject } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CoreService } from "src/app/core/core.service";

@Component({
    selector: 'red-word-dialog',
    templateUrl: 'red-word-dialog.component.html',
    styleUrls: ['red-word-dialog.component.scss']
})
export class RedWordsDialogComponent {
    customWord: UntypedFormControl = new UntypedFormControl('', Validators.required);
    isAddingNewWord = false;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<RedWordsDialogComponent>,
        private coreService: CoreService,
    ) {
        let newWord = this.data['newWord'];
        this.customWord.setValue(newWord);
    }

    onCancel() {
        this.dialogRef.close(false);
    }

    onAdd() {
        if (!(this.customWord.valid && this.customWord.value.trim())) {
            this.customWord.setValue('');
            this.customWord.markAsTouched();
            return;
        }
        this.isAddingNewWord = true;
        this.coreService.addNewRedWord(this.customWord.value).subscribe({
            next: resp => {
                this.dialogRef.close(resp);
                this.isAddingNewWord = false;
                this.coreService.openSnackBar("Custom Red Word Added", 3000, "primary", "end", "bottom");
            },
            error: err => {
                console.log(err);
                this.isAddingNewWord = false;
                this.coreService.openSnackBar("Some error occurred", 3000, "warn", "end", "bottom");
            }
        });

        // this.dialogRef.close(this.customWord.value.trim());
    }
}