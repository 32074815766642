import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ClassData } from "src/app/classes/classData";
import { ConceptMaterial } from "src/app/classes/concept";
import { ConceptSentenceClue, ConceptSentenceClueDetails, ConceptWordClue, ConceptWordClueDetails, dictationSaveDto, savedDictationDto } from "src/app/classes/Concepts/dictation";
import { CoreService } from "src/app/core/core.service";
import { NavbarService } from "src/app/layout/app-header/navbar.service";
import { ConfirmDialogBox } from "../../dialog-box/confirm-dialog/confirm-dialog.component";
import { DictationHelper } from "../../dialog-box/dictation-helper/dictation-helper.component";


@Component({
    selector: 'app-dictation',
    templateUrl: 'app-dictation.component.html',
    styleUrls: ['app-dictation.component.scss']
})
export class DictationWordsSentencesComponent implements OnInit, OnDestroy {

    todayDate = new Date();
    selectedConcept!: ConceptMaterial;
    _subscription: Subscription[] = [];
    visualWordsClueList!: ConceptWordClueDetails;
    visualWordsClueListFiltered!: ConceptWordClueDetails;
    visualSentenceClueList!: ConceptSentenceClueDetails;
    visualSentenceClueListFiltered!: ConceptSentenceClueDetails;
    filterOutWords: { concept: string, word: string }[] = []; // Words are filtered out in UI instead of BE due to these words are already used in lesson and concept materials

    isSaveInProgress = false;
    isStudentPdfCreationInProgress = false;
    isTeacherPdfCreationInProgress = false;

    conceptMaterialList: { [key: string]: number } = {};
    descriptionControl: UntypedFormControl;

    conceptFilterControl!: UntypedFormControl;

    conceptWordSelected: wordSelector[] = [];
    defaultConceptWord!: ConceptWordClue;
    conceptSentenceSelected: {
        sentenceLineOption?: string;
        selectedConceptSentenceClue?: ConceptSentenceClue;
        wordsOption?: {
            [index: string]: string;
            type: string;
            visualCue: string;
            noCues: string;
        }[];
        cupsOptions: string;
    }[] = [];
    defaultConceptSentence!: ConceptSentenceClue;
    pageOrientation = 'PORTRAIT';
    visualCueSize = 'SMALL';

    savedDictations!: savedDictationDto;
    isLessonConcept = false;

    leftHeaderButtons: { title: string, event: string }[] = [{
        title: 'Back to Home',
        event: 'homeButton'
    }];

    // rightHeaderButtons: { title: string, event: string }[] = [{
    //     title: 'Saved Materials',
    //     event: 'savedLessonConcept'
    // }];

    rightHeaderButtons: { title: string, event: string }[] = [];

    functonList: { [name: string]: Function } = {
        homeButton: () => this.gotoToHome(),
        openClassSelection: () => this.goToClassManagement()
    }
    selectedClass: ClassData | null;
    constructor(private coreService: CoreService,
        private router: Router,
        private navbarService: NavbarService,
        public dialog: MatDialog) {
        this.selectedClass = this.coreService.selectedClass;
        if (this.selectedClass) {
            this.leftHeaderButtons.push({
                title: this.selectedClass.name,
                event: 'openClassSelection'
            });
        }

        this.filterOutWords.push({
            concept: '51.  Suffix -ed',
            word: 'stampede'
        });

        this.navbarService.updateLeftHeaderBtn(this.leftHeaderButtons);
        this.navbarService.updateRightHeaderBtn(this.rightHeaderButtons);
        let navServiceSub = this.navbarService.headerBtnClicked$.subscribe({
            next: action => this.onNavigationServiceActionCalled(action)
        });
        this._subscription.push(navServiceSub);

        let routerData = this.router.getCurrentNavigation()?.extras.state as {
            selectedConcept: ConceptMaterial,
            savedConceptMaterial: savedDictationDto
        };
        this.selectedConcept = routerData.selectedConcept;
        this.savedDictations = routerData.savedConceptMaterial;

        this.descriptionControl = new UntypedFormControl('', [Validators.required, Validators.maxLength(40)]);
        let descChangeSub = this.descriptionControl.valueChanges.subscribe({
            next: (value: string) => {
                if (this.descriptionControl.errors?.['maxlength']) {
                    this.descriptionControl.setValue(value.substring(0, 40));
                }
            }
        });
        this._subscription.push(descChangeSub);

        this.conceptFilterControl = new UntypedFormControl('all');
        let conceptFilterSub = this.conceptFilterControl.valueChanges.subscribe({
            next: (value: string) => {
                this.conceptWordSelected = [];
                this.conceptWordSelected.push({ isRemoved: false });
                this.conceptSentenceSelected = [];
                this.conceptSentenceSelected.push({ cupsOptions: 'CUPS' });
                if (value === 'all') {
                    this.visualWordsClueListFiltered = this.visualWordsClueList;
                    this.visualSentenceClueListFiltered = this.visualSentenceClueList;
                } else {
                    this.visualWordsClueListFiltered = {
                        [value]: this.visualWordsClueList[value]
                    };
                    this.visualSentenceClueListFiltered = {
                        [value]: this.visualSentenceClueList[value]
                    };
                }
            }
        });
        this._subscription.push(conceptFilterSub);
        if (this.savedDictations) {
            this.populateSavedDictationGenral();
        } else {
            this.conceptWordSelected.push({ isRemoved: false });
            this.conceptSentenceSelected.push({ cupsOptions: 'CUPS' });
        }
    }

    ngOnInit(): void {
        let visualWordsClueSub = this.coreService.getVisualCueWord().subscribe({
            next: resp => {
                // let entires = Object.entries(resp);
                // let orderedWordClue: ConceptWordClueDetails = {};
                // entires.filter(d => d[0] === this.selectedConcept.name).forEach(d => orderedWordClue[d[0]] = d[1]);
                // entires.filter(d => d[0] !== this.selectedConcept.name).forEach(d => orderedWordClue[d[0]] = d[1]);
                this.visualWordsClueList = resp;
                this.visualWordsClueListFiltered = { ...this.visualWordsClueList };
                this.filterOutWords.forEach(filterWord => {
                    let wordConcept = this.visualWordsClueListFiltered[filterWord.concept];
                    if (wordConcept?.length) {
                        this.visualWordsClueListFiltered[filterWord.concept] = wordConcept.filter(w => w.word !== filterWord.word);
                    }
                });
                Object.entries(this.visualWordsClueListFiltered).forEach(arr => {
                    this.conceptMaterialList[arr[0]] = arr[1].length;
                });
                if(resp[this.selectedConcept.name]) {
                    this.defaultConceptWord = resp[this.selectedConcept.name][0];
                }
                this.savedDictations && this.populateSavedWords();
            }
        });

        visualWordsClueSub && this._subscription.push(visualWordsClueSub);

        let visualSentenceClueSub = this.coreService.getVisualcueSentence().subscribe({
            next: resp => {
                // let entires = Object.entries(resp);
                // let orderedSentenceClue: ConceptSentenceClueDetails = {};
                // entires.filter(d => d[0] === this.selectedConcept.name).forEach(d => orderedSentenceClue[d[0]] = d[1]);
                // entires.filter(d => d[0] !== this.selectedConcept.name).forEach(d => orderedSentenceClue[d[0]] = d[1]);
                this.visualSentenceClueList = resp;
                this.visualSentenceClueListFiltered = { ...resp };
                if(resp[this.selectedConcept.name]) {
                    this.defaultConceptSentence = resp[this.selectedConcept.name][0];
                }
                this.savedDictations && this.populateSavedSentences();
            }
        });

        visualWordsClueSub && this._subscription.push(visualSentenceClueSub);
    }

    ngOnDestroy(): void {
        this._subscription.forEach(sub => sub?.unsubscribe());
    }

    keepOriginalOrder = (a: any, b: any) => 1;

    populateSavedDictationGenral() {
        if (this.savedDictations.lessonMaterial) {
            this.isLessonConcept = true;
            this.descriptionControl = new UntypedFormControl('');
        } else {
            this.descriptionControl.setValue(this.savedDictations.reportTitle);
        }
        this.savedDictations.pageOrientation && (this.pageOrientation = this.savedDictations.pageOrientation);
        this.savedDictations.visualCueSize && (this.visualCueSize = this.savedDictations.visualCueSize);
    }

    populateSavedWords() {
        this.savedDictations.visualCueWords.forEach(words => {
            let savedWord: wordSelector = { isRemoved: false };
            savedWord.wordLineOption = words.wordLineOption;

            let selectedWordCue = this.visualWordsClueList[words.concept.name]
                .find(w => w.guid === words.visualCueWord.guid);
            savedWord.selectedConceptWordClue = selectedWordCue;
            // filter out words
            savedWord.isRemoved = this.filterOutWords.some(filterWord => filterWord.concept === words.concept.name
                && filterWord.word === savedWord.selectedConceptWordClue?.word);
            const wordIndexCount = 10;
            let chars = [];
            for (let w = 1; w <= wordIndexCount; w++) {
                if (selectedWordCue && selectedWordCue['intVQ' + w]) {
                    let type = '';
                    switch (words['visualCueWordCueType0' + w]) {
                        case 'VISUAL_CUE':
                            type = 'visualCues';
                            break;
                        case 'GREYSCALE':
                            type = 'greyScale';
                            break;
                        case 'SOUND_LINE':
                            type = 'soundLines';
                            break;
                        default:
                            type = 'visualCues';
                    }
                    let char = {
                        type: type,
                        soundLines: selectedWordCue['soundLineQ' + w]?.documentFileName || 'vcSoundLine.png',
                        visualCues: selectedWordCue['intVQ' + w]?.documentFileName || 'vcSoundLine.png',
                        greyScale: selectedWordCue['intGreyQ' + w]?.documentFileName
                    }
                    chars.push(char);
                }
            }
            savedWord.charsOption = chars;
            this.conceptWordSelected.push(savedWord);
        });
        this.conceptWordSelected.push({ isRemoved: false });
        setTimeout(() => window.scroll({ top: 0, left: 0, behavior: 'smooth' }), 2000);
    }

    populateSavedSentences() {
        this.savedDictations.visualCueSentences.forEach(sent => {
            let sentence: {
                sentenceLineOption?: string;
                selectedConceptSentenceClue?: ConceptSentenceClue;
                wordsOption?: {
                    [index: string]: string;
                    type: string;
                    visualCue: string;
                    noCues: string;
                }[];
                cupsOptions: string;
            } = { cupsOptions: 'CUPS', sentenceLineOption: sent.sentenceLineOption };

            let selectedCue: ConceptSentenceClue | undefined;
            for (const [key, value] of Object.entries(this.visualSentenceClueListFiltered)) {
                selectedCue = value.find(s => s.guid === sent.visualCueSentence.guid);
                if (selectedCue) {
                    sentence.selectedConceptSentenceClue = selectedCue;
                    break;
                }
            }

            let words: { type: string; visualCue: any; noCues: string; }[] = [];

            const wordIndexList = ['intVQ1', 'intVQ2', 'intVQ3', 'intVQ4', 'intVQ5', 'intVQ6', 'intVQ7', 'intVQ8', 'intVQ9', 'intVQ10', 'intVQ11', 'intVQ12', 'intVQ13', 'intVQ14', 'intVQ15', 'intVQ16'];

            wordIndexList.forEach(wordIndex => {
                if (selectedCue && selectedCue[wordIndex]) {
                    let word = {
                        type: sent.sentenceLineOption,
                        visualCue: selectedCue[wordIndex].documentFileName || 'vcSentenceWordLine.png',
                        noCues: selectedCue[wordIndex].documentFileName == 'vcSentencePunctuation.png' ? 'spacer.png' : 'vcSentenceWordLine.png'
                    }
                    words.push(word);
                }
            });
            sentence.wordsOption = words;
            this.conceptSentenceSelected.push(sentence);
        });
        this.conceptSentenceSelected.push({ cupsOptions: 'CUPS' });
        // setTimeout(() => window.scroll({ top: 0, left: 0, behavior: 'smooth' }), 2000);
    }

    onNavigationServiceActionCalled(action: string) {
        if (this.functonList[action]) {
            this.functonList[action]();
        }
    }

    gotoToHome() {
        this.router.navigate(['/dashboard']);
    }

    goToClassManagement() {
        this.router.navigate(['class']);
    }

    goToConcept() {
        this.router.navigateByUrl('concept', {
            state: {
                selectedConcept: this.selectedConcept
            }
        });
    }

    goToLessonBuilder() {
        this.router.navigateByUrl('lessonBuilder', {
            state: {
                lessonData: this.savedDictations.lessonMaterial?.lesson
            }
        });
    }

    changeConceptWord(selectedWord: ConceptWordClue, index: number) {
        if (this.conceptWordSelected.length === (index + 1)) {
            this.conceptWordSelected.push({ isRemoved: false });
        }
        let currentCoceptWord = this.conceptWordSelected[index];
        // currentCoceptWord.selectedConceptWordClue = selectedWord;
        currentCoceptWord.wordLineOption = selectedWord.wordLineOption;
        const wordIndexCount = 10;
        let chars = [];
        for (let w = 1; w <= wordIndexCount; w++) {
            if (selectedWord['intVQ' + w]) {
                let char = {
                    type: selectedWord.wordLineOption,
                    soundLines: selectedWord['soundLineQ' + w]?.documentFileName || 'vcSoundLine.png',
                    visualCues: selectedWord['intVQ' + w]?.documentFileName || 'vcSoundLine.png',
                    greyScale: selectedWord['intGreyQ' + w]?.documentFileName
                }
                chars.push(char);
            }
        }
        currentCoceptWord.charsOption = chars;
    }

    changeConceptWordOption(value: string, index: number) {
        if (value !== 'wordLine') {
            let currentCoceptWord = this.conceptWordSelected[index];
            currentCoceptWord.charsOption?.forEach(char => char.type = value);
        }
    }

    removeConceptWord(event: any, index: number) {
        event?.stopPropagation && event.stopPropagation();
        this.conceptWordSelected.splice(index, 1);
        this.coreService.openSnackBar(`Word removed from Position: ${(index + 1)}`, 3000, "warn", "end", "bottom");
    }

    addNewWord(index: number, position: 'above' | 'below') {
        let indexPosition = index + (position === 'above' ? 0 : 1);
        let newEmptyWord: wordSelector = { isRemoved: false };
        this.conceptWordSelected.splice(indexPosition, 0, newEmptyWord);
    }

    changeConceptSentence(selectedSentence: ConceptSentenceClue, index: number) {
        if (this.conceptSentenceSelected.length === (index + 1)) {
            this.conceptSentenceSelected.push({ cupsOptions: 'CUPS' });

            // setTimeout(() => {
            //     console.log(window.screenY);
            //     window.scrollBy({
            //         top: 300,
            //         left: 0,
            //         behavior: 'smooth'
            //     });
            // }, 200);
        }
        let currentConceptSentence = this.conceptSentenceSelected[index];
        // currentConceptSentence.selectedConceptSentenceClue = selectedSentence;
        currentConceptSentence.sentenceLineOption = selectedSentence.sentenceLineOption;
        let words: { type: string; visualCue: any; noCues: string; }[] = [];

        const wordIndexList = ['intVQ1', 'intVQ2', 'intVQ3', 'intVQ4', 'intVQ5', 'intVQ6', 'intVQ7', 'intVQ8', 'intVQ9', 'intVQ10', 'intVQ11', 'intVQ12', 'intVQ13', 'intVQ14', 'intVQ15', 'intVQ16'];

        wordIndexList.forEach(wordIndex => {
            if (selectedSentence[wordIndex]) {
                let word = {
                    type: selectedSentence.sentenceLineOption,
                    visualCue: selectedSentence[wordIndex].documentFileName || 'vcSentenceWordLine.png',
                    noCues: selectedSentence[wordIndex].documentFileName == 'vcSentencePunctuation.png' ? 'spacer.png' : 'vcSentenceWordLine.png'
                }
                words.push(word);
            }
        });

        currentConceptSentence.wordsOption = words;
    }

    changeConceptSentenceOption(value: string, index: number) {
        if (value !== 'sentenceLine') {
            let currentConceptSentence = this.conceptSentenceSelected[index];
            currentConceptSentence.wordsOption?.forEach(word => word.type = value);
        }
    }

    removeConceptSentence(event: any, index: number) {
        event?.stopPropagation && event.stopPropagation();
        this.conceptSentenceSelected.splice(index, 1);
        this.coreService.openSnackBar(`Sentence removed from position: ${(index + 1)}`, 3000, "warn", "end", "bottom");
    }

    addNewSentence(index: number, position: 'above' | 'below') {
        let indexPosition = index + (position === 'above' ? 0 : 1);
        this.conceptSentenceSelected.splice(indexPosition, 0, { cupsOptions: 'CUPS' });
    }

    saveDictation(printPdfType: string | null, copyToConcept: boolean) {
        if (this.descriptionControl.errors) {
            this.descriptionControl.markAsTouched();
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            this.coreService.openSnackBar("Report Title Required", 3000, "warn", "end", "bottom");
            return;
        }
        let sentencesDetailList = this.conceptSentenceSelected.filter(sen => !!sen.selectedConceptSentenceClue);
        let sentenceList = sentencesDetailList.map(sen => sen.selectedConceptSentenceClue?.guid)
        let sentenceRadios = sentencesDetailList.map(sen => sen.sentenceLineOption);

        let wordsDetailList = this.conceptWordSelected.filter(word => !!word.selectedConceptWordClue);
        let wordList = wordsDetailList.map(w => w.selectedConceptWordClue?.guid);
        let wordRadios = wordsDetailList.map(w => w.wordLineOption);
        let cupsOptions = sentencesDetailList.map(sen => "1");

        if (!(sentenceList.length || wordList.length)) {
            this.coreService.openSnackBar("Select a word or Sentence", 3000, "warn", "end", "bottom");
            return;
        }
        let wSLs = wordsDetailList.map(w => {
            let chars = [];
            for (let c = 0; c < 10; c++) {
                if (w.charsOption && w.charsOption[c]) {
                    switch (w.charsOption[c].type) {
                        case 'visualCues':
                            chars.push(0);
                            break;
                        case 'greyScale':
                            chars.push(2);
                            break;
                        case 'soundLines':
                            chars.push(1);
                            break;
                        default:
                            chars.push(0);
                    }
                } else {
                    chars.push(0)
                }
            }
            return chars;
        });

        let dataToSaved: dictationSaveDto = {
            conceptId: this.selectedConcept.guid,
            cupsOptions: cupsOptions,
            reportTitle: this.descriptionControl.value.trim(),
            sentenceList: sentenceList,
            sentenceRadios: sentenceRadios,
            pageOrientation: this.pageOrientation,
            userType: "STUDENT",
            visualCueSize: this.visualCueSize,
            wSLs: wSLs,
            wordList: wordList,
            wordRadios: wordRadios
        };

        this.isSaveInProgress = true;
        let displayMessage = '';
        let UpdateById = copyToConcept ? null : this.savedDictations?.id;
        if (copyToConcept) {
            dataToSaved.reportTitle = ('Copy from ' + this.savedDictations.lessonMaterial.lesson.title).substring(0, 40);
            displayMessage = 'Copied to Concept Material';
            this.saveData(dataToSaved, UpdateById, displayMessage, copyToConcept, printPdfType);
            return;
        } else {
            displayMessage = 'Data ' + (this.savedDictations?.id ? 'Updated' : 'Saved');
        }

        let getSavedConceptsSub = this.coreService.getSavedConceptMaterialByClass().subscribe(
            {
                next: savedConcepts => {
                    let isDuplicateConceptName = savedConcepts.filter(d => d.materialTypeId !== UpdateById)
                        .some(d => d.materialTypeTitle?.toLowerCase().trim() === dataToSaved.reportTitle?.toLowerCase());
                    if (isDuplicateConceptName) {
                        let confirmDialogBox = this.dialog.open(ConfirmDialogBox, {
                            data: {
                                message: `Concept material already exists with the same name. Do you want to save?`,
                                confirmBtnClass: 'blue',
                                confirmBtnLabel: 'Save'
                            },
                            disableClose: true,
                        });
                        let confirmDialogSub = confirmDialogBox.beforeClosed().subscribe({
                            next: data => {
                                if (data) {
                                    this.saveData(dataToSaved, UpdateById, displayMessage, copyToConcept, printPdfType);
                                } else {
                                    this.isSaveInProgress = false;
                                }
                            }
                        });
                        this._subscription.push(confirmDialogSub);
                    } else {
                        this.saveData(dataToSaved, UpdateById, displayMessage, copyToConcept, printPdfType);
                    }
                }
            }
        );
        this._subscription.push(getSavedConceptsSub);

    }

    saveData(dataToSaved: dictationSaveDto, UpdateById: string | null, displayMessage: string, copyToConcept: boolean, printPdfType: string | null) {
        let apiSub = this.coreService.saveDictation(dataToSaved, UpdateById).subscribe({
            next: resp => {
                this.isSaveInProgress = false;
                this.coreService.openSnackBar(displayMessage, 3000, "primary", "end", "bottom");
                if (printPdfType) {
                    this.printPdf(resp.id, printPdfType);
                }
                if (!copyToConcept) {
                    this.savedDictations = resp;
                }
            },
            error: err => {
                this.isSaveInProgress = false;
                console.log(err);
                this.coreService.openSnackBar("Some error occurred", 3000, "warn", "end", "bottom");
            }
        });
        this._subscription.push(apiSub);
    }

    printPdf(id: string, type: string) {
        if (type === 'STUDENT') {
            this.isStudentPdfCreationInProgress = true;
        } else {
            this.isTeacherPdfCreationInProgress = true;
        }
        let apiSub = this.coreService.getDictationPDF(id, type).subscribe({
            next: (data: Blob) => {
                try {
                    this.isStudentPdfCreationInProgress = false;
                    this.isTeacherPdfCreationInProgress = false;
                    this.coreService.openPdf(data, 'Dictation');
                } catch (err) {
                    console.log(err);
                    this.coreService.openSnackBar("Error occurred while opening PDF", 3000, "warn", "end", "bottom");
                }
            },
            error: err => {
                this.isStudentPdfCreationInProgress = false;
                this.isTeacherPdfCreationInProgress = false;
                console.log(err);
                this.coreService.openSnackBar("Some error occurred", 3000, "warn", "end", "bottom");
            }
        });
        this._subscription.push(apiSub);
    }

    openHelper(type: string) {
        this.dialog.open(DictationHelper, {
            data: {
                type: type
            },
            disableClose: true,
            panelClass: ['dialogOverflow']
        })
    }

    scrollToSelectedConcept(toggleData: boolean, type: string, data: any) {
        if (toggleData && !data) {
            let idValue = this.selectedConcept.name;
            let defaultOption = document.getElementById(idValue);
            try {
                defaultOption && defaultOption.scrollIntoView(true);
            } catch (e) {
                console.log(e);
            }
        }
    }
}


interface wordSelector {
    wordLineOption?: string;
    selectedConceptWordClue?: ConceptWordClue;
    charsOption?: {
        [index: string]: string;
        type: string;
        soundLines: string;
        visualCues: string;
        greyScale: string;
    }[];
    isRemoved: boolean;
}