<div class="container">

    <app-dialog-title [title]="title"></app-dialog-title>
    <mat-progress-spinner *ngIf="isApiLoading" mode="indeterminate" diameter="40" class="spinner"></mat-progress-spinner>
    <form class="formClass" *ngIf="!isApiLoading" [formGroup]="inviteFormGroup" autocomplete="off">
        <mat-form-field appearance="outline" class="teachersData">
            <mat-label>Teacher: </mat-label>
            <input matInput placeholder="Search by Teacher name/email" [formControl]="teacherFormControl" [matAutocomplete]="teacherSuggestion">
            <mat-autocomplete #teacherSuggestion="matAutocomplete">
                <mat-option *ngFor="let teacher of teachersListFiltered" [value]="teacher" class="teacherOption">
                    <div class="teacherName">
                        {{teacher.teacherName}}<span class="subTitleInSelect">, {{teacher.teacherEmail}}</span>
                    </div>
                    <div class="subTitleInSelect">
                        {{teacher.school?.schoolName || ""}}
                    </div>
                </mat-option>
            </mat-autocomplete>
            <mat-hint *ngIf="hintMessage" class="hintMessage">{{hintMessage}}</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>User Role:</mat-label>
            <mat-select formControlName="roleControl">
                <mat-option value="GenEd">Gen Ed</mat-option>
                <mat-option value="Interventionist">Interventionist</mat-option>
                <mat-option value="Other">Other</mat-option>
                <mat-option value="SpecEd">Spec. Ed</mat-option>
                <mat-option value="Admin">Admin</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" *ngIf="!this.editStudentData">
            <mat-label>Message:</mat-label>
            <textarea matInput formControlName="notesControl"></textarea>
        </mat-form-field>
        <mat-checkbox [color]="'primary'" formControlName="readOnlyControl">Share as read only</mat-checkbox>
        <div>
            Note: Teacher is required to have IOG 2 subscription
        </div>
    </form>
    <div class="errorMessage">{{errorMessage}}</div>
    <mat-dialog-actions class="btnClass">
        <app-button [content]="'Cancel'" (click)="onCancel()"></app-button>
        <app-button [content]="'Remove Access'" *ngIf="this.editStudentData" [btnClasses]="'lightRed'" (click)="confirmDeleteShare()" [isLoading]="isDeleting" [isDisabled]="isSharing || isDeleting"></app-button>
        <app-button [content]="editStudentData ? 'Update' : 'Share'" (click)="editStudentData ? onUpdateShare() : onShare()" [btnClasses]="'blue'" [isLoading]="isSharing" [isDisabled]="isSharing || isDeleting"></app-button>
    </mat-dialog-actions>
</div>