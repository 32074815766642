import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { filter, Subscription } from 'rxjs';
import { Grades, LevelForUI, Levels } from 'src/app/classes/enums';

import * as _moment from 'moment';
import { CoreService } from 'src/app/core/core.service';


const moment = _moment;
@Component({
    selector: 'edit-student-migrate',
    templateUrl: './edit-student-migrate.component.html',
    styleUrls: ['./edit-student-migrate.component.scss'],
})
export class EditStudentMigrateComponent implements OnInit, OnDestroy {
    private _subscription: Subscription[] = [];
    levelList = Levels;
    studentForm!: FormGroup;
    firstNameControl!: FormControl;
    lastNameControl!: FormControl;
    studentIdControl!: FormControl;
    levelControl!: FormControl;
    classLinkControl!: FormControl<boolean>;
    studentData!: {
        firstName: string;
        lastName: string;
        studentID: string;
        level: string;
        class: string;
        linked: boolean;
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<EditStudentMigrateComponent>,
        private coreService: CoreService) {

        this.studentData = data['studentData'];
        this.firstNameControl = new FormControl(this.studentData.firstName || '');
        this.lastNameControl = new FormControl(this.studentData.lastName || '');
        this.levelControl = new FormControl(LevelForUI(this.studentData.level || 'BR'), Validators.required);
        this.studentIdControl = new FormControl(this.studentData.studentID || '', Validators.required);
        this.classLinkControl = new FormControl<boolean>(this.studentData.linked, { nonNullable: true });
        this.studentForm = new FormGroup({
            firstName: this.firstNameControl,
            lastName: this.lastNameControl,
            studentID: this.studentIdControl,
            level: this.levelControl,
            linked: this.classLinkControl
        });
    }
    ngOnDestroy(): void {
        this._subscription.forEach(sub => sub?.unsubscribe());
    }

    ngOnInit(): void {
    }

    onCancel() {
        this.dialogRef.close(null);
    }

    onUpdate() {
        if (!this.studentForm.valid) {
            this.coreService.openSnackBar("Please fill required values", 3000, "error", "right", "top");
            return;
        }
        this.dialogRef.close(this.studentForm.value);
    }

}