<div class="editStudent">
    <app-dialog-title [title]="'Edit Student Details'"></app-dialog-title>

    <form class="editStudentContainer" [formGroup]="editStudentFormGroup">
        <mat-form-field appearance="fill" class="item first">
            <mat-label>Student ID/Code:</mat-label>
            <input matInput placeholder="" formControlName="studentIdControl">
        </mat-form-field>

        <mat-form-field appearance="fill" class="item">
            <mat-label>Student First Name (Optional):</mat-label>
            <input matInput placeholder="" formControlName="studentFirstNameControl">
        </mat-form-field>
        <mat-form-field appearance="fill" class="item">
            <mat-label>Student Last Name (Optional):</mat-label>
            <input matInput placeholder="" formControlName="studentLastNameControl">
        </mat-form-field>
        <mat-form-field appearance="fill" class="item">
            <mat-label>Reading Level:</mat-label>
            <mat-select formControlName="studentLevelControl">
                <mat-option value="BR">Beginning Reader</mat-option>
                <mat-option value="L1_INIT">Level 1</mat-option>
                <mat-option value="L2_INIT">Level 2</mat-option>
                <mat-option value="L3_INIT">Level 3 and Up</mat-option>
            </mat-select>
        </mat-form-field>
        <div class="sharedUserList" *ngIf="editStudent.sharedUsers.length">
            <span>Shared With:</span>
            <div class="item" *ngFor="let user of editStudent.sharedUsers">
                <div class="userInfo">{{user?.user?.fullName}}
                    <span class="email">{{ user?.user?.email || user.email}}</span>
                </div>
                
                <app-button [content]="'Edit'" [btnClasses]="'blue conceptBtn'" (click)="onEditshare(user)"></app-button>
            </div>
        </div>
    </form>
    <mat-dialog-actions class="btnClass">
        <app-button [content]="'Cancel'" (click)="onCancel()"></app-button>
        <app-button [content]="'Remove'" [btnClasses]="'lightRed'" [isDisabled]="isDeleteApiRunning || isUpdateApiRunning" [isLoading]="isDeleteApiRunning" (click)="onDelete()"></app-button>
        <app-button [content]="'Save'" [btnClasses]="'blue'" [isDisabled]="isDeleteApiRunning || isUpdateApiRunning" [isLoading]="isUpdateApiRunning" (click)="onEditStudent()"></app-button>
    </mat-dialog-actions>
</div>