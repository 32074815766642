import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class NavbarService {

    public headerLeftButtons!: { title: string, event: string }[];
    public headerRightButtons!: { title: string, event: string }[];

    private headerLeftBtnsSubject = new BehaviorSubject<{ title: string, event: string }[] | null>(null);
    headerLeftBtns$ = this.headerLeftBtnsSubject.asObservable();

    private headerBtnClickedSubject = new Subject<string>();
    headerBtnClicked$ = this.headerBtnClickedSubject.asObservable();

    private headerRightBtnsSubject = new BehaviorSubject<{ title: string, event: string }[] | null>(null);
    headerRightBtns$ = this.headerRightBtnsSubject.asObservable();

    updateLeftHeaderBtn(btnList: { title: string, event: string }[] | null) {
        this.headerLeftBtnsSubject.next(btnList);
    }

    onHeaderBtnClicked(action: string) {
        this.headerBtnClickedSubject.next(action);
    }

    updateRightHeaderBtn(btnList: { title: string, event: string }[] | null) {
        this.headerRightBtnsSubject.next(btnList);
    }

}