import { Student } from "./student";

export class ClassData {
   archive!: boolean;
   finalAssessmentDate!: string;
   grade!: string;
   guid!: string;
   id!: string;
   initialAssessmentDate!: string;
   iogVersion!: number;
   level!: string;
   midAssessmentDate!: string;
   name!: string;
   outsideDistrict!: boolean;
   owner = false;
   readOnly!: boolean;
   ysnDefault!: boolean;
   classTerm!: ClassTerm;
   metrics!: {
      classId?: string;
      className?: string;
      studentCount?: number;
      lessonCount?: number;
      conceptCount?: number;
      assessmentCount?: number;
      classArchived?: boolean;
      iogVersion?: number;
   };
   student!: Student[];
}

export interface ClassTerm {
   id: number;
   createDate: Date;
   updateDate: Date;
   name: string;
   startDate: Date;
   endDate: Date;
   note: string;
   districtId: number;
   archived: boolean
}

export class ClassDto {
   classId!: string;
   className!: string;
   grade!: number;
   level!: number;
   initialAssessmentDate!: string;
   midAssessmentDate!: string;
   finalAssessmentDate!: string;
   userId!: string;
   outsideDistrict!: boolean;
   classTermId!: number;
   districtManaged = false;
   constructor(classId: string, className: string, grade: number, level: number,
      initialAssessmentDate: string, midAssessmentDate: string, finalAssessmentDate: string, outsideDistrict: boolean) {
      this.classId = classId;
      this.className = className;
      this.grade = grade;
      this.level = level;
      this.initialAssessmentDate = initialAssessmentDate;
      this.midAssessmentDate = midAssessmentDate;
      this.finalAssessmentDate = finalAssessmentDate;
      this.outsideDistrict = outsideDistrict;
   }
}