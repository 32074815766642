export const literacyComponentList: { label: string, materialType: string }[] = [
    { label: 'Three Part Drill', materialType: 'THREE_PART_DRILL' },
    { label: 'Phonological Awareness', materialType: 'PHONOLOGICAL_AWARENESS' },
    { label: 'New Concept', materialType: 'MULTI_SENSORY' },
    { label: 'Word and Sentence Dictation', materialType: 'DICTATION' },
    { label: 'Red Words', materialType: 'RED_WORDS' },
    { label: 'Decodable Reader', materialType: 'DECODABLE_READER' },
    { label: 'Fluency', materialType: 'FLUENCY' },
    { label: 'Language Comprehension', materialType: 'COMPREHENSION' },
    { label: 'Written Expression', materialType: 'WRITTEN_EXPRESSION' },
    { label: 'Letter Formation', materialType: 'LETTER_FORMATION' },
    { label: 'Practice Reading', materialType: 'PRACTICE_READING' },
    { label: 'Literature Connection', materialType: 'LITERACY_CONNECTIONS' },
    { label: 'Extension Activities', materialType: 'EXTENSION_ACTIVITIES' },
    { label: 'Word Cards', materialType: 'WORD_CARD' },
    { label: 'Closed/Open Door', materialType: 'OPEN_CLOSE_DOOR' },
    { label: 'Syllable Division: Decoding Words', materialType: 'DECODING_WORDS' }
    // 'Add Activity',
    // 'Classroom Games',
    // 'Letter Formation',
];

export interface componentCards {
    id?: string;
    title?: string;
    subtitle?: string;
    description?: {
        title?: string;
        listPoints?: string[][]
    }[];
    materialType: string;
    materialId?: string;
    haveCustomConcept: boolean;
    preMadeUrl?: string;
    isEmpty?: boolean;
    preMadeUrl2?: string;
    preMadeUrl2Content?: string;
}

export const ComponentCardData: {
    [key: string]: {
        [key: string]: componentCards
    }
} = {
    Monday: {
        'THREE_PART_DRILL': {
            title: 'Three Part Drill',
            description: [
                {
                    listPoints: [
                        ['Visual'],
                        ['Auditory/Kinesthetic','Grid (Custom Material button)'],
                        ['Blending'],
                        ['Vowel Intensive']
                    ]
                }
            ],
            materialType: 'THREE_PART_DRILL',
            haveCustomConcept: true
        },
        'PHONOLOGICAL_AWARENESS': {
            title: 'Phonological Awareness',
            subtitle: 'Recommended daily for K-1 or older students if needed.',
            description: [
                {
                    listPoints: [
                        ['Activity in Interventions for All: Phonological Awareness K-2 by Yvette Zgonc'],
                        ['One-Minute Activity in Equipped for Reading Success by David A. Kilpatrick, PhD','Incorporate additional One-Minute Activities 5-10x/Day in various time periods']
                    ]
                }
            ],
            materialType: 'PHONOLOGICAL_AWARENESS',
            haveCustomConcept: false
        },
        'MULTI_SENSORY': {
            title: 'Teaching a New Concept',
            subtitle: 'Multi-Sensory Experience',
            description: [
                {
                    listPoints: [['card'], ['object'], ['literature']]
                }
            ],
            materialType: 'MULTI_SENSORY',
            haveCustomConcept: true
        },
        'DICTATION': {
            title: 'Word and Sentence Dictation',
            subtitle: 'Application of words and sentences',
            description: [
                {
                    listPoints: [['Select words and sentences for dictation from IMSE\'s Comprehensive O-G Plus Teachers Guide']]
                }
            ],
            materialType: 'DICTATION',
            haveCustomConcept: true
        },
        'RED_WORDS': {
            title: 'Red Words',
            subtitle: 'Optional: Review from last week\'s words',
            materialType: 'RED_WORDS',
            haveCustomConcept: true
        },
        'DECODABLE_READER': {
            title: 'Decodable Reader',
            subtitle: 'IMSE Book',
            description: [
                {
                    listPoints: [
                        ['Highlight concept words in green and read'],
                        ['Underline Red words in red and read']
                    ]
                }
            ],
            materialType: 'DECODABLE_READER',
            haveCustomConcept: false,
            preMadeUrl2Content: "Single Page",
        },
        'FLUENCY': {
            title: 'Fluency',
            description: [
                {
                    title: 'Optional:',
                    listPoints: [
                        ['Cold read decodable for repeated reading throughout the week'],
                        ['Rapid word Chart']
                    ]
                }
            ],
            materialType: 'FLUENCY',
            haveCustomConcept: true
        },
        'COMPREHENSION': {
            title: 'Language Comprehension',
            description: [
                {
                    title: 'Read rich literature to students',
                    listPoints: [
                        ['Identify concept words']
                    ]
                },
                {
                    title: 'Vocabulary (3-5 Tier 2 words from decodable reader or rich literature)'
                },
                {
                    title: 'Comprehension',
                    listPoints: [
                        ['Discussion questions']
                    ]
                },
            ],
            materialType: 'COMPREHENSION',
            haveCustomConcept: false
        },
        'WRITTEN_EXPRESSION': {
            title: 'Written Expression',
            description: [
                {
                    listPoints: [
                        ['Daily journal writing or other skills (sentence writing, paragraph writing, etc.)']
                    ]
                }
            ],
            materialType: 'WRITTEN_EXPRESSION',
            haveCustomConcept: false
        },
        'LETTER_FORMATION': {
            title: 'Letter Formation',
            materialType: 'LETTER_FORMATION',
            haveCustomConcept: true
        },
        'PRACTICE_READING': {
            title: 'Practice Reading',
            materialType: 'PRACTICE_READING',
            haveCustomConcept: false,
            // preMadeUrl2: "./assets/pdfs/StudentReadingPassageDirections.pdf", // moved to DB
            preMadeUrl2Content: "Directions"
        },
        'LITERACY_CONNECTIONS': {
            title: 'Literature Connection',
            materialType: 'LITERACY_CONNECTIONS',
            haveCustomConcept: false
        },
        'EXTENSION_ACTIVITIES': {
            title: 'Extension Activities',
            materialType: 'EXTENSION_ACTIVITIES',
            haveCustomConcept: false
        },
        'WORD_CARD': {
            title: 'Word Cards',
            materialType: 'WORD_CARD',
            haveCustomConcept: false
        },
        'OPEN_CLOSE_DOOR': {
            title: 'Closed/Open Door',
            materialType: 'OPEN_CLOSE_DOOR',
            haveCustomConcept: true
        },
        'DECODING_WORDS': {
            title: 'Syllable Division: Decoding Words',
            subtitle: 'Click here to add decoding words to your lesson plan and print a student copy of selected words',
            materialType: 'DECODING_WORDS',
            haveCustomConcept: true
        }
    },
    Tuesday: {
        'THREE_PART_DRILL': {
            title: 'Three Part Drill',
            description: [
                {
                    listPoints: [
                        ['Visual'],
                        ['Auditory/Kinesthetic','Grid (Custom Material button)'],
                        ['Blending'],
                        ['Vowel Intensive']
                    ]
                }
            ],
            materialType: 'THREE_PART_DRILL',
            haveCustomConcept: true
        },
        'PHONOLOGICAL_AWARENESS': {
            title: 'Phonological Awareness',
            subtitle: 'Recommended daily for K-1 or older students if needed.',
            description: [
                {
                    listPoints: [['One-Minute Activity in Equipped for Reading Success by David A. Kilpatrick, PhD','Incorporate additional One-Minute Activities 5-10x/Day in various time periods.']]
                }
            ],
            materialType: 'PHONOLOGICAL_AWARENESS',
            haveCustomConcept: false
        },
        'MULTI_SENSORY': {
            title: 'Teaching a New Concept',
            subtitle: 'Optional Centers:',
            description: [
                {
                    listPoints: [['Practice capital and lowercase letters on house paper.']]
                }
            ],
            materialType: 'MULTI_SENSORY',
            haveCustomConcept: true
        },
        'DICTATION': {
            title: 'Word and Sentence Dictation',
            subtitle: 'Application of words and sentences',
            description: [
                {
                    listPoints: [['Select words and sentences for dictation from IMSE\'s Comprehensive Teacher\'s Guide.']]
                }
            ],
            materialType: 'DICTATION',
            haveCustomConcept: true
        },
        'RED_WORDS': {
            title: 'Red Words',
            subtitle: 'Teach 1-5 New Red Words',
            materialType: 'RED_WORDS',
            haveCustomConcept: true
        },
        'DECODABLE_READER': {
            title: 'Decodable Reader',
            subtitle: 'IMSE Book',
            description: [
                {
                    listPoints: [
                        ['Read pages. Students should read without errors. If there is an error, correct and have them reread the sentence']
                    ]
                }
            ],
            materialType: 'DECODABLE_READER',
            haveCustomConcept: false,
            preMadeUrl2Content: "Single Page",
        },
        'FLUENCY': {
            title: 'Fluency',
            subtitle: 'Rapid Word Charts',
            materialType: 'FLUENCY',
            haveCustomConcept: true
        },
        'COMPREHENSION': {
            title: 'Language Comprehension',
            description: [
                {
                    title: 'Vocabulary (3-5, Tier 2 words from decodable readers or rich literature)'
                },
                {
                    title: 'Comprehension',
                    listPoints: [
                        ['Discussion questions']
                    ]
                },
            ],
            materialType: 'COMPREHENSION',
            haveCustomConcept: false
        },
        'WRITTEN_EXPRESSION': {
            title: 'Written Expression',
            description: [
                {
                    title: 'Activity (10)',
                    listPoints: [
                        ['Select one of the oral language comprehension questions and have students write a written response in their journals']
                    ]
                }
            ],
            materialType: 'WRITTEN_EXPRESSION',
            haveCustomConcept: false
        },
        'LETTER_FORMATION': {
            title: 'Letter Formation',
            materialType: 'LETTER_FORMATION',
            haveCustomConcept: true
        },
        'PRACTICE_READING': {
            title: 'Practice Reading',
            materialType: 'PRACTICE_READING',
            haveCustomConcept: false,
            // preMadeUrl2: "./assets/pdfs/StudentReadingPassageDirections.pdf",
            preMadeUrl2Content: "Directions"
        },
        'LITERACY_CONNECTIONS': {
            title: 'Literature Connection',
            materialType: 'LITERACY_CONNECTIONS',
            haveCustomConcept: false
        },
        'EXTENSION_ACTIVITIES': {
            title: 'Extension Activities',
            materialType: 'EXTENSION_ACTIVITIES',
            haveCustomConcept: false
        },
        'WORD_CARD': {
            title: 'Word Cards',
            materialType: 'WORD_CARD',
            haveCustomConcept: false
        },
        'OPEN_CLOSE_DOOR': {
            title: 'Closed/Open Door',
            materialType: 'OPEN_CLOSE_DOOR',
            haveCustomConcept: true
        },
        'DECODING_WORDS': {
            title: 'Syllable Division: Decoding Words',
            subtitle: 'Click here to add decoding words to your lesson plan and print a student copy of selected words',
            materialType: 'DECODING_WORDS',
            haveCustomConcept: true
        }
    },
    Wednesday: {
        'THREE_PART_DRILL': {
            title: 'Three Part Drill',
            description: [
                {
                    listPoints: [
                        ['Visual'],
                        ['Auditory/Kinesthetic','Grid (Custom Material button)'],
                        ['Blending'],
                        ['Vowel Intensive']
                    ]
                }
            ],
            materialType: 'THREE_PART_DRILL',
            haveCustomConcept: true
        },
        'PHONOLOGICAL_AWARENESS': {
            title: 'Phonological Awareness',
            subtitle: 'Recommended daily for K-1 or older students if needed.',
            description: [
                {
                    listPoints: [
                        ['Activity in Interventions for All: Phonological Awareness K-2 by Yvette Zgonc'],
                        ['One-Minute Activity in Equipped for Reading Success by David A. Kilpatrick, PhD','Incorporate additional One-Minute Activities 5-10x/Day in various time periods']
                    ]
                }
            ],
            materialType: 'PHONOLOGICAL_AWARENESS',
            haveCustomConcept: false
        },
        'MULTI_SENSORY': {
            title: 'Teaching a New Concept',
            subtitle: 'Optional Centers:',
            description: [
                {
                    listPoints: [['Practice letter formation using house paper.']]
                }
            ],
            materialType: 'MULTI_SENSORY',
            haveCustomConcept: true
        },
        'DICTATION': {
            title: 'Word and Sentence Dictation',
            subtitle: 'Application of words and sentences',
            description: [
                {
                    listPoints: [['Select words and sentences for dictation from IMSE\'s Comprehensive Teacher Guide']]
                }
            ],
            materialType: 'DICTATION',
            haveCustomConcept: true
        },
        'RED_WORDS': {
            title: 'Red Words',
            subtitle: 'Review',
            materialType: 'RED_WORDS',
            haveCustomConcept: true
        },
        'DECODABLE_READER': {
            title: 'Decodable Reader',
            subtitle: 'IMSE Book',
            description: [
                {
                    listPoints: [
                        ['Read pages. Students should read without errors. If there is an error, correct and have them reread the sentence']
                    ]
                }
            ],
            materialType: 'DECODABLE_READER',
            haveCustomConcept: false,
            preMadeUrl2Content: "Single Page",
        },
        'FLUENCY': {
            title: 'Fluency',
            description: [
                {
                    title: 'Optional:',
                    listPoints: [
                        ['Rapid Word Charts'],
                        ['IMSE Practice Book'],
                        ['Can assign either of these for homework']
                    ]
                }
            ],
            materialType: 'FLUENCY',
            haveCustomConcept: true
        },
        'COMPREHENSION': {
            title: 'Language Comprehension',
            description: [
                {
                    title: 'Vocabulary (3-5, Tier 2 words from decodable readers or rich literature)'
                },
                {
                    title: 'Comprehension',
                    listPoints: [
                        ['Discussion questions']
                    ]
                },
            ],
            materialType: 'COMPREHENSION',
            haveCustomConcept: false
        },
        'WRITTEN_EXPRESSION': {
            title: 'Written Expression',
            description: [
                {
                    listPoints: [
                        ['Daily journal writing or other skills (sentence writing, paragraph writing, etc.)']
                    ]
                }
            ],
            materialType: 'WRITTEN_EXPRESSION',
            haveCustomConcept: false
        },
        'LETTER_FORMATION': {
            title: 'Letter Formation',
            materialType: 'LETTER_FORMATION',
            haveCustomConcept: true
        },
        'PRACTICE_READING': {
            title: 'Practice Reading',
            materialType: 'PRACTICE_READING',
            haveCustomConcept: false,
            // preMadeUrl2: "./assets/pdfs/StudentReadingPassageDirections.pdf",
            preMadeUrl2Content: "Directions"
        },
        'LITERACY_CONNECTIONS': {
            title: 'Literature Connection',
            materialType: 'LITERACY_CONNECTIONS',
            haveCustomConcept: false
        },
        'EXTENSION_ACTIVITIES': {
            title: 'Extension Activities',
            materialType: 'EXTENSION_ACTIVITIES',
            haveCustomConcept: false
        },
        'WORD_CARD': {
            title: 'Word Cards',
            materialType: 'WORD_CARD',
            haveCustomConcept: false
        },
        'OPEN_CLOSE_DOOR': {
            title: 'Closed/Open Door',
            materialType: 'OPEN_CLOSE_DOOR',
            haveCustomConcept: true
        },
        'DECODING_WORDS': {
            title: 'Syllable Division: Decoding Words',
            subtitle: 'Click here to add decoding words to your lesson plan and print a student copy of selected words',
            materialType: 'DECODING_WORDS',
            haveCustomConcept: true
        }
    },
    Thursday: {
        'THREE_PART_DRILL': {
            title: 'Three Part Drill',
            description: [
                {
                    listPoints: [
                        ['Visual'],
                        ['Auditory/Kinesthetic','Grid (Custom Material button)'],
                        ['Blending'],
                        ['Vowel Intensive']
                    ]
                }
            ],
            materialType: 'THREE_PART_DRILL',
            haveCustomConcept: true
        },
        'PHONOLOGICAL_AWARENESS': {
            title: 'Phonological Awareness',
            subtitle: 'Recommended daily for K-1 or older students if needed.',
            description: [
                {
                    listPoints: [['One-Minute Activity in Equipped for Reading Success by David A. Kilpatrick, PhD','Incorporate additional One-Minute Activities 5-10x/Day in various time periods.']]
                }
            ],
            materialType: 'PHONOLOGICAL_AWARENESS',
            haveCustomConcept: false
        },
        'MULTI_SENSORY': {
            title: 'Teaching a New Concept',
            subtitle: 'Optional Centers:',
            description: [
                {
                    listPoints: [['Review new concept, continue with letter formation practice (e.g., capital letters, cursive)']]
                }
            ],
            materialType: 'MULTI_SENSORY',
            haveCustomConcept: true
        },
        'DICTATION': {
            title: 'Word and Sentence Dictation',
            subtitle: 'Concept and Red Word Practice Test',
            description: [
                {
                    listPoints: [['Select concept words and sentences as well as Red Words from IMSE\'s Comprehensive Teacher\'s Guide']]
                }
            ],
            materialType: 'DICTATION',
            haveCustomConcept: true
        },
        'RED_WORDS': {
            title: 'Red Words',
            subtitle: 'Review',
            materialType: 'RED_WORDS',
            haveCustomConcept: true
        },
        'DECODABLE_READER': {
            title: 'Decodable Reader',
            subtitle: 'IMSE Book',
            description: [
                {
                    listPoints: [
                        ['Read pages. Students should read without errors. If there is an error, correct and have them reread the sentence']
                    ]
                }
            ],
            materialType: 'DECODABLE_READER',
            haveCustomConcept: false,
            preMadeUrl2Content: "Single Page",
        },
        'FLUENCY': {
            title: 'Fluency',
            description: [
                {
                    listPoints: [
                        ['Rapid Word Charts (10)'],
                        ['IMSE Practice Book']
                    ]
                }
            ],
            materialType: 'FLUENCY',
            haveCustomConcept: true
        },
        'COMPREHENSION': {
            title: 'Language Comprehension',
            description: [
                {
                    title: 'Vocabulary (3-5 Tier 2 words from decodable reader or rich literature)'
                },
                {
                    title: 'Comprehension',
                    listPoints: [
                        ['Discussion questions']
                    ]
                },
            ],
            materialType: 'COMPREHENSION',
            haveCustomConcept: false
        },
        'WRITTEN_EXPRESSION': {
            title: 'Written Expression',
            description: [
                {
                    title: 'Activity',
                    listPoints: [
                        ['Incorporate writing activity that correlates with the decodable reader']
                    ]
                }
            ],
            materialType: 'WRITTEN_EXPRESSION',
            haveCustomConcept: false
        },
        'LETTER_FORMATION': {
            title: 'Letter Formation',
            materialType: 'LETTER_FORMATION',
            haveCustomConcept: true
        },
        'PRACTICE_READING': {
            title: 'Practice Reading',
            materialType: 'PRACTICE_READING',
            haveCustomConcept: false,
            // preMadeUrl2: "./assets/pdfs/StudentReadingPassageDirections.pdf",
            preMadeUrl2Content: "Directions"
        },
        'LITERACY_CONNECTIONS': {
            title: 'Literature Connection',
            materialType: 'LITERACY_CONNECTIONS',
            haveCustomConcept: false
        },
        'EXTENSION_ACTIVITIES': {
            title: 'Extension Activities',
            materialType: 'EXTENSION_ACTIVITIES',
            haveCustomConcept: false
        },
        'WORD_CARD': {
            title: 'Word Cards',
            materialType: 'WORD_CARD',
            haveCustomConcept: false
        },
        'OPEN_CLOSE_DOOR': {
            title: 'Closed/Open Door',
            materialType: 'OPEN_CLOSE_DOOR',
            haveCustomConcept: true
        },
        'DECODING_WORDS': {
            title: 'Syllable Division: Decoding Words',
            subtitle: 'Click here to add decoding words to your lesson plan and print a student copy of selected words',
            materialType: 'DECODING_WORDS',
            haveCustomConcept: true
        }
    },
    Friday: {
        'THREE_PART_DRILL': {
            title: 'Three Part Drill',
            description: [
                {
                    listPoints: [
                        ['Visual'],
                        ['Auditory/Kinesthetic','Grid (Custom Material button)'],
                        ['Blending'],
                        ['Vowel Intensive']
                    ]
                }
            ],
            materialType: 'THREE_PART_DRILL',
            haveCustomConcept: true
        },
        'PHONOLOGICAL_AWARENESS': {
            title: 'Phonological Awareness',
            subtitle: 'Recommended daily for K-1 or older students if needed.',
            description: [
                {
                    listPoints: [
                        ['One-Minute Activity in Equipped for Reading Success by David A. Kilpatrick, PhD','Incorporate additional One-Minute Activities 5-10x/Day in various time periods.']
                    ]
                }
            ],
            materialType: 'PHONOLOGICAL_AWARENESS',
            haveCustomConcept: false
        },
        'MULTI_SENSORY': {
            title: 'Teaching a New Concept',
            subtitle: 'Optional Centers:',
            description: [
                {
                    listPoints: [['Practice letter formation using house paper']]
                }
            ],
            materialType: 'MULTI_SENSORY',
            haveCustomConcept: true
        },
        'DICTATION': {
            title: 'Word and Sentence Dictation',
            subtitle: 'Application of words and sentences',
            description: [
                {
                    listPoints: [['Select concept words and sentences as well as Red Words from IMSE\'s Comprehensive Teacher\'s Guide']]
                }
            ],
            materialType: 'DICTATION',
            haveCustomConcept: true
        },
        'RED_WORDS': {
            title: 'Red Words',
            subtitle: 'Assess (see above)',
            materialType: 'RED_WORDS',
            haveCustomConcept: true
        },
        'DECODABLE_READER': {
            title: 'Decodable Reader',
            subtitle: 'IMSE Book',
            description: [
                {
                    listPoints: [
                        ['Use a clean copy without pictures for students to read. Can combine this fluency',
                        'Comprehension Questions']
                    ]
                }
            ],
            materialType: 'DECODABLE_READER',
            haveCustomConcept: false,
            preMadeUrl2Content: "Single Page",
        },
        'FLUENCY': {
            title: 'Fluency',
            subtitle: 'Progress Monitor & Practice',
            description: [
                {
                    title: 'Optional:',
                    listPoints: [
                        ['Read clean copy of decodable reader'],
                        ['Progress Monitor with CBM']
                    ]
                }
            ],
            materialType: 'FLUENCY',
            haveCustomConcept: true
        },
        'COMPREHENSION': {
            title: 'Language Comprehension',
            description: [
                {
                    title: 'Vocabulary (3-5 Tier 2 words from decodable readers or rich literature)',
                    listPoints: [['Assess']]
                },
                {
                    title: 'Comprehension',
                    listPoints: [
                        ['Reread the story for deeper understanding']
                    ]
                },
            ],
            materialType: 'COMPREHENSION',
            haveCustomConcept: false
        },
        'WRITTEN_EXPRESSION': {
            title: 'Written Expression',
            description: [
                {
                    listPoints: [
                        ['Daily journal writing or other skills (sentence writing, paragraph writing, etc.)']
                    ]
                }
            ],
            materialType: 'WRITTEN_EXPRESSION',
            haveCustomConcept: false
        },
        'LETTER_FORMATION': {
            title: 'Letter Formation',
            materialType: 'LETTER_FORMATION',
            haveCustomConcept: true
        },
        'PRACTICE_READING': {
            title: 'Practice Reading',
            materialType: 'PRACTICE_READING',
            haveCustomConcept: false,
            // preMadeUrl2: "./assets/pdfs/StudentReadingPassageDirections.pdf",
            preMadeUrl2Content: "Directions"
        },
        'LITERACY_CONNECTIONS': {
            title: 'Literature Connection',
            materialType: 'LITERACY_CONNECTIONS',
            haveCustomConcept: false
        },
        'EXTENSION_ACTIVITIES': {
            title: 'Extension Activities',
            materialType: 'EXTENSION_ACTIVITIES',
            haveCustomConcept: false
        },
        'WORD_CARD': {
            title: 'Word Cards',
            materialType: 'WORD_CARD',
            haveCustomConcept: false
        },
        'OPEN_CLOSE_DOOR': {
            title: 'Closed/Open Door',
            materialType: 'OPEN_CLOSE_DOOR',
            haveCustomConcept: true
        },
        'DECODING_WORDS': {
            title: 'Syllable Division: Decoding Words',
            subtitle: 'Click here to add decoding words to your lesson plan and print a student copy of selected words',
            materialType: 'DECODING_WORDS',
            haveCustomConcept: true
        }
    }
}