<div class="classMigrateDialog">
    <app-dialog-title title="Edit Class"></app-dialog-title>
    <mat-dialog-content>
        <form class="formClass" [formGroup]="classForm">
            <div class="classInputs">
                <mat-form-field appearance="outline" class="formInput bottomMargin">
                    <mat-label>Class Name (Max 30 Chars)</mat-label>
                    <input matInput placeholder="Please enter class name" [formControl]="classNameControl" id="className">
                    <mat-hint>
                        <span *ngIf="classNameControl.value?.length === 30" class="maxCharReached">
                            Max limit reached
                        </span>
                        <span *ngIf="classNameControl.value?.length != 30" class="charLeft">
                            {{ 30 - (classNameControl.value?.length || 0) }} Chars remaining
                        </span>
                    </mat-hint>
                </mat-form-field>

                <mat-form-field appearance="outline" class="formInput">
                    <mat-label>Grade: </mat-label>
                    <mat-select [formControl]="classGardeControl" placeholder="Choose a grade">
                        <mat-option *ngFor="let grade of gradeList | keyvalue:keepOriginalOrder" [value]="grade.value.key"> {{grade.value.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="formInput">
                    <mat-label>Baseline: </mat-label>
                    <mat-select [formControl]="classBaseLineControl" placeholder="Choose a Baseline">
                        <mat-option [value]="this.levelList['BR'].key">{{this.levelList['BR'].name}}</mat-option>
                        <mat-option [value]="this.levelList['L1_INIT'].key">{{this.levelList['L1_INIT'].name}}</mat-option>
                        <mat-option [value]="this.levelList['L2_INIT'].key">{{this.levelList['L2_INIT'].name}}</mat-option>
                        <mat-option [value]="this.levelList['L3_INIT'].key">{{this.levelList['L3_INIT'].name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </form>
    </mat-dialog-content>
    
    <mat-dialog-actions class="btnClass">
        <app-button content="Cancel" (click)="onCancel()"></app-button>
        <app-button content="Update" [btnClasses]="'blue'" (click)="onUpdate()"></app-button>
    </mat-dialog-actions>
</div>