import { Component, Inject, OnDestroy, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSelectionList } from "@angular/material/list";
import { Subscription } from "rxjs";
import { ClassData } from "src/app/classes/classData";
import { Student } from "src/app/classes/student";
import { CoreService } from "src/app/core/core.service";

@Component({
    selector: 'transfer-student',
    templateUrl: 'transfer-student.component.html',
    styleUrls: ['transfer-student.component.scss']
})
export class TransferStudentDialog implements OnDestroy {

    private _subscription: Subscription[] = [];
    transferStudents: Student[];
    classList!: ClassData[];
    isGetClassApiRunning = false;
    isTransfering = false;
    toClass!: ClassData;
    isTransferComplete = false;
    resultData!: string[][];

    @ViewChild('classSelecion') classSelector!: MatSelectionList;

    constructor(
        public dialogRef: MatDialogRef<TransferStudentDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private coreService: CoreService,
    ) {
        this.transferStudents = data['studentList'];
        this.isGetClassApiRunning = true;
        this.coreService.getAllClassData().subscribe({
            next: resp => {
                this.classList = resp.filter(classinfo => classinfo.guid !== this.coreService.selectedClass?.guid)
                .sort((a, b) => a.name.localeCompare(b.name));
                this.isGetClassApiRunning = false;
            },
            error: err => {
                console.log(err);
                this.coreService.openSnackBar("Error occured while getting Class data", 3000, "error", "right", "top");
                this.isGetClassApiRunning = false;
            }
        });
    }

    ngOnDestroy(): void {
        this._subscription.forEach(sub => sub?.unsubscribe());
    }

    onCancel() {
        this.dialogRef.close(this.isTransferComplete);
    }

    transferStudent() {

        if (!this.classSelector.selectedOptions.selected[0]) {
            this.coreService.openSnackBar("Please select a Class", 3000, "warn", "end", "bottom");
            return;
        }

        this.toClass = this.classSelector.selectedOptions.selected[0].value;
        let transferStudentDto = {
            newClassId: this.toClass.guid,
            oldClassId: this.coreService.selectedClass?.guid,
            studentIds: this.transferStudents.map(stu => stu.guid),
            userId: this.coreService.userData?.id
        };
        this.isTransfering = true;
        let apiSub = this.coreService.transferStudent(transferStudentDto).subscribe({
            next: resp => {
                //[studentGuid, result, studentId]
                this.resultData = resp ;
                // Get StudentId from student guid
                this.resultData.forEach(stu => {
                    let student = this.transferStudents.find(s => s.guid === stu[0]);
                    student && stu.push(student.studentID);
                })
                // this.coreService.openSnackBar("Student(s) Transferred", 3000, "primary", "end", "bottom");
                this.isTransferComplete = true;
                this.isTransfering = false;
            },
            error: err => {
                console.log(err);
                this.isTransfering = false;
                this.coreService.openSnackBar("Error Occured, please try later", 3000, "warn", "end", "bottom");
            }
        });

        this._subscription.push(apiSub);
    }
}