import { ClassData } from "./classData";

export interface ConceptMaterial {
    guid: string;
    core: boolean;
    name: string;
    sort: number;
    letter: boolean;
    version: number;
    char?: string;
    isSelected?: boolean;

}

export interface SavedConceptDetail {
    concept: ConceptMaterial;
    createdDate: string;
    id: string;
    materialType: string;
    materialTypeId: string;
    teacherClass: ClassData;
    materialTypeTitle: string;
    isSelected?: boolean;
}
// Concepts have Custom option
export const ConceptType: { [key: string]: string } = {
    THREE_PART_DRILL: 'Three Part Drill',
    DICTATION: 'Dictation',
    MULTI_SENSORY: 'New Concept',
    FLUENCY: 'Fluency',
    RED_WORDS: 'Red Words',
    LETTER_FORMATION: 'Letter Formation',
    OPEN_CLOSE_DOOR: 'Closed/Open Door',
    DECODING_WORDS: 'Syllable Division: Decoding Words'
}