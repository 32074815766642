<div class="studentMigrateDialog">
    <app-dialog-title title="Edit Student"></app-dialog-title>
    <mat-dialog-content>
        <form class="formClass" [formGroup]="studentForm">
            <mat-form-field appearance="outline" class="inputField">
                <mat-label>Student Id:</mat-label>
                <input matInput placeholder="" [formControl]="studentIdControl">
            </mat-form-field>
            <mat-form-field appearance="outline" class="inputField">
                <mat-label>Student First Name (Optional):</mat-label>
                <input matInput placeholder="" [formControl]="firstNameControl">
            </mat-form-field>
            <mat-form-field appearance="outline" class="inputField">
                <mat-label>Student Last Name (Optional):</mat-label>
                <input matInput placeholder="" [formControl]="lastNameControl">
            </mat-form-field>
            <div class="btnFlex">
                <mat-form-field appearance="outline">
                    <mat-label>Reading Level:</mat-label>
                    <mat-select [formControl]="levelControl">
                        <mat-option value="BR">Beginning Reader</mat-option>
                        <mat-option value="L1_INIT">Level 1</mat-option>
                        <mat-option value="L2_INIT">Level 2</mat-option>
                        <mat-option value="L3_INIT">Level 3 and Up</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-slide-toggle [formControl]="classLinkControl" color="primary"> {{classLinkControl.value ? 'Linked' : 'Unlinked'}} with Class
                    {{studentData.class}}</mat-slide-toggle>
            </div>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions class="btnClass">
        <app-button content="Cancel" (click)="onCancel()"></app-button>
        <app-button content="Update" [btnClasses]="'blue'" (click)="onUpdate()"></app-button>
    </mat-dialog-actions>
</div>