<div class="lesson-container" *ngIf="!isGetSavedApiInProgress">
    <div class="titelCotainer">
        <div class="homeBtn">
            <app-button [content]="'&#x2B05; Classroom Materials'" (click)="goToClassManagement()"></app-button>
        </div>
        <div class="title">
            Lesson Builder
        </div>
        <div class="title sub">
            Concept : {{ selectedConcept.name }}
        </div>
        <div class="timeInfo">
            MTSS guidelines suggest at least 30 minutes per day of instruction
            <img src="./assets/images/icons/help_question_mark_icon.svg" alt="icon" class="icon" matTooltip="Each day will include all the components from the OG Outline. You can add or remove components as needed. Please refer to Guidelines for lessons in IMSE Teacher Guides (K-2) for recommendations for 30 and 90 minute lessons."
                matTooltipPosition="below" matTooltipClass="tool-tip-info max-width" #tooltip="matTooltip" (click)="tooltip.toggle()" />
        </div>
        <div class="tour">
            <span class="link" (click)="openTourDialog($event)">Click here for tutorial walkthrough</span>
        </div>
    </div>

    <!-- <img src="./assets/images/icons/help_question_mark_icon.svg" alt="helpIcon" class="helpIcon" (click)="openTourDialog($event)"> -->
    <main>
        <!-- <button mat-icon-button color="primary" (click)="printLesson()" class="printIcon" onclick="this.blur()">
                <mat-icon>print</mat-icon>
        </button> -->
        <div class="reportTitleCotainer">
            <div class="titleCotainer">
                <mat-form-field appearance="outline" class="titleForm">
                    <mat-label>Title for Lesson (Max 40 Chars)</mat-label>
                    <input matInput placeholder="Short title for your lesson" [formControl]="descriptionControl" id="descriptionControl">
                    <mat-hint class="hintMessage">
                        <div *ngIf="descriptionControl.value?.length === 40" class="maxCharReached">
                            Max limit reached
                        </div>
                        <div *ngIf="descriptionControl.value?.length != 40" class="charLeft">
                            {{ 40 - (descriptionControl.value?.length || 0) }} Chars remaining
                        </div>
                    </mat-hint>
                </mat-form-field>
                <app-button [isDisabled]="selectedClass?.readOnly || false" *ngIf="showUpdateLessonBtn" [content]="'Update'" [btnClasses]="'blue'" class="updateBtn" (click)="updateLessonName()"></app-button>
                <app-button [content]="'Print'" [btnClasses]="'green'" class="updateBtn" (click)="printLesson()"></app-button>
            </div>
        </div>
        <div class="days">
            <ng-container *ngFor="let day of lessonDays">
                <div class="day" [id]="day.name" [ngClass]="[day.day , day.isActive? 'active': '']" matRipple (click)="onDayChange(day.name)">
                    <div class="number">{{day.number}}</div>
                    <div class="dayName">{{day.name}}</div>
                    <!-- <div class="estTime" *ngIf="day.isActive">30 minutes</div> -->
                </div>
            </ng-container>
        </div>
        <div class="content">
            <div class="conceptList">
                <!-- ComponentDisplay -->
                <div cdkDropList (cdkDropListDropped)="dropLessonConceptList($event)">
                    <div *ngIf="addedComponentDisplayList.length <= 0 && isTourOn" class="card">
                        <!-- sample Card for Tour -->
                        <div class="title"> Literacy Component </div>
                        <div class="subtitle"> Time Frame </div>
                        <div class="btnSection">
                            <app-button content="Digital Slides" btnClasses="conceptBtn"></app-button>
                            <app-button content="Custom Material" btnClasses="blue conceptBtn"></app-button>
                        </div>
                    </div>
                    <div class="card" *ngFor="let concept of addedComponentDisplayList;let j=index" cdkDrag cdkDragBoundary=".conceptList" [cdkDragDisabled]="selectedClass?.readOnly || isTourOn">
                        <button mat-icon-button color="warn" class="deleteBtn" (click)="removeComponent(concept)" [disabled]="selectedClass?.readOnly || false">
                            <mat-icon>delete_forever</mat-icon>
                        </button>
                        <div class="drag-handle" cdkDragHandle *ngIf="!selectedClass?.readOnly">
                            <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                              <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                              <path d="M0 0h24v24H0z" fill="none"></path>
                            </svg>
                        </div>
                        <div class="title">{{concept.title}}</div>
                        <div class="subtitle">
                            {{concept.subtitle}}
                        </div>
                        <div>
                            <ng-container *ngFor="let conceptDesc of concept.description">
                                <div class="ListTitle">
                                    {{conceptDesc.title}}
                                    <ul>
                                        <li *ngFor="let points of conceptDesc.listPoints">
                                            <!-- First Element is main, upcoming's are sub items -->
                                            <span *ngIf="points[0]">{{points[0]}}</span>
                                            <ul>
                                                <ng-container *ngFor="let subPoints of points;let l=index">
                                                    <li *ngIf="l!==0">
                                                        {{subPoints}}
                                                    </li>
                                                </ng-container>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </ng-container>
                        </div>
                        <div class="btnSection">
                            <!-- <app-button [isDisabled]="selectedClass?.readOnly || false" content="Remove" btnClasses="whiteRed conceptBtn" (click)="removeComponent(concept)"></app-button> -->
                            <app-button content="Digital Slides" btnClasses="" *ngIf="concept.preMadeUrl" (click)="goToPreMadeUrl(concept)"></app-button>
                            <app-button *ngIf="concept.preMadeUrl && concept.preMadeUrl2" [content]="concept.preMadeUrl2Content || ''" btnClasses="blue" (click)="goToPreMadeUrl2(concept)"></app-button>
                            <app-button content="Custom Material" btnClasses="blue" *ngIf="concept.haveCustomConcept" (click)="goToCustomConcept(concept)"></app-button>
                        </div>
                        <div *ngIf="concept.haveCustomConcept && concept.isEmpty" class="customNote">
                            <b>Note:</b> Has not been customized
                        </div>
                        <span *cdkDragPreview>
                            {{concept.title}}
                        </span>
                    </div>
                </div>
            </div>
            <div class="conceptAddSection" [ngClass]="{'conceptAddSectionForTour': isTourOn}">
                <!-- literacyComponent -->
                <mat-progress-bar mode="query" *ngIf="isGotoConceptInProgress"></mat-progress-bar>
                <div class="title" id="LiteracyComponentTitle">+ Add Literacy Component</div>
                <div class="subtitle">
                    Have more time? Add more literacy components below.
                </div>
                <div class="addConceptBtns">
                    <div *ngFor="let literacyComp of literacyComponentList;let i=index">
                        <app-button [content]=" '+ ' + literacyComp.label" [isDisabled]="selectedClass?.readOnly || literacyComp.isAdded || literacyComp.isAdding" [isLoading]="literacyComp.isAdding" [btnClasses]="'blue w100'" (click)="onAddComponent(i)"></app-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="reorder" *ngIf="isReOrdered">
            <app-button content="Save Updated Order" [btnClasses]="'green'" (click)="saveReOrderedConcepts(true)" [isDisabled]="isReOrderingInProgress" [isLoading]="isReOrderingInProgress"></app-button>
        </div>

    </main>
</div>
<div *ngIf="isGetSavedApiInProgress">
    <mat-progress-bar mode="query"></mat-progress-bar>
</div>