<div class="assessmentContainer">

    <!-- <div class="studetnDetail">
        <div class="toStudentBtn">
            <app-button [content]="'< Back to Student Management'" (click)="backToStudentManagement()"></app-button>
        </div>
        <div class="title">
            <div class="heading">{{selectedClass.name}} Assessment
            </div>
        </div>
    </div> -->
    <div class="titelCotainer">
        <div class="toStudentBtn">
            <app-button [content]="'< Back to Classroom Materials'" (click)="backToClassManagement()"></app-button>
        </div>
        <div class="title">
            {{selectedClass.name}} Assessment
        </div>
    </div>
    <div class="tour">
        <span class="link" (click)="openTourDialog($event)">Click here for tutorial walkthrough</span>
    </div>
    <div *ngIf="getStudentApiRunning" class="loader">
        <mat-progress-spinner class="spinner" mode="indeterminate" diameter="30"></mat-progress-spinner>
    </div>
    <div class="addStudent" *ngIf="!getStudentApiRunning">
        <div class="studentBtn" *ngIf="canAddStudent && studentsDaList">
            <app-button [isDisabled]="selectedClass?.readOnly || false" [content]="'Add New Student' + (userData?.districtManaged && canAddStudent ? ' / Assign to Class' : '')" [btnClasses]="'blue'" [iconPath]="addStudentIcon" (click)="AddStudentIcon()" id="addStudentBtn"></app-button>
        </div>
        <ng-container *ngIf="studentsDaList == null">
            <div class="studentBtn error">
                Error while getting Students list
            </div>
        </ng-container>
        <form class="addStudentContainer" *ngIf="isAddStudentExpanded" [formGroup]="addStudentFormGroup" autocomplete="off">
            <mat-form-field appearance="fill" class="item" *ngIf="!userData?.districtManaged">
                <mat-label>Student ID/Code:</mat-label>
                <input matInput placeholder="" formControlName="studentIdControl">
            </mat-form-field>
            <mat-form-field [appearance]="'fill'" class="item" floatLabel="auto" *ngIf="userData?.districtManaged">
                <mat-label>Student Id / Code</mat-label>
                <input matInput [formControl]="studentIdControl" [matAutocomplete]="studentIdSuggestion">
                <mat-autocomplete #studentIdSuggestion="matAutocomplete">
                    <mat-option *ngFor="let student of studentsDaFliterList" [value]="student">
                        {{student.studentId}} - {{student.firstName}} {{student.lastName}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field appearance="fill" class="item">
                <mat-label>Student First Name (Optional):</mat-label>
                <input matInput placeholder="" formControlName="studentFirstNameControl">
            </mat-form-field>
            <mat-form-field appearance="fill" class="item">
                <mat-label>Student Last Name (Optional):</mat-label>
                <input matInput placeholder="" formControlName="studentLastNameControl">
            </mat-form-field>
            <div class="flexBreak"></div>
            <mat-form-field appearance="fill" class="item">
                <mat-label>Reading Level:</mat-label>
                <mat-select formControlName="studentLevelControl">
                    <mat-option value="BR">Beginning Reader</mat-option>
                    <mat-option value="L1_INIT">Level 1</mat-option>
                    <mat-option value="L2_INIT">Level 2</mat-option>
                    <mat-option value="L3_INIT">Level 3 and Up</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="item">
                <mat-progress-bar *ngIf="isAddStudentInProgress" mode="indeterminate"></mat-progress-bar>
                <app-button *ngIf="!isAddStudentInProgress" [content]="studentData ? 'Assign to Class' : 'Add and Save Student'" [btnClasses]="'blue'" (click)="onAddStudent()"></app-button>
            </div>
        </form>
    </div>
    <div class="assessmentDetails">
        <div class="AssessmentHeading">
            Assessment Dates:
            <img src="./assets/images/icons/help_question_mark_icon.svg" alt="icon" class="icon" matTooltip="These are your planned assessment dates. They can modified by editing your class in Classroom Materials." matTooltipPosition="above" matTooltipClass="tool-tip-info"
                #tooltip="matTooltip" (click)="tooltip.toggle()" />
        </div>
        <div class="assessmentInfo">
            <div class="assessmentDates">
                <p>Initial :</p>
                <p>{{selectedClass.initialAssessmentDate | date: 'MM-dd-yyyy'}}</p>
            </div>
            <div class="assessmentDates">
                <p>Mid :</p>
                <p>{{selectedClass.midAssessmentDate | date: 'MM-dd-yyyy'}}</p>
            </div>
            <div class="assessmentDates">
                <p>Final :</p>
                <p>{{selectedClass.finalAssessmentDate | date: 'MM-dd-yyyy'}}</p>
            </div>
        </div>
    </div>
    <div class="mat-elevation-z4" id="studentListTable">
        <div class="tableInfo">
            <div class="heading">Student(s) List</div>
        </div>
        <mat-progress-bar mode="query" *ngIf="isGetStudentLoading"></mat-progress-bar>
        <div class="searchBar" *ngIf="studentList?.length">
            <mat-form-field appearance="outline" floatLabel="auto">
                <mat-label>Search by typing student name or ID...</mat-label>
                <button mat-icon-button matPrefix>
                    <mat-icon>search</mat-icon>
                  </button>
                <input matInput [formControl]="searchStudentControl" id="searchStudentControl">
            </mat-form-field>
        </div>
        <div class="tableContainer">
            <table *ngIf="studentListFiltered" mat-table [dataSource]="studentListFiltered" class="table">
                <!-- Student Column -->
                <ng-container matColumnDef="student">
                    <th mat-header-cell *matHeaderCellDef> ID - Name
                    </th>
                    <td mat-cell *matCellDef="let student">
                        {{student.studentID}} {{ (student.firstName || '') + ' ' + (student.lastName || '') }}
                    </td>
                </ng-container>

                <!-- Edit Column -->
                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let student">
                        <button mat-icon-button color="primary" (click)="editStudent(student)" *ngIf="!selectedClass?.readOnly">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <!-- Level Column -->
                <!-- <ng-container matColumnDef="level">
                    <th mat-header-cell *matHeaderCellDef> Level </th>
                    <td mat-cell *matCellDef="let student"> {{student.level}} </td>
                </ng-container> -->

                <!-- Assessment Level Column -->
                <ng-container matColumnDef="assessLevel">
                    <th mat-header-cell *matHeaderCellDef> Assessment </th>
                    <td mat-cell *matCellDef="let student" [ngClass]="{ 'reading-assessment' : !student.assessmentLevel }"> {{ student.assessmentLevel || studentLevels[student.level]?.uiName || ''}} </td>
                </ng-container>

                <!-- Assessment Level Column -->
                <ng-container matColumnDef="assessDate">
                    <th mat-header-cell *matHeaderCellDef> Date </th>
                    <td mat-cell *matCellDef="let student"> {{student.assessmentDate | date: 'MM-dd-yyyy'}} </td>
                </ng-container>

                <!-- Assessment Level Column -->
                <ng-container matColumnDef="AssessScore">
                    <th mat-header-cell *matHeaderCellDef class="sort-header" (click)="sortStudentByScore()"> Score
                        <ng-container [ngSwitch]="sortType">
                            <img *ngSwitchCase="'none'" src="./assets/images/icons/sort_unselected.png" alt="sort_icon" class="sortIcon unselected"/>
                            <img *ngSwitchDefault src="./assets/images/icons/sort_icon.png" alt="sort_icon" class="sortIcon" [ngClass]="sortType"/>
                        </ng-container>
                        
                    </th>
                    <td mat-cell *matCellDef="let student"> {{student.avgScore}} </td>
                </ng-container>

                <!-- Recommendation Column -->
                <!-- <ng-container matColumnDef="recommendation">
                <th mat-header-cell *matHeaderCellDef> Recommendation </th>
                <td mat-cell *matCellDef="let student" class="editBtns">
                    <app-button [content]="'Teach New Concepts >'" [btnClasses]="'blue conceptBtn'"></app-button>
                </td>
            </ng-container> -->

                <!-- Assess Column -->
                <ng-container matColumnDef="assess">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let student" class="assessBtn">
                        <app-button [content]="student.readOnly ? 'View' : 'Assess >'" [btnClasses]="(student.readOnly ? 'green ' : 'blue ') + 'conceptBtn singleBtn'" (click)="goToAssessmentApp(student)"></app-button>
                    </td>
                </ng-container>

                <!-- Share Column -->
                <ng-container matColumnDef="share">
                    <th mat-header-cell *matHeaderCellDef class="shareBtn"> Shared </th>
                    <td mat-cell *matCellDef="let student" class="shareBtn">
                        <span *ngIf="student.sharedUsers.length">
                            <img src="./assets/images/icons/shareIcon.svg" alt="icon" class="icon" [matTooltip]="student.sharedUserInfo"
                                matTooltipPosition="right" matTooltipClass="tool-tip-info" />
                        </span>
                    </td>
                </ng-container>

                <!-- Select Column -->
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef class="selectAllBtn">
                        <mat-checkbox [color]="'primary'" class="checkbox" (change)="selectAllStudent($event.checked)"></mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let student" class="selectAllBtn">
                        <mat-checkbox [color]="'primary'" class="checkbox" [(ngModel)]="student.selected" (change)="studentSelected($event)" class="studentSelector"></mat-checkbox>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
            </table>
        </div>

        <div class="studentShareBtns">
            <app-button [content]="'Analyze, Metrics & Reporting >'" [btnClasses]="'green'" (click)="goToAssessmentRepotingApp()" id="metricsBtn"></app-button>
            <app-button *ngIf="canShareStudent" [content]="'Share Selected'" class="shareStudent" [isDisabled]="selectedClass?.readOnly || false" [btnClasses]="'blue'" (click)="shareStudent()" id="shareBtn"></app-button>
            <app-button *ngIf="!userData?.districtManaged" [isDisabled]="selectedClass?.readOnly || false" [content]="'Transfer Selected'" [btnClasses]="'blue'" (click)="transferStudent()" id="transferBtn"></app-button>
        </div>
    </div>
</div>