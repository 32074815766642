import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ClassData } from "src/app/classes/classData";
import { ConceptMaterial } from "src/app/classes/concept";
import { houseWords, savedSyllableOpenClose, syllableOpenCloseSaveDto } from "src/app/classes/Concepts/syllableOpenClose";
import { CoreService } from "src/app/core/core.service";
import { NavbarService } from "src/app/layout/app-header/navbar.service";
import { ConfirmDialogBox } from "../../dialog-box/confirm-dialog/confirm-dialog.component";

@Component({
    selector: 'app-syllable-open-close',
    templateUrl: 'syllable-open-close.component.html',
    styleUrls: ['syllable-open-close.component.scss']
})
export class SyllableOpenCloseComponent implements OnInit, OnDestroy {
    selectedConcept!: ConceptMaterial;
    _subscription: Subscription[] = [];
    isApiLoading = true;
    isSaveInProgress = false;
    isSavePrintInProgress = false;
    descriptionControl: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.maxLength(40)]);
    isLessonConcept = false;
    leftHeaderButtons: { title: string, event: string }[] = [{
        title: 'Back to Home',
        event: 'homeButton'
    }];

    // rightHeaderButtons: { title: string, event: string }[] = [{
    //     title: 'Saved Materials',
    //     event: 'savedLessonConcept'
    // }];

    rightHeaderButtons: { title: string, event: string }[] = [];

    functonList: { [name: string]: Function } = {
        homeButton: () => this.gotoToHome(),
        openClassSelection: () => this.goToClassManagement()
    }
    selectedClass: ClassData | null;
    savedOpenClose!: savedSyllableOpenClose;
    wordsList: houseWords[] = [];
    includeInLessonPlan1: UntypedFormControl = new UntypedFormControl(false);
    includeInLessonPlan2: UntypedFormControl = new UntypedFormControl(false);
    topHouse1Word = new UntypedFormControl();
    bottomHouse1Word = new UntypedFormControl();
    topHouse2Word = new UntypedFormControl();
    bottomHouse2Word = new UntypedFormControl();

    constructor(private coreService: CoreService,
        private router: Router,
        private navbarService: NavbarService,
        public dialog: MatDialog) {
        this.selectedClass = this.coreService.selectedClass;
        if (this.selectedClass) {
            this.leftHeaderButtons.push({
                title: this.selectedClass.name,
                event: 'openClassSelection'
            });
        }

        this.navbarService.updateLeftHeaderBtn(this.leftHeaderButtons);
        this.navbarService.updateRightHeaderBtn(this.rightHeaderButtons);
        let navServiceSub = this.navbarService.headerBtnClicked$.subscribe({
            next: action => this.onNavigationServiceActionCalled(action)
        });
        this._subscription.push(navServiceSub);

        let routerData = this.router.getCurrentNavigation()?.extras.state as {
            selectedConcept: ConceptMaterial,
            savedConceptMaterial: savedSyllableOpenClose
        };

        this.selectedConcept = routerData.selectedConcept;
        if (routerData.savedConceptMaterial) {
            this.savedOpenClose = routerData.savedConceptMaterial;
            if (this.savedOpenClose.lessonMaterial) {
                this.isLessonConcept = true;
                this.descriptionControl = new UntypedFormControl('');
            }
        }
        let descChangeSub = this.descriptionControl.valueChanges.subscribe({
            next: (value: string) => {
                if (this.descriptionControl.errors?.['maxlength']) {
                    this.descriptionControl.setValue(value.substring(0, 40));
                }
            }
        });
        this._subscription.push(descChangeSub);

        let getHouseApiSub = this.coreService.getHouseWords().subscribe({
            next: resp => {
                this.wordsList = [...resp].sort((a, b) => a.word.localeCompare(b.word));
                this.isApiLoading = false;
                this.savedOpenClose && this.updateSavedData();
            },
            error: err => {
                console.log(err);
                this.isApiLoading = false;
                this.coreService.openSnackBar("Error getting House Words List", 3000, "warn", "end", "bottom");
            }
        });
        this._subscription.push(getHouseApiSub);
    }

    ngOnInit(): void {
    }
    ngOnDestroy(): void {
        this._subscription.forEach(sub => sub?.unsubscribe());
    }
    onNavigationServiceActionCalled(action: string) {
        if (this.functonList[action]) {
            this.functonList[action]();
        }
    }

    gotoToHome() {
        this.router.navigate(['/dashboard']);
    }

    goToClassManagement() {
        this.router.navigate(['class']);
    }

    goToConcept() {
        this.router.navigateByUrl('concept', {
            state: {
                selectedConcept: this.selectedConcept
            }
        });
    }

    goToLessonBuilder() {
        this.router.navigateByUrl('lessonBuilder', {
            state: {
                lessonData: this.savedOpenClose.lessonMaterial?.lesson
            }
        });
    }

    updateSavedData() {
        if (!this.savedOpenClose) {
            return;
        }
        this.descriptionControl.setValue(this.savedOpenClose.title || '');
        this.includeInLessonPlan1.setValue(this.savedOpenClose.includeWord1);
        this.includeInLessonPlan2.setValue(this.savedOpenClose.includeWord2);
        this.topHouse1Word.setValue(this.getHouseWords(this.savedOpenClose.topHouseWord1?.guid));
        this.topHouse2Word.setValue(this.getHouseWords(this.savedOpenClose.topHouseWord2?.guid));
        this.bottomHouse1Word.setValue(this.getHouseWords(this.savedOpenClose.bottomHouseWord1?.guid));
        this.bottomHouse2Word.setValue(this.getHouseWords(this.savedOpenClose.bottomHouseWord2?.guid));
    }

    getHouseWords(guid: string): houseWords {
        return this.wordsList?.filter(word => word.guid === guid)[0];
    }

    saveOpenClose(printPdf: boolean, copyToConcept: boolean) {
        if (this.descriptionControl.errors) {
            this.descriptionControl.markAsTouched();
            this.coreService.openSnackBar("Report Title Required", 3000, "warn", "end", "bottom");
            return;
        }
        let toBeSaved: syllableOpenCloseSaveDto = {
            conceptId: this.selectedConcept.guid,
            includeHouse1: this.includeInLessonPlan1.value,
            includeHouse2: this.includeInLessonPlan2.value,
            select_bottom_house_letter1: this.bottomHouse1Word.value?.guid,
            select_bottom_house_letter2: this.bottomHouse2Word.value?.guid,
            select_top_house_letter1: this.topHouse1Word.value?.guid,
            select_top_house_letter2: this.topHouse2Word.value?.guid,
            title: this.descriptionControl.value.trim()
        };

        if (printPdf) {
            this.isSavePrintInProgress = true;
        }
        this.isSaveInProgress = true;
        let displayMessage = '';
        let updateById = copyToConcept ? null : this.savedOpenClose?.id;

        if (copyToConcept) {
            displayMessage = 'Copied to Concept Material';
            toBeSaved.title = ('Copy From ' + this.savedOpenClose.lessonMaterial?.lesson.title).substring(0, 40);
            this.saveData(toBeSaved, updateById, displayMessage, copyToConcept, printPdf);
            return;

        } else {
            displayMessage = 'Data ' + (this.savedOpenClose?.id ? 'Updated' : 'Saved');
        }

        let getSavedConceptsSub = this.coreService.getSavedConceptMaterialByClass().subscribe(
            {
                next: savedConcepts => {
                    let isDuplicateConceptName = savedConcepts.filter(d => d.materialTypeId !== updateById)
                        .some(d => d.materialTypeTitle?.toLowerCase().trim() === toBeSaved.title?.toLowerCase());
                    if (isDuplicateConceptName) {
                        let confirmDialogBox = this.dialog.open(ConfirmDialogBox, {
                            data: {
                                message: `Concept material already exists with the same name. Do you want to save?`,
                                confirmBtnClass: 'blue',
                                confirmBtnLabel: 'Save'
                            },
                            disableClose: true,
                        });
                        let confirmDialogSub = confirmDialogBox.beforeClosed().subscribe({
                            next: data => {
                                if (data) {
                                    this.saveData(toBeSaved, updateById, displayMessage, copyToConcept, printPdf);
                                } else {
                                    this.isSaveInProgress = false;
                                    this.isSavePrintInProgress = false;
                                }
                            }
                        });
                        this._subscription.push(confirmDialogSub);
                    } else {
                        this.saveData(toBeSaved, updateById, displayMessage, copyToConcept, printPdf);
                    }
                }
            }
        );
        this._subscription.push(getSavedConceptsSub);
    }

    saveData(toBeSaved: syllableOpenCloseSaveDto, updateById: string | null, displayMessage: string, copyToConcept: boolean, printPdf: boolean) {
        let saveApiSub = this.coreService.saveSyllableOpenClose(toBeSaved, updateById,).subscribe({
            next: resp => {
                this.coreService.openSnackBar(displayMessage, 3000, "primary", "end", "bottom");
                if (!copyToConcept) {
                    this.savedOpenClose = resp;
                }
                this.isSaveInProgress = false;
                printPdf && this.printPdf();
            },
            error: err => {
                console.log(err);
                this.coreService.openSnackBar("Error occured while saving", 3000, "warn", "end", "bottom");
                this.isSaveInProgress = false;
                this.isSavePrintInProgress = false;
            }
        });
        this._subscription.push(saveApiSub);
    }
    printPdf() {
        let apiSub = this.coreService.getSyllableOpenClosePDF(this.savedOpenClose?.id).subscribe({
            next: (data: Blob) => {
                try {
                    this.isSavePrintInProgress = false;
                    this.coreService.openPdf(data, 'Open/Close');
                } catch (err) {
                    console.log(err);
                    this.isSavePrintInProgress = false;
                    this.coreService.openSnackBar("Error occurred while opening PDF", 3000, "warn", "end", "bottom");
                }
            },
            error: err => {
                this.isSavePrintInProgress = false;
                console.log(err);
                this.coreService.openSnackBar("Some error occurred", 3000, "warn", "end", "bottom");
            }
        });
        this._subscription.push(apiSub);
    }
}