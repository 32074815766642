<div class="three-part-container">
    <div class="titelCotainer">
        <div class="homeBtn">
            <app-button [content]="'&#x2B05; Concept Materials'" (click)="goToConcept()" *ngIf="!isLessonConcept"></app-button>
            <app-button [content]="'&#x2B05; Lesson Builder'" (click)="goToLessonBuilder()" *ngIf="isLessonConcept"></app-button>
        </div>
        <div class="title">
            Three Part Drill - Create Your Own
            <div class="subTitle">
                {{selectedConcept.name}}
            </div>
        </div>
    </div>
    <div class="details-cards">
        <div class="cards card1">
            <p class="subTitle">1. Visual</p>
            <p>T: Flash Cards</p>
            <p>S: State name and sound or just sound</p>
        </div>
        <div class="cards card2">
            <p class="subTitle">2. Auditory</p>
            <p>T: State sound using chart</p>
            <p>S: Repeat, write letter kinesthetically in sand, name letter and sound from left to right.</p>
            <p class="gridInfo">
                Option: Can use screen, green crayon, and grid - Select below to create reproducible grid sheets:
            </p>
            <p class="note">
                NOTE: Only Auditory can be generated and saved
            </p>
        </div>
        <div class="cards card3">
            <p class="subTitle">3. Blending</p>
            <p>T: Tap cards individually (stating sound if needed)</p>
            <p>S: State individual sound and then blend together</p>
            <p class="gridInfo">*** Vowel intensive if needed</p>
            <p class="gridInfo">T: State learned vowel sounds</p>
            <p class="gridInfo">S: Repeat, hold up or mark visual, name letter and sound</p>
        </div>
    </div>

    <div class="auditory">
        
        <mat-form-field appearance="outline" class="description" *ngIf="!isLessonConcept">
            <mat-label>Title for Three Part Drill (Max 40 Chars)</mat-label>
            <input matInput placeholder="Short title for your custom concept" [formControl]="descriptionControl" id="descriptionControl">
            <mat-hint class="hintMessage">
                <div *ngIf="descriptionControl.value?.length === 40" class="maxCharReached">
                    Max limit reached
                </div>
                <div *ngIf="descriptionControl.value?.length != 40" class="charLeft">
                    {{ 40 - (descriptionControl.value?.length || 0) }} Chars remaining
                </div>
            </mat-hint>
        </mat-form-field>
        <div class="gridOptions">
            <mat-radio-group [color]="'primary'" [formControl]="gridSelectionControl" (change)="gridChanged($event)">
                <mat-radio-button value="GRID_4" class="grid">
                    <img src="./assets/images/concept_images/4grid.png" alt="Gridicon" class="gridIcon">
                </mat-radio-button>
                <mat-radio-button value="GRID_6" class="grid">
                    <img src="./assets/images/concept_images/6grid.png" alt="Gridicon" class="gridIcon">
                </mat-radio-button>
                <mat-radio-button value="GRID_8" class="grid">
                    <img src="./assets/images/concept_images/8grid.png" alt="Gridicon" class="gridIcon">
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="previewPane">
        <div class="charSelectionContainer">
            <mat-button-toggle-group name="letterType" aria-label="bottomLetterCaseType" class="letterType toggle-blue" [formControl]="letterTypeControl" (change)="letterTypeChanged($event)">
                <mat-button-toggle value="dn">D'Nealian</mat-button-toggle>
                <mat-button-toggle value="zb">Zaner-Bloser</mat-button-toggle>
            </mat-button-toggle-group>
            <div class="charSelection">
                <mat-form-field appearance="fill">
                    <mat-label>Letter 1</mat-label>
                    <mat-select [formControl]="charControl1" (selectionChange)="charSelectionChange($event.value.char, 'img1')">
                        <mat-option *ngFor="let char of charSelectionList" [value]="char">{{char.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Letter 2</mat-label>
                    <mat-select [formControl]="charControl2" (selectionChange)="charSelectionChange($event.value.char, 'img2')">
                        <mat-option *ngFor="let char of charSelectionList" [value]="char">{{char.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Letter 3</mat-label>
                    <mat-select [formControl]="charControl3" (selectionChange)="charSelectionChange($event.value.char, 'img3')">
                        <mat-option *ngFor="let char of charSelectionList" [value]="char">{{char.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Letter 4</mat-label>
                    <mat-select [formControl]="charControl4" (selectionChange)="charSelectionChange($event.value.char, 'img4')">
                        <mat-option *ngFor="let char of charSelectionList" [value]="char">{{char.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" *ngIf="gridSelectionControl.value == 'GRID_6' || gridSelectionControl.value == 'GRID_8'">
                    <mat-label>Letter 5</mat-label>
                    <mat-select [formControl]="charControl5" (selectionChange)="charSelectionChange($event.value.char, 'img5')">
                        <mat-option *ngFor="let char of charSelectionList" [value]="char">{{char.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" *ngIf="gridSelectionControl.value == 'GRID_6' || gridSelectionControl.value == 'GRID_8'">
                    <mat-label>Letter 6</mat-label>
                    <mat-select [formControl]="charControl6" (selectionChange)="charSelectionChange($event.value.char, 'img6')">
                        <mat-option *ngFor="let char of charSelectionList" [value]="char">{{char.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" *ngIf="gridSelectionControl.value == 'GRID_8'">
                    <mat-label>Letter 7</mat-label>
                    <mat-select [formControl]="charControl7" (selectionChange)="charSelectionChange($event.value.char, 'img7')">
                        <mat-option *ngFor="let char of charSelectionList" [value]="char">{{char.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" *ngIf="gridSelectionControl.value == 'GRID_8'">
                    <mat-label>Letter 8</mat-label>
                    <mat-select [formControl]="charControl8" (selectionChange)="charSelectionChange($event.value.char, 'img8')">
                        <mat-option *ngFor="let char of charSelectionList" [value]="char">{{char.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="gridImgContainer">
            <img [src]="gridPreviewImgSrc" alt="Gridimg" class="gridImg">
            <div class="footer">
                <div>Teacher: {{userName}}</div>
                <div> {{ todayDate | date}} </div>
            </div>
            <div [ngClass]="gridSelectionControl.value">
                <img *ngIf="this.charImgsSrc['img1']" [src]="this.charImgsSrc['img1']" alt="charImg" class="charImg char1">
                <img *ngIf="this.charImgsSrc['img2']" [src]="this.charImgsSrc['img2']" alt="charImg" class="charImg char2">
                <img *ngIf="this.charImgsSrc['img3']" [src]="this.charImgsSrc['img3']" alt="charImg" class="charImg char3">
                <img *ngIf="this.charImgsSrc['img4']" [src]="this.charImgsSrc['img4']" alt="charImg" class="charImg char4">
                <img *ngIf="this.charImgsSrc['img5'] && (gridSelectionControl.value == 'GRID_6' || gridSelectionControl.value == 'GRID_8')" [src]="this.charImgsSrc['img5']" alt="charImg" class="charImg char5">
                <img *ngIf="this.charImgsSrc['img6'] && (gridSelectionControl.value == 'GRID_6' || gridSelectionControl.value == 'GRID_8')" [src]="this.charImgsSrc['img6']" alt="charImg" class="charImg char6">
                <img *ngIf="this.charImgsSrc['img7'] && gridSelectionControl.value == 'GRID_8'" [src]="this.charImgsSrc['img7']" alt="charImg" class="charImg char7">
                <img *ngIf="this.charImgsSrc['img8'] && gridSelectionControl.value == 'GRID_8'" [src]="this.charImgsSrc['img8']" alt="charImg" class="charImg char8">
            </div>
        </div>
    </div>
    <div class="footerBtn">
        <app-button [content]="(!savedConceptMaterial ? 'Save ' : 'Update ')+ (isLessonConcept? 'Lesson' :  'Concept')+ ' Material'" [btnClasses]="'blue'" [isLoading]="isSaveInProgress" [isDisabled]="selectedClass?.readOnly || isSaveInProgress || isPdfCreationInProgress"
            (click)="saveThreePartDrill(false, false)"></app-button>
        <app-button [content]="(!savedConceptMaterial ? 'Save ' : 'Update ')+ 'and Print PDF'" [btnClasses]="'blue'" [isDisabled]="selectedClass?.readOnly || isSaveInProgress || isPdfCreationInProgress" [isLoading]="isPdfCreationInProgress" (click)="saveThreePartDrill(false, true)"></app-button>
        <!-- <app-button *ngIf="isLessonConcept" [content]="'Copy to Concept Material'" [btnClasses]="'blue'" [isLoading]="isSaveInProgress" [isDisabled]="selectedClass?.readOnly || isSaveInProgress" (click)="saveThreePartDrill(true, false)"></app-button> -->
    </div>
</div>