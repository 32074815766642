import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ClassData } from "src/app/classes/classData";
import { ConceptMaterial } from "src/app/classes/concept";
import { decodeWord, savedDecodeWord, saveDecodeWordDto } from "src/app/classes/Concepts/syllableDecodeWord";
import { LessonData } from "src/app/classes/lessonData";
import { CoreService } from "src/app/core/core.service";
import { NavbarService } from "src/app/layout/app-header/navbar.service";
import { ConfirmDialogBox } from "../../dialog-box/confirm-dialog/confirm-dialog.component";

@Component({
    selector: 'app-syllable-decoding-words',
    templateUrl: 'syllable-decoding-words.component.html',
    styleUrls: ['syllable-decoding-words.component.scss']
})
export class SyllableDecodingWordsComponent implements OnInit, OnDestroy {
    selectedConcept!: ConceptMaterial;
    _subscription: Subscription[] = [];
    descriptionControl: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.maxLength(40)]);
    isLessonConcept = false;
    leftHeaderButtons: { title: string, event: string }[] = [{
        title: 'Back to Home',
        event: 'homeButton'
    }];

    // rightHeaderButtons: { title: string, event: string }[] = [{
    //     title: 'Saved Materials',
    //     event: 'savedLessonConcept'
    // }];

    rightHeaderButtons: { title: string, event: string }[] = [];

    functonList: { [name: string]: Function } = {
        homeButton: () => this.gotoToHome(),
        openClassSelection: () => this.goToClassManagement()
    }
    selectedClass: ClassData | null;
    wordSearchControl: UntypedFormControl = new UntypedFormControl('');
    newDecodeWordControl: UntypedFormControl = new UntypedFormControl('');
    wordsPerPage: UntypedFormControl = new UntypedFormControl("3");
    wordsList!: decodeWord[];
    wordsListFiltered!: decodeWord[];
    savedWordsList: decodeWord[] = [];
    savedDecodeData!: savedDecodeWord[];
    savedLessonData!: LessonData;
    updateById: string | null = null;
    isApiLoading = true;
    isSaveInProgress = false;
    isSavePrintInProgress = false;
    dropSavedWordList(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.savedWordsList, event.previousIndex, event.currentIndex);
    }

    constructor(private coreService: CoreService,
        private router: Router,
        private navbarService: NavbarService,
        public dialog: MatDialog) {
        this.selectedClass = this.coreService.selectedClass;
        if (this.selectedClass) {
            this.leftHeaderButtons.push({
                title: this.selectedClass.name,
                event: 'openClassSelection'
            });
        }

        this.navbarService.updateLeftHeaderBtn(this.leftHeaderButtons);
        this.navbarService.updateRightHeaderBtn(this.rightHeaderButtons);
        let navServiceSub = this.navbarService.headerBtnClicked$.subscribe({
            next: action => this.onNavigationServiceActionCalled(action)
        });
        this._subscription.push(navServiceSub);

        let routerData = this.router.getCurrentNavigation()?.extras.state as {
            selectedConcept: ConceptMaterial,
            savedConceptMaterial: savedDecodeWord[],
            savedLessonData: LessonData,
            updateById: string
        };

        this.selectedConcept = routerData.selectedConcept;
        let descChangeSub = this.descriptionControl.valueChanges.subscribe({
            next: (value: string) => {
                if (this.descriptionControl.errors?.['maxlength']) {
                    this.descriptionControl.setValue(value.substring(0, 40));
                }
            }
        });
        this._subscription.push(descChangeSub);

        if (routerData.savedConceptMaterial) {
            this.savedDecodeData = routerData.savedConceptMaterial;
            if (routerData.savedLessonData) {
                this.savedLessonData = routerData.savedLessonData;
                this.isLessonConcept = true;
                this.descriptionControl = new UntypedFormControl('');
            }
            this.updateSavedData();
        }
        if (routerData.updateById) {
            this.updateById = routerData.updateById;
        }

        let wordSearchSub = this.wordSearchControl.valueChanges.subscribe({
            next: (value: string) => {
                if (!this.wordsList?.length) {
                    return;
                }
                if (!value.trim()) {
                    this.wordsListFiltered = [...this.wordsList];
                    return;
                }
                this.wordsListFiltered = [
                    ...this.wordsList.filter(w => {
                        return w.word.trim().toLowerCase().indexOf(value.trim().toLowerCase()) >= 0 ||
                            (w.concept && w.concept.name.toLowerCase().indexOf(value.trim().toLowerCase()) >= 0)
                    })
                ];
            }
        });
        this._subscription.push(wordSearchSub);
        this.getDecodeWords();
    }

    ngOnInit(): void {
    }
    ngOnDestroy(): void {
        this._subscription.forEach(sub => sub?.unsubscribe());
    }
    onNavigationServiceActionCalled(action: string) {
        if (this.functonList[action]) {
            this.functonList[action]();
        }
    }

    gotoToHome() {
        this.router.navigate(['/dashboard']);
    }

    goToClassManagement() {
        this.router.navigate(['class']);
    }

    goToConcept() {
        this.router.navigateByUrl('concept', {
            state: {
                selectedConcept: this.selectedConcept
            }
        });
    }

    goToLessonBuilder() {
        this.router.navigateByUrl('lessonBuilder', {
            state: {
                lessonData: this.savedLessonData
            }
        });
    }

    getDecodeWords() {
        this.isApiLoading = true;
        let getDecodeSub = this.coreService.getDecodeWords().subscribe({
            next: resp => {
                this.wordsList = resp;
                this.wordsListFiltered = [...this.wordsList];
                this.isApiLoading = false;
            },
            error: err => {
                console.log(err);
                this.isApiLoading = false;
                this.coreService.openSnackBar("Error getting House Words List", 3000, "warn", "end", "bottom");
            }
        });
        this._subscription.push(getDecodeSub);
    }

    updateSavedData() {
        this.savedWordsList = [];
        this.savedDecodeData?.forEach(w => {
            let newDecodeWord: decodeWord = {
                guid: w.decodeWord.guid,
                word: w.decodeWord.word,
                concept: w.decodeWord.concept
            };
            this.savedWordsList.push(newDecodeWord);
            this.updateById = w.classConceptMaterial?.id || w.lessonMaterial?.id || null;
            this.descriptionControl.setValue(w.title || '');
        });
        this.savedDecodeData && this.savedDecodeData[0] && this.wordsPerPage.setValue('' + (this.savedDecodeData[0].wordsPerPage || 3));
    }

    addToSavedWord(word: decodeWord) {
        this.savedWordsList.push(word);
    }

    removeFromSavedWord(index: number) {
        this.savedWordsList.splice(index, 1);
    }

    addCustomDecodeWord(word: string) {
        if (word) {
            let newDecodeWord: decodeWord = {
                guid: "-1",
                word: word
            };
            this.savedWordsList.push(newDecodeWord);
            this.newDecodeWordControl.setValue('');
            // setTimeout(() => {
            //     let newlyAddedWord = document.getElementById("SavedWordsList")?.lastElementChild;
            //     newlyAddedWord?.scrollIntoView();
            // }, 1000);
        }
    }

    saveDecodeWord(printPdf: boolean, copyToConcept: boolean) {
        if (this.descriptionControl.errors) {
            this.descriptionControl.markAsTouched();
            this.coreService.openSnackBar("Report Title Required", 3000, "warn", "end", "bottom");
            return;
        }

        let decodeWordGuidList = this.savedWordsList.map(w => w.guid);
        let decodeWordList = this.savedWordsList.map(w => w.word);

        if (!decodeWordGuidList.length) {
            this.coreService.openSnackBar("Please add a Decode Word", 3000, "warn", "end", "bottom");
            return;
        }
        let toBeSaved: saveDecodeWordDto = {
            conceptId: this.selectedConcept.guid,
            decodeWordGuidList: decodeWordGuidList,
            decodeWordList: decodeWordList,
            title: this.descriptionControl.value.trim(),
            wordsPerPage: this.wordsPerPage.value
        }
        if (printPdf) {
            this.isSavePrintInProgress = true;
        }
        this.isSaveInProgress = true;
        let displayMessage = '';
        let updateBy = copyToConcept ? null : this.updateById;
        let updateFor = this.isLessonConcept ? 'lesson' : 'class'
        if (copyToConcept) {
            displayMessage = 'Copied to Concept Material';
            toBeSaved.title = ('Copy From ' + this.savedLessonData?.title).substring(0, 40);
            this.saveData(toBeSaved, updateBy, updateFor, displayMessage, copyToConcept, printPdf);
            return;
        } else {
            displayMessage = 'Data ' + (this.savedDecodeData?.length ? 'Updated' : 'Saved');
        }
        let getSavedConceptsSub = this.coreService.getSavedConceptMaterialByClass().subscribe(
            {
                next: savedConcepts => {
                    let isDuplicateConceptName = savedConcepts.filter(d => d.materialTypeId !== updateBy)
                        .some(d => d.materialTypeTitle?.toLowerCase().trim() === toBeSaved.title?.toLowerCase());
                    if (isDuplicateConceptName) {
                        let confirmDialogBox = this.dialog.open(ConfirmDialogBox, {
                            data: {
                                message: `Concept material already exists with the same name. Do you want to save?`,
                                confirmBtnClass: 'blue',
                                confirmBtnLabel: 'Save'
                            },
                            disableClose: true,
                        });
                        let confirmDialogSub = confirmDialogBox.beforeClosed().subscribe({
                            next: data => {
                                if (data) {
                                    this.saveData(toBeSaved, updateBy, updateFor, displayMessage, copyToConcept, printPdf);
                                } else {
                                    this.isSaveInProgress = false;
                                    this.isSavePrintInProgress = false;
                                }
                            }
                        });
                        this._subscription.push(confirmDialogSub);
                    } else {
                        this.saveData(toBeSaved, updateBy, updateFor, displayMessage, copyToConcept, printPdf);
                    }
                }
            }
        );
        this._subscription.push(getSavedConceptsSub);

    }

    saveData(toBeSaved: saveDecodeWordDto, updateBy: string | null, updateFor: string, displayMessage: string, copyToConcept: boolean, printPdf: boolean) {
        let saveApiSub = this.coreService.saveSyllableDecodeWord(toBeSaved, updateBy, updateFor).subscribe({
            next: resp => {
                this.coreService.openSnackBar(displayMessage, 3000, "primary", "end", "bottom");
                if (!copyToConcept) {
                    this.savedDecodeData = resp;
                    this.getDecodeWords();
                    this.updateSavedData();
                }
                this.isSaveInProgress = false;
                printPdf && this.printPdf(updateFor);
            },
            error: err => {
                console.log(err);
                this.coreService.openSnackBar("Error occured while saving", 3000, "warn", "end", "bottom");
                this.isSaveInProgress = false;
                this.isSavePrintInProgress = false;
            }
        });
        this._subscription.push(saveApiSub);
    }

    printPdf(type: string) {
        let apiSub = this.coreService.getSyllableDecodeWordPDF(this.updateById, type).subscribe({
            next: (data: Blob) => {
                try {
                    this.isSavePrintInProgress = false;
                    this.coreService.openPdf(data, 'DecodeWords');
                } catch (err) {
                    console.log(err);
                    this.isSavePrintInProgress = false;
                    this.coreService.openSnackBar("Error occurred while opening PDF", 3000, "warn", "end", "bottom");
                }
            },
            error: err => {
                this.isSavePrintInProgress = false;
                console.log(err);
                this.coreService.openSnackBar("Some error occurred", 3000, "warn", "end", "bottom");
            }
        });
        this._subscription.push(apiSub);
    }

    deleteCustomWord(word: decodeWord) {
        let confirmDialogBox = this.dialog.open(ConfirmDialogBox, {
            data: {
                message: `Are you sure you want to delete <b> ${word.word} </b>?`,
                confirmBtnClass: 'lightRed',
                confirmBtnLabel: 'Delete'
            },
            disableClose: true,
        });
        let confirmDialogSub = confirmDialogBox.beforeClosed().subscribe({
            next: data => {
                if (data) {
                    let wordDeleteSub = this.coreService.deleteCustomDecodeWord(word.guid).subscribe({
                        next: resp => {
                            this.getDecodeWords();
                            this.coreService.openSnackBar(`DecodeWord: ${word.word} deleted`, 3000, "warn", "end", "bottom");
                        },
                        error: err => {
                            console.log(err);
                            this.coreService.openSnackBar("Error Occurrd while deleting", 3000, "warn", "end", "bottom");
                        }
                    });
                    this._subscription.push(wordDeleteSub);
                }
            }
        });

        this._subscription.push(confirmDialogSub);
    }
}