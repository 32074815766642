import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ClassData } from "src/app/classes/classData";
import { ConceptMaterial } from "src/app/classes/concept";
import { newConceptSaveDto, SavedNewConceptDto } from "src/app/classes/Concepts/newConcept";
import { CoreService } from "src/app/core/core.service";
import { NavbarService } from "src/app/layout/app-header/navbar.service";
import { ConfirmDialogBox } from "../../dialog-box/confirm-dialog/confirm-dialog.component";

@Component({
    selector: 'app-letter-formation-concept',
    templateUrl: 'app-letter-formation.component.html',
    styleUrls: ['app-letter-formation.component.scss']
})
export class LetterFormationComponent implements OnInit, OnDestroy {
    todayDate = new Date();
    selectedConcept!: ConceptMaterial;
    conceptMaterialList!: ConceptMaterial[]
    _subscription: Subscription[] = [];
    isSaveInProgress = false;
    isSavePrintInProgress = false;

    descriptionControl: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.maxLength(40)]);
    letterFormationType: UntypedFormControl = new UntypedFormControl('largeSmallHouse');

    tobeSaveNewConcept!: newConceptSaveDto;
    savedData!: SavedNewConceptDto;
    // For Large Small Letter
    largeSmallLetterConcept!: UntypedFormControl;
    largeSmallLetterCaseType = 'lower';
    largeSmallLetterType!: UntypedFormControl;
    largeSmallTopLetterImgSrc = '';
    largeSmallBottomLetterImgSrc = '';
    // Font defaulted to zanerbloser
    // For 3 House Letter
    topLetterConcept!: UntypedFormControl;
    topLetterCaseType = 'lower';
    topLetterLetterType!: UntypedFormControl;
    topLetterVisualType = 'BlankHouse';
    topLetterImgSrc = '';

    middleLetterConcept!: UntypedFormControl;
    middleLetterCaseType = 'lower';
    middleLetterLetterType!: UntypedFormControl;
    middleLetterVisualType = 'BlankHouse';
    middleLetterImgSrc = '';

    bottomLetterConcept!: UntypedFormControl;
    bottomLetterCaseType = 'lower';
    bottomLetterLetterType!: UntypedFormControl;
    bottomLetterVisualType = 'BlankHouse';
    bottomLetterImgSrc = '';
    isLessonConcept = false;
    leftHeaderButtons: { title: string, event: string }[] = [{
        title: 'Back to Home',
        event: 'homeButton'
    }];

    // rightHeaderButtons: { title: string, event: string }[] = [{
    //     title: 'Saved Materials',
    //     event: 'savedLessonConcept'
    // }];

    rightHeaderButtons: { title: string, event: string }[] = [];

    functonList: { [name: string]: Function } = {
        homeButton: () => this.gotoToHome(),
        openClassSelection: () => this.goToClassManagement()
    }
    selectedClass: ClassData | null;
    constructor(private coreService: CoreService,
        private router: Router,
        private navbarService: NavbarService,
        public dialog: MatDialog) {
        this.selectedClass = this.coreService.selectedClass;
        if (this.selectedClass) {
            this.leftHeaderButtons.push({
                title: this.selectedClass.name,
                event: 'openClassSelection'
            });
        }

        this.navbarService.updateLeftHeaderBtn(this.leftHeaderButtons);
        this.navbarService.updateRightHeaderBtn(this.rightHeaderButtons);
        let navServiceSub = this.navbarService.headerBtnClicked$.subscribe({
            next: action => this.onNavigationServiceActionCalled(action)
        });
        this._subscription.push(navServiceSub);

        let routerData = this.router.getCurrentNavigation()?.extras.state as {
            selectedConcept: ConceptMaterial,
            conceptMaterialList?: ConceptMaterial[],
            savedConceptMaterial?: SavedNewConceptDto
        };
        this.selectedConcept = routerData.selectedConcept;
        if (routerData.conceptMaterialList) {
            this.conceptMaterialList = routerData.conceptMaterialList.
                filter(data => data.letter).
                map(data => {
                    return {
                        ...data,
                        char: this.fetchCharFromConceptName(data.name)
                    }
                });
            // ConceptMaterial will be sent only when creating new Concept
            this.largeSmallLetterConcept = new UntypedFormControl(this.conceptMaterialList.find(c => c.guid === this.selectedConcept.guid));
            this.largeSmallLetterType = new UntypedFormControl('zb');
            this.topLetterConcept = new UntypedFormControl(this.conceptMaterialList.find(c => c.guid === this.selectedConcept.guid));
            this.topLetterLetterType = new UntypedFormControl('zb');
            this.middleLetterConcept = new UntypedFormControl(this.conceptMaterialList.find(c => c.guid === this.selectedConcept.guid));
            this.middleLetterLetterType = new UntypedFormControl('zb');
            this.bottomLetterConcept = new UntypedFormControl(this.conceptMaterialList.find(c => c.guid === this.selectedConcept.guid));
            this.bottomLetterLetterType = new UntypedFormControl('zb');
            this.onLetterFormationTypeChange('largeSmallHouse');
        }

        if (routerData.savedConceptMaterial) {
            this.savedData = routerData.savedConceptMaterial;
            let getConceptSub = this.coreService.getConceptMaterial().subscribe({
                next: resp => {
                    this.conceptMaterialList = resp.filter(data => data.letter).map(data => {
                        return {
                            ...data,
                            char: this.fetchCharFromConceptName(data.name)
                        }
                    });
                    this.largeSmallLetterConcept = new UntypedFormControl(this.conceptMaterialList.find(c => c.guid === (this.savedData.letterFormationDay1?.letterID1 || this.selectedConcept.guid)));
                    this.largeSmallLetterType = new UntypedFormControl(this.savedData.letterFormationDay1?.font1 ? 'zb' : 'dn');
                    this.topLetterConcept = new UntypedFormControl(this.conceptMaterialList.find(c => c.guid === (this.savedData.letterFormationDay2?.letterID1 || this.selectedConcept.guid)));
                    this.topLetterLetterType = new UntypedFormControl(this.savedData.letterFormationDay2?.font1 ? 'zb' : 'dn');
                    this.middleLetterConcept = new UntypedFormControl(this.conceptMaterialList.find(c => c.guid === (this.savedData.letterFormationDay2?.letterID2 || this.selectedConcept.guid)));
                    this.middleLetterLetterType = new UntypedFormControl(this.savedData.letterFormationDay2?.font2 ? 'zb' : 'dn');
                    this.bottomLetterConcept = new UntypedFormControl(this.conceptMaterialList.find(c => c.guid === (this.savedData.letterFormationDay2?.letterID3 || this.selectedConcept.guid)));
                    this.bottomLetterLetterType = new UntypedFormControl(this.savedData.letterFormationDay2?.font3 ? 'zb' : 'dn');
                    this.populateSavedData();
                    this.onLetterFormationTypeChange('largeSmallHouse');
                }
            });
            this._subscription.push(getConceptSub);
        }

        let descChangeSub = this.descriptionControl.valueChanges.subscribe({
            next: (value: string) => {
                if (this.descriptionControl.errors?.['maxlength']) {
                    this.descriptionControl.setValue(value.substring(0, 40));
                }
            }
        });
        this._subscription.push(descChangeSub);

    }

    ngOnDestroy(): void {
        this._subscription.forEach(sub => sub?.unsubscribe());
    }
    ngOnInit(): void {
    }

    populateSavedData() {
        if (this.savedData.lessonMaterial) {
            this.isLessonConcept = true;
            this.descriptionControl = new UntypedFormControl('');
        } else {
            this.descriptionControl.setValue(this.savedData.reportTitle);
        }

        this.largeSmallLetterCaseType = this.savedData.letterFormationDay1?.case1 ? 'capital' : 'lower';

        this.topLetterCaseType = this.savedData.letterFormationDay2?.case1 ? 'capital' : 'lower';
        this.middleLetterCaseType = this.savedData.letterFormationDay2?.case2 ? 'capital' : 'lower';
        this.bottomLetterCaseType = this.savedData.letterFormationDay2?.case3 ? 'capital' : 'lower';

        this.topLetterVisualType = this.fetchVisualCueName(this.savedData.letterFormationDay2?.letterCueLevelID1);
        this.middleLetterVisualType = this.fetchVisualCueName(this.savedData.letterFormationDay2?.letterCueLevelID2);
        this.bottomLetterVisualType = this.fetchVisualCueName(this.savedData.letterFormationDay2?.letterCueLevelID3);
    }

    fetchCharFromConceptName(name: string) {
        let ch = name.trim().split(/\s+/)[1];
        if (!ch) {
            return ''
        } else if (ch.length == 1) {
            return ch;
        } else if (ch.length == 2) {
            if (ch[0].toLowerCase() == ch[1]) {
                return ch[1];
            } else {
                return ch;
            }
        }
        return '';
    }

    fetchVisualCueName(id: number | undefined) {
        let vcEnum: { [key: number]: string } = {
            0: 'BlankHouse',
            1: 'Letter',
            2: 'LetterDot',
            3: 'LetterDotArrow'
        }
        if (!id) {
            return vcEnum[0];
        }
        return vcEnum[id] || vcEnum[0];
    }

    onNavigationServiceActionCalled(action: string) {
        if (this.functonList[action]) {
            this.functonList[action]();
        }
    }

    gotoToHome() {
        this.router.navigate(['/dashboard']);
    }

    goToClassManagement() {
        this.router.navigate(['class']);
    }

    goToConcept() {
        this.router.navigateByUrl('concept', {
            state: {
                selectedConcept: this.selectedConcept
            }
        });
    }

    goToLessonBuilder() {
        this.router.navigateByUrl('lessonBuilder', {
            state: {
                lessonData: this.savedData.lessonMaterial?.lesson
            }
        });
    }

    onLetterFormationTypeChange(type: string) {
        if (type === 'largeSmallHouse') {
            this.updateLargeSmallImg();
        } else {
            this.update3HouseImg();
        }
    }

    updateLargeSmallImg() {
        if (!this.largeSmallLetterConcept.value) {
            return;
        }
        let letter: string = this.largeSmallLetterConcept.value.char;
        let letterType = this.largeSmallLetterType.value;
        let folderName = this.largeSmallLetterType.value === 'zb' ? 'Zaner' : 'DNealian';
        if (this.largeSmallLetterCaseType === 'capital') {
            this.largeSmallTopLetterImgSrc = `./assets/images/concept_images/NewCustomConcept/Top ${folderName} Capital/${letter.toUpperCase()}-${letterType.toUpperCase()}-CAP.png`;
            this.largeSmallBottomLetterImgSrc = `./assets/images/concept_images/NewCustomConcept/Bottom ${folderName} Capital/${letter.toUpperCase()}-${letterType.toUpperCase()}-CAP.png`;
        } else {
            this.largeSmallTopLetterImgSrc = `./assets/images/concept_images/NewCustomConcept/Top ${folderName} Lower Case/${letter.toLowerCase()}-${letterType}-lower.png`;
            this.largeSmallBottomLetterImgSrc = `./assets/images/concept_images/NewCustomConcept/Bottom ${folderName} Lower Case/${letter.toLowerCase()}-${letterType}-lower.png`;
        }
    }

    update3HouseImg() {
        this.update3HouseTopImg();
        this.update3HouseMiddleImg();
        this.update3HouseBottomImg();
    }

    update3HouseTopImg() {
        if (!this.topLetterConcept.value) {
            return;
        }
        let letter: string = this.topLetterConcept.value.char;
        if (this.topLetterVisualType === 'BlankHouse') {
            this.topLetterImgSrc = './assets/images/concept_images/NewCustomConcept/BlankHouse_LetterFormation.png';
        } else {
            let imgtype = '';
            let letterType = this.topLetterLetterType.value;
            let folderName = this.topLetterLetterType.value === 'zb' ? 'Zaner' : 'DNealian';
            switch (this.topLetterVisualType) {
                case 'Letter':
                    imgtype = this.topLetterCaseType === 'capital' ? `-Solid-${letterType.toUpperCase()}-CAP` : `-solid-${letterType}-lower`;
                    break;
                case 'LetterDot':
                    imgtype = this.topLetterCaseType === 'capital' ? `-Dot-${letterType.toUpperCase()}-CAP` : `-dot-${letterType}-lower`;
                    break;
                case 'LetterDotArrow':
                    imgtype = this.topLetterCaseType === 'capital' ? `-Arrow-${letterType.toUpperCase()}-CAP` : `-arrow-${letterType}-lower`;
                    break;
            }
            if (this.topLetterCaseType === 'capital') {
                this.topLetterImgSrc = `./assets/images/concept_images/NewCustomConcept/${folderName}Capital3house/${letter.toUpperCase()}${imgtype}.png`;
            } else {
                this.topLetterImgSrc = `./assets/images/concept_images/NewCustomConcept/${folderName}Lower3house/${letter.toLowerCase()}${imgtype}.png`;
            }
        }
    }

    update3HouseMiddleImg() {
        if (!this.middleLetterConcept.value) {
            return;
        }
        let letter: string = this.middleLetterConcept.value.char;
        if (this.middleLetterVisualType === 'BlankHouse') {
            this.middleLetterImgSrc = './assets/images/concept_images/NewCustomConcept/BlankHouse_LetterFormation.png';
        } else {
            let imgtype = '';
            let letterType = this.middleLetterLetterType.value;
            let folderName = this.middleLetterLetterType.value === 'zb' ? 'Zaner' : 'DNealian';
            switch (this.middleLetterVisualType) {
                case 'Letter':
                    imgtype = this.middleLetterCaseType === 'capital' ? `-Solid-${letterType.toUpperCase()}-CAP` : `-solid-${letterType}-lower`;
                    break;
                case 'LetterDot':
                    imgtype = this.middleLetterCaseType === 'capital' ? `-Dot-${letterType.toUpperCase()}-CAP` : `-dot-${letterType}-lower`;
                    break;
                case 'LetterDotArrow':
                    imgtype = this.middleLetterCaseType === 'capital' ? `-Arrow-${letterType.toUpperCase()}-CAP` : `-arrow-${letterType}-lower`;
                    break;
            }
            if (this.middleLetterCaseType === 'capital') {
                this.middleLetterImgSrc = `./assets/images/concept_images/NewCustomConcept/${folderName}Capital3house/${letter.toUpperCase()}${imgtype}.png`;
            } else {
                this.middleLetterImgSrc = `./assets/images/concept_images/NewCustomConcept/${folderName}Lower3house/${letter.toLowerCase()}${imgtype}.png`;
            }
        }
    }

    update3HouseBottomImg() {
        if (!this.bottomLetterConcept.value) {
            return;
        }
        let letter: string = this.bottomLetterConcept.value.char;
        if (this.bottomLetterVisualType === 'BlankHouse') {
            this.bottomLetterImgSrc = './assets/images/concept_images/NewCustomConcept/BlankHouse_LetterFormation.png';
        } else {
            let imgtype = '';
            let letterType = this.bottomLetterLetterType.value;
            let folderName = this.bottomLetterLetterType.value === 'zb' ? 'Zaner' : 'DNealian';
            switch (this.bottomLetterVisualType) {
                case 'Letter':
                    imgtype = this.bottomLetterCaseType === 'capital' ? `-Solid-${letterType.toUpperCase()}-CAP` : `-solid-${letterType}-lower`;
                    break;
                case 'LetterDot':
                    imgtype = this.bottomLetterCaseType === 'capital' ? `-Dot-${letterType.toUpperCase()}-CAP` : `-dot-${letterType}-lower`;
                    break;
                case 'LetterDotArrow':
                    imgtype = this.bottomLetterCaseType === 'capital' ? `-Arrow-${letterType.toUpperCase()}-CAP` : `-arrow-${letterType}-lower`;
                    break;
            }
            if (this.bottomLetterCaseType === 'capital') {
                this.bottomLetterImgSrc = `./assets/images/concept_images/NewCustomConcept/${folderName}Capital3house/${letter.toUpperCase()}${imgtype}.png`;
            } else {
                this.bottomLetterImgSrc = `./assets/images/concept_images/NewCustomConcept/${folderName}Lower3house/${letter.toLowerCase()}${imgtype}.png`;
            }
        }
    }

    saveNewConcept(printPdf: boolean, copyToConcept: boolean) {
        if (this.descriptionControl.errors) {
            this.descriptionControl.markAsTouched();
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            this.coreService.openSnackBar("Report Title Required", 3000, "warn", "end", "bottom");
            return;
        }
        if (printPdf) {
            this.isSavePrintInProgress = true;
        } else {
            this.isSaveInProgress = true;
        }
        this.tobeSaveNewConcept = {
            newConceptId: this.selectedConcept.guid,
            reportTitle: this.descriptionControl.value.trim(),
            select_paper1: 'largeSmall',
            select_paper2: '3SmallHouse'
        };

        if (this.largeSmallLetterConcept.value) {
            this.tobeSaveNewConcept.paper1_l1letter = this.largeSmallLetterConcept.value.guid;
            this.tobeSaveNewConcept.paper1_l1case = this.largeSmallLetterCaseType;
            this.tobeSaveNewConcept.paper1_l1font = this.largeSmallLetterType.value === 'zb' ? 'zanerbloser' : 'dnealian';
            this.tobeSaveNewConcept.paper1_l1vc = 'LetterDotArrow';
        }

        if (this.topLetterConcept.value) {
            this.tobeSaveNewConcept.paper2_l1letter = this.topLetterConcept.value.guid;
            this.tobeSaveNewConcept.paper2_l1case = this.topLetterCaseType;
            this.tobeSaveNewConcept.paper2_l1font = this.topLetterLetterType.value === 'zb' ? 'zanerbloser' : 'dnealian';
            this.tobeSaveNewConcept.paper2_l1vc = this.topLetterVisualType;
        }

        if (this.middleLetterConcept.value) {
            this.tobeSaveNewConcept.paper2_l2letter = this.middleLetterConcept.value.guid;
            this.tobeSaveNewConcept.paper2_l2case = this.middleLetterCaseType;
            this.tobeSaveNewConcept.paper2_l2font = this.middleLetterLetterType.value === 'zb' ? 'zanerbloser' : 'dnealian';
            this.tobeSaveNewConcept.paper2_l2vc = this.middleLetterVisualType;
        }

        if (this.bottomLetterConcept.value) {
            this.tobeSaveNewConcept.paper2_l3letter = this.bottomLetterConcept.value.guid;
            this.tobeSaveNewConcept.paper2_l3case = this.bottomLetterCaseType;
            this.tobeSaveNewConcept.paper2_l3font = this.bottomLetterLetterType.value === 'zb' ? 'zanerbloser' : 'dnealian';
            this.tobeSaveNewConcept.paper2_l3vc = this.bottomLetterVisualType;
        }
        let displayMessage = '';
        let updateById = copyToConcept ? null : this.savedData?.id;

        if (copyToConcept) {
            displayMessage = 'Copied to Concept Material';
            this.tobeSaveNewConcept.reportTitle = ('Copy From ' + this.savedData.lessonMaterial?.lesson.title).substring(0, 40);
            this.saveData(updateById, displayMessage, copyToConcept, printPdf);
            return;
        } else {
            displayMessage = 'Data ' + (this.savedData?.id ? 'Updated' : 'Saved');
        }

        let getSavedConceptsSub = this.coreService.getSavedConceptMaterialByClass().subscribe(
            {
                next: savedConcepts => {
                    let isDuplicateConceptName = savedConcepts.filter(d => d.materialTypeId !== updateById)
                        .some(d => d.materialTypeTitle?.toLowerCase().trim() === this.tobeSaveNewConcept.reportTitle?.toLowerCase());
                    if (isDuplicateConceptName) {
                        let confirmDialogBox = this.dialog.open(ConfirmDialogBox, {
                            data: {
                                message: `Concept material already exists with the same name. Do you want to save?`,
                                confirmBtnClass: 'blue',
                                confirmBtnLabel: 'Save'
                            },
                            disableClose: true,
                        });
                        let confirmDialogSub = confirmDialogBox.beforeClosed().subscribe({
                            next: data => {
                                if (data) {
                                    this.saveData(updateById, displayMessage, copyToConcept, printPdf);
                                } else {
                                    this.isSaveInProgress = false;
                                    this.isSavePrintInProgress = false;
                                }
                            }
                        });
                        this._subscription.push(confirmDialogSub);
                    } else {
                        this.saveData(updateById, displayMessage, copyToConcept, printPdf);
                    }
                }
            }
        );
        this._subscription.push(getSavedConceptsSub);

    }

    saveData(updateById: string | null, displayMessage: string, copyToConcept: boolean, printPdf: boolean) {
        let apiSub = this.coreService.saveLetterFormationConcept(this.tobeSaveNewConcept, updateById).subscribe({
            next: (resp: any) => {
                this.coreService.openSnackBar(displayMessage, 3000, "primary", "end", "bottom");
                if (!copyToConcept) {
                    this.savedData = resp;
                }
                this.isSaveInProgress = false;
                printPdf && this.printPdf();
            },
            error: err => {
                console.log(err);
                this.coreService.openSnackBar("Some error occurred", 3000, "warn", "end", "bottom");
                this.isSaveInProgress = false;
            }
        });
        this._subscription.push(apiSub);
    }

    printPdf() {
        let apiSub = this.coreService.getLetterFormationPDF(this.savedData.id).subscribe({
            next: (data: Blob) => {
                try {
                    this.isSavePrintInProgress = false;

                    this.coreService.openPdf(data, 'LetterFormation');
                } catch (err) {
                    console.log(err);
                    this.coreService.openSnackBar("Error occurred while opening PDF", 3000, "warn", "end", "bottom");
                }
            },
            error: err => {
                this.isSavePrintInProgress = false;
                console.log(err);
                this.coreService.openSnackBar("Some error occurred", 3000, "warn", "end", "bottom");
            }
        });
        this._subscription.push(apiSub);
    }
}