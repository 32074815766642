import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxTeacherstudentModule } from 'ngx-teacher-student';


import { AppFooterComponent } from './footer/footer.component';
import { HeaderComponent } from './app-header/app-header.component';
import { CoreModule } from '../core/core.module';
import { HeaderRightComponent } from './app-header/app-header-right/app-header-right.component';
import { HeaderLeftComponent } from './app-header/app-header-left/app-header-left.component';
import { HeaderCenterComponent } from './app-header/app-header-center/app-header-center.component';
@NgModule({
  declarations: [
    AppFooterComponent,
    HeaderComponent,
    HeaderRightComponent,
    HeaderLeftComponent,
    HeaderCenterComponent
  ],
  exports: [
    AppFooterComponent,
    HeaderComponent,
    HeaderRightComponent,
    HeaderLeftComponent,
    HeaderCenterComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    NgxTeacherstudentModule
  ]
})
export class LayoutModule { }