import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { filter, Subscription } from 'rxjs';
import { ClassData, ClassDto, ClassTerm } from 'src/app/classes/classData';
import { Grades, Levels } from 'src/app/classes/enums';
import { CoreService } from 'src/app/core/core.service';

import * as _moment from 'moment';

import { ConfirmDialogBox } from '../confirm-dialog/confirm-dialog.component';
import { User } from 'src/app/classes/user';
const moment = _moment;
@Component({
  selector: 'add-edit-class',
  templateUrl: './add-edit-class.component.html',
  styleUrls: ['./add-edit-class.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddEditClassComponent implements OnInit, OnDestroy {
  private _subscription: Subscription[] = [];
  userData!: User;
  classForm!: UntypedFormGroup;
  classNameControl!: UntypedFormControl;
  classGardeControl!: UntypedFormControl;
  classBaseLineControl!: UntypedFormControl;
  initialDateControl!: UntypedFormControl;
  midDateControl!: UntypedFormControl;
  finalDateControl!: UntypedFormControl;
  assignmentDateCheckboxControl!: UntypedFormControl;
  outsideDistrictCheckboxControl!: UntypedFormControl;
  typeOfDialogBox = '';
  redirectToStudent: boolean = false;
  classData!: ClassData;
  notValidDistrictId = false;
  gradeList = Grades;
  levelList = Levels;
  isCloneApiInProgress = false;

  isGetTermsApiLoading = true;
  termControl!: FormControl;
  classTermList: ClassTerm[] = [];
  filterClassTermList: ClassTerm[] = [];
  changeValueFlag = false;
  constructor (
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddEditClassComponent>,
    private coreService: CoreService,
    public dialog: MatDialog) {
    this.typeOfDialogBox = data['type'];
    this.redirectToStudent = data['redirectStudent'] || false;;
    if (this.typeOfDialogBox === 'edit') {
      this.classData = data['classData'];
    }
    if (this.coreService.userData) {
      this.userData = this.coreService.userData;
    } else {
      this.onCancel();
      return;
    }

    if (this.userData.districtManaged) {
      this.getTermsList();
    } else {
      this.isGetTermsApiLoading = false;
    }
  }

  ngOnInit(): void {
    this.classNameControl = new UntypedFormControl('', [Validators.required, Validators.maxLength(30)]);
    this.classGardeControl = new UntypedFormControl('Choose a grade', [Validators.required, DropDownSelectValidator()]);
    this.classBaseLineControl = new UntypedFormControl(this.levelList['BR'].value, DropDownSelectValidator());
    this.initialDateControl = new UntypedFormControl();
    this.midDateControl = new UntypedFormControl();
    this.finalDateControl = new UntypedFormControl();
    this.assignmentDateCheckboxControl = new UntypedFormControl(false);
    let districtId = this.userData.districtId;
    this.notValidDistrictId = (!districtId || districtId <= 0);
    if (this.notValidDistrictId) {
      this.outsideDistrictCheckboxControl = new UntypedFormControl({ value: true, disabled: true });
    } else {
      this.outsideDistrictCheckboxControl = new UntypedFormControl(false);
    }
    this.termControl = new FormControl<ClassTerm | null>(null);
    if (this.userData.districtManaged) {
      this.termControl.setValidators(Validators.required);
    }
    this.termControl.disable();
    this.classForm = new UntypedFormGroup({
      className: this.classNameControl,
      gradeControl: this.classGardeControl,
      baselineControl: this.classBaseLineControl,
      initialDateControl: this.initialDateControl,
      midDateControl: this.midDateControl,
      finalDateControl: this.finalDateControl,
      assignmentDateCheckboxControl: this.assignmentDateCheckboxControl,
      outsideDistrictCheckboxControl: this.outsideDistrictCheckboxControl,
    });

    let descChangeSub = this.classNameControl.valueChanges.subscribe({
      next: (value: string) => {
        if (this.classNameControl.errors?.['maxlength']) {
          this.classNameControl.setValue(value.substring(0, 30));
        }
      }
    });
    this._subscription.push(descChangeSub);
  
    this.termControl.valueChanges.subscribe({
      next: value => {
        if (value && (typeof value === 'string')) {
          let trimedValue: string = <string>value.trim();
          this.filterClassTermList = this.classTermList.filter(term => term.name.toLowerCase().indexOf(trimedValue.toLowerCase()) > -1);
        } else if (!value) {
          this.filterClassTermList = [...this.classTermList];
        }
      }
    });

    if (this.typeOfDialogBox === 'edit') {
      this.classNameControl.setValue(this.classData.name);
      this.classGardeControl.setValue(this.gradeList[this.classData.grade]?.value);
      this.classBaseLineControl.setValue(this.levelList[this.classData.level]?.value);
      this.outsideDistrictCheckboxControl.setValue(this.classData.outsideDistrict);
      if (this.classData.initialAssessmentDate ||
        this.classData.midAssessmentDate || this.classData.finalAssessmentDate) {
        this.assignmentDateCheckboxControl.setValue(true);

        if (this.classData.initialAssessmentDate) {
          this.initialDateControl.setValue(moment(this.classData.initialAssessmentDate));
        }
        if (this.classData.midAssessmentDate) {
          this.midDateControl.setValue(moment(this.classData.midAssessmentDate));
        }
        if (this.classData.finalAssessmentDate) {
          this.finalDateControl.setValue(moment(this.classData.finalAssessmentDate));
        }
      }
    }

    this.classForm.valueChanges.subscribe({
      next: value => {
        this.changeValueFlag = true;
      }
    });
  }

  ngOnDestroy(): void {
    this._subscription.forEach(sub => sub?.unsubscribe());
  }

  onCancel() {
    if (this.changeValueFlag) {
      const confirmDialog = this.dialog.open(ConfirmDialogBox, {
        data: {
          message: `This will discard all changes. Continue?`,
          confirmBtnLabel: "Yes",
          confirmBtnClass: "lightRed",
          cancelBtnLabel: "No",
        },
        disableClose: true,
      });
      let dialogSub = confirmDialog.afterClosed().subscribe({
        next: isConfirmed => {
          if (isConfirmed) {
            this.dialogRef.close(null);
          }
        }
      });
      this._subscription.push(dialogSub);
    } else {
      this.dialogRef.close(null);
    }
  }

  onArchive() {
    if (!this.classData.owner) {
      return;
    }
    this.dialogRef.close('archive');
  }

  onAddCLass() {
    this.classForm.markAllAsTouched();
    if (this.classForm.invalid) {
      this.coreService.openSnackBar("Please fill the required fields", 3000, "warn", "end", "bottom");
      return
    }
    if (this.userData.districtManaged && this.classTermList.length &&
      typeof this.termControl.value !== 'object') {
      this.termControl.setValue(null, { emitEvent: false });
      this.filterClassTermList = [...this.classTermList];
      this.coreService.openSnackBar("Please select the Term form list", 3000, "warn", "end", "bottom");
      return;
    }
    let newClassDto = new ClassDto(
      this.typeOfDialogBox === 'edit' ? this.classData.guid : '',
      this.classNameControl.value,
      this.classGardeControl.value,
      this.classBaseLineControl.value,
      this.assignmentDateCheckboxControl.value ? this.initialDateControl.value : null,
      this.assignmentDateCheckboxControl.value ? this.midDateControl.value : null,
      this.assignmentDateCheckboxControl.value ? this.finalDateControl.value : null,
      // this.outsideDistrictCheckboxControl.value
      true, // setting default value to True, ticket: https://3lbgames.monday.com/boards/2835670689/views/64816357/pulses/2703358700
    );
    newClassDto.districtManaged = this.userData.districtManaged || false;
    if (this.userData.districtManaged && this.termControl.value) {
      newClassDto.classTermId = this.termControl.value?.id;
    }
    this.dialogRef.close(newClassDto);
  }

  cloneClass() {
    let cloneConfirmDia = this.dialog.open(ConfirmDialogBox, {
      data: {
        message: `Everything except student data will be cloned to a new class. <br/><br/> Confirm clone?`,
        confirmBtnClass: 'blue',
        confirmBtnLabel: 'Clone'
      },
      disableClose: true,
      panelClass: ['dialogOverflow']
    });
    let dialogSub = cloneConfirmDia.afterClosed().subscribe({
      next: canClone => {
        if (canClone) {
          this.isCloneApiInProgress = true;
          this.cloneClassCall();
        }
      }
    });
    this._subscription.push(dialogSub);
  }

  cloneClassCall() {
    let apiCallSub = this.coreService.cloneClass(this.classData.guid).subscribe({
      next: resp => {
        this.isCloneApiInProgress = false;
        this.coreService.openSnackBar('Successfully cloned to ' + resp.name, 3000, "green", "end", "bottom");
      },
      error: err => {
        console.log(err);
        this.isCloneApiInProgress = false;
        this.coreService.openSnackBar("Error Occured while Cloning", 3000, "warn", "end", "bottom");
      }
    });

    this._subscription.push(apiCallSub);
  }

  keepOriginalOrder = (a: any, b: any) => 1;
  displayTermName = (selectedTerm: ClassTerm) => selectedTerm?.name || '';

  getTermsList() {
    let termApiCallSub = this.coreService.getAllTerms().subscribe({
      next: resp => {
        this.isGetTermsApiLoading = false;
        this.classTermList = resp.filter(t => !t.archived);
        this.filterClassTermList = [...this.classTermList];
        this.termControl.enable({ emitEvent: false });
        if (this.typeOfDialogBox === 'edit' && this.classData.classTerm) {
          let userSelectedTerm = this.filterClassTermList.find(term => term.id === this.classData.classTerm.id);
          userSelectedTerm && this.termControl.setValue(userSelectedTerm, { emitEvent: false });
        }
        if (this.classTermList.length) {
          this.classForm.addControl("termControl", this.termControl, { emitEvent: false });
        }
      },
      error: err => {
        this.isGetTermsApiLoading = false;
        this.coreService.openSnackBar("Error Occured while Class Terms", 3000, "warn", "end", "bottom");
      }
    });
    this._subscription.push(termApiCallSub);
  }

}

export function DropDownSelectValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return isNaN(control.value) ? { error: true } : null;
  };
}