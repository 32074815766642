<div class="sharedContainer">
    <div *ngIf="loader" class="loader">
        <h3>Checking for Notifications...</h3>
        <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
    </div>
    <div *ngIf="!loader">
        <mat-tab-group mat-align-tabs="center" *ngIf="(classSharedUserList.length || studentSharedUserList.length || classAssessmentDetails.length)">
            <mat-tab label="Notifications" *ngIf="classSharedUserList.length || studentSharedUserList.length">
                <div class="mark_read_all">
                    <app-button (click)="markAllread()" [content]="'Mark all as read'" [iconPath]="'./assets/images/icons/mark_read_all_icon.svg'" [isLoading]="false" [isDisabled]="false"></app-button>
                </div>
                <div *ngIf="classSharedUserList.length">
                    <h3>Newly Shared Classes :</h3>
                    <div class="classShareList">
                        <div *ngFor="let classShare of classSharedUserList" class="classShareItem">
                            <div class="nameHolder">
                                <span class="className">{{classShare.teacherClass.name}}</span>
                                <button mat-icon-button color="primary" (click)="classSelect(classShare.teacherClass, false)">
                                    <mat-icon>open_in_new</mat-icon>
                                </button>
                            </div>
                            <div>
                                <app-button [content]="'Mark Read'" [iconPath]="'./assets/images/icons/mark_read_icon.svg'" (click)="onMarkReadClass(classShare)" [isLoading]="classShare.isLoading" [isDisabled]="classShare.isLoading"></app-button>
                            </div>
                        </div>
                    </div>
    
                </div>
                <div *ngIf="studentSharedUserList.length">
                    <h3>Newly Shared Students :</h3>
                    <div class="classShareList">
                        <div *ngFor="let stuShare of studentSharedUserList" class="classShareItem">
                            <div class="nameHolder">
                                <span class="className">{{stuShare.teacherClassStudent?.teacherClass?.name}} - 
                                {{stuShare.teacherClassStudent?.student?.firstName || stuShare.teacherClassStudent?.student?.studentID}}
                                <button mat-icon-button color="primary" (click)="classSelect(stuShare.teacherClassStudent.teacherClass, true)">
                                    <mat-icon>open_in_new</mat-icon>
                                </button>
                            </span>
                            </div>
                            <div>
                                <app-button [content]="'Mark Read'" [iconPath]="'./assets/images/icons/mark_read_icon.svg'" btnClasses="" (click)="onMarkReadStudent(stuShare)" [isLoading]="stuShare.isLoading" [isDisabled]="stuShare.isLoading"></app-button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Upcoming Assessments" *ngIf="classAssessmentDetails.length">
                <div class="assessments">
                    <h3>Assessments</h3>
                    <div *ngFor="let classAssessment of classAssessmentDetails" class="lists">
                        <span [ngClass]="classAssessment.type">
                            {{classAssessment.type}}
                        </span> {{classAssessment.name}} due <b> {{classAssessment.assessmentInDay === 0 ? 'Today' : 'in ' + classAssessment.assessmentInDay + ' days'}} </b>
                        <ng-template *ngIf="classAssessment.assessmentInDay === 0">
                            <b>Today</b>
                        </ng-template>
                        <ng-template *ngIf="classAssessment.assessmentInDay !== 0">
                            <b> in {{classAssessment.assessmentInDay + ' days'}}</b>
                        </ng-template>
                        <!-- <mat-icon *ngIf="classAssessment.assessmentInDay < 2" class="material-icons color_warn">warning</mat-icon> -->
                        <button mat-icon-button class="assessmentClassLink" color="primary" (click)="classSelect(classAssessment.classData, false)">
                            <mat-icon>open_in_new</mat-icon>
                        </button>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
        <div *ngIf="!(classSharedUserList.length || studentSharedUserList.length || classAssessmentDetails.length)"
            class="noNotification">
            <img alt="icon" src="./assets/images/icons/no_notification_icon.svg" />
            <div class="note">No New Notifications</div>
        </div>
        <mat-dialog-actions class="btnClass">
            <app-button [content]="'Close'" (click)="onCancel()"></app-button>
        </mat-dialog-actions>
    </div>
</div>