<mat-progress-bar *ngIf="isGetClassLoading" mode="query"></mat-progress-bar>
<main>
    <p>
        Hello, {{userData?.firstName}}!
        <br/>
        <span class="info" *ngIf="userData?.districtManaged">This account is managed by District</span>
    </p>
    <!-- <div *ngIf="classDataList">
        <mat-form-field appearance="fill" [floatLabel]="'auto'">
            <mat-label>
                {{ classSelectControl.value ? 'Change/Add Class' : 'Please Select Class'}} </mat-label>
            <mat-select [formControl]="classSelectControl" panelClass="classOptions" (selectionChange)="onClassChanged($event.value)">
                <mat-option *ngFor="let class of classDataList" [value]="class.guid">
                    {{class.name}}
                </mat-option>
                <mat-option value="AddNewClass" class="newClassBtn">
                    <app-button [content]="'Add New Class'" [btnClasses]="'green w100'"></app-button>
                </mat-option>
                <div>&nbsp;</div>
            </mat-select>
        </mat-form-field>
    </div> -->
    <div class="buttonsContainer">
        <div *ngIf="selectedClass" class="className">
            {{selectedClass.name}}
        </div>
        <div class="buttons">
            <app-button [content]="selectedClass ? 'Change' + (canAddClass ? ' / Add' : '') + ' Class'  : 'Select a Class'" [btnClasses]="'blue'" (click)="selectClass()" [isDisabled]="isGetClassLoading"></app-button>
        </div>
    </div>
    <div class="walkthrough">
        <span class="link" (click)="openTourDialog($event)">Click here for a tutorial walkthrough</span>
    </div>
    <div class="helpCenter">
        <a href="https://www.imse.com/help-center" target="_blank">
            Visit the IOG2 Help Center 
        </a>
    </div>
</main>
<div class="taskCard">
    <div class="row1">
        <div class="card class" id="ClassManagement">
            <img src="./assets/images/class_icon.svg" class="icon svg">
            <p>Manage students and classroom materials</p>
            <app-button [content]="'Classroom Materials'" [btnClasses]="'blue extraWidth'" (click)="openClassSelectDialog(false)" [isDisabled]="isGetClassLoading"></app-button>
            <!-- <img src="./assets/images/icons/students_green.svg" class="studentIcon" matTooltip="New Shared Students" matTooltipPosition="above"> -->
        </div>
        <div class="card" id="StudentManagement">
            <img src="./assets/images/student_list.png" class="icon">
            <p>See list of students for assessment</p>
            <app-button [content]="'Student Assessment'" [btnClasses]="'blue extraWidth'" (click)="openClassSelectDialog(true)" [isDisabled]="isGetClassLoading"></app-button>
        </div>
        <div class="card" id="ReportsCard">
            <img src="./assets/images/analyze_icon.png" class="icon">
            <p>See how classes are performing</p>
            <app-button [content]="'Analyze, Metrics & Reporting'" [btnClasses]="'blue extraWidth'" (click)="goToAssessmentApp()" [isDisabled]="isGetClassLoading"></app-button>
        </div>
    </div>
    <div class="row2">
        <div style="display: flex;"></div>
        <div class="card" id="LessonBuilder">
            <img src="./assets/images/lesson_icon.png" class="icon">
            <p>Build lessons using IMSE materials and customize</p>
            <app-button [content]="'Lesson Builder'" [btnClasses]="'blue extraWidth'" (click)="toGoLessonBuilder()" [isDisabled]="isGetClassLoading"></app-button>
        </div>
        <div class="card" id="ConceptMaterial">
            <img src="./assets/images/concept_icon.svg" class="icon svg">
            <p>Quickly access IMSE classroom material or customize them</p>
            <app-button [content]="'Quick Access Material'" [btnClasses]="'blue extraWidth'" (click)="openConcept()" [isDisabled]="isGetClassLoading"></app-button>
        </div>
        <div style="display: flex;"></div>
    </div>
</div>
<div class="unArchiveBtn">
    <app-button content="Restore Archived Class" btnClasses="lightRed" (click)="openArchivedClassDialog()" [isDisabled]="isGetClassLoading"></app-button>
</div>