<div class="migration-container">
    <div class="migration-title">
        <div class="heading">Migrate to District Managed</div>
    </div>
    <div class="contentContainer">
        <div class="messageContainer slide-in-left" *ngIf="currentIndex === 1 || currentIndex === 2">
            <div class="messageContent">
                <p class="heading">
                    Review Your Account
                </p>
                <div *ngIf="currentIndex === 1">
                    <p class="item">
                        Students will only be imported upon class import.
                    </p>
                    <p class="item">
                        Import = Merge the selected class to the district.
                    </p>
                    <p class="item">
                        Delete = Remove the selected class from your account. It will not be merged.
                    </p>
                    <p class="item">
                        Before importing, make sure to update class names to align with your district's naming conventions.
                    </p>
                </div>
                <div *ngIf="currentIndex === 2">
                    <p class="item">
                        In order to ensure students are merged correctly, please confirm all imported students have a 
                        matching District Student ID.
                    </p>
                    <p class="item">
                        Import = Merge the selected student to the district.
                    </p>
                    <p class="item">
                        Delete = Remove the selected student from your account. It will not be merged.
                    </p>
                    <p class="item">
                        You can unlink a student from class using Edit option.
                    </p>
                </div>
                <!-- <div *ngIf="currentIndex === 3">
                    summary review
                </div> -->
            </div>
        </div>
        <mat-horizontal-stepper #stepper [linear]="true" (selectionChange)="onStepChange($event, stepper)" class="stepper-container">
            <mat-step label="Information" state="info" [editable]="false" [completed]="infoStepCompleted">
                <p class="step-title">
                    Review Data
                </p>
                <div class="content">
                    <p>
                        Please review and edit your classes. The student data entered will be merged with your district through your District Managed Account.
                    </p>
                    <p>
                        Note that Student IDs should match your District's Student ID format.
                    </p>
                    <p>
                        Ensure that students are already added to your district and all data errors are resolved before proceeding with the merge.
                    </p>
                </div>
                <div class="btn-container">
                    <app-button content="Next" btnClasses="blue" (onClick)="markInfoStep(stepper)"></app-button>
                </div>
            </mat-step>
            <mat-step label="Review Classes" state="class" [editable]="true" [completed]="classStepCompleted">
                <mat-progress-bar color="primary" mode="indeterminate" *ngIf="isGetApiRunning"></mat-progress-bar>
                <p class="step-title">
                    Verify Class Data
                </p>
                <div class="mat-elevation-z2">
                    <div class="tableContainer">
                        <table mat-table [dataSource]="classDataSource" matSort matSortActive="name" matSortDirection="asc" class="table" #classSort="matSort"
                            (matSortChange)="onClassSortChange()">
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Name
                                    <span class="sort-none">
                                        <img src="./assets/images/icons/sort_unselected.png" alt="sort_icon" class="sortIcon unselected"/>
                                    </span>
                                </th>
                                <td mat-cell *matCellDef="let cls">{{ cls.updated.name || cls.name }}</td>
                            </ng-container>
                    
                            <ng-container matColumnDef="level">
                                <th mat-header-cell *matHeaderCellDef>Level</th>
                                <td mat-cell *matCellDef="let cls">{{ levelList[cls.updated.level || cls.level].uiName }}</td>
                            </ng-container>
        
                            <ng-container matColumnDef="grade">
                                <th mat-header-cell *matHeaderCellDef>Grade</th>
                                <td mat-cell *matCellDef="let cls">{{ gradeList[cls.updated.grade || cls.grade].name }}</td>
                            </ng-container>
        
                            <ng-container matColumnDef="studentCount">
                                <th mat-header-cell *matHeaderCellDef class="center">Students</th>
                                <td mat-cell *matCellDef="let cls" class="center">{{ cls.metrics.studentCount }}</td>
                            </ng-container>
        
                            <ng-container matColumnDef="conceptCount">
                                <th mat-header-cell *matHeaderCellDef class="center">Concept Materials</th>
                                <td mat-cell *matCellDef="let cls" class="center">{{ cls.metrics.conceptCount }}</td>
                            </ng-container>
        
                            <ng-container matColumnDef="lessonCount">
                                <th mat-header-cell *matHeaderCellDef class="center">Lessons</th>
                                <td mat-cell *matCellDef="let cls" class="center">{{ cls.metrics.lessonCount }}</td>
                            </ng-container>
        
                            <ng-container matColumnDef="edit">
                                <th mat-header-cell *matHeaderCellDef>Edit</th>
                                <td mat-cell *matCellDef="let cls">
                                    <button mat-icon-button color="primary" (click)="onEditClassData(cls)" [disabled]="cls.status !== 'Import'">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                </td>
                            </ng-container>
        
                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef class="center">Status</th>
                                <td mat-cell *matCellDef="let cls" class="center">
                                    <mat-button-toggle-group [(ngModel)]="cls.status" aria-label="Status">
                                        <mat-button-toggle class="toggle-blue" value="Import">Import</mat-button-toggle>
                                        <!-- <mat-button-toggle class="toggle-grey" value="Archive">Archive</mat-button-toggle> -->
                                        <mat-button-toggle class="toggle-red" value="Delete">Delete</mat-button-toggle>
                                    </mat-button-toggle-group>
                                </td>
                            </ng-container>
        
                            <ng-container matColumnDef="isChanged">
                                <th mat-header-cell *matHeaderCellDef class="center">Updated</th>
                                <td mat-cell *matCellDef="let cls" class="center">
                                    <mat-icon *ngIf="cls.status === 'Import' && cls.isChanged" color="primary" [matTooltip]="cls.toolTipData" matTooltipClass="tool-tip-info max-width" matTooltipPosition="left">difference</mat-icon>
                                </td>
                            </ng-container>
                    
                            <tr mat-header-row *matHeaderRowDef="classDisplayColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: classDisplayColumns;"></tr>
                        </table>
                    </div>
                    <mat-paginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons aria-label="Select page of Class" #classPaginator></mat-paginator>
                </div>
                <div class="btn-container">
                    <app-button content="Next" btnClasses="blue" (onClick)="markClassStep(stepper)"></app-button>
                </div>
            </mat-step>
            <mat-step label="Review Students" state="student" [editable]="true" [completed]="studentStepCompleted">
                <p class="step-title">
                    Review Students
                </p>
                <div class="studentIdChecks">
                    <p> <mat-icon [color]="duplicateStudentIdPresent ? 'red': 'green'">{{duplicateStudentIdPresent ?  'dynamic_feed' : 'check_circle'}}</mat-icon> 
                        <span>Resolve Duplicate Student ID </span>
                    </p>
                    <p> <mat-icon [color]="invalidStudentIdPresent ? 'red': 'green'">{{invalidStudentIdPresent ?  'cancel' : 'check_circle'}}</mat-icon> 
                        <span>Fix Student ID Formatting </span>
                    </p>
                    <p>
                        <mat-icon *ngIf="!isConflictStudentCheckApiRunning"
                            [color]="daStudentIdAllPresent == null ? 'grey' : (daStudentIdAllPresent ? 'green': 'accent')">
                            {{daStudentIdAllPresent == null ? 'pending' : (daStudentIdAllPresent ? 'check_circle' : 'cancel')}}
                        </mat-icon> 
                        <mat-icon *ngIf="isConflictStudentCheckApiRunning" class="spin" color="primary">refresh</mat-icon>
                        <span>
                            Merge Student with District Data
                        </span>
                    </p>
                </div>
                <div class="mat-elevation-z2">
                    <div class="tableContainer">
                        <table mat-table [dataSource]="studentDataSource" matSort class="table" #studentSort="matSort" 
                        matSortActive="studentID" matSortDirection="asc"
                        (matSortChange)="onStudentSortChange()">
                            <ng-container matColumnDef="statusIcons">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status
                                    <span class="sort-none">
                                        <img src="./assets/images/icons/sort_unselected.png" alt="sort_icon" class="sortIcon unselected"/>
                                    </span>
                                </th>
                                <td mat-cell *matCellDef="let stu">
                                    <ng-container *ngIf="stu.status === 'Import'">
                                        <!-- if daStudentIdExists can false or undefined for awaiting DA id values, so check for false direct -->
                                        <div *ngIf="stu.formatInValid || stu.dulipcateStudentId || !stu.daStudentIdExists" class="icons">
                                            <mat-icon *ngIf="stu.dulipcateStudentId" color="warn" matTooltip="Duplicate Student Id" matTooltipClass="tool-tip-info max-width red" matTooltipPosition="above">dynamic_feed</mat-icon>
                                            <mat-icon *ngIf="stu.formatInValid" color="warn" [matTooltip]="stu.formatErrorsValues"
                                                matTooltipClass="tool-tip-info max-width red" matTooltipPosition="above">cancel</mat-icon>
                                            <mat-icon *ngIf="!stu.daStudentIdExists"
                                                [color]="stu.daStudentIdExists === false ? 'accent' : 'grey'"
                                                [matTooltip]="stu.daStudentIdExists === false ? 'Student ID was not found in your district' : 'Waiting for District Student ID Match'" matTooltipClass="tool-tip-info max-width red" matTooltipPosition="above">
                                                {{stu.daStudentIdExists === false ? 'cancel' : 'pending'}}</mat-icon>
                                        </div>
                                        <ng-container *ngIf="!stu.formatInValid && !stu.dulipcateStudentId && stu.daStudentIdExists">
                                            <mat-icon color="green">check_circle</mat-icon>
                                        </ng-container>
                                    </ng-container>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="studentID">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    ID
                                    <span class="sort-none">
                                        <img src="./assets/images/icons/sort_unselected.png" alt="sort_icon" class="sortIcon unselected"/>
                                    </span>
                                </th>
                                <td mat-cell *matCellDef="let stu">{{stu.updated.studentID || stu.studentID }}</td>
                            </ng-container>
                    
                            <ng-container matColumnDef="firstName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    First Name
                                    <span class="sort-none">
                                        <img src="./assets/images/icons/sort_unselected.png" alt="sort_icon" class="sortIcon unselected"/>
                                    </span>
                                </th>
                                <td mat-cell *matCellDef="let stu">{{ stu.updated.firstName || stu.firstName }}</td>
                            </ng-container>
        
                            <ng-container matColumnDef="lastName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Last Name
                                    <span class="sort-none">
                                        <img src="./assets/images/icons/sort_unselected.png" alt="sort_icon" class="sortIcon unselected"/>
                                    </span>
                                </th>
                                <td mat-cell *matCellDef="let stu">{{ stu.updated.lastName || stu.lastName }}</td>
                            </ng-container>
        
                            <ng-container matColumnDef="level">
                                <th mat-header-cell *matHeaderCellDef>Level</th>
                                <td mat-cell *matCellDef="let stu">{{ levelList[stu.updated.level || stu.level].uiName  }}</td>
                            </ng-container>
        
                            <ng-container matColumnDef="class.name">
                                <th mat-header-cell *matHeaderCellDef>Class</th>
                                <td mat-cell *matCellDef="let stu" [ngClass]="{ 'unlinked': !stu.classLinked}">{{ stu.class.updated.name || stu.class.name }}</td>
                            </ng-container>
    
                            <ng-container matColumnDef="edit">
                                <th mat-header-cell *matHeaderCellDef>Edit</th>
                                <td mat-cell *matCellDef="let stu">
                                    <button mat-icon-button color="primary" [disabled]="stu.status !== 'Import'" (click)="onEditStudent(stu)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                </td>
                            </ng-container>
    
                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef class="center">Status</th>
                                <td mat-cell *matCellDef="let stu" class="center">
                                    <mat-button-toggle-group [(ngModel)]="stu.status" aria-label="Status" (change)="onStudentStatusToggle()">
                                        <mat-button-toggle class="toggle-blue" value="Import">Import</mat-button-toggle>
                                        <mat-button-toggle class="toggle-red" value="Delete">Delete</mat-button-toggle>
                                    </mat-button-toggle-group>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="changed">
                                <th mat-header-cell *matHeaderCellDef class="center"></th>
                                <td mat-cell *matCellDef="let stu" class="center">
                                    <mat-icon *ngIf="stu.status === 'Import' && stu.isChanged" color="primary" [matTooltip]="stu.toolTipData" matTooltipClass="tool-tip-info" matTooltipPosition="left">difference</mat-icon>
                                </td>
                            </ng-container>
        
                            <tr mat-header-row *matHeaderRowDef="studentDisplayColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: studentDisplayColumns;"></tr>
                        </table>
                    </div>
                    <mat-paginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons aria-label="Select page of Class" #studentPaginator></mat-paginator>
                </div>
                <div class="btn-container">
                    <app-button content="Previous" (onClick)="backToClassStep(stepper)"></app-button>
                    <app-button [content]="daStudentIdAllPresent ? 'Summary' : 'Perform District Student ID Match'" btnClasses="blue" (onClick)="checkStudentIdConflicts(stepper)" [isDisabled]="isConflictStudentCheckApiRunning" [isLoading]="isConflictStudentCheckApiRunning"></app-button>
                </div>
            </mat-step>
            <mat-step label="Summary" state="preview" [editable]="true" [completed]="previewStepCompleted">
                <p class="step-title">
                    Import Summary
                </p>
                <p>
                    Please review your changes before proceeding.
                </p>
                <div class="preview-content">
                    <div class="card-container mat-elevation-z2" *ngIf="classCounts">
                        <div class="title">Class summary</div>
                        <div class="card-list">
                            <div class="card lightGreen">
                                <div class="heading">Import</div>
                                <div class="value">{{classCounts.import || 0}}</div>
                            </div>
                            <div class="card greenMint">
                                <div class="heading">Edited</div>
                                <div class="value">{{classCounts.edited || 0}}</div>
                            </div>
                            <!-- <div class="card yellow">
                                <div class="heading">Archived</div>
                                <div class="value">{{classCounts.archive || 0}}</div>
                            </div> -->
                            <div class="card lightOrange">
                                <div class="heading">Delete</div>
                                <div class="value">{{classCounts.delete || 0}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="card-container mat-elevation-z2" *ngIf="studentCounts">
                        <div class="title">Students summary</div>
                        <div class="card-list">
                            <div class="card lightGreen">
                                <div class="heading">Import</div>
                                <div class="value">{{studentCounts.import || 0}}</div>
                            </div>
                            <div class="card greenMint">
                                <div class="heading">Edited</div>
                                <div class="value">{{studentCounts.edited || 0}}</div>
                            </div>
                            <div class="card lightOrange">
                                <div class="heading">Delete</div>
                                <div class="value">{{studentCounts.delete || 0}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="terms-content mat-elevation-z5">
                        <p class="bold">
                            Notice:
                        </p>
                        <p>
                            Upon agreeing, this account will be managed and owned by the district providing the service.
                        </p>
                        <p class="bold">
                            Terms and Conditions:
                        </p>
                        <p>
                            Your district reserves the right to modify, suspend or discontinue district managed account services at any time without 
                            notice or liability. Your district may also revise the terms and conditions at any time, and your continued use of these 
                            IMSE services after such revisions will constitute your acceptance of the updated terms.
                        </p>
                        <p class="previewToggle">
                            Do you authorize the district's request to control and manage this account?
                            <mat-checkbox [formControl]="confirmToggle" color="primary" class="checkBtn">I agree</mat-checkbox>
                        </p>
                    </div>
                </div>
                <div class="btn-container">
                    <app-button content="Previous" (onClick)="backToStudentStep(stepper)"></app-button>
                    <app-button content="Next" btnClasses="blue" (onClick)="markPreviewStep(stepper)" [isDisabled]="!confirmToggle.value"></app-button>
                </div>
            </mat-step>
            <mat-step label="Migration" state="confirm" [editable]="false">
                <h1>TODO</h1>
            </mat-step>
            <!-- Icon overrides states -->
            <ng-template matStepperIcon="info"><mat-icon>info</mat-icon></ng-template>
            <ng-template matStepperIcon="class"><mat-icon>person</mat-icon></ng-template>
            <ng-template matStepperIcon="student"><mat-icon>group</mat-icon></ng-template>
            <ng-template matStepperIcon="preview"><mat-icon>preview</mat-icon></ng-template>
            <ng-template matStepperIcon="confirm"><mat-icon>verified_user</mat-icon></ng-template>
        </mat-horizontal-stepper>
    </div>
    
</div>