import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { LessonData, LessonPrintDto, LessonPrintOverviewDto, LessonPrintMaterialDto } from "src/app/classes/lessonData";
import { CoreService } from "src/app/core/core.service";
import { NavbarService } from "src/app/layout/app-header/navbar.service";
import { componentCards } from "../app-lesson-builder/app-lesson-builder.constant";

@Component({
    selector: 'lesson-print',
    templateUrl: './lesson-print.component.html',
    styleUrls: ['./lesson-print.component.scss']
})
export class LessonPrintComponent implements OnInit, OnDestroy {

    private _subscription: Subscription[] = [];
    savedLessonData!: LessonData;
    addedComponentList!: { [key: string]: componentCards[] };
    leftHeaderButtons: { title: string, event: string }[] = [{
        title: 'Back to Home',
        event: 'homeButton'
    }];

    // rightHeaderButtons: { title: string, event: string }[] = [{
    //     title: 'Saved Materials',
    //     event: 'savedLessonConcept'
    // }];

    rightHeaderButtons: { title: string, event: string }[] = [];

    functonList: { [name: string]: Function } = {
        homeButton: () => this.gotoToHome(),
        openClassSelection: () => this.goToClassManagement()
    }

    ComponentList!: PrintComponent[];
    tableComponentList: any = [];
    lessonIndex = [0, 1, 2, 3, 4]; //each index is for lesson day
    lessonDay = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    legandCheckBox = false;
    legandToggleBtn = false;
    isSavePrintInProgress = false;
    preMadeUrlList: string[][] = [];
    digitalSlidesConcepts = ['Three Part Drill', 'Teaching a New Concept'];
    digitalSlidesLinks: string[][] = [];
    pdfsLinksConcepts = ['Word and Sentence Dictation', 'Decodable Reader', 'Red Words', 'Word Cards', 'Practice Reading', 'Letter Formation'];
    pdfsLinks: string[][] = [];
    premadeLinksConcepts = ['Literature Connection'];
    premadeLinks: string[][] = [];
    includeDailyOverview: UntypedFormControl = new UntypedFormControl(true);
    includeWeeklyOverview: UntypedFormControl = new UntypedFormControl(true);
    constructor(
        private coreService: CoreService,
        private router: Router,
        private navbarService: NavbarService,
        ) {

        if (this.coreService.selectedClass) {
            this.leftHeaderButtons.push({
                title: this.coreService.selectedClass.name,
                event: 'openClassSelection'
            });
        }

        this.navbarService.updateLeftHeaderBtn(this.leftHeaderButtons);
        this.navbarService.updateRightHeaderBtn(this.rightHeaderButtons);

        const navSub = this.navbarService.headerBtnClicked$.subscribe({
            next: action => this.onNavigationServiceActionCalled(action)
        });

        this._subscription.push(navSub);

        let state = this.router.getCurrentNavigation()?.extras.state as {
            savedLessonData: LessonData,
            addedComponentList: { [key: string]: componentCards[] }
        };
        this.savedLessonData = state.savedLessonData;
        this.addedComponentList = state.addedComponentList;
        let index = 0;
        while (true) {
            let mondayComponent = this.convertComponent(this.addedComponentList['Monday'][index], 'Monday');
            let tuesdayComponent = this.convertComponent(this.addedComponentList['Tuesday'][index], 'Tuesday');
            let wednesdayComponent = this.convertComponent(this.addedComponentList['Wednesday'][index], 'Wednesday');
            let thursdayComponent = this.convertComponent(this.addedComponentList['Thursday'][index], 'Thursday');
            let fridayComponent = this.convertComponent(this.addedComponentList['Friday'][index], 'Friday');

            let componentsList = [mondayComponent, tuesdayComponent, wednesdayComponent, thursdayComponent, fridayComponent];

            if (!componentsList.some(c => c)) {
                break;
            } else {
                this.tableComponentList.push(componentsList);
                index++;
            }
        }
        let preMadeUrl: { [key: string]: string | null } = {};
        this.lessonDay.forEach(day => {
            this.addedComponentList[day].forEach(comp => {
                let title = comp.title || '';
                preMadeUrl[title] = comp.preMadeUrl || null;
            });
        });
        for (const [key, value] of Object.entries(preMadeUrl)) {
            if (value) {
                this.preMadeUrlList.push([key, value]);
                if (this.pdfsLinksConcepts.indexOf(key) > -1) {
                    this.pdfsLinks.push([key, value]);
                } else if (this.premadeLinksConcepts.indexOf(key) > -1) {
                    this.premadeLinks.push([key, value]);
                } else {
                    this.digitalSlidesLinks.push([key, value]);
                }
            }
        }
        this.preMadeUrlList.sort();
        this.pdfsLinks.sort();
        this.premadeLinks.sort();
        this.digitalSlidesLinks.sort();
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this._subscription.forEach(sub => sub?.unsubscribe());
    }

    onNavigationServiceActionCalled(action: string) {
        if (this.functonList[action]) {
            this.functonList[action]();
        }
    }

    gotoToHome() {
        this.router.navigate(['/dashboard']);
    }

    goToClassManagement() {
        this.router.navigate(['class']);
    }

    goToLessonBuilder() {
        this.router.navigateByUrl('lessonBuilder', {
            state: {
                lessonData: this.savedLessonData
            }
        });
    }

    convertComponent(comp: componentCards | undefined, lessonDay: string): PrintComponent | null {
        if (!comp) {
            return null;
        }
        let converted: PrintComponent = {
            materialId: comp.materialId,
            materialType: comp.materialType,
            title: comp.title,
            preMadeUrl: comp.preMadeUrl,
            haveCustomConcept: comp.haveCustomConcept,
            isSelected: !comp.isEmpty && comp.haveCustomConcept,
            lessonDay: lessonDay,
            isEmpty: comp.isEmpty || false,
            description: comp.description,
            subTitle: comp.subtitle
        };
        return converted;
    }

    goToPreMadeUrl(e: any, premadeUrl: string) {
        if (e && e.stopPropagation) {
            e.stopPropagation();
        }
        if (premadeUrl) {
            window.open(premadeUrl, '_blank', 'noopener,resizable,scrollbars,popup=false');
        }
    }

    printPdf() {
        let lessonPrint: LessonPrintDto;
        let printList: LessonPrintMaterialDto[] = [];
        let overviewData: LessonPrintOverviewDto[][] = [];

        let printMaterialIdList = new Set();
        this.tableComponentList.forEach((d: PrintComponent[]) => {
            let dayData: LessonPrintOverviewDto[] = [];
            d.forEach((conp: PrintComponent) => {
                conp?.haveCustomConcept && !conp.isEmpty && conp?.materialId && conp?.isSelected && printMaterialIdList.add(conp.materialId);
                let conceptData: LessonPrintOverviewDto = {
                    title: conp?.title || '',
                    isEmpty: conp?.isEmpty || true,
                    haveCustomConcept: conp?.haveCustomConcept || false,
                    isSelected: conp?.isSelected || false,
                    description: conp?.description,
                    subTitle: conp?.subTitle
                };
                dayData.push(conceptData);
            });
            overviewData.push(dayData);
        })
        this.lessonDay.forEach(day => {
            let tmpList = this.addedComponentList[day]
                .filter(comp => printMaterialIdList.has(comp.materialId))
                .map(comp => {
                    return {
                        id: comp.materialId || '',
                        materialType: comp.materialType,
                        lessonDay: day.toUpperCase()
                    }
                });
            printList.push(...tmpList);
        });

        if (!(printList?.length || this.includeDailyOverview.value || this.includeWeeklyOverview.value)) {
            this.coreService.openSnackBar("Please select alteast one concept or Include Overview", 3000, "warn", "end", "bottom");
            this.isSavePrintInProgress = false;
            return;
        }
        this.isSavePrintInProgress = true;
        lessonPrint = {
            materialData: printList,
            lessonOverview: overviewData,
            includeDailyOverview: this.includeDailyOverview.value,
            includeWeeklyOverview: this.includeWeeklyOverview.value
        };

        let apiSub = this.coreService.printLessonPdf(lessonPrint).subscribe({
            next: (data: Blob) => {
                try {
                    this.isSavePrintInProgress = false;
                    this.coreService.openPdf(data, 'Lesson Print');
                } catch (err) {
                    console.log(err);
                    this.coreService.openSnackBar("Error occurred while opening PDF", 3000, "warn", "end", "bottom");
                }
            },
            error: err => {
                this.isSavePrintInProgress = false;
                console.log(err);
                this.coreService.openSnackBar("Some error occurred", 3000, "warn", "end", "bottom");
            }
        });
        this._subscription.push(apiSub);

    }

    stopPropagation(event: Event) {
        event && event.stopPropagation();
    }

    updateCheckbox(selectedPrint: PrintComponent) {
        if (selectedPrint && selectedPrint.haveCustomConcept) {
            selectedPrint.isSelected = !selectedPrint.isSelected;
        }
    }

    updateCheckboxByDay(selectionValue: boolean, index: number) {
        this.tableComponentList.forEach((d: PrintComponent[]) => {
            let conp = d[index];
            if (conp && conp.haveCustomConcept) {
                conp.isSelected = selectionValue;
            }
        })
    }
}

interface PrintComponent {
    materialId?: string,
    materialType: string,
    title?: string,
    subTitle?: string,
    preMadeUrl?: string,
    haveCustomConcept: boolean,
    isSelected: boolean,
    lessonDay: string,
    isEmpty: boolean,
    description?: {
        title?: string;
        listPoints?: string[][]
    }[];
}