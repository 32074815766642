import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'src/app/classes/user';
import { CoreService } from '../../core.service';
import { LoginService } from '../../login.service';
import { MfaVerifyDialog } from '../dialog-box/mfa-code-verify/app-mfa-verify.component';
import { ConvertToDistrict } from '../dialog-box/app-convert-district/app-convert-district.component';

@Component({
  selector: 'app-login',
  templateUrl: './app-login.component.html',
  styleUrls: ['./app-login.component.scss']
})
export class AppLoginComponent implements OnInit, OnDestroy {
  userName!: string;
  userPass!: string;
  loginErrorMessage = '';
  ssoErrorMessage = '';
  apiLoading = false;
  sessionId: string;
  sessionId2: string;
  // rememberMeSet!: boolean;

  //DA SSO values
  teacherId!: string | null;
  classId!: string | null;
  gotoPage!: string | null;

  _subscription: Subscription[] = [];
  displayLogin = true;
  constructor(
    private router: Router,
    private loginService: LoginService,
    private coreService: CoreService,
    private dialog: MatDialog,
  ) {
    if (this.loginService.userData) {
      this.router.navigate(['dashboard']);
    }

    this.sessionId = sessionStorage.getItem('sessionId') || '';
    this.sessionId2 = sessionStorage.getItem('sessionId2') || '';
    this.teacherId = sessionStorage.getItem('teacherId'); // this will be set when SSO from DA app
    this.classId = sessionStorage.getItem('classId');
    this.sessionId && this.loginBySessionId();
  }

  ngOnDestroy(): void {
    this._subscription.forEach(sub => sub?.unsubscribe());
  }

  ngOnInit(): void {
  }

  clearLoginErrorMessage() {
    this.loginErrorMessage = '';
    this.ssoErrorMessage = '';
  }

  forgotPasswordClicked() {

  }

  // rememberMeChecked() {

  // }

  termsOfServiceAndPrivacy() {

  }

  handleLogin() {
    if (this.userName && this.userPass) {
      this.clearLoginErrorMessage();
      this.apiLoading = true;
      let apiSub = this.loginService.authenticateUser(this.userName, this.userPass).subscribe({
        next: userData => {
          if (userData?.verifyNeeded) {
            this.openMfaDialog(userData);
          } else {
            this.checkForDistrictConversion(userData);
          }
        },
        error: err => {
          this.apiLoading = false;
          if (err?.message && err.message.indexOf('Invalid Credentials') != -1) {
            this.loginErrorMessage = 'InvalidCredentials';
          } else {
            this.loginErrorMessage = 'InvalidSubscription';
          }
        }
      });
      this._subscription.push(apiSub);
    } else {
      this.loginErrorMessage = 'Please enter Username and password';
    }
  }

  loginBySessionId() {
    this.displayLogin = false;
    this.clearLoginErrorMessage();
    let apiSub = this.loginService.loginUsingSessionId(this.sessionId, this.sessionId2).subscribe({
      next: userData => {
        userData.ssoToken = this.sessionId;
        if (this.teacherId && this.classId) { // this case is for DA impersonate as IOG user, no need of DA convert
          // Only class and students page can be Accessed, no access other pages. RouteGuard will be take care of this          
          let teacherName = sessionStorage.getItem('teacherName') || '';
          let gotoPage = sessionStorage.getItem('goto') || '';
          userData.adminId = userData.id;
          // update user data.id with teacher id and set to service. 
          // Because login belongs DA and will be inpersonated by teacher
          userData.id = this.teacherId;
          userData.teacherId = this.teacherId;
          userData.teacherName = teacherName;
          userData.ssoReadOnly = true;
          this.loginService.userData = userData;
          this.coreService.userData = userData;
          let getClassSub = this.coreService.getClassData(this.classId).subscribe({
            next: updatedClass => {
              updatedClass.readOnly = true;
              this.coreService.selectedClass = updatedClass;
              if (gotoPage === 'students') {
                this.router.navigate(['student-assessment']);
              } else {
                this.router.navigate(['class']);
              }
            },
            error: err => {
              console.log(err);
              this.coreService.openSnackBar("Error Occured, please try later", 3000, "warn", "end", "bottom");
            }
          });
          this._subscription.push(getClassSub);
          // if student page then, update student page to make all read only
          // handle back and page closing

        } else {
          this.checkForDistrictConversion(userData);
        }
      },
      error: err => {
        console.log(err);
        this.displayLogin = true;
        this.ssoErrorMessage = err;
      }
    });
    this._subscription.push(apiSub);
  }

  openMfaDialog(userData: User) {
    const mfaDialog = this.dialog.open(MfaVerifyDialog, {
      data: {
        userData: userData,
      },
      disableClose: true,
    });
    let dialogSub = mfaDialog.afterClosed().subscribe({
      next: (isVerified: boolean | null) => {
        if (isVerified) {
          this.checkForDistrictConversion(userData);
        } else {
          this.userName = '';
          this.userPass = '';
          this.loginErrorMessage = 'Please Login Again';
          this.apiLoading = false;
        }
      }
    });
    this._subscription.push(dialogSub);
  }

  checkForDistrictConversion(userData: User) {
    if (!userData?.convertDistrict) {
      this.setUserData(userData);
      return;
    }
    const disDialog = this.dialog.open(ConvertToDistrict, {
      data: {
        userData: userData,
      },
      disableClose: true,
    });
    let diaSub = disDialog.afterClosed().subscribe({
      next: resp => {
        if (resp) {
          // if (this.sessionId) {
          //   this.loginBySessionId();
          // } else {
          //   this.handleLogin();
          // }
          // userData.districtManaged = true;
          if (resp === 'notNow') {
            this.setUserData(userData);
          }
        } else {
          this.userName = '';
          this.userPass = '';
          this.loginErrorMessage = 'Please Login Again';
          this.apiLoading = false;
          this.displayLogin = true;
        }
      }
    });
    this._subscription.push(diaSub);

  }

  setUserData(userData: User) {
    this.coreService.setUserData(userData);
  }

}
