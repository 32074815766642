<div class="terms-container">
    <table>
        <tbody>
            <tr>
                <td style="text-align:center;">
                    <h2><strong>TERMS OF SERVICE</strong></h2>
                </td>
            </tr>
            <tr class="body">
                <td>
                    <div class="toc">THE ORDER FORM(S) OF THE INSTITUTE FOR MULTI-SENSORY EDUCATION, L.L.C. (<strong>"IMSE"</strong>) AND THESE SERVICE TERMS AND CONDITIONS (COLLECTIVELY, THIS <strong>"AGREEMENT"</strong>) SHALL CONSTITUTE THE ENTIRE AGREEMENT BETWEEN
                        THE TEACHER IDENTIFIED IN THE ORDER FORM (<strong>"TEACHER"</strong>) AND IMSE CONCERNING TEACHER'S USE OF THE SERVICES. BY ORDERING OR OTHERWISE USING THE SERVICES, TEACHER AGREES TO AND ACCEPTS THIS AGREEMENT, INCLUDING THE SPECIFIC
                        LIMITATIONS SET FORTH IN SECTIONS 2, 7, 8, 9, AND 11. TEACHER MAY USE THE SERVICES ONLY IN ACCORDANCE WITH THIS AGREEMENT. NO OTHER CONTRACT OR TERMS CONCERNING THE SERVICES MAY BE CREATED IN ANY OTHER MANNER, INCLUDING BY MEANS
                        OF TEACHER'S PURCHASE ORDERS OR SIMILAR DOCUMENTS (EVEN IF SIGNED OR ACKNOWLEDGED BY IMSE), AND SHALL NOT MODIFY OR AMEND THIS AGREEMENT. IMSE AND TEACHER MAY BE REFERRED TO HEREIN COLLECTIVELY AS THE <strong>"PARTIES"</strong>                        OR INDIVIDUALLY AS A <strong>"PARTY."</strong></div>
                    <div class="toc"><u>1. Definitions</u></div>

                    <div class="toc"><strong>"Authorized User"</strong> means Teacher and any of Teacher's agents (i) who are authorized by Teacher to access and use the Services under the rights granted to Teacher pursuant to this Agreement and (ii) for whom access to
                        the Services has been purchased hereunder.</div>

                    <div class="toc"><strong>"Documentation"</strong> means IMSE's user manuals, handbooks, and guides relating to the Services provided by IMSE to Teacher either electronically or in hard copy form, which may be amended from time to time by IMSE.</div>

                    <div class="toc"><strong>"IMSE IP"</strong> means the Services, the Documentation, and any and all intellectual property provided to Teacher or any Authorized User in connection with the foregoing. For the avoidance of doubt, IMSE IP includes Usage
                        Data and any information, data, or other content derived from IMSE's monitoring of Teacher's access to or use of the Services, but does not include Teacher Data.</div>

                    <div class="toc"><strong>"Order Form(s)"</strong> means the order forms or other forms from IMSE (submitted in written form or online) evidencing the initial order for access and use of the Services, and any subsequent order forms or other forms from
                        IMSE.
                    </div>

                    <div class="toc"><strong>"Services"</strong> means IMSE's proprietary software-as-a-service applications known as Interactive OG and IMSE Assessment, online lesson planning and assessment applications.</div>

                    <div class="toc"><strong>"Student Assessment Data"</strong> means other than Usage Data, information, data, and other content, in any form or medium, that is submitted, posted, or otherwise transmitted by or on behalf of Teacher or an Authorized User
                        through the Service with respect to documenting the knowledge, skill, aptitude or other learning outcomes of students based on student examination or other data to refine programs and improve student learning. Student Assessment
                        Data is anonomyized data and does not, and cannot be used to, identify any individual student.<strong>"Teacher Data"</strong> means, other than Usage Data, information, data, and other content, in any form or medium, that is submitted,
                        posted, or otherwise transmitted by or on behalf of Teacher or an Authorized User through the Services, including, without limitation, Student Assessment Data. <strong>"Third-Party Products"</strong> means any third-party products
                        described in the Documentation provided with or incorporated into the Services.</div>

                    <div class="toc"><strong>"Usage Data"</strong> means data and information related to Teacher's use of the Services that is used by IMSE in an anonymized manner, including to compile statistical and performance information related to the provision,
                        effectiveness, and operation of the Services.</div>

                    <div class="toc"><u>2. Access and Use</u></div>

                    <div class="toc">(a) <u>Provision of Access.</u> Subject to and conditioned on Teacher's payment of Fees and compliance with all other terms and conditions of this Agreement, IMSE hereby grants Teacher a non-exclusive, non-transferable (except in compliance
                        with Section 12(g)) right to access and use the Services during the Term, solely for use by Authorized Users in accordance with the terms and conditions herein. Such use is limited to Teacher's internal use. IMSE shall provide
                        to Teacher the necessary passwords and network links or connections to allow Teacher to access the Services. </div>

                    <div class="toc">(b) <u>Documentation License.</u> Subject to the terms and conditions contained in this Agreement, IMSE hereby grants to Teacher a non-exclusive, non-sublicenseable, non-transferable (except in compliance with Section 12(g)) license
                        to use the Documentation during the Term solely for Teacher's internal business purposes in connection with its use of the Services.</div>

                    <div class="toc">(c) <u>Use Restrictions.</u> Teacher shall not use the Services for any purposes beyond the scope of the access granted in this Agreement. Teacher shall not at any time, directly or indirectly, and shall not permit any Authorized Users
                        to: (i) copy, modify, or create derivative works of the Services or Documentation, in whole or in part; (ii) rent, lease, lend, sell, license, sublicense, assign, distribute, publish, transfer, or otherwise make available the Services
                        or Documentation; (iii) reverse engineer, disassemble, decompile, decode, adapt, or otherwise attempt to derive or gain access to any software component of the Services, in whole or in part; (iv) remove any proprietary notices
                        from the Services or Documentation; or (v) use the Services or Documentation in any manner or for any purpose that infringes, misappropriates, or otherwise violates any intellectual property right or other right of any person,
                        or that violates any applicable law.</div>

                    <div class="toc">(d) <u>Reservation of Rights.</u> IMSE reserves all rights not expressly granted to Teacher in this Agreement. Except for the limited rights and licenses expressly granted under this Agreement, nothing in this Agreement grants, by
                        implication, waiver, estoppel, or otherwise, to Teacher or any third party any intellectual property rights or other right, title, or interest in or to the IMSE IP.</div>

                    <div class="toc">(e) <u>Suspension.</u> Notwithstanding anything to the contrary in this Agreement, IMSE may temporarily suspend Teacher's and any Authorized End User's access to any portion or all of the Services if: (i) IMSE reasonably determines
                        that (A) there is a threat or attack on any of the IMSE IP; (B) Teacher's or any Authorized End User's use of the IMSE IP disrupts or poses a security risk to the IMSE IP or to any other Teacher or vendor of IMSE; (C) Teacher,
                        or any Authorized End User, is using the IMSE IP for fraudulent or illegal activities; (D) subject to applicable law, Teacher has ceased to continue its business in the ordinary course, made an assignment for the benefit of creditors
                        or similar disposition of its assets, or become the subject of any bankruptcy, reorganization, liquidation, dissolution, or similar proceeding; or (E) IMSE's provision of the Services to Teacher or any Authorized End User is prohibited
                        by applicable law; (ii) any vendor of IMSE has suspended or terminated IMSE's access to or use of any third-party services or products required to enable Teacher to access the Services; or (iii) in accordance with Section 5(a)(iii)
                        (any such suspension described in subclause (i), (ii), or (iii), a <strong>"Service Suspension"</strong>). IMSE shall use commercially reasonable efforts to provide written notice of any Service Suspension to Teacher and to provide
                        updates regarding resumption of access to the Services following any Service Suspension. IMSE shall use commercially reasonable efforts to resume providing access to the Services as soon as reasonably possible after the event giving
                        rise to the Service Suspension is cured. IMSE will have no liability for any damage, liabilities, losses (including any loss of data or profits), or any other consequences that Teacher or any Authorized User may incur as a result
                        of a Service Suspension.</div>

                    <div class="toc">(f) <u>Usage Data.</u> Notwithstanding anything to the contrary in this Agreement, IMSE may monitor Teacher's use of the Services and collect and compile Usage Data. As between IMSE and Teacher, all right, title, and interest in Usage
                        Data, and all intellectual property rights therein, belong to and are retained solely by IMSE. Teacher acknowledges that IMSE may compile Usage Data based on Teacher Data input into the Services. Teacher agrees that IMSE may (i)
                        make Usage Data publicly available in compliance with applicable law, and (ii) use Usage Data to the extent and in the manner permitted under applicable law; provided that such Usage Data do not identify Teacher or any student
                        of Teacher or Teacher's Confidential Information. Without limiting the generality of the foregoing, Teacher acknowledges and agreements that IMSE may use Student Assessment Data, either individually or aggregated, for purposes
                        of measuring, analyzing, and improving student learning outcomes.</div>


                    <div class="toc">3. <u>Teacher Responsibilities</u></div>

                    <div class="toc">(a) <u>General.</u> Teacher is responsible and liable for all uses of the Services and Documentation resulting from access provided by Teacher, directly or indirectly, whether such access or use is permitted by or in violation of this
                        Agreement. Without limiting the generality of the foregoing, Teacher is responsible for all acts and omissions of Authorized Users, and any act or omission by an Authorized User that would constitute a breach of this Agreement
                        if taken by Teacher will be deemed a breach of this Agreement by Teacher. Teacher shall use reasonable efforts to make all Authorized Users aware of this Agreement's provisions as applicable to such Authorized User's use of the
                        Services, and shall cause Authorized Users to comply with such provisions.</div>

                    <div class="toc">(b) <u>Third-Party Products.</u> IMSE may from time to time make Third-Party Products available to Teacher. For purposes of this Agreement, such Third-Party Products are subject to their own terms and conditions and the applicable
                        flow through provisions referred to in the Documentation. If Teacher does not agree to abide by the applicable terms for any such Third-Party Products, then Teacher should not install or use such Third-Party Products.</div>

                    <div class="toc">4. <u>Service Levels and Support.</u></div>

                    <div class="toc">(a) <u>Service Levels.</u> Subject to the terms and conditions of this Agreement, IMSE shall use commercially reasonable efforts to make the Services available during standard business hours. The Services may be subject to downtime
                        during non-business hours for maintance. </div>

                    <div class="toc">(b) <u>Support.</u> IMSE provides telephone and email technical support for the Services during standard business hours in accordance with IMSE's standard support services policy then in effect. Support hours are subject to change
                        without advanced notice.</div>

                    <div class="toc">5. <u>Fees and Payment.</u> </div>

                    <div class="toc">(a) <u>Fees.</u> Teacher shall pay IMSE the applicable fees (<strong>"Fees"</strong>) for use of the Services as provided for in the Order Form(s) in without offset or deduction. Teacher shall make all payments hereunder in US dollars
                        on or before the due date set forth in the Order Forms. If Teacher fails to make any payment when due, without limiting IMSE's other rights and remedies: (i) IMSE may charge interest on the past due amount at the rate of 1.5% per
                        month calculated daily and compounded monthly or, if lower, the highest rate permitted under applicable law; (ii) Teacher shall reimburse IMSE for all reasonable costs incurred by IMSE in collecting any late payments or interest,
                        including attorneys' fees, court costs, and collection agency fees; and (iii) if such failure continues for five (5) business days or more, IMSE may suspend Teacher's and its Authorized Users' access to any portion or all of the
                        Services until such amounts are paid in full.</div>

                    <div class="toc">(b) <u>Taxes.</u> All Fees and other amounts payable by Teacher under this Agreement are exclusive of taxes and similar assessments. Teacher is responsible for all sales, use, and excise taxes, and any other similar taxes, duties,
                        and charges of any kind imposed by any federal, state, or local governmental or regulatory authority on any amounts payable by Teacher hereunder, other than any taxes imposed on IMSE's income.</div>

                    <div class="toc">6. <u>Confidential Information.</u> From time to time during the Term, either Party may disclose or make available to the other Party information about its business affairs, products, confidential intellectual property, trade secrets,
                        third-party confidential information, and other sensitive or proprietary information, that is marked, designated or otherwise identified as "confidential" (collectively, <strong>"Confidential Information"</strong>). Confidential
                        Information does not include information that, at the time of disclosure is: (a) in the public domain; (b) known to the receiving Party at the time of disclosure; (c) rightfully obtained by the receiving Party on a non-confidential
                        basis from a third party; or (d) independently developed by the receiving Party. The receiving Party shall not disclose the disclosing Party's Confidential Information to any person or entity, except to the receiving Party's employees
                        who have a need to know the Confidential Information for the receiving Party to exercise its rights or perform its obligations hereunder. Notwithstanding the foregoing, each Party may disclose Confidential Information to the limited
                        extent required (i) in order to comply with the order of a court or other governmental body, or as otherwise necessary to comply with applicable law, provided that the Party making the disclosure pursuant to the order shall first
                        have given written notice to the other Party and made a reasonable effort to obtain a protective order; or (ii) to establish a Party's rights under this Agreement, including to make required court filings. On the expiration or
                        termination of the Agreement, the receiving Party shall promptly return to the disclosing Party all copies, whether in written, electronic, or other form or media, of the disclosing Party's Confidential Information, or destroy
                        all such copies and certify in writing to the disclosing Party that such Confidential Information has been destroyed. Each Party's obligations of non-disclosure with regard to Confidential Information are effective as of the Effective
                        Date and will expire five years from the date first disclosed to the receiving Party; provided, however, with respect to any Confidential Information that constitutes a trade secret (as determined under applicable law), such obligations
                        of non-disclosure will survive the termination or expiration of this Agreement for as long as such Confidential Information remains subject to trade secret protection under applicable law.
                    </div>

                    <div class="toc">7. <u>Intellectual Property Ownership; Feedback.</u></div>

                    <div class="toc">(a) <u>IMSE IP.</u> Teacher acknowledges that, as between Teacher and IMSE, IMSE owns all right, title, and interest, including all intellectual property rights, in and to the IMSE IP and, with respect to Third-Party Products, the
                        applicable third-party IMSEs own all right, title, and interest, including all intellectual property rights, in and to the Third-Party Products.</div>

                    <div class="toc">(b) <u>Teacher Data.</u> IMSE acknowledges that, as between IMSE and Teacher, Teacher owns all right, title, and interest, including all intellectual property rights, in and to the Teacher Data. Teacher hereby grants to IMSE a non-exclusive,
                        royalty-free, worldwide license to reproduce, distribute, and otherwise use and display the Teacher Data and perform all acts with respect to the Teacher Data as may be necessary for IMSE to provide the Services to Teacher, and
                        a non-exclusive, perpetual, irrevocable, royalty-free, worldwide license to reproduce, distribute, modify, and otherwise use and display Teacher Data incorporated within the Usage Data.</div>

                    <div class="toc">(c) <u>Feedback.</u> If Teacher or any of its employees or contractors sends or transmits any communications or materials to IMSE by mail, email, telephone, or otherwise, suggesting or recommending changes to the IMSE IP, including
                        without limitation, new features or functionality relating thereto, or any comments, questions, suggestions, or the like (<strong>"Feedback"</strong>), IMSE is free to use such Feedback irrespective of any other obligation or limitation
                        between the Parties governing such Feedback. Teacher hereby assigns to IMSE on Teacher's behalf, and on behalf of its employees, contractors and/or agents, all right, title, and interest in, and IMSE is free to use, without any
                        attribution or compensation to any party, any ideas, know-how, concepts, techniques, or other intellectual property rights contained in the Feedback, for any purpose whatsoever, although IMSE is not required to use any Feedback.</div>

                    <div class="toc">8. <u>Warranty Disclaimer.</u></div>

                    <div class="toc">THE IMSE IP IS PROVIDED "AS IS" AND IMSE HEREBY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE. IMSE SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
                        AND NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE OF DEALING, USAGE, OR TRADE PRACTICE. IMSE MAKES NO WARRANTY OF ANY KIND THAT THE IMSE IP, OR ANY PRODUCTS OR RESULTS OF THE USE THEREOF, WILL MEET TEACHER'S OR ANY OTHER
                        PERSON'S REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED RESULT, BE COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM OR OTHER SERVICES, OR BE SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL CODE, OR ERROR FREE.
                    </div>

                    <div class="toc">9. <u>Indemnification.</u> </div>

                    <div class="toc">(a) <u>IMSE Indemnification.</u></div>

                    <div class="toc">(i) IMSE shall indemnify, defend, and hold harmless Teacher from and against any and all losses, damages, liabilities, costs (including reasonable attorneys' fees) (<strong>"Losses"</strong>) incurred by Teacher resulting from any
                        third-party claim, suit, action, or proceeding (<strong>"Third-Party Claim"</strong>) that the Services, or any use of the Services in accordance with this Agreement, infringes or misappropriates such third party's US intellectual
                        property rights, provided that Teacher promptly notifies IMSE in writing of the claim, cooperates with IMSE, and allows IMSE sole authority to control the defense and settlement of such claim.</div>

                    <div class="toc">(ii) If such a claim is made or appears possible, Teacher agrees to permit IMSE, at IMSE's sole discretion, to (A) modify or replace the Services, or component or part thereof, to make it non-infringing, or (B) obtain the right for
                        Teacher to continue use. If IMSE determines that neither alternative is reasonably available, IMSE may terminate this Agreement, in its entirety or with respect to the affected component or part, effective immediately on written
                        notice to Teacher. </div>

                    <div class="toc">(iii) This Section 9(a) will not apply to the extent that the alleged infringement arises from: (A) use of the Services in combination with data, software, hardware, equipment, or technology not provided by IMSE or authorized by IMSE
                        in writing; (B) modifications to the Services not made by IMSE; (C) Teacher Data; or (D) Third-Party Products.</div>

                    <div class="toc">(b) <u>Teacher Indemnification.</u> Teacher shall indemnify, hold harmless, and, at IMSE's option, defend IMSE from and against any Losses resulting from any Third-Party Claim that the Teacher Data, or any use of the Teacher Data in
                        accordance with this Agreement, infringes or misappropriates such third party's US intellectual property rights and any Third-Party Claims based on Teacher's or any Authorized User's (i) negligence or willful misconduct; (ii) use
                        of the Services in a manner not authorized by this Agreement; (iii) use of the Services in combination with data, software, hardware, equipment or technology not provided by IMSE or authorized by IMSE in writing; or (iv) modifications
                        to the Services not made by IMSE, provided that Teacher may not settle any Third-Party Claim against IMSE unless IMSE consents to such settlement, and further provided that IMSE will have the right, at its option, to defend itself
                        against any such Third-Party Claim or to participate in the defense thereof by counsel of its own choice. </div>

                    <div class="toc">(c) <u>Sole Remedy.</u> THIS SECTION 9 SETS FORTH TEACHER'S SOLE REMEDIES AND IMSE'S SOLE LIABILITY AND OBLIGATION FOR ANY ACTUAL, THREATENED, OR ALLEGED CLAIMS THAT THE SERVICES INFRINGE, MISAPPROPRIATE, OR OTHERWISE VIOLATE ANY INTELLECTUAL
                        PROPERTY RIGHTS OF ANY THIRD PARTY. </div>

                    <div class="toc">10. <u>Limitations of Liability.</u> IN NO EVENT WILL IMSE BE LIABLE UNDER OR IN CONNECTION WITH THIS AGREEMENT UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE,
                        FOR ANY: (a) CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED, OR PUNITIVE DAMAGES; (b) INCREASED COSTS, DIMINUTION IN VALUE OR LOST BUSINESS, PRODUCTION, REVENUES, OR PROFITS; (c) LOSS OF GOODWILL OR REPUTATION;
                        (d) USE, INABILITY TO USE, LOSS, INTERRUPTION, DELAY OR RECOVERY OF ANY DATA, OR BREACH OF DATA OR SYSTEM SECURITY; OR (e) COST OF REPLACEMENT GOODS OR SERVICES, IN EACH CASE REGARDLESS OF WHETHER IMSE WAS ADVISED OF THE POSSIBILITY
                        OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE. IN NO EVENT WILL IMSE'S AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT,
                        TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE EXCEED THE TOTAL AMOUNTS PAID TO IMSE UNDER THIS AGREEMENT IN THE TWELVE (12) MONTH PERIOD PRECEDING THE EVENT GIVING RISE TO THE CLAIM.</div>

                    <div class="toc">11. <u>Term and Termination.</u></div>

                    <div class="toc">(a) <u>Term.</u> The initial term of this Agreement begins on the Effective Date and, unless terminated earlier pursuant to this Agreement's express provisions, will continue in effect until one (1) year from such date (the <strong>"Initial Term"</strong>).
                        This Agreement will automatically renew for additional successive one (1) year terms unless earlier terminated pursuant to this Agreement's express provisions or either Party gives the other Party written notice of non-renewal
                        at least 90 days prior to the expiration of the then-current term (each a <strong>"Renewal Term"</strong> and together with the Initial Term, the <strong>"Term"</strong>)</div>

                    <div class="toc">(b) <u>Termination.</u> In addition to any other express termination right set forth in this Agreement:</div>

                    <div class="toc">(i) IMSE may terminate this Agreement, effective on written notice to Teacher, if Teacher: (A) fails to pay any amount when due hereunder, and such failure continues more than five (5) business days after IMSE's delivery of written
                        notice thereof; or (B) breaches any of its obligations under Section 2(c) or Section 6</div>

                    <div class="toc">(ii) either Party may terminate this Agreement, effective on written notice to the other Party, if the other Party materially breaches this Agreement, and such breach: (A) is incapable of cure; or (B) being capable of cure, remains
                        uncured 30 days after the non-breaching Party provides the breaching Party with written notice of such breach; or</div>

                    <div class="toc">(iii) either Party may terminate this Agreement, effective immediately upon written notice to the other Party, if the other Party: (A) becomes insolvent or is generally unable to pay, or fails to pay, its debts as they become due;
                        (B) files or has filed against it, a petition for voluntary or involuntary bankruptcy or otherwise becomes subject, voluntarily or involuntarily, to any proceeding under any domestic or foreign bankruptcy or insolvency law; (C)
                        makes or seeks to make a general assignment for the benefit of its creditors; or (D) applies for or has appointed a receiver, trustee, custodian, or similar agent appointed by order of any court of competent jurisdiction to take
                        charge of or sell any material portion of its property or business.</div>

                    <div class="toc">(c) <u>Effect of Expiration or Termination.</u> Upon expiration or earlier termination of this Agreement, Teacher shall immediately discontinue use of the IMSE IP and, without limiting Teacher's obligations under Section 6, Teacher
                        shall delete, destroy, or return all copies of the IMSE IP and certify in writing to the IMSE that the IMSE IP has been deleted or destroyed. No expiration or termination will affect Teacher's obligation to pay all Fees that may
                        have become due before such expiration or termination, or entitle Teacher to any refund.</div>

                    <div class="toc">(d) <u>Survival.</u> This Section 11(d) and Sections 1, 5, 6, 7, 8(b), 9, 10, and 12 survive any termination or expiration of this Agreement. No other provisions of this Agreement survive the expiration or earlier termination of this
                        Agreement.
                    </div>

                    <div class="toc">12. <u>Miscellaneous.</u></div>

                    <div class="toc">(a) <u>Entire Agreement.</u> This Agreement, together with any other documents incorporated herein by reference and all related Exhibits, constitutes the sole and entire agreement of the Parties with respect to the subject matter of
                        this Agreement and supersedes all prior and contemporaneous understandings, agreements, and representations and warranties, both written and oral, with respect to such subject matter. In the event of any inconsistency between the
                        statements made in the body of this Agreement, the related Exhibits, and any other documents incorporated herein by reference, the following order of precedence governs: (i) first, this Agreement, excluding its Exhibits; (ii) second,
                        the Exhibits to this Agreement as of the Effective Date; and (iii) third, any other documents incorporated herein by reference.</div>

                    <div class="toc">(b) <u>Notices.</u> All notices, requests, consents, claims, demands, waivers, and other communications hereunder (each, a <strong>"Notice"</strong>) must be in writing and addressed to the Parties at the addresses set forth on the
                        first page of this Agreement (or to such other address that may be designated by the Party giving Notice from time to time in accordance with this Section). All Notices must be delivered by personal delivery, nationally recognized
                        overnight courier (with all fees pre-paid), facsimile or email (with confirmation of transmission) or certified or registered mail (in each case, return receipt requested, postage pre-paid). Except as otherwise provided in this
                        Agreement, a Notice is effective only: (i) upon receipt by the receiving Party; and (ii) if the Party giving the Notice has complied with the requirements of this Section.</div>

                    <div class="toc">(c) <u>Force Majeure.</u> In no event shall IMSE be liable to Teacher, or be deemed to have breached this Agreement, for any failure or delay in performing its obligations under this Agreement, if and to the extent such failure or
                        delay is caused by any circumstances beyond IMSE's reasonable control, including but not limited to acts of God, flood, fire, earthquake, explosion, war, terrorism, invasion, riot or other civil unrest, strikes, labor stoppages
                        or slowdowns or other industrial disturbances, or passage of law or any action taken by a governmental or public authority, including imposing an embargo.</div>

                    <div class="toc">(d) <u>Amendment and Modification;</u> Waiver. No amendment to or modification of this Agreement is effective unless it is in writing and signed by an authorized representative of each Party. No waiver by any Party of any of the provisions
                        hereof will be effective unless explicitly set forth in writing and signed by the Party so waiving. Except as otherwise set forth in this Agreement, (i) no failure to exercise, or delay in exercising, any rights, remedy, power,
                        or privilege arising from this Agreement will operate or be construed as a waiver thereof and (ii) no single or partial exercise of any right, remedy, power, or privilege hereunder will preclude any other or further exercise thereof
                        or the exercise of any other right, remedy, power, or privilege.</div>

                    <div class="toc">(e) <u>Severability.</u> If any provision of this Agreement is invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability will not affect any other term or provision of this Agreement or
                        invalidate or render unenforceable such term or provision in any other jurisdiction. Upon such determination that any term or other provision is invalid, illegal, or unenforceable, the Parties shall negotiate in good faith to modify
                        this Agreement so as to effect their original intent as closely as possible in a mutually acceptable manner in order that the transactions contemplated hereby be consummated as originally contemplated to the greatest extent possible.</div>

                    <div class="toc">(f) <u>Governing Law; Submission to Jurisdiction.</u> This Agreement is governed by and construed in accordance with the internal laws of the State of Michigan without giving effect to any choice or conflict of law provision or rule
                        that would require or permit the application of the laws of any jurisdiction other than those of the State of Michigan. Any legal suit, action, or proceeding arising out of or related to this Agreement or the licenses granted hereunder
                        will be instituted exclusively in the federal courts of the United States or the courts of the State of Michigan in each case located in the city of Warren and Macomb County, and each Party irrevocably submits to the exclusive
                        jurisdiction of such courts in any such suit, action, or proceeding.</div>

                    <div class="toc">(g) <u>Assignment.</u> Teacher may not assign any of its rights or delegate any of its obligations hereunder, in each case whether voluntarily, involuntarily, by operation of law or otherwise, without the prior written consent of IMSE,
                        which consent shall not be unreasonably withheld, conditioned, or delayed. Any purported assignment or delegation in violation of this Section will be null and void. No assignment or delegation will relieve the assigning or delegating
                        Party of any of its obligations hereunder. This Agreement is binding upon and inures to the benefit of the Parties and their respective permitted successors and assigns. </div>

                    <div class="toc">(h) <u>Export Regulation.</u> The Services utilize software and technology that may be subject to US export control laws, including the US Export Administration Act and its associated regulations. Teacher shall not, directly or indirectly,
                        export, re-export, or release the Services or the underlying software or technology to, or make the Services or the underlying software or technology accessible from, any jurisdiction or country to which export, re-export, or release
                        is prohibited by law, rule, or regulation. Teacher shall comply with all applicable federal laws, regulations, and rules, and complete all required undertakings (including obtaining any necessary export license or other governmental
                        approval), prior to exporting, re-exporting, releasing, or otherwise making the Services or the underlying software or technology available outside the US. </div>

                    <div class="toc">(i) <u>US Government Rights.</u> Each of the Documentation and the software components that constitute the Services is a "commercial item" as that term is defined at 48 C.F.R. § 2.101, consisting of "commercial computer software" and
                        "commercial computer software documentation" as such terms are used in 48 C.F.R. § 12.212. Accordingly, if Teacher is an agency of the US Government or any contractor therefor, Teacher only receives those rights with respect to
                        the Services and Documentation as are granted to all other end users, in accordance with (a) 48 C.F.R. § 227.7201 through 48 C.F.R. § 227.7204, with respect to the Department of Defense and their contractors, or (b) 48 C.F.R. §
                        12.212, with respect to all other US Government users and their contractors.</div>

                    <div class="toc">(j) <u>Equitable Relief.</u> Each Party acknowledges and agrees that a breach or threatened breach by such Party of any of its obligations under Section 6 or, in the case of Teacher, Section 2(c), would cause the other Party irreparable
                        harm for which monetary damages would not be an adequate remedy and agrees that, in the event of such breach or threatened breach, the other Party will be entitled to equitable relief, including a restraining order, an injunction,
                        specific performance and any other relief that may be available from any court, without any requirement to post a bond or other security, or to prove actual damages or that monetary damages are not an adequate remedy. Such remedies
                        are not exclusive and are in addition to all other remedies that may be available at law, in equity or otherwise.</div>

                </td>
            </tr>
            <tr>
                <td>
                    <div class="CheckBoxContainer" [ngClass]="{'error': (acceptTermsControl.dirty || acceptTermsControl.touched) && !acceptTermsControl.value}">
                        <input type="checkbox" class="checkBox" id="acceptTerms" [formControl]="acceptTermsControl">
                        <label for="acceptTerms" class="checkBoxlabel">
                            <strong> Check here to indicate that you have read and agreed to the terms of Service of the IMSE Customer Agreement.</strong>
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td style="text-align:center;">
                    <h2><strong>Privacy Policy</strong></h2>
                </td>
            </tr>
            <tr class="body">
                <td>
                    <div class="toc"><strong>Introduction</strong></div>

                    <div class="toc">The Institute for Multi-Sensory Education, L.L.C. (<strong>"Company"</strong> or <strong>"we"</strong>, <strong>"us"</strong>, or <strong>"our"</strong> and similar designations) respects your privacy and is committed to protecting
                        it through our compliance with this policy.</div>

                    <div class="toc">This policy describes the types of information we may collect from you or that you may provide when you visit or use the Interactive OG and IMSE Assessment applications or any other integrated applications (collectively, our <strong>"Applications"</strong>)
                        and our practices for collecting, using, maintaining, protecting, and disclosing that information.</div>

                    <div class="toc">This policy applies to information we collect: On the Applications and integrated applications. In email, text, and other electronic messages between you and the Company
                    </div>
                    <div class="toc">
                        It does not apply to information collected by: us offline or through any other means, including on any other application or website operated by Company or any third party (including our affiliates and subsidiaries); or any third party (including our affiliates
                        and subsidiaries), including through any application or content that may link to or be accessible from or on the Applications.
                    </div>
                    <div class="toc">Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our Application. By accessing
                        or using this Application, you agree to this privacy policy. This policy may change from time to time (see <strong><u>Changes to Our Privacy Policy</u></strong>). Your continued use of this Application after we make changes is
                        deemed to be acceptance of those changes, so please check the policy periodically for updates. </div>

                    <u><strong>Children Under the Age of 18</strong></u>

                    <div class="toc">Our Application is not intended to allow the input and collection of personal information of children under 18 years of age. You are prohibited from inputting and collecting any personal information on the Application on behalf of
                        children under 18 years of age. We do not knowingly collect personal information from children under 18. Information inputted and collected by you about children under 18 years of age must be anonymized. If we learn we have collected
                        or received personal information from a child under 18 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 18, please contact us.</div>

                    <u><strong>Information We Collect About You and How We Collect It</strong></u>

                    <div class="toc">We collect several types of information from and about users of our Application, including information: by which you may be personally identified, such as your name, postal address, e-mail address, telephone number, or any other identifier
                        by which you may be contacted online or offline (<strong>"personal information"</strong>); that is about you but individually does not identify you, such as your school district, grade levels, classrooms, and lesson plans and student
                        assessment data; and/or about your internet connection, the equipment you use to access our Application and usage details.
                    </div>
                    <div class="toc">
                        We collect this information Directly from you when you provide it to us. Automatically as you navigate through the Application. Information collected automatically may include usage details, IP addresses, and information collected through cookies. From
                        third parties, for example, our business partners.
                    </div>
                    <div class="toc">
                        Information You Provide to Us. The information we collect on or through our Application may include: Information that you provide by filling in forms on our Application. This includes information provided at the time of registering to use our Application,
                        subscribing to our service, or requesting further services. We may also ask you for information when you report a problem with our Application. Records and copies of your correspondence (including email addresses), if you contact
                        us. Your responses to surveys that we might ask you to complete for research purposes Lesson plans and Student Assessment Data.
                    </div>
                    <div class="toc">
                        As further described in our Terms of Use, we use Student Assessment Data, either individually or aggregated, for purposes of measuring, analyzing, and improving student learning outcomes, including, without limitation, improving the services available
                        through the Applications or developing new services and applications.</div>

                    <div class="toc">Information We Collect Through Automatic Data Collection Technologies. As you navigate through and interact with our Application, we may use automatic data collection technologies to collect certain information about your equipment,
                        browsing actions, and patterns, including: Details of your visits to our Application, including traffic data, location data, logs, and other communication data and the resources that you access and use on the Application. Information
                        about your computer and internet connection, including your IP address, operating system, and browser type.
                    </div>
                    <div class="toc">The information we collect automatically is statistical data and does not, but we may maintain it or associate it with personal information we collect in other ways or receive from third parties. It helps us to improve our Application
                        and to deliver a better and more personalized service, including by enabling us to: Estimate our audience size and usage patterns. Store information about your preferences, allowing us to customize our Application according to
                        your individual interests. Speed up your searches. Recognize you when you return to our Application.
                    </div>
                    <div class="toc">The technologies we use for this automatic data collection may include:</div>
                    <div class="toc">
                        <strong>Cookies (or browser cookies).</strong> A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you
                        select this setting you may be unable to access certain parts of our Application. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Application.
                    </div>
                    <div class="toc">
                        We do not collect personal information automatically, but we may tie this information to personal information about you that we collect from other sources or you provide to us.
                    </div>
                    <u><strong>How We Use Your Information</strong></u>
                    <div class="toc">
                        We use information that we collect about you or that you provide to us, including any personal information: To present our Application and its contents to you. To provide you with information, products, or services that you request from us. To fulfill
                        any other purpose for which you provide it. To provide you with notices about your account/subscription, including expiration and renewal notices. To carry out our obligations and enforce our rights arising from any contracts entered
                        into between you and us, including for billing and collection. To notify you about changes to our Application or any products or services we offer or provide though it. In any other way we may describe when you provide the information.
                        For any other purpose with your consent.
                    </div>
                    <div class="toc">
                        We may also use your information to contact you about our own and third-parties' goods and services that may be of interest to you. If you do not want us to use your information in this way, please adjust your user preferences in your account profile.
                        For more information, see <strong><u>Choices About How We Use and Disclose Your Information.</u></strong>
                    </div>
                    <u><strong>Disclosure of Your Information</strong></u>
                    <div class="toc">
                        We may disclose aggregated information about our users, and information that does not identify any individual, without restriction.
                    </div>
                    <div class="toc">
                        We may disclose personal information that we collect or you provide as described in this privacy policy: To our subsidiaries and affiliates. To contractors, service providers, and other third parties we use to support our business and who are bound by
                        contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them. To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization,
                        dissolution, or other sale or transfer of some or all of Institute for Multi-Sensory Educations, L.L.C.s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information
                        held by Institute for Multi-Sensory Education, L.L.C. about our Application users is among the assets transferred. To third parties to market their products or services to you if you have not opted out of these disclosures. We
                        contractually require these third parties to keep personal information confidential and use it only for the purposes for which we disclose it to them. For more information, see <u><strong>Choices About How We Use and Disclose Your Information.</strong></u>                        To fulfill the purpose for which you provide it. For any other purpose disclosed by us when you provide the information. With your consent.
                    </div>
                    <div class="toc">
                        We may also disclose your personal information: To comply with any court order, law, or legal process, including to respond to any government or regulatory request. To enforce or apply our Terms of Use and other agreements, including for billing and collection
                        purposes. If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Institute for Multi-Sensory Education, L.L.C., our customers, or others. This includes exchanging information with other
                        companies and organizations for the purposes of fraud protection and credit risk reduction.
                    </div>
                    <u><strong>Choices About How We Use and Disclose Your Information</strong></u>
                    <div class="toc">
                        We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information:

                        <strong>Tracking Technologies and Advertising.</strong> You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit
                        the Flash player settings page on Adobe's Application. If you disable or refuse cookies, please note that some parts of this site may then be inaccessible or not function properly.
                        <strong>Disclosure of Your Information for Third-Party Advertising.</strong> If you do not want us to share your personal information with unaffiliated or non-agent third parties for promotional purposes, you can opt-out by hitting
                        unsubscribe on emails. You can also always opt-out by sending us an email stating your request to info@imse.com
                        <strong>Promotional Offers from the Company.</strong> If you do not wish to have your used by the Company to promote our own or third parties' products or services, you can opt-out by hitting unsubscribe on emails or at any other
                        time by sending us an email stating your request to info@imse.com. If we have sent you a promotional email, you may send us a return email asking to be omitted from future email distributions. This opt out does not apply to information
                        provided to the Company as a result of a purchase, service experience or other transactions.
                        <strong>Targeted Advertising.</strong> If you do not want us to use information that we collect or that you provide to us to deliver advertisements according to our advertisers' target-audience preferences, you can opt-out by sending
                        us an email stating your request to info@imse.com. For this opt-out to function, you must have your browser set to accept browser cookies.
                    </div>
                    <div class="toc">
                        We do not control third parties' collection or use of your information to serve interest-based advertising. However these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of
                        receiving targeted ads from members of the Network Advertising Initiative (<strong>"NAI"</strong>) on the NAI's Application.
                    </div>
                    <u><strong>Accessing and Correcting Your Information</strong></u>
                    <div class="toc">
                        You can review and change your personal information by logging into the Application and visiting your account profile page.
                    </div>
                    <u><strong>Your California Privacy Rights</strong></u>
                    <div class="toc">
                        California Civil Code Section § 1798.83 permits users of our Application that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a
                        request, please send an email to info@imse.com or write us at: Institute for Multi-Sensory Education, L.L.C., 2000 Town Center, Suite 2000, Southfield MI, 48075.
                    </div>
                    <u><strong>Data Security</strong></u>
                    <div class="toc">
                        We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls. Any payment
                        transactions handled by our third-party payment processors will be encrypted.
                    </div>
                    <div class="toc">
                        The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Application, you are responsible for keeping this password confidential. We ask you not to
                        share your password with anyone. We urge you to be careful about giving out information in public areas of the Application like message boards. The information you share in public areas may be viewed by any user of the Application.
                    </div>
                    <div class="toc">
                        Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Application. Any transmission
                        of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Application.
                    </div>
                    <u><strong>Changes to Our Privacy Policy</strong></u>
                    <div class="toc">
                        It is our policy to post any changes we make to our privacy policy on this page with a notice that the privacy policy has been updated on the Application home page. If we make material changes to how we treat our users' personal information, we will notify
                        you by email to the email address specified in your account and through a notice on the Application home page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring
                        we have an up-to-date active and deliverable email address for you, and for periodically visiting our Application and this privacy policy to check for any changes.
                    </div>
                    <u><strong>Contact Information</strong></u>
                    <div class="toc">
                        To ask questions or comment about this privacy policy and our privacy practices, contact us at: info@imse.com or via our toll-free number: 800-646-9788
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div class="CheckBoxContainer" [ngClass]="{'error': (acceptPrivacyControl.dirty || acceptPrivacyControl.touched) && !acceptPrivacyControl.value}">
                        <input type="checkbox" class="checkBox" id="acceptPrivacy" [formControl]="acceptPrivacyControl">
                        <label for="acceptPrivacy" class="checkBoxlabel">
                            <strong> Check here to indicate that you have read and agreed to the Privacy Policy.</strong>
                        </label>
                    </div>

                </td>
            </tr>
            <tr>
                <td style="text-align:center">
                    <app-button content="Accept" btnClasses="blue extraWidth" (click)="onAcceptTerms()" [isLoading]="isApiLoading" [isDisabled]="isApiLoading"></app-button>
                </td>
            </tr>
        </tbody>
    </table>
</div>