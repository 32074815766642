import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'dictation-helper',
    templateUrl: 'dictation-helper.component.html',
    styleUrls: ['dictation-helper.component.scss']
})
export class DictationHelper {
    type!: string;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DictationHelper>
    ) {
        this.type = this.data['type'];
    }

    onClose() {
        this.dialogRef.close(false);
    }
}