import {Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavbarService } from '../navbar.service';

@Component({
  selector: 'app-header-right',
  templateUrl: './app-header-right.component.html',
  styleUrls: ['./app-header-right.component.scss']
})
export class HeaderRightComponent {
  buttonList!: { title: string, event: string }[] | null;
  _subscription!: Subscription;

    constructor(private navbarService: NavbarService) {
      this._subscription = this.navbarService.headerRightBtns$.subscribe({
        next: data => {
          this.buttonList = data;
        }
      });
  }

  onBtnClicked(action: string) {
    this.navbarService.onHeaderBtnClicked(action);
  }
}
