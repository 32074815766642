import { Component, OnDestroy, ViewEncapsulation } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ClassData } from "src/app/classes/classData";
import { Levels } from "src/app/classes/enums";
import { Student } from "src/app/classes/student";
import { User } from "src/app/classes/user";
import { NavbarService } from "src/app/layout/app-header/navbar.service";
import { CoreService } from "../../core.service";
import { AddInviteStudentDialog } from "../dialog-box/add-edit-invite-student/add-invite-student.component";
import { EditStudentDialog } from "../dialog-box/edit-student/edit-student.component";
import { TransferStudentDialog } from "../dialog-box/transfer-student/transfer-student.component";
import { environment } from 'src/environments/environment';

@Component({
    selector: 'student-detail',
    templateUrl: './student-detail.component.html',
    styleUrls: ['./student-detail.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class StudentDetailComponent implements OnDestroy {
    // this component is not used
    selectedClass!: ClassData;
    userData!: User | null;
    private _subscription: Subscription[] = [];
    studentList: Student[] = [];
    displayColumns!: string[];
    addIconPath = './assets/images/icons/plus_icon.svg';
    closeIconPath = './assets/images/icons/close_icon.svg';
    addStudentIcon = this.addIconPath;
    isAddStudentExpanded = false;
    isAddStudentInProgress = false;
    studentLevels = Levels;

    studentIdControl!: UntypedFormControl;
    studentFirstNameControl!: UntypedFormControl;
    studentLastNameControl!: UntypedFormControl;
    studentLevelControl!: UntypedFormControl;
    studentLevelFromClass!: string;
    addStudentFormGroup!: UntypedFormGroup;

    leftHeaderButtons: { title: string, event: string }[] = [{
        title: 'Back to Home',
        event: 'homeButton'
    }];

    // rightHeaderButtons: { title: string, event: string }[] = [{
    //     title: 'Class Reports',
    //     event: 'classReportBtn'
    // }];

    functonList: { [name: string]: Function } = {
        homeButton: () => this.gotoToHome()
    }

    constructor(
        private coreService: CoreService,
        private router: Router,
        private navbarService: NavbarService,
        public dialog: MatDialog,
        ) {
        if (!this.coreService.selectedClass) {
            this.router.navigate(['dashboard']);
            return;
        }
        this.selectedClass = this.coreService.selectedClass;
        this.userData = this.coreService.userData;
        this.navbarService.updateLeftHeaderBtn(this.leftHeaderButtons);
        this.navbarService.updateRightHeaderBtn(null);
        // this.navbarService.updateRightHeaderBtn(this.rightHeaderButtons);
        const navSub = this.navbarService.headerBtnClicked$.subscribe({
            next: action => this.onNavigationServiceActionCalled(action)
        });

        this._subscription.push(navSub);

        this.displayColumns = ['id', 'name', 'level', 'share', 'edit'];
        this.studentLevelFromClass = ['BR', 'L1_INIT', 'L2_INIT', 'L3_INIT'].includes(this.selectedClass.level) ? this.selectedClass.level : '';

        this.studentIdControl = new UntypedFormControl('', Validators.required);
        this.studentFirstNameControl = new UntypedFormControl('');
        this.studentLastNameControl = new UntypedFormControl('');
        this.studentLevelControl = new UntypedFormControl(this.studentLevelFromClass, Validators.required);
        this.addStudentFormGroup = new UntypedFormGroup({
            studentIdControl: this.studentIdControl,
            studentFirstNameControl: this.studentFirstNameControl,
            studentLastNameControl: this.studentLastNameControl,
            studentLevelControl: this.studentLevelControl
        });
        this.getStudentList();

    }

    onNavigationServiceActionCalled(action: string) {
        if (this.functonList[action]) {
            this.functonList[action]();
        }
    }

    ngOnDestroy(): void {
        this._subscription.forEach(sub => sub?.unsubscribe());
    }

    gotoToHome() {
        this.router.navigate(['/dashboard']);
    }

    getStudentList() {
        let studentSub = this.coreService.getStudents().subscribe({
            next: resp => {
                this.studentList = resp;
                this.studentList.forEach(stu => {
                    if (stu.sharedUsers.length) {
                        stu.sharedUserInfo = 'Shared with ' + stu.sharedUsers.map(usr => usr.user?.fullName || usr.name).join(', ');
                    }
                });
            },
            error: err => {
                console.log(err);
            }
        });
        this._subscription.push(studentSub);
    }

    editStudent(student: Student) {
        let editDialogSub = this.dialog.open(EditStudentDialog, {
            data: {
                student
            },
            disableClose: true,
            panelClass: ['dialogOverflow']
        }).afterClosed().subscribe({
            next: resp => {
                if (resp) {
                    this.getStudentList();
                }
            }
        });

        this._subscription.push(editDialogSub);
    }

    AddStudentIcon() {
        this.addStudentIcon = this.addStudentIcon === this.closeIconPath ? this.addIconPath : this.closeIconPath;
        this.isAddStudentExpanded = !this.isAddStudentExpanded;

    }

    onAddStudent(formDirective: FormGroupDirective) {
        this.addStudentFormGroup.markAllAsTouched();
        if (this.addStudentFormGroup.invalid) {
            return;
        }
        this.isAddStudentInProgress = true;
        let newStudent = new Student(this.studentIdControl.value, this.studentFirstNameControl.value, this.studentLastNameControl.value, this.studentLevelControl.value);
        let addApiSub = this.coreService.saveStudent(newStudent.studentDto(this.coreService.userData?.id || '')).subscribe({
            next: resp => {
                this.coreService.openSnackBar("Student Added", 3000, "primary", "end", "bottom");
                this.addStudentFormGroup.reset();
                formDirective.resetForm();
                this.studentLevelControl.setValue(this.studentLevelFromClass);
                this.isAddStudentInProgress = false;
                this.getStudentList();
            },
            error: err => {
                console.log(err);
                this.coreService.openSnackBar("Error Occured, please try later", 3000, "warn", "end", "bottom");
                this.isAddStudentInProgress = false;
            }
        });
        this._subscription.push(addApiSub);
    }

    openNoStudentSelectedSnackBar() {
        this.coreService.openSnackBar("Please select atleast one Student", 3000, "warn", "end", "bottom");
    }

    shareStudent() {
        let selectedStudent = this.studentList.filter(stu => stu.selected);
        if (!(selectedStudent && selectedStudent.length)) {
            this.openNoStudentSelectedSnackBar();
            return;
        }
        let shareStudentDialog = this.dialog.open(AddInviteStudentDialog, {
            data: {
                studentList: selectedStudent
            },
            disableClose: true,
            panelClass: ['dialogOverflow']
        });

        let sharedStudentDialogSub = shareStudentDialog.afterClosed().subscribe({
            next: resp => {
                if (resp) {
                    this.getStudentList();
                }
            }
        });
        this._subscription.push(sharedStudentDialogSub);
    }

    transferStudent() {
        let selectedStudent = this.studentList.filter(stu => stu.selected);
        if (!(selectedStudent && selectedStudent.length)) {
            this.openNoStudentSelectedSnackBar();
            return;
        }

        let transferDialog = this.dialog.open(TransferStudentDialog, {
            data: {
                studentList: selectedStudent
            },
            disableClose: true,
            panelClass: ['dialogOverflow']
        });
        let transferDialogSub = transferDialog.afterClosed().subscribe({
            next: data => {
                if (data) {
                    this.getStudentList();
                }
            }
        });
        this._subscription.push(transferDialogSub);

    }

    backToClassManagement() {
        this.router.navigate(['class']);
    }

    goToAssessmentApp() {
        this.router.navigate(['student-assessment'], {
            state: {
                studentList: this.studentList
            }
        });
        // if (this.userData?.ssoToken) {
        //     window.open(environment.assessmentAppUrl + '/?sessionId=' + this.userData?.ssoToken +
        //         '&classId=' + this.selectedClass.guid
        //         , "assessmentAppIog1");
        // }
    }
}