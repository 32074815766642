<div class="fluency-container">
    <div class="titelCotainer">
        <div class="homeBtn">
            <app-button [content]="'&#x2B05; Concept Materials'" (click)="goToConcept()" *ngIf="!isLessonConcept"></app-button>
            <app-button [content]="'&#x2B05; Lesson Builder'" (click)="goToLessonBuilder()" *ngIf="isLessonConcept"></app-button>
        </div>
        <div class="title">
            Fluency - Create Your Own
        </div>
    </div>
    <div class="content">
        <div class="subTitle">
            Concept:
            <span class="conceptName">
                {{selectedConcept.name}}
            </span>
        </div>
        <div class="reportTitleCotainer" *ngIf="!isLessonConcept">
            <div class="titleCotainer">
                <mat-form-field appearance="outline" class="titleForm">
                    <mat-label>Title for Fluency (Max 40 Chars)</mat-label>
                    <input matInput placeholder="Short title for your custom concept" [formControl]="descriptionControl" id="descriptionControl">
                    <mat-hint class="hintMessage">
                        <div *ngIf="descriptionControl.value?.length === 40" class="maxCharReached">
                            Max limit reached
                        </div>
                        <div *ngIf="descriptionControl.value?.length != 40" class="charLeft">
                            {{ 40 - (descriptionControl.value?.length || 0) }} Chars remaining
                        </div>
                    </mat-hint>
                </mat-form-field>
            </div>
        </div>
        <div class="wordChartContainer">
            <div class="wordChart1">
                <h4>
                    <mat-slide-toggle *ngIf="isLessonConcept" [color]="'accent'" [formControl]="includeInLessonPlan1" [labelPosition]="'before'">Include Lesson Plan 1
                    </mat-slide-toggle>
                </h4>
                <div class="wordContainer">
                    <div class="fluency5Words">
                        <ng-container *ngFor="let word of fluencyFiveWordList1; let i = index;trackBy:trackByIdx">
                            <input type="text" placeholder="" class="inputBox word5Input" [(ngModel)]="fluencyFiveWordList1[i]">
                        </ng-container>
                        <app-button (click)="updateRandom(fluencyFiveWordList1, fluencyTwentyFiveWordList1, includeInLessonPlan1)" [content]="'Generate Word Chart'" [btnClasses]="'green'" class="generateWordChart"></app-button>
                    </div>
                    <div class="fluency25Words">
                        <div class="subWordList" *ngFor="let splitNum of flucencyWordSplitter;trackBy:trackByIdx">
                            <div *ngFor="let word25 of fluencyTwentyFiveWordList1 | slice:splitNum:(splitNum+5);let i = index;trackBy:trackByIdx">
                                <input class="inputBox word25Input" type="text" placeholder="" [(ngModel)]="fluencyTwentyFiveWordList1[i + splitNum ]">
                            </div>
                        </div>
                        <div class="clearWordChart">
                            <app-button [content]="'Clear Word Chart'" [btnClasses]="'lightRed'" (click)="clearWords(fluencyTwentyFiveWordList1)"></app-button>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
            <div class="wordChart2">
                <h4>
                    <mat-slide-toggle *ngIf="isLessonConcept" [color]="'accent'" [formControl]="includeInLessonPlan2" [labelPosition]="'before'">Include Lesson Plan 2
                    </mat-slide-toggle>
                </h4>
                <div class="wordContainer">
                    <div class="fluency5Words">
                        <ng-container *ngFor="let word of fluencyFiveWordList2; let i = index;trackBy:trackByIdx">
                            <input type="text" placeholder="" class="inputBox word5Input" [(ngModel)]="fluencyFiveWordList2[i]">
                        </ng-container>
                        <app-button (click)="updateRandom(fluencyFiveWordList2, fluencyTwentyFiveWordList2, includeInLessonPlan2)" [content]="'Generate Word Chart'" [btnClasses]="'green'" class="generateWordChart"></app-button>
                    </div>
                    <div class="fluency25Words">
                        <div class="subWordList" *ngFor="let splitNum of flucencyWordSplitter;trackBy:trackByIdx">
                            <div *ngFor="let word25 of fluencyTwentyFiveWordList2 | slice:splitNum:(splitNum+5);let i = index;trackBy:trackByIdx">
                                <input class="inputBox word25Input" type="text" placeholder="" [(ngModel)]="fluencyTwentyFiveWordList2[i + splitNum ]">
                            </div>
                        </div>
                        <div class="clearWordChart">
                            <app-button [content]="'Clear Word Chart'" [btnClasses]="'lightRed'" (click)="clearWords(fluencyTwentyFiveWordList2)"></app-button>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
            <div class="wordChart3">
                <h4>
                    <mat-slide-toggle *ngIf="isLessonConcept" [color]="'accent'" [formControl]="includeInLessonPlan3" [labelPosition]="'before'">Include Lesson Plan 3
                    </mat-slide-toggle>
                </h4>
                <div class="wordContainer">
                    <div class="fluency5Words">
                        <ng-container *ngFor="let word of fluencyFiveWordList3; let i = index;trackBy:trackByIdx">
                            <input type="text" placeholder="" class="inputBox word5Input" [(ngModel)]="fluencyFiveWordList3[i]">
                        </ng-container>
                        <app-button (click)="updateRandom(fluencyFiveWordList3, fluencyTwentyFiveWordList3, includeInLessonPlan3)" [content]="'Generate Word Chart'" [btnClasses]="'green'" class="generateWordChart"></app-button>
                    </div>
                    <div class="fluency25Words">
                        <div class="subWordList" *ngFor="let splitNum of flucencyWordSplitter;trackBy:trackByIdx">
                            <div *ngFor="let word25 of fluencyTwentyFiveWordList3 | slice:splitNum:(splitNum+5);let i = index;trackBy:trackByIdx">
                                <input class="inputBox word25Input" type="text" placeholder="" [(ngModel)]="fluencyTwentyFiveWordList3[i + splitNum ]">
                            </div>
                        </div>
                        <div class="clearWordChart">
                            <app-button [content]="'Clear Word Chart'" [btnClasses]="'lightRed'" (click)="clearWords(fluencyTwentyFiveWordList3)"></app-button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="SaveBtnContainer">
        <app-button [content]="'Save'" [btnClasses]="'blue'" (click)="saveFluency(false, false)" [isDisabled]="selectedClass?.readOnly || isSaveInProgress || isSavePrintInProgress" [isLoading]="isSaveInProgress"></app-button>
        <app-button [content]="'Save and Print Teacher PDF'" (click)="saveFluency(true, false)" [isDisabled]="selectedClass?.readOnly || isSaveInProgress || isSavePrintInProgress" [isLoading]="isSavePrintInProgress"></app-button>
        <!-- <app-button *ngIf="isLessonConcept" [content]="'Copy to Concept Material'" [btnClasses]="'blue'" (click)="saveFluency(false, true)" [isDisabled]="selectedClass?.readOnly || isSaveInProgress || isSavePrintInProgress" [isLoading]="isSaveInProgress"></app-button> -->
    </div>
</div>