<div class="lessonPrint">
    <div class="titelCotainer">
        <div class="homeBtn">
            <app-button [content]="'&#x2B05; Lesson Builder'" (click)="goToLessonBuilder()"></app-button>
        </div>
        <div class="title">
            Print Lesson : {{savedLessonData.title}}
        </div>
        <div class="title sub">
            Concept : {{savedLessonData.concept.name}}
        </div>
    </div>
    <div class="lessonCardData" *ngIf="false">
        <!-- Hidden, sample code -->
        <div class="days">
            <div class="dayname">Monday</div>
            <div class="card" *ngFor="let conceptData of addedComponentList['Monday']">
                <div class="title">
                    {{conceptData.title}}
                </div>
                <div>
                    <button mat-icon-button color="primary">
                            <mat-icon>print</mat-icon>
                        </button>
                    <button mat-icon-button color="primary">
                            <mat-icon>open_in_new</mat-icon>
                        </button>
                </div>
            </div>
        </div>
        <div class="days">
            <div class="dayname">Tuesday</div>
            <div class="card" *ngFor="let conceptData of addedComponentList['Tuesday']">
                <div class="title">
                    {{conceptData.title}}
                </div>
                <div>
                    <button mat-icon-button color="primary">
                            <mat-icon>print</mat-icon>
                        </button>
                    <button mat-icon-button color="primary">
                            <mat-icon>open_in_new</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="days">
            <div class="dayname">Wednesday</div>
            <div class="card" *ngFor="let conceptData of addedComponentList['Wednesday']">
                <div class="title">
                    {{conceptData.title}}
                </div>
                <div>
                    <button mat-icon-button color="primary">
                            <mat-icon>print</mat-icon>
                        </button>
                    <button mat-icon-button color="primary">
                            <mat-icon>open_in_new</mat-icon>
                        </button>
                </div>
            </div>
        </div>
        <div class="days">
            <div class="dayname">Thursday</div>
            <div class="card" *ngFor="let conceptData of addedComponentList['Thursday']">
                <div class="title">
                    {{conceptData.title}}
                </div>
                <div>
                    <button mat-icon-button color="primary">
                            <mat-icon>print</mat-icon>
                        </button>
                    <button mat-icon-button color="primary">
                            <mat-icon>open_in_new</mat-icon>
                        </button>
                </div>
            </div>
        </div>
        <div class="days">
            <div class="dayname">Friday</div>
            <div class="card" *ngFor="let conceptData of addedComponentList['Friday']">
                <div class="title">
                    {{conceptData.title}}
                </div>
                <div>
                    <button mat-icon-button color="primary">
                            <mat-icon>print</mat-icon>
                        </button>
                    <button mat-icon-button color="primary">
                            <mat-icon>open_in_new</mat-icon>
                        </button>
                </div>
            </div>
        </div>

    </div>

    <table class="table-style">
        <thead>
            <tr>
                <th>
                    Monday
                    <mat-slide-toggle [color]="'primary'" [checked]="true" (change)="updateCheckboxByDay($event.checked, 0)" class="toogleBtn"></mat-slide-toggle>
                </th>
                <th>
                    Tuesday
                    <mat-slide-toggle [color]="'primary'" [checked]="true" (change)="updateCheckboxByDay($event.checked, 1)" class="toogleBtn"></mat-slide-toggle>
                </th>
                <th>
                    Wednesday
                    <mat-slide-toggle [color]="'primary'" [checked]="true" (change)="updateCheckboxByDay($event.checked, 2)" class="toogleBtn"></mat-slide-toggle>
                </th>
                <th>
                    Thursday
                    <mat-slide-toggle [color]="'primary'" [checked]="true" (change)="updateCheckboxByDay($event.checked, 3)" class="toogleBtn"></mat-slide-toggle>
                </th>
                <th>
                    Friday
                    <mat-slide-toggle [color]="'primary'" [checked]="true" (change)="updateCheckboxByDay($event.checked, 4)" class="toogleBtn"></mat-slide-toggle>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let cmpDay of tableComponentList">
                <ng-container *ngFor="let lesInd of lessonIndex">
                    <td class="checkboxSelector" [ngClass]="cmpDay[lesInd]?.preMadeUrl || cmpDay[lesInd]?.haveCustomConcept ? '' : 'disabled'" (click)="updateCheckbox(cmpDay[lesInd])">
                        <div *ngIf="cmpDay[lesInd]">
                            {{cmpDay[lesInd].title}}
                            <!-- <button mat-icon-button color="primary" class="preMadeBtn" *ngIf="cmpDay[lesInd].preMadeUrl" (click)="goToPreMadeUrl(cmpDay[lesInd].preMadeUrl)">
                                <mat-icon>open_in_new</mat-icon>
                            </button> -->
                            <!-- <div>
                                <app-button content="Digital Slides" [btnClasses]="'blue conceptBtn'" *ngIf="cmpDay[lesInd].preMadeUrl"></app-button>
                            </div> -->
                            <mat-checkbox [(ngModel)]="cmpDay[lesInd].isSelected" [color]="'primary'" *ngIf="cmpDay[lesInd].haveCustomConcept" class="selectionCheckbox" (click)="stopPropagation($event)" [indeterminate]="cmpDay[lesInd].isEmpty" [disabled]="cmpDay[lesInd].isEmpty"></mat-checkbox>
                        </div>
                    </td>
                </ng-container>
            </tr>
        </tbody>
    </table>
    <div class="legands">
        <!-- <div>
            Digital Slides Link: <button mat-icon-button color="primary"><mat-icon>open_in_new</mat-icon></button>
        </div> -->
        <div>
            Use check marks to include in the lesson plan
            <!-- <mat-checkbox [(ngModel)]="legandCheckBox" [color]="'primary'"></mat-checkbox> -->
        </div>
        <div>
            Use the day of the week toggle to deselect/select all printable items for that day
            <!-- <mat-slide-toggle [color]="'primary'" [(ngModel)]="legandToggleBtn" [checked]="true"></mat-slide-toggle> -->
        </div>
        <div>
            <b>Note:</b> Disabled Checkboxes have printable Concept Materials that have not been customized
        </div>
        <div class="disabled">
            Greyed out lesson components do not have printable Concept Materials.
        </div>
    </div>
    <div class="overviewBtns">
        <mat-slide-toggle [color]="'accent'" [checked]="true" [formControl]="includeWeeklyOverview">Include Weekly Lesson Overview</mat-slide-toggle>
        <mat-slide-toggle [color]="'accent'" [checked]="true" [formControl]="includeDailyOverview">Include Daily Lesson Overview</mat-slide-toggle>
    </div>
</div>

<div class="btnClass">
    <app-button [content]="'Print'" [btnClasses]="'blue'" (click)="printPdf()" [isDisabled]="isSavePrintInProgress" [isLoading]="isSavePrintInProgress"></app-button>
</div>

<div class="premadeDiv">
    <div *ngIf="digitalSlidesLinks.length" class="premadeList">
        <h3>Digital Slides</h3>
        <ng-container *ngFor="let premade of digitalSlidesLinks">
            <button mat-raised-button class="premadeLink" (click)="goToPreMadeUrl($event, premade[1])">
                    {{premade[0]}} <mat-icon>open_in_new</mat-icon>
                </button>
        </ng-container>
    </div>
    <div *ngIf="pdfsLinks.length" class="premadeList">
        <h3>PDFs</h3>
        <ng-container *ngFor="let premade of pdfsLinks">
            <button mat-raised-button class="premadeLink" (click)="goToPreMadeUrl($event, premade[1])">
                    {{premade[0]}} <mat-icon>open_in_new</mat-icon>
                </button>
        </ng-container>
    </div>
    <div *ngIf="premadeLinks.length" class="premadeList">
        <h3>Links</h3>
        <ng-container *ngFor="let premade of premadeLinks">
            <button mat-raised-button class="premadeLink" (click)="goToPreMadeUrl($event, premade[1])">
                    {{premade[0]}} <mat-icon>open_in_new</mat-icon>
                </button>
        </ng-container>
    </div>
</div>