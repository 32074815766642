<div class="red-words-container">
    <div class="titelCotainer">
        <div class="homeBtn">
            <app-button [content]="'&#x2B05; Concept Materials'" (click)="goToConcept()" *ngIf="!isLessonConcept"></app-button>
            <app-button [content]="'&#x2B05; Lesson Builder'" (click)="goToLessonBuilder()" *ngIf="isLessonConcept"></app-button>
        </div>
        <div class="title">
            Red Words
        </div>
    </div>
    <div class="content">
        <div class="subTitle">
            Concept:
            <span class="conceptName">
                {{selectedConcept.name}}
            </span>
        </div>
        <div class="reportTitleCotainer" *ngIf="!isLessonConcept">
            <div class="titleCotainer">
                <mat-form-field appearance="outline" class="titleForm">
                    <mat-label>Title for Red Words (Max 40 Chars)</mat-label>
                    <input matInput placeholder="Short title for your custom concept" [formControl]="descriptionControl" id="descriptionControl">
                    <mat-hint class="hintMessage">
                        <div *ngIf="descriptionControl.value?.length === 40" class="maxCharReached">
                            Max limit reached
                        </div>
                        <div *ngIf="descriptionControl.value?.length != 40" class="charLeft">
                            {{ 40 - (descriptionControl.value?.length || 0) }} Chars remaining
                        </div>
                    </mat-hint>
                </mat-form-field>
            </div>
        </div>
        <main>
            <div *ngIf="redwordApiLoading" class="redWordLoader">
                <mat-progress-spinner mode="indeterminate" diameter="40"></mat-progress-spinner>
            </div>
            <div *ngIf="!redwordApiLoading">

                <!-- <div class="selectedWord">
                    <div class="subTitle">
                        {{ !!selectedWord?.fullword ? 'Select which list to add the word:' : 'Select red word from the list above.' }}
                    </div>
                    <p *ngIf="selectedWord?.fullword">
                        {{ selectedWord?.fullword }}
                    </p>
                    <div class="wordAddBtn">
                        <app-button [content]="'Add to New List'" [btnClasses]="'blue'" (click)="addWordList('new')" [isDisabled]="!selectedWord?.fullword"></app-button>
                        <app-button [content]="'Add to Review List'" [btnClasses]="'green'" (click)="addWordList('review')" [isDisabled]="!selectedWord?.fullword"></app-button>
                    </div>
                </div> -->

                <div class="wordsList">
                    <div class="newWord">
                        <div class="formSelector">
                            <mat-form-field appearance="outline">
                                <mat-label>Select New Red Word</mat-label>
                                <input matInput placeholder="Search for red word" [formControl]="wordSearchNewWordControl" [matAutocomplete]="newWordsSuggestion">
                                <mat-autocomplete #newWordsSuggestion="matAutocomplete">
                                    <div>&nbsp;</div>
                                    <mat-option *ngIf="!selectedClass?.readOnly && (!wordSearchNewWordControl.value || !(
                                        redNewWordsListFiltered.custom?.length || redNewWordsListFiltered.IMSE?.length)
                                    )">
                                        <app-button [content]="'Add new red word'" [btnClasses]="'green w100'" (click)="addNewRedWord('new')"></app-button>
                                    </mat-option>
                                    <mat-optgroup [label]="'Your Red Words'" *ngIf="redNewWordsListFiltered.custom?.length">
                                        <mat-option *ngFor="let word of redNewWordsListFiltered.custom" [value]="word">
                                            <div class="customWordOption">
                                                <div class="word">
                                                    {{word.words}}
                                                </div>
                                                <div class="concept">
                                                    {{word.conceptName}}
                                                </div>
                                                <mat-icon *ngIf="!selectedClass?.readOnly" (click)="softDeleteCustomWord($event, word)" color="warn">delete_forever</mat-icon>
                                            </div>

                                        </mat-option>
                                    </mat-optgroup>
                                    <mat-optgroup [label]="'IMSE Words'" *ngIf="redNewWordsListFiltered.IMSE?.length">
                                        <mat-option *ngFor="let word of redNewWordsListFiltered.IMSE" [value]="word">
                                            <div class="customWordOption">
                                                <div class="word">
                                                    {{word.words}}
                                                </div>
                                                <div class="concept">
                                                    {{word.conceptName}}
                                                </div>
                                            </div>
                                        </mat-option>
                                    </mat-optgroup>
                                    <div>&nbsp;</div>
                                </mat-autocomplete>
                            </mat-form-field>
                            <app-button [content]="'Add to New List'" [btnClasses]="'blue'" (click)="addWordList('new')" [isDisabled]="!selectedNewWord?.fullword"></app-button>
                        </div>

                        <div>New Red Words List</div>
                        <div cdkDropList class="listContainer" (cdkDropListDropped)="dropNewWordList($event)">
                            <div *ngFor="let word of savedNewWordsList;let i=index" class="wordDetails" cdkDrag cdkDragBoundary=".newWord">
                                <div class="name"> {{ word.words }}</div>
                                <div class="wordType">
                                    <div class="type"> {{ word.conceptName || "custom"}}</div>
                                    <div class="btn">
                                        <button mat-icon-button color="warn" (click)="deleteWordFromSaved('new',i)"><mat-icon>close</mat-icon></button>
                                    </div>
                                </div>
                                <span *cdkDragPreview class="dragPreview">
                                    {{ word.words }}
                                </span>
                            </div>
                        </div>
                        <div>&nbsp;</div>
                    </div>
                    <div class="reviewWord">
                        <div class="formSelector">
                            <mat-form-field appearance="outline">
                                <mat-label>Select Review Red Words</mat-label>
                                <input matInput placeholder="Search for red word" [formControl]="wordSearchReviewWordControl" [matAutocomplete]="reviewWordsSuggestion">
                                <mat-autocomplete #reviewWordsSuggestion="matAutocomplete">
                                    <div>&nbsp;</div>
                                    <mat-option *ngIf="!selectedClass?.readOnly && (!wordSearchReviewWordControl.value || !(
                                        redReviewWordsListFiltered.custom?.length || redReviewWordsListFiltered.IMSE?.length)
                                    )">
                                        <app-button [content]="'Add new red word'" [btnClasses]="'green w100'" (click)="addNewRedWord('review')"></app-button>
                                    </mat-option>
                                    <mat-optgroup [label]="'Your Red Words'" *ngIf="redReviewWordsListFiltered.custom?.length">
                                        <mat-option *ngFor="let word of redReviewWordsListFiltered.custom" [value]="word">
                                            <div class="customWordOption">
                                                <div class="word">
                                                    {{word.words}}
                                                </div>
                                                <div class="concept">
                                                    {{word.conceptName}}
                                                </div>
                                                <mat-icon *ngIf="!selectedClass?.readOnly" (click)="softDeleteCustomWord($event, word)" color="warn">delete_forever</mat-icon>
                                            </div>

                                        </mat-option>
                                    </mat-optgroup>
                                    <mat-optgroup [label]="'IMSE Words'" *ngIf="redReviewWordsListFiltered.IMSE?.length">
                                        <mat-option *ngFor="let word of redReviewWordsListFiltered.IMSE" [value]="word">
                                            <div class="customWordOption">
                                                <div class="word">
                                                    {{word.words}}
                                                </div>
                                                <div class="concept">
                                                    {{word.conceptName}}
                                                </div>
                                            </div>
                                        </mat-option>
                                    </mat-optgroup>
                                    <div>&nbsp;</div>
                                </mat-autocomplete>
                            </mat-form-field>
                            <app-button [content]="'Add to Review List'" [btnClasses]="'blue'" (click)="addWordList('review')" [isDisabled]="!selectedReviewWord?.fullword"></app-button>
                        </div>
                        <div>Review Red Words List</div>
                        <div cdkDropList class="listContainer" (cdkDropListDropped)="dropReviewWordList($event)">
                            <div *ngFor="let word of savedReviewWordsList;let i=index" class="wordDetails" cdkDrag cdkDragBoundary=".newWord">
                                <div class="name"> {{ word.words }}</div>
                                <div class="wordType">
                                    <div class="type"> {{ word.conceptName || "custom"}}</div>
                                    <div class="btn">
                                        <button mat-icon-button color="warn" (click)="deleteWordFromSaved('review',i)"><mat-icon>close</mat-icon></button>
                                    </div>
                                </div>
                                <span *cdkDragPreview class="dragPreview">
                                    {{ word.words }}
                                </span>
                            </div>
                        </div>
                        <div>&nbsp;</div>
                    </div>
                </div>
            </div>
        </main>
        <div class="SaveBtnContainer">
            <app-button [content]="'Save'" [btnClasses]="'blue'" (click)="saveRedWords(false, false)" [isDisabled]="selectedClass?.readOnly || isSaveInProgress || isSavePrintInProgress" [isLoading]="isSaveInProgress"></app-button>
            <app-button [content]="'Save and Print PDF'" (click)="saveRedWords(true, false)" [isDisabled]="selectedClass?.readOnly || isSaveInProgress || isSavePrintInProgress" [isLoading]="isSavePrintInProgress"></app-button>
            <!-- <app-button *ngIf="isLessonConcept" [content]="'Copy to Concept Material'" [btnClasses]="'blue'" (click)="saveRedWords(false, true)" [isDisabled]="selectedClass?.readOnly || isSaveInProgress || isSavePrintInProgress" [isLoading]="isSaveInProgress"></app-button> -->
        </div>
        <hr />
        <div class="subcontent">
            <h2 class="printInfoText">
                Choose a Red Word PDF below to download and print
            </h2>
            <div class="paperImgInfos">
                <div *ngFor="let card of flashCardsData1" class="flashCard">
                    <h4 class="flashHeading">{{card[0]}}</h4>
                    <h4 class="flashHeading subHeading">{{card[1]}}</h4>
                    <img [src]="'./assets/images/concept_images/Red-words/'+ card[2] +'.jpg'" class="paperImg" alt="solid_img">
                    <p class="pdfLink">
                        <a [href]="'./assets/pdfs/Red_words/'+ card[2] +'.pdf'" [target]="'Red_Words_'+ card[2] +'_pdf'" download>
                            Download <mat-icon class="icon">open_in_new</mat-icon>
                        </a>
                    </p>
                </div>
            </div>
            <div class="paperImgInfos">
                <div *ngFor="let card of flashCardsData2" class="flashCard">
                    <h4 class="flashHeading">{{card[0]}}</h4>
                    <h4 class="flashHeading subHeading">{{card[1]}}</h4>
                    <img [src]="'./assets/images/concept_images/Red-words/'+ card[2] +'.jpg'" class="paperImg" alt="solid_img">
                    <p class="pdfLink">
                        <a [href]="'./assets/pdfs/Red_words/'+ card[2] +'.pdf'" [target]="'Red_Words_'+ card[2] +'_pdf'" download>
                            Download <mat-icon class="icon">open_in_new</mat-icon>
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>