import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { catchError, map, Observable, of, throwError } from "rxjs";
import { CoreService } from "./core.service";
import { LoginService } from "./login.service";

@Injectable({
    providedIn: 'root',
})
export class HttpInterceptor implements HttpInterceptor {
    private cache: Map<string, HttpResponse<any>> = new Map();
    // private cacheUrlList = ['/concept/v1'];
    private cacheUrlList = [];

    constructor(private coreService: CoreService,
        private loginService: LoginService) {

    }
    //'visualcueword/v1/user' - handled on core service, saved to Session Storage
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let inCacheList = this.cacheUrlList.some(url => req.url.indexOf(url) > 1);
        let newRequest: HttpRequest<any>;
        if (req.url.indexOf('login/v1/authenticate') > 1) {
            newRequest = req;
        } else {
            //Adding session token
            newRequest = req.clone({
                headers: req.headers.set('x-token-header', encodeURIComponent(this.coreService.userData?.token || ''))
            });
        }

        if (!(req.method === "GET" && inCacheList)) {
            return next.handle(newRequest).pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.error?.status === 403) {
                        // alert("Please Login again");
                        this.loginService.logoutUser();
                    } else if (error.error?.status === 406) {
                        return throwError(() => new Error('Invalid Credentials'));
                    }
                    return throwError(() => new Error(JSON.stringify(error.error || error.message || {})));
                })
            );;
        }
        const cachedResponse: HttpResponse<any> | undefined = this.cache.get(req.urlWithParams);
        if (cachedResponse) {
            return of(cachedResponse.clone());
        } else {
            return next.handle(newRequest).pipe(
                map((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        this.cache.set(req.urlWithParams, event);
                    }
                    return event;
                })
            );
        }
    }
}