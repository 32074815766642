import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'confirm-box',
    templateUrl: 'confirm-dialog.component.html',
    styleUrls: ['confirm-dialog.component.scss']
})
export class ConfirmDialogBox {
    cancelBtnLabel = 'Cancel';
    cancelBtnClass = '';
    confirmBtnLabel = 'Confirm';
    confirmBtnClass = '';
    message = 'are you sure?';
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ConfirmDialogBox>
    ) {
        this.message = this.data['message'];
        this.confirmBtnClass = this.data['confirmBtnClass'];
        this.confirmBtnLabel = this.data['confirmBtnLabel'];
        if (this.data['cancelBtnLabel']) {
            this.cancelBtnLabel = this.data['cancelBtnLabel'];
        }
        if (this.data['cancelBtnClass']) {
            this.cancelBtnClass = this.data['cancelBtnClass'];
        }
    }

    onCancel() {
        this.dialogRef.close(false);
    }

    onConfirm() {
        this.dialogRef.close(true);
    }
}