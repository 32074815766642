import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, retry, tap } from 'rxjs';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';

import { ConceptMaterial, SavedConceptDetail } from '../classes/concept';
import { ConceptSentenceClueDetails, ConceptWordClueDetails, dictationSaveDto, savedDictationDto } from '../classes/Concepts/dictation';
import { savedThreeParttDrill, saveThreePartDrillDto } from '../classes/Concepts/threePartDrill';
import { LoginService } from './login.service';
import { User, Properties } from '../classes/user';
import { ClassData, ClassDto, ClassTerm } from '../classes/classData';
import { invitedUserData } from '../classes/invitedUserData';
import { Student } from '../classes/student';
import { deleteLessonDto, LessonData, LessonPrintDto, newSaveConceptGenericDto, SavedLessonData } from '../classes/lessonData';
import { newConceptSaveDto, SavedNewConceptDto } from '../classes/Concepts/newConcept';
import { SavedFluencyDto } from '../classes/Concepts/fluency';
import { redWordRespDto, RedWordSavedDto, RedWordSaveDto } from '../classes/Concepts/redWords';
import { preMadeDto } from '../classes/Concepts/premade';
import { houseWords, savedSyllableOpenClose, syllableOpenCloseSaveDto } from '../classes/Concepts/syllableOpenClose';
import { decodeWord, savedDecodeWord, saveDecodeWordDto } from '../classes/Concepts/syllableDecodeWord';
import { TeacherDto } from '../classes/teachersDto';
import { StudentResponseDto } from '../classes/studentResponseDto';
import { generalCountDto } from '../classes/reporting';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root',
})
export class CoreService {
    userData: User | null;
    selectedClass!: ClassData | null;
    assessmentApp: Window | null = null;
    public isAssessmentDateNotified = false;
    constructor(private httpClient: HttpClient, private router: Router,
        private loginService: LoginService,
        private _snackBar: MatSnackBar) {
        this.userData = this.loginService.userData;
        // this.getVisualCueWord()?.subscribe();
        window.URL = window.URL || window.webkitURL;
    }

    setUserData(userData: User) {
        this.loginService.userData = userData;
        this.userData = userData;
        localStorage.setItem("userData", JSON.stringify(this.userData));
        this.router.navigate(['dashboard']);
    }

    getConceptMaterial(): Observable<ConceptMaterial[]> {
        const url = environment.apiUrl + '/concept/v1/';
        return this.httpClient.get<ConceptMaterial[]>(url);
    }

    getConceptPremadeMaterial(conceptId: string): Observable<preMadeDto[]> {
        const url = environment.apiUrl + '/conceptmaterial/v1/concept/' + conceptId + '/premade';
        return this.httpClient.get<preMadeDto[]>(url);
    }

    deleteConceptBySavedIds(savedIds: string[]) {
        const url = environment.apiUrl + '/conceptmaterial/v1/deleteConceptMaterialsByIds';
        return this.httpClient.post(url, savedIds);
    }

    deleteConceptByConceptId(conceptIds: string[]) {
        const url = environment.apiUrl + '/conceptmaterial/v1/deleteConceptMaterialsByConceptAndClass?classId=' + this.selectedClass?.guid;
        return this.httpClient.post(url, conceptIds);
    }

    getAllClassData(): Observable<ClassData[]> {
        const url = environment.apiUrl + '/class/v1/user/' + this.userData?.id;
        return this.httpClient.get<ClassData[]>(url);
    }

    getAllArchivedClassData(): Observable<ClassData[]> {
        const url = environment.apiUrl + '/class/v1/archivedClasses?userId=' + this.userData?.id;
        return this.httpClient.get<ClassData[]>(url);
    }

    getClassData(classId: string): Observable<ClassData> {
        const url = environment.apiUrl + '/class/v1/' + classId;
        return this.httpClient.get<ClassData>(url);
    }

    saveUpdateClass(classDto: ClassDto) {
        const url = environment.apiUrl + '/class/v1/saveClass';
        classDto.userId = this.userData?.id || '';
        return this.httpClient.post(url, classDto);
    }

    addNewClass(classDto: ClassDto): Observable<ClassData> {
        const url = environment.apiUrl + '/class/v1/saveClass';
        classDto.userId = this.userData?.id || '';
        return this.httpClient.post<ClassData>(url, classDto);
    }

    getSharedClass(): Observable<invitedUserData[]> {
        const url = environment.apiUrl + '/class/v1/sharedUsers?classId=' + this.selectedClass?.guid;
        return this.httpClient.get<invitedUserData[]>(url);
    }

    getDistrictAdminProperties(forDistrictManaged?: boolean): Observable<Properties[]> {
        let url = environment.apiUrl + '/lookup/v1/da/properties';
        if (forDistrictManaged) {
            url += "?forDistrictManaged=" + forDistrictManaged;
        }
        return this.httpClient.get<Properties[]>(url);
    }

    getTeachersListUnderDa() {
        const url = environment.apiUrl + '/districtAdmin/v1/teachers/iog';
        return this.httpClient.get<TeacherDto[]>(url);
    }

    getSharedClassForNotify(): Observable<invitedUserData[]> {
        const url = environment.apiUrl + '/class/v1/sharedUserNotification?userId=' + this.userData?.id || '';
        return this.httpClient.get<invitedUserData[]>(url);
    }

    markClassShareNotification(id: string) {
        const url = environment.apiUrl + '/class/v1/markClassShareNotification?classTeacherShareId=' + id;
        return this.httpClient.post<ClassData>(url, {});
    }

    deleteSharedClass(sharedUserId: string) {
        const url = environment.apiUrl + '/class/v1/deleteClassShare?classTeacherShareId=' + sharedUserId;
        return this.httpClient.post(url, {});
    }

    archiveClass(classId: string) {
        const url = environment.apiUrl + '/class/v1/archiveClass?classId=' + classId;
        return this.httpClient.post(url, {});
    }

    cloneClass(classId: string): Observable<ClassData> {
        const url = environment.apiUrl + '/class/v1/cloneClass/' + classId + '?districtManaged=' + (this.userData?.districtManaged || false);
        return this.httpClient.post<ClassData>(url, {});
    }

    addUpdateSharedClassInvite(userInviteData: Object): Observable<invitedUserData> {
        const url = environment.apiUrl + '/class/v1/shareClass';
        return this.httpClient.post<invitedUserData>(url, userInviteData);
    }

    getUserByName(name: string): Observable<User[]> {
        const url = environment.apiUrl + '/user/v1/findUsers?name=' + name;
        return this.httpClient.get<User[]>(url);
    }

    acceptTemrsOfService() {
        const url = environment.apiUrl + '/user/v1/acceptTerms';
        return this.httpClient.post(url, {});
    }

    getSavedLessonByClass(): Observable<LessonData[]> {
        const url = environment.apiUrl + '/lesson/v1/class/' + this.selectedClass?.guid;
        return this.httpClient.get<LessonData[]>(url);
    }

    getStudents(teacherId?: string): Observable<Student[]> {
        const url = environment.apiUrl + '/student/v1/class/' + this.selectedClass?.guid + (teacherId ? '?teacherId=' + teacherId : '');
        return this.httpClient.get<Student[]>(url);
    }

    getStudentFromDA() {
        const url = environment.apiUrl + '/districtAdmin/v1/students/iog';
        return this.httpClient.get<StudentResponseDto[]>(url);
    }

    saveStudent(studentDto: Object) {
        const url = environment.apiUrl + '/student/v1/saveStudent/' + this.selectedClass?.guid;
        return this.httpClient.post(url, studentDto);
    }

    linkStudent(studentGuId: string) {
        const url = environment.apiUrl + '/student/v1/linkStudent/' + this.selectedClass?.guid + '?studentId=' + studentGuId;
        return this.httpClient.post(url, {});
    }

    deleteStudent(students: string[]) {
        const url = environment.apiUrl + '/student/v1/deleteStudents/' + this.selectedClass?.guid + '?userId=' + this.userData?.id;
        return this.httpClient.post(url, students);
    }

    shareStudent(studentsList: Object) {
        const url = environment.apiUrl + '/student/v1/shareStudents';
        return this.httpClient.post(url, studentsList);
    }

    updateSharedStudent(studentupdateDto: Object) {
        const url = environment.apiUrl + '/student/v1/updateSharedStudents';
        return this.httpClient.post(url, studentupdateDto);
    }

    getSharedStudentForNotify(): Observable<invitedUserData[]> {
        const url = environment.apiUrl + '/student/v1/sharedUserNotification?userId=' + this.userData?.id || '';
        return this.httpClient.get<invitedUserData[]>(url);
    }

    markStudentShareNotification(id: string) {
        const url = environment.apiUrl + '/student/v1/markStudentShareNotification?studentShareId=' + id;
        return this.httpClient.post<ClassData>(url, {});
    }

    markAllNotificationRead() {
        //for both students and class
        const url = environment.apiUrl + '/student/v1/markAllNotificationRead';
        return this.httpClient.post(url, {});
    }

    deleteSharedStudent(sharedStudetId: string) {
        const url = environment.apiUrl + '/student/v1/deleteStudentShare?studentShareId=' + sharedStudetId;
        return this.httpClient.post(url, null);
    }

    transferStudent(transferDto: Object) {
        const url = environment.apiUrl + '/student/v1/transferStudents';
        return this.httpClient.post<string[][]>(url, transferDto);
    }

    getVisualCueWord(): Observable<ConceptWordClueDetails> {
        const url = environment.apiUrl + '/visualcueword/v1/user/' + this.userData?.id + '/groupedByConcept';

        if (sessionStorage.getItem('conceptWordClue')) {
            return of(<ConceptWordClueDetails>JSON.parse(sessionStorage.getItem('conceptWordClue') || ''));
        }

        return this.httpClient.get<ConceptWordClueDetails>(url).pipe(tap(resp => {
            try {
                sessionStorage.setItem('conceptWordClue', JSON.stringify(resp))
            } catch (error) {
                console.log(error);
            }
        }));
    }

    getVisualcueSentence(): Observable<ConceptSentenceClueDetails> {
        const url = environment.apiUrl + '/visualcuesentence/v1/user/' + this.userData?.id + '/groupedByConcept';

        if (sessionStorage.getItem('conceptSentenceClue')) {
            return of(<ConceptSentenceClueDetails>JSON.parse(sessionStorage.getItem('conceptSentenceClue') || ''));
        }

        return this.httpClient.get<ConceptSentenceClueDetails>(url).pipe(tap(resp => {
            try {
                sessionStorage.setItem('conceptSentenceClue', JSON.stringify(resp))
            } catch (error) {
                console.log(error);
            }
        }));
    }

    // saveThreePartDrill(save3partDrill: saveThreePartDrillDto): Observable<savedThreeParttDrill> {
    //     const url = environment.apiUrl + '/lesson3partdrill/v1/class/' + this.selectedClass?.guid;
    //     return this.httpClient.post<savedThreeParttDrill>(url, save3partDrill);
    // }

    saveThreePartDrill(save3partDrill: saveThreePartDrillDto, id: string | null): Observable<savedThreeParttDrill>  {
        let url: string;
        if (id) {
            url = environment.apiUrl + '/lesson3partdrill/v1/' + id;
        } else {
            url = environment.apiUrl + '/lesson3partdrill/v1/class/' + this.selectedClass?.guid;
        }
        return this.httpClient.post<savedThreeParttDrill>(url, save3partDrill);
    }

    // updateThreePartDrill(save3partDrill: saveThreePartDrillDto, id: string): Observable<savedThreeParttDrill> {
    //     const url = environment.apiUrl + '/lesson3partdrill/v1/' + id;
    //     return this.httpClient.post<savedThreeParttDrill>(url, save3partDrill);
    // }

    getSavedConceptMaterialByClass(): Observable<SavedConceptDetail[]> {
        const url = environment.apiUrl + '/conceptmaterial/v1/class/' + this.selectedClass?.guid;
        return this.httpClient.get<SavedConceptDetail[]>(url);
    }

    getSaved3partdrillById(id: string): Observable<savedThreeParttDrill> {
        const url = environment.apiUrl + '/lesson3partdrill/v1/' + id;
        return this.httpClient.get<savedThreeParttDrill>(url);
    }

    get3PartDrillPDF(id: string, type: string): Observable<Blob> {
        const url = environment.apiUrl + '/lesson3partdrill/v1/' + id + '/pdf?pdfs=' + type;
        return this.httpClient.get<Blob>(url, {
            responseType: 'blob' as 'json'
        });
    }

    saveDictation(dataToSaved: dictationSaveDto, updateId: string | null): Observable<savedDictationDto> {
        let url: string;
        if (updateId) {
            url = environment.apiUrl + '/lessondictation/v1/' + updateId;
        } else {
            url = environment.apiUrl + '/lessondictation/v1/class/' + this.selectedClass?.guid;
        }
        return this.httpClient.post<savedDictationDto>(url, dataToSaved);
    }

    getSavedDictation(dictationId: string): Observable<savedDictationDto> {
        let url = environment.apiUrl + '/lessondictation/v1/' + dictationId;
        return this.httpClient.get<savedDictationDto>(url);
    }

    getDictationPDF(id: string, type: string) {
        let url = environment.apiUrl + '/lessondictation/v1/' + id + '/pdf?type=' + type;
        return this.httpClient.get<Blob>(url, {
            responseType: 'blob' as 'json'
        });
    }

    saveNewConcept(dataToSave: newConceptSaveDto, updateId: string | null): Observable<SavedNewConceptDto> {
        let url: string;
        if (updateId) {
            url = environment.apiUrl + '/lessonmse/v1/' + updateId;
        } else {
            url = environment.apiUrl + '/lessonmse/v1/class/' + this.selectedClass?.guid;
        }
        return this.httpClient.post<SavedNewConceptDto>(url, dataToSave);
    }

    getSavedNewConcept(newConceptID: string): Observable<SavedNewConceptDto> {
        let url = environment.apiUrl + '/lessonmse/v1/' + newConceptID;
        return this.httpClient.get<SavedNewConceptDto>(url);
    }

    getNewCoceptPDF(id: string) {
        let url = environment.apiUrl + '/lessonmse/v1/' + id + '/pdf';
        return this.httpClient.get<Blob>(url, {
            responseType: 'blob' as 'json'
        });
    }

    saveFluency(data: object, updateId: string | null): Observable<SavedFluencyDto> {
        let url: string;
        if (updateId) {
            url = environment.apiUrl + '/lessonfluency/v1/' + updateId;
        } else {
            url = environment.apiUrl + '/lessonfluency/v1/class/' + this.selectedClass?.guid;
        }
        return this.httpClient.post<SavedFluencyDto>(url, data);
    }

    getSavedFluency(id: string): Observable<SavedFluencyDto> {
        let url = environment.apiUrl + '/lessonfluency/v1/' + id;
        return this.httpClient.get<SavedFluencyDto>(url);
    }

    getFluencyPDF(id: string) {
        let url = environment.apiUrl + '/lessonfluency/v1/' + id + '/pdf';
        return this.httpClient.get<Blob>(url, {
            responseType: 'blob' as 'json'
        });
    }

    getRedWords(): Observable<redWordRespDto[]> {
        let url = environment.apiUrl + '/lookup/v1/redwords';
        return this.httpClient.get<redWordRespDto[]>(url);
    }

    saveRedWords(data: RedWordSaveDto, updateId: string | null): Observable<RedWordSavedDto> {
        let url: string;
        if (updateId) {
            url = environment.apiUrl + '/lessonredwords/v1/' + updateId;
        } else {
            url = environment.apiUrl + '/lessonredwords/v1/class/' + this.selectedClass?.guid;
        }
        return this.httpClient.post<RedWordSavedDto>(url, data);
    }

    addNewRedWord(newRedWord: string) {
        let url = environment.apiUrl + '/lessonredwords/v1/newCustomWord';
        let data = {
            classId: this.selectedClass?.guid,
            redWord: newRedWord
        };
        return this.httpClient.post<redWordRespDto>(url, data);
    }

    getSavedRedWords(id: string): Observable<RedWordSavedDto> {
        let url = environment.apiUrl + '/lessonredwords/v1/' + id;
        return this.httpClient.get<RedWordSavedDto>(url);
    }

    getRedWordsPDF(id: string) {
        let url = environment.apiUrl + '/lessonredwords/v1/' + id + '/pdf';
        return this.httpClient.get<Blob>(url, {
            responseType: 'blob' as 'json'
        });
    }

    deleteCustomRedWord(id: string) {
        let url = environment.apiUrl + '/lessonredwords/v1/delete/redword/' + id;
        return this.httpClient.post(url, {});
    }

    createNewLessonBuilder(data: object): Observable<LessonData> {
        let url = environment.apiUrl + '/lesson/v1/';
        return this.httpClient.post<LessonData>(url, data);
    }

    updateLessonName(lessonId: string, data: object) {
        let url = environment.apiUrl + '/lesson/v1/' + lessonId;
        return this.httpClient.post<LessonData>(url, data);
    }

    getSavedLessonMaterials(lessonId: string): Observable<SavedLessonData[]> {
        let url = environment.apiUrl + '/lesson/v1/' + lessonId + '/lessonMaterial';
        return this.httpClient.get<SavedLessonData[]>(url);
    }

    createNewConceptForLesson(conceptUrlPath: string, lessonId: string, lessonDay: string): Observable<newSaveConceptGenericDto> {
        let url = environment.apiUrl + `/${conceptUrlPath}/v1/lesson/${lessonId}/day/${lessonDay}`;
        return this.httpClient.post<newSaveConceptGenericDto>(url, {});
    }

    reorderLessonConcept(lessonId: string, data: object) {
        let url = environment.apiUrl + '/lesson/v1/' + lessonId + '/reorder';
        return this.httpClient.post(url, data);
    }

    deleteLesson(selectedLessons: deleteLessonDto[]) {
        let url = environment.apiUrl + '/lesson/v1/deleteLesson';
        return this.httpClient.post(url, selectedLessons);
    }

    deleteConceptInLesson(conceptUrlPath: string, materialId: string) {
        let url = environment.apiUrl + `/${conceptUrlPath}/v1/delete/${materialId}`;
        return this.httpClient.post(url, {});
    }

    saveLetterFormationConcept(dataToSave: newConceptSaveDto, updateId: string | null): Observable<SavedNewConceptDto> {
        let url: string;
        if (updateId) {
            url = environment.apiUrl + '/lessonletterformation/v1/' + updateId;
        } else {
            url = environment.apiUrl + '/lessonletterformation/v1/class/' + this.selectedClass?.guid;
        }
        return this.httpClient.post<SavedNewConceptDto>(url, dataToSave);
    }

    getLetterFormationConcept(newConceptID: string): Observable<SavedNewConceptDto> {
        let url = environment.apiUrl + '/lessonletterformation/v1/' + newConceptID;
        return this.httpClient.get<SavedNewConceptDto>(url);
    }

    getLetterFormationPDF(id: string) {
        let url = environment.apiUrl + '/lessonletterformation/v1/' + id + '/pdf';
        return this.httpClient.get<Blob>(url, {
            responseType: 'blob' as 'json'
        });
    }

    getHouseWords(): Observable<houseWords[]> {
        let url = environment.apiUrl + '/lookup/v1/housewords';
        return this.httpClient.get<houseWords[]>(url);
    }

    saveSyllableOpenClose(data: syllableOpenCloseSaveDto, updateId: string | null): Observable<savedSyllableOpenClose> {
        let url: string;
        if (updateId) {
            url = environment.apiUrl + '/lessonsyllableopenclose/v1/' + updateId;
        } else {
            url = environment.apiUrl + '/lessonsyllableopenclose/v1/class/' + this.selectedClass?.guid;
        }
        return this.httpClient.post<savedSyllableOpenClose>(url, data);
    }

    getSavedSyllableOpenClose(id: string): Observable<savedSyllableOpenClose> {
        let url = environment.apiUrl + '/lessonsyllableopenclose/v1/' + id;
        return this.httpClient.get<savedSyllableOpenClose>(url);
    }

    getSyllableOpenClosePDF(id: string) {
        let url = environment.apiUrl + '/lessonsyllableopenclose/v1/' + id + '/pdf';
        return this.httpClient.get<Blob>(url, {
            responseType: 'blob' as 'json'
        });
    }

    getDecodeWords(): Observable<decodeWord[]> {
        let url = environment.apiUrl + '/lookup/v1/decodewords';
        return this.httpClient.get<decodeWord[]>(url);
    }

    saveSyllableDecodeWord(data: saveDecodeWordDto, updateId: string | null, type: string): Observable<savedDecodeWord[]> {
        let url: string;
        if (updateId) {
            url = environment.apiUrl + '/lessonsyllabledecodewords/v1/' + updateId + '?type=' + type;
        } else {
            url = environment.apiUrl + '/lessonsyllabledecodewords/v1/class/' + this.selectedClass?.guid;
        }
        return this.httpClient.post<savedDecodeWord[]>(url, data);
    }

    getSavedSyllableDecodeWord(id: string, type: string): Observable<savedDecodeWord[]> {
        let url = environment.apiUrl + '/lessonsyllabledecodewords/v1/' + id + '?type=' + type;
        return this.httpClient.get<savedDecodeWord[]>(url);
    }

    getSyllableDecodeWordPDF(id: string | null, type: string) {
        let url = environment.apiUrl + '/lessonsyllabledecodewords/v1/' + id + '/pdf?type=' + type;
        return this.httpClient.get<Blob>(url, {
            responseType: 'blob' as 'json'
        });
    }

    deleteCustomDecodeWord(id: string) {
        let url = environment.apiUrl + '/lessonsyllabledecodewords/v1/delete/decode/' + id;
        return this.httpClient.post(url, {});
    }

    openPdf(data: Blob, conceptName: string): void {
        let file = new Blob([data], { type: 'application/pdf' });
        let fileUrl = window.URL.createObjectURL(file);
        let newWindow = window.open(fileUrl);
        if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
            //If new tab blocked then download the pdf
            const e = document.createElement('a');
            e.href = fileUrl;
            e.download = conceptName;
            document.body.appendChild(e);
            e.click();
            setTimeout(function () {
                document.body.removeChild(e);
                window.URL.revokeObjectURL(fileUrl);
            }, 1000);
        } else {
            window.URL.revokeObjectURL(fileUrl);
        }
    }

    printLessonPdf(conceptList: LessonPrintDto) {
        let url = environment.apiUrl + '/lesson/v1/pdf';
        return this.httpClient.post<Blob>(url, conceptList, {
            responseType: 'blob' as 'json'
        });
    }

    printConceptMaterialPdf(conceptList: LessonPrintDto) {
        let url = environment.apiUrl + '/conceptmaterial/v1/pdf';
        return this.httpClient.post<Blob>(url, conceptList, {
            responseType: 'blob' as 'json'
        });
    }

    // Reporting API's
    getClassCount(startDate: string, endDate: string, domain: string) {
        let url = environment.apiUrl + '/report/v1/class/count?startDate=' + startDate + '&endDate=' + endDate;
        if (domain?.trim()) {
            url += '&domain=' + encodeURIComponent(domain.trim());
        }
        return this.httpClient.get<generalCountDto[]>(url);
    }

    getLessonCount(startDate: string, endDate: string, domain: string) {
        let url = environment.apiUrl + '/report/v1/lesson/count?startDate=' + startDate + '&endDate=' + endDate;
        if (domain?.trim()) {
            url += '&domain=' + encodeURIComponent(domain.trim());
        }
        return this.httpClient.get<generalCountDto[]>(url);
    }

    getStudentCount(startDate: string, endDate: string, domain: string) {
        let url = environment.apiUrl + '/report/v1/student/count?startDate=' + startDate + '&endDate=' + endDate;
        if (domain?.trim()) {
            url += '&domain=' + encodeURIComponent(domain.trim());
        }
        return this.httpClient.get<generalCountDto[]>(url);
    }

    getUserCount(startDate: string, endDate: string, domain: string) {
        let url = environment.apiUrl + '/report/v1/user/count?startDate=' + startDate + '&endDate=' + endDate;
        if (domain?.trim()) {
            url += '&domain=' + encodeURIComponent(domain.trim());
        }
        return this.httpClient.get<generalCountDto[]>(url);
    }

    saveFile(data: any, fileName: string, fileType: string): void {
        let file = new Blob([data], { type: fileType });
        let fileUrl = window.URL.createObjectURL(file);
        const e = document.createElement('a');
        e.href = fileUrl;
        e.download = fileName;
        document.body.appendChild(e);
        e.click();
        setTimeout(function () {
            document.body.removeChild(e);
            window.URL.revokeObjectURL(fileUrl);
        }, 1000);

    }

    openSnackBar(message: string, duartion: number, color: "primary" | "blue" | "green" | "warn" | "error" | "accent", xPosition: MatSnackBarHorizontalPosition,
        yPosition: MatSnackBarVerticalPosition) {
        this._snackBar.open(message, undefined, {
            duration: duartion,
            panelClass: ['snackbar', color],
            horizontalPosition: xPosition,
            verticalPosition: yPosition
        });

    }

    getAllTerms(): Observable<ClassTerm[]> {
        let url = environment.apiUrl + "/class/v1/getClassTerms?districtManaged=true";
        return this.httpClient.get<ClassTerm[]>(url);
    }

    getClassMetricsData(): Observable<ClassData[]> {
        let url = environment.apiUrl + "/districtAdmin/v1/getClassMetrics";
        return this.httpClient.get<ClassData[]>(url);
    }

    checkDaStudentConflict(studenId: string[]) {
        let url = environment.apiUrl + "/districtAdmin/v1/checkStudentIdConflicts";
        return this.httpClient.post<string[]>(url, studenId);
    }

    softLogoutUser() {
        this.userData = null;
        this.loginService.userData = null;
    }
}