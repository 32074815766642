<div class="classDetailsContainer">
    <div class="class-title">
        <div class="heading">Classroom Materials</div>
    </div>
    <div class="classInfo">
        <div class="className">
            {{ selectedClass.name }}
        </div>
        <div class="classBtns">
            <!-- <app-button [content]="'Add New Class'" [btnClasses]="'green'" class="rightAlign" (click)="addNewClass()"></app-button> -->
            <app-button [isDisabled]="selectedClass.readOnly || !selectedClass.owner || false" [content]="'Edit Class'" [btnClasses]="'blue'" (onClick)="editClass()"></app-button>
            <app-button *ngIf="canShareClass" [isDisabled]="selectedClass.readOnly || !selectedClass.owner || false" [content]="'Share Class'" [btnClasses]="'blue'" (click)="shareClassInvite()"></app-button>
            <app-button [content]="'Student List >'" [btnClasses]="'blue'" (click)="gotoStudent()"></app-button>
            <!-- <app-button [isDisabled]="selectedClass.readOnly || false" [content]="'Archive Class'" [btnClasses]="'whiteRed'" (click)="confirmArchiveClass()"></app-button> -->
        </div>
    </div>
    <div *ngIf="selectedClass?.classTerm" class="termInfo">
        <span class="text">Term: {{selectedClass.classTerm.name}}</span>
    </div>
    <div class="assessmentUserInfo">
        <div class="assessmentInfo">
            <div class="heading">
                Assessment Dates:
                <img src="./assets/images/icons/help_question_mark_icon.svg" alt="icon" class="icon" matTooltip="These are suggested assessment dates than you can change to any dates you need for your class." matTooltipPosition="above" matTooltipClass="tool-tip-info"
                    #tooltip="matTooltip" (click)="tooltip.toggle()" />
            </div>
            <div class="assessmentDates">Initial Assessment: {{selectedClass.initialAssessmentDate | date: 'MM-dd-yyyy'}}</div>
            <div class="assessmentDates">Mid Assessment: {{selectedClass.midAssessmentDate | date: 'MM-dd-yyyy'}}</div>
            <div class="assessmentDates">Final Assessment: {{selectedClass.finalAssessmentDate | date: 'MM-dd-yyyy'}}</div>
        </div>
        <div class="userInfo">
            <div class="heading">Class Sharing:</div>
            <ng-container *ngFor="let user of invitedUser">
                <div class="userName">
                    <div>{{user.user?.fullName || user.name}}
                        <span class="sameUser">{{userData?.id == user.user?.id ? "(me)" : ""}}</span>
                    </div>
                    <div class="email">{{user.user?.email || user.email}}</div>
                </div>
                <div class="userEdit">
                    <div>{{user.userRoleType}}</div>
                    <div class="userBtns">
                        <span *ngIf="!user.user" class="invited">Invited</span>
                        <app-button [isDisabled]="selectedClass.readOnly || (!selectedClass.owner && !(userData?.id == user.user?.id)) || false" [content]="'Edit'" [btnClasses]="'blue conceptBtn'" (click)="editInvitedUser(user)">
                        </app-button>
                    </div>
                </div>
            </ng-container>
            <hr *ngIf="!invitedUser.length"/>
            <div class="heading student" *ngIf="invitedStudentUser && invitedStudentUser.length">Student Sharing:</div>
            <ng-container *ngFor="let user of invitedStudentUser">
                <div class="userName students">
                    <div>{{user.studentSharedInfo}} <span class="sameUser">{{userData?.id == user.user?.id ? "(me)" : ""}}</span></div>
                </div>
            </ng-container>
            <div *ngIf="!(invitedUser && invitedUser.length) && !(invitedStudentUser && invitedStudentUser.length)">
                No users shared
            </div>
        </div>
    </div>
    
    <div class="expansionDetails">
        <div class="newAddBtn">
            <app-button [isDisabled]="selectedClass.readOnly || false" [content]="'Create New Lesson >'" [btnClasses]="'blue'" (click)="openConceptDialog()"></app-button>
        </div>
        <div class="expansionPanel">
            <mat-expansion-panel hideToggle="true" expanded="true" #lessonPanel>
                <mat-expansion-panel-header class="expansionHeader">
                    <mat-panel-title>
                        Lessons
                    </mat-panel-title>
                    <img src="./assets/images/icons/expansion_arrow_down.svg" alt="icon" class="icon" [ngClass]="{ 'expanded': lessonPanel.expanded}">
                </mat-expansion-panel-header>
    
                <div class="listContainer">
                    <mat-progress-bar *ngIf="isGetLessonLoading" mode="query"></mat-progress-bar>
                    <ng-container *ngFor="let lesson of savedLessonList">
                        <mat-expansion-panel hideToggle="false" expanded="false" class="LessonExpansionPanel">
                            <mat-expansion-panel-header>
                                <div class="item lessonData">
                                    <div class="conceptItem">
                                        <div class="itemName">
                                            {{lesson.title}}
                                        </div>
                                        <div class="itemName conceptName">
                                            {{lesson.concept.name}}
                                        </div>
                                    </div>
                                    <div class="listBtns">
                                        <app-button [content]="'View/Edit'" [btnClasses]="'blue conceptBtn'" (click)="goToLessonBuilder(lesson)"></app-button>
                                        <mat-checkbox [color]="'primary'" class="checkbox" [(ngModel)]="lesson.selected" onclick="event.stopPropagation()"></mat-checkbox>
                                    </div>
                                </div>
                            </mat-expansion-panel-header>
                            <mat-progress-spinner class="spinner" *ngIf="lesson.isGetSavedConceptLoading" mode="indeterminate" diameter="30"></mat-progress-spinner>
                            <div *ngIf="!lesson.isGetSavedConceptLoading" class="subLessonConcept">
                                <mat-tab-group stretchTabs="true" mat-align-tabs="center">
                                    <mat-tab *ngFor="let day of days" [label]="day">
                                        <div *ngFor="let dayWiseConcept of lesson.addedComponentList[day]" class="item conceptData">
                                            <div class="itemName mini">
                                                {{dayWiseConcept.materialUiName}}
                                            </div>
                                            <div class="listBtns">
                                                <app-button [content]="'View'" [btnClasses]="'blue conceptBtn'" (click)="goToLessonConcept(dayWiseConcept, lesson)"></app-button>
                                            </div>
                                        </div>
                                    </mat-tab>
                                </mat-tab-group>
                            </div>
                        </mat-expansion-panel>
                    </ng-container>
                    <div class="actionBtns" *ngIf="savedLessonList.length">
                        <app-button [isDisabled]="selectedClass.readOnly || false" [content]="'Delete Selected'" [btnClasses]="'lightRed'" (click)="deleteSelectedLesson()"></app-button>
                        <!-- <app-button [content]="'View/Edit'" [btnClasses]="'blue'"></app-button> -->
                    </div>
                    <div *ngIf="!isGetLessonLoading && !savedLessonList.length" class="noData">
                        Please create Lesson
                    </div>
                </div>
    
            </mat-expansion-panel>
        </div>
    
    </div>
    
    <div class="expansionDetails conceptPanel">
        <div class="newAddBtn">
            <app-button [isDisabled]="selectedClass.readOnly || false" [content]="'Add New Concept Material >'" [btnClasses]="'blue'" (click)="goToConcept()"></app-button>
        </div>
        <div class="expansionPanel">
            <mat-expansion-panel hideToggle="true" expanded="true" #savedConceptPanel>
                <mat-expansion-panel-header class="expansionHeader">
                    <mat-panel-title>
                        Saved Concept Materials
                    </mat-panel-title>
                    <!-- {{ lessonPanel.expanded}} -->
                    <img src="./assets/images/icons/expansion_arrow_down.svg" alt="icon" class="icon" [ngClass]="{ 'expanded': savedConceptPanel.expanded}">
                </mat-expansion-panel-header>
    
                <div class="listContainer">
                    <mat-progress-bar *ngIf="isGetConceptSavedLoading" mode="query"></mat-progress-bar>
                    <div class="item conceptData" *ngFor="let concept of savedConceptNames">
                        <div class="itemName">
                            {{concept.name}}
                        </div>
                        <div class="listBtns">
                            <app-button [content]="'View'" [btnClasses]="'blue conceptBtn'" (click)="openSavedConceptMaterial(concept.name)"></app-button>
                            <mat-checkbox [color]="'primary'" class="checkbox" [(ngModel)]="concept.isSelected"></mat-checkbox>
                        </div>
                    </div>
    
                    <div class="actionBtns" *ngIf="savedConceptNames.length">
                        <app-button [isDisabled]="selectedClass.readOnly || false" [content]="'Delete Selected'" [btnClasses]="'lightRed'" (click)="deleteConcept()"></app-button>
                    </div>
                    <div *ngIf="!isGetConceptSavedLoading && !savedConceptNames.length" class="noData">
                        Please create Concept Material
                    </div>
                </div>
    
            </mat-expansion-panel>
        </div>
    </div>
    <!-- <div class="archiveBtn"> -->
    <!-- </div> -->
</div>
