import { Levels } from "./enums";
import { User } from "./user";

export class Student {
    guid!: string;
    studentID!: string;
    firstName!: string;
    lastName!: string;
    notes!: string;
    active!: boolean;
    level!: string;
    tabEnable!: string;
    buttonEnable!: string;
    group!: string;
    sharedUsers!: sharedUsers[];
    selected = false;
    sharedUserInfo = '';
    assessmentDate!: string;
    assessmentLevel!: string;
    assessmentScore!: number;
    avgScore!: number;
    readOnly!: boolean;

    public constructor(studentId: string, firstName: string, lastName: string, level: string, guid?: string) {
        this.studentID = studentId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.level = level;
        if (guid) {
            this.guid = guid;
        }
    }

    studentDto(userId: string) {
        let level = Levels[this.level].value;
        return {
            firstName: this.firstName,
            lastName: this.lastName,
            level,
            notes: this.notes,
            studentId: this.studentID,
            userId,
            id: this.guid
        };
    }
}

export interface sharedUsers {
    name?: string,
    email?: string,
    readOnly: boolean,
    userRoleType: string,   
    user?: User,
    id: string
}