<div class="classSelector">
    <app-dialog-title [title]="'Select a concept for the class.'"></app-dialog-title>

    <mat-form-field appearance="fill" class="titleForm">
        <mat-label>Title for Lesson (Max 40 Chars)</mat-label>
        <input matInput placeholder="Short title for your lesson" [formControl]="descriptionControl" id="descriptionControl">
        <mat-hint class="hintMessage">
            <div *ngIf="descriptionControl.value?.length === 40" class="maxCharReached">
                Max limit reached
            </div>
            <div *ngIf="descriptionControl.value?.length != 40" class="charLeft">
                {{ 40 - (descriptionControl.value?.length || 0) }} Chars remaining
            </div>
        </mat-hint>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Choose a Concept</mat-label>
        <mat-select [formControl]="selectedConceptControl">
            <mat-option *ngFor="let concept of conceptList" [value]="concept">
                {{concept.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div class="classInfo">
        *Access saved Lessons in Classroom Materials
    </div>
    <mat-dialog-actions class="btnClass">
        <app-button [content]="'Cancel'" (click)="onCancel()"></app-button>
        <app-button [content]="'Create Lesson>'" [btnClasses]="'blue'" [isDisabled]="isSaveInProgress" [isLoading]="isSaveInProgress" (click)="onAddLesson()"></app-button>
    </mat-dialog-actions>
</div>