<div class="syllable-container">
    <div class="titelCotainer">
        <div class="homeBtn">
            <app-button [content]="'&#x2B05; Concept Materials'" (click)="goToConcept()" *ngIf="!isLessonConcept"></app-button>
            <app-button [content]="'&#x2B05; Lesson Builder'" (click)="goToLessonBuilder()" *ngIf="isLessonConcept"></app-button>
        </div>
        <div class="title">
            Closed / Open Door - Create Your Own
        </div>
    </div>
    <div class="content">
        <div class="subTitle">
            Concept:
            <span class="conceptName">
                {{selectedConcept.name}}
            </span>
        </div>
        <div class="reportTitleCotainer" *ngIf="!isLessonConcept">
            <div class="titleCotainer">
                <mat-form-field appearance="outline" class="titleForm">
                    <mat-label>Title for Syllable Open/Close (Max 40 Chars)</mat-label>
                    <input matInput placeholder="Short title for your custom concept" [formControl]="descriptionControl" id="descriptionControl">
                    <mat-hint class="hintMessage">
                        <div *ngIf="descriptionControl.value?.length === 40" class="maxCharReached">
                            Max limit reached
                        </div>
                        <div *ngIf="descriptionControl.value?.length != 40" class="charLeft">
                            {{ 40 - (descriptionControl.value?.length || 0) }} Chars remaining
                        </div>
                    </mat-hint>
                </mat-form-field>
            </div>
        </div>
        <main>
            <mat-progress-bar *ngIf="isApiLoading" mode="query"></mat-progress-bar>
            <div *ngIf="!isApiLoading" class="house-container">
                <div class="left">
                    <mat-slide-toggle *ngIf="isLessonConcept" [color]="'accent'" [formControl]="includeInLessonPlan1" [labelPosition]="'after'">Include in Lesson Plan</mat-slide-toggle>
                    <div class="selector-previewer">
                        <div class="words-selector">
                            <mat-form-field appearance="outline">
                                <mat-label>Word for the top house</mat-label>
                                <mat-select [formControl]="topHouse1Word">
                                    <mat-option *ngFor="let word of wordsList" [value]="word">{{word.word}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Word for the bottom house</mat-label>
                                <mat-select [formControl]="bottomHouse1Word">
                                    <mat-option *ngFor="let word of wordsList" [value]="word">{{word.word}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="words-preview">
                            <div class="tophouseImg">
                                <span class="leftWord">{{topHouse1Word.value?.word.slice(0,-1)}}</span>
                                <span class="rightWord">{{topHouse1Word.value?.word.slice(-1)}}</span>
                            </div>
                            <div class="bottomhouseImg">
                                <span class="leftWord">{{bottomHouse1Word.value?.word.slice(0,-1)}}</span>
                                <span class="rightWord">{{bottomHouse1Word.value?.word.slice(-1)}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <mat-slide-toggle *ngIf="isLessonConcept" [color]="'accent'" [formControl]="includeInLessonPlan2" [labelPosition]="'after'">Include in Lesson Plan</mat-slide-toggle>
                    <div class="selector-previewer">
                        <div class="words-selector">
                            <mat-form-field appearance="outline">
                                <mat-label>Word for the top house</mat-label>
                                <mat-select [formControl]="topHouse2Word">
                                    <mat-option *ngFor="let word of wordsList" [value]="word">{{word.word}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Word for the bottom house</mat-label>
                                <mat-select [formControl]="bottomHouse2Word">
                                    <mat-option *ngFor="let word of wordsList" [value]="word">{{word.word}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="words-preview">
                            <div class="tophouseImg">
                                <span class="leftWord">{{topHouse2Word.value?.word.slice(0,-1)}}</span>
                                <span class="rightWord">{{topHouse2Word.value?.word.slice(-1)}}</span>
                            </div>
                            <div class="bottomhouseImg">
                                <span class="leftWord">{{bottomHouse2Word.value?.word.slice(0,-1)}}</span>
                                <span class="rightWord">{{bottomHouse2Word.value?.word.slice(-1)}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
    <div class="SaveBtnContainer">
        <app-button [content]="'Save'" [btnClasses]="'blue'" (click)="saveOpenClose(false,false)" [isDisabled]="selectedClass?.readOnly || isSaveInProgress || isSavePrintInProgress" [isLoading]="isSaveInProgress"></app-button>
        <app-button [content]="'Save and Print PDF'" (click)="saveOpenClose(true,false)" [isDisabled]="selectedClass?.readOnly || isSaveInProgress || isSavePrintInProgress" [isLoading]="isSavePrintInProgress"></app-button>
        <!-- <app-button *ngIf="isLessonConcept" [content]="'Copy to Concept Material'" [btnClasses]="'blue'" (click)="saveOpenClose(false,true)" [isDisabled]="selectedClass?.readOnly || isSaveInProgress || isSavePrintInProgress"></app-button> -->
    </div>
</div>