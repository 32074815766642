<div class="classSelector">
    <div class="archiveDialogBtn">
        <app-button *ngIf="typeOfDialogBox === 'edit'" [content]="classData.owner ? 'Archive Class' : 'Archive'" [btnClasses]="'whiteRed'" [isDisabled]="!classData.owner" [iconPath]="classData.owner ? '' : './assets/images/icons/help_question_mark_icon.svg'"
        (click)="tooltip.toggle();onArchive()" matTooltip="Class cannot be archived because you are not the owner. This record is shared with you. Class can be unlinked by using Edit under Users in Class Information page." matTooltipPosition="below"
        matTooltipClass="tool-tip-info" #tooltip="matTooltip" [matTooltipDisabled]="classData.owner"></app-button>
    </div>
    <app-dialog-title [title]="data.title"></app-dialog-title>
    <mat-dialog-content>
        <mat-progress-bar mode="query" *ngIf="isGetTermsApiLoading"></mat-progress-bar>
        <form class="formClass" [formGroup]="classForm">
            <div class="classInputs">
                <mat-form-field appearance="outline" class="formInput bottomMargin">
                    <mat-label>Class Name (Max 30 Chars)</mat-label>
                    <input matInput placeholder="Please enter class name" [formControl]="classNameControl" id="className">
                    <mat-hint>
                        <span *ngIf="classNameControl.value?.length === 30" class="maxCharReached">
                            Max limit reached
                        </span>
                        <span *ngIf="classNameControl.value?.length != 30" class="charLeft">
                            {{ 30 - (classNameControl.value?.length || 0) }} Chars remaining
                        </span>
                    </mat-hint>
                </mat-form-field>

                <mat-form-field appearance="outline" class="formInput">
                    <mat-label>Grade: </mat-label>
                    <mat-select [formControl]="classGardeControl" placeholder="Choose a grade">
                        <mat-option *ngFor="let grade of gradeList | keyvalue:keepOriginalOrder" [value]="grade.value.value"> {{grade.value.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="formInput">
                    <mat-label>Baseline: </mat-label>
                    <mat-select [formControl]="classBaseLineControl" placeholder="Choose a Baseline">
                        <mat-option [value]="this.levelList['BR'].value">{{this.levelList['BR'].name}}</mat-option>
                        <mat-option [value]="this.levelList['L1_INIT'].value">{{this.levelList['L1_INIT'].name}}</mat-option>
                        <mat-option [value]="this.levelList['L2_INIT'].value">{{this.levelList['L2_INIT'].name}}</mat-option>
                        <mat-option [value]="this.levelList['L3_INIT'].value">{{this.levelList['L3_INIT'].name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="formInput" *ngIf="userData.districtManaged && classTermList.length">
                    <mat-label>Term: </mat-label>
                    <input matInput placeholder="Search by Term name" [formControl]="termControl" [matAutocomplete]="termSuggestion">
                    <mat-autocomplete #termSuggestion="matAutocomplete" [displayWith]="displayTermName">
                        <mat-option *ngFor="let classTerm of filterClassTermList" [value]="classTerm">
                            <div>
                                {{classTerm.name}}
                                <div class="subText"> {{classTerm.startDate | date:'longDate'}} - {{classTerm.endDate | date:'longDate'}}</div>
                            </div>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <!-- <mat-form-field appearance="outline" class="formInput">
                <mat-label>Class Year:</mat-label>
                <input matInput formControlName="classYear" type="number" [max]="maxYear" [min]="minYear">
            </mat-form-field> -->
            <div class="dateInfo" *ngIf="false">
                <!-- Hide from UI, refer component for Ticket -->
                Outside District:
                <mat-checkbox [color]="'primary'" class="checkbox" formControlName="outsideDistrictCheckboxControl"></mat-checkbox>
                <img *ngIf="notValidDistrictId" src="./assets/images/icons/help_question_mark_icon.svg" alt="icon" class="icon" matTooltip="Please update your district and school in your account settings to change this selection" matTooltipPosition="above" matTooltipClass="tool-tip-info"
                    #tooltip="matTooltip" (click)="tooltip.toggle()" />
            </div>
            <div class="dateInfo">
                Choose Assessment Dates (Optional):
                <mat-checkbox [color]="'primary'" class="checkbox" formControlName="assignmentDateCheckboxControl"></mat-checkbox>
                <img src="./assets/images/icons/help_question_mark_icon.svg" alt="icon" class="icon" matTooltip="These are suggested assessment dates than you can change to any dates you need for your class." matTooltipPosition="above" matTooltipClass="tool-tip-info"
                    #tooltip2="matTooltip" (click)="tooltip2.toggle()" />
                <div class="assessmentDates" *ngIf="assignmentDateCheckboxControl.value">

                    <mat-form-field appearance="outline" class="formInput bottomMargin">
                        <mat-label>Initial Assessment:</mat-label>
                        <input matInput [matDatepicker]="initialDatepicker" formControlName="initialDateControl" placeholder="MM-DD-YYYY">
                        <mat-datepicker-toggle matSuffix [for]="initialDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #initialDatepicker></mat-datepicker>
                        <mat-hint>MM-DD-YYYY</mat-hint>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="formInput bottomMargin">
                        <mat-label>Mid Assessment</mat-label>
                        <input matInput [matDatepicker]="midDatepicker" formControlName="midDateControl" placeholder="MM-DD-YYYY">
                        <mat-datepicker-toggle matSuffix [for]="midDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #midDatepicker></mat-datepicker>
                        <mat-hint>MM-DD-YYYY</mat-hint>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="formInput bottomMargin">
                        <mat-label>Final Assessment</mat-label>
                        <input matInput [matDatepicker]="finalDatepicker" formControlName="finalDateControl" placeholder="MM-DD-YYYY">
                        <mat-datepicker-toggle matSuffix [for]="finalDatepicker"></mat-datepicker-toggle>
                        <mat-datepicker #finalDatepicker></mat-datepicker>
                        <mat-hint>MM-DD-YYYY</mat-hint>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </mat-dialog-content>
</div>

<mat-dialog-actions class="btnClass">
    <app-button [content]="'Cancel'" [isDisabled]="isCloneApiInProgress" (click)="onCancel()"></app-button>
    <app-button [content]="'Clone'" *ngIf="typeOfDialogBox === 'edit'" [btnClasses]="'blue'" [isDisabled]="isCloneApiInProgress" [isLoading]="isCloneApiInProgress" (click)="cloneClass()"></app-button>
    <app-button [content]="typeOfDialogBox=='new' && redirectToStudent? 'Save and Add Students' : 'Save'" [btnClasses]="'blue'" [isDisabled]="isCloneApiInProgress" (click)="onAddCLass()"></app-button>
</mat-dialog-actions>