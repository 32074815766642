<mat-progress-bar *ngIf="!(visualWordsClueList && visualSentenceClueList)" mode="query"></mat-progress-bar>
<div class="dictation-container">
    <div class="titelCotainer">
        <div class="homeBtn">
            <app-button [content]="'&#x2B05; Concept Materials'" (click)="goToConcept()" *ngIf="!isLessonConcept"></app-button>
            <app-button [content]="'&#x2B05; Lesson Builder'" (click)="goToLessonBuilder()" *ngIf="isLessonConcept"></app-button>
        </div>
        <div class="title">
            Words and Sentences Dictation - Create Your Own
        </div>
    </div>
    <div class="content" *ngIf="visualWordsClueList">
        <div class="subTitle">
            Concept:
            <span class="conceptName">
                {{selectedConcept.name}}
            </span>
        </div>
        <div class="reportTitleCotainer">
            <div class="titleCotainer" *ngIf="!isLessonConcept">
                <mat-form-field appearance="outline" class="titleForm">
                    <mat-label>Title for Dictation (Max 40 Chars)</mat-label>
                    <input matInput placeholder="Short title for your custom concept" [formControl]="descriptionControl" id="descriptionControl">
                    <mat-hint class="hintMessage">
                        <div *ngIf="descriptionControl.value?.length === 40" class="maxCharReached">
                            Max limit reached
                        </div>
                        <div *ngIf="descriptionControl.value?.length != 40" class="charLeft">
                            {{ 40 - (descriptionControl.value?.length || 0) }} Chars remaining
                        </div>
                    </mat-hint>
                </mat-form-field>
            </div>
            <div class="conceptFilter">
                <mat-form-field appearance="outline" class="conceptFilterSelect">
                    <mat-label>Filter Concepts</mat-label>
                    <mat-select [formControl]="conceptFilterControl">
                        <mat-option [value]="'all'"> All </mat-option>
                        <mat-option *ngFor="let concept of conceptMaterialList | keyvalue:keepOriginalOrder" [value]="concept.key" [disabled]="!concept.value">
                            {{concept.key}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="wordSelectionContainer">
            <div class="wordSelectTitle">
                Select new concept words or review words (Word list includes real and nonsense words)
                <img src="./assets/images/icons/help_question_mark_icon.svg" alt="helpIcon" class="helpIcon" (click)="openHelper('Word')">
            </div>
            <div class="wordsSelector" *ngFor="let word of conceptWordSelected;let i=index">
                <app-button *ngIf="word.selectedConceptWordClue" content="Add Word above &#x2B0F;" btnClasses="white" class="addToAbove" (click)="addNewWord(i, 'above')"></app-button>
                <app-button *ngIf="word.selectedConceptWordClue" content="Add Word below &#x2B0E;" btnClasses="blue" class="addToBelow" (click)="addNewWord(i, 'below')"></app-button>
                <mat-form-field appearance="outline" class="wordSelectInput">
                    <mat-label>{{i + 1}}. Select Word</mat-label>
                    <mat-select *ngIf="!word.isRemoved" [(ngModel)]="word.selectedConceptWordClue" (selectionChange)="changeConceptWord($event.value,i)" (openedChange)="scrollToSelectedConcept($event, 'word',word.selectedConceptWordClue)">
                        <ng-container *ngFor="let group of visualWordsClueListFiltered | keyvalue:keepOriginalOrder">
                            <mat-optgroup [label]="group.key" *ngIf="group.value.length" [id]="group.key">
                                <mat-option *ngFor="let wordData of group.value" [value]="wordData">
                                    {{wordData.word}}
                                </mat-option>
                            </mat-optgroup>
                        </ng-container>
                    </mat-select>
                    <input matInput *ngIf="word.isRemoved" [value]="word.selectedConceptWordClue?.word" [disabled]="word.isRemoved">
                    <button *ngIf="conceptWordSelected.length !== (i+1)" mat-icon-button color="warn" class="clear-icon" (click)="removeConceptWord($event, i)">
                        <mat-icon >delete_outline</mat-icon>
                    </button>
                    <mat-hint align="end" *ngIf="word.isRemoved" [ngClass]="'isRemoved'">This word has been removed from System</mat-hint>
                </mat-form-field>
                <div *ngIf="word.selectedConceptWordClue">
                    <div class="wordInputsContainer">
                        <mat-radio-group aria-label="Select an option" [(ngModel)]="word.wordLineOption" [color]="'primary'" (change)="changeConceptWordOption($event.value,i)">
                            <mat-radio-button value="soundLines" class="typeSelector">Grapheme Lines</mat-radio-button>
                            <mat-radio-button value="visualCues" class="typeSelector">Visual Cues</mat-radio-button>
                            <mat-radio-button value="wordLine" class="typeSelector">Word Line</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="imagesRadioBtns" *ngIf="word.wordLineOption !== 'wordLine'">
                        <div class="word" *ngFor="let char of word.charsOption;let j=index">
                            <img [src]="'./assets/images/concept_images/Dictation/'+ char[char.type]" class="img">
                            <div>
                                <input type="radio" [id]="'soundLine' + i + j" [(ngModel)]="char.type" value="soundLines" [name]="'wordType' + i + j">
                                <label [for]="'soundLine' + i + j">Grapheme Lines</label>
                            </div>
                            <div>
                                <input type="radio" [id]="'visualCues' + i + j" [(ngModel)]="char.type" value="visualCues" [name]="'wordType' + i + j">
                                <label [for]="'visualCues' + i + j">Visual Clue</label>
                            </div>
                            <div *ngIf="char.greyScale">
                                <input type="radio" [id]="'greyScale' + i + j" [(ngModel)]="char.type" value="greyScale" [name]="'wordType' + i + j">
                                <label [for]="'greyScale' + i + j">Grey Scale</label>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="word.wordLineOption === 'wordLine'">
                        <img [src]="'./assets/images/concept_images/Dictation/imgWordLine.png'" class="wordLineImg">
                    </div>
                </div>
            </div>
        </div>
        <div class="SentenceSelectionContainer">
            <hr/>
            <div class="SentenceSelectTitle">
                Select Sentences
                <img src="./assets/images/icons/help_question_mark_icon.svg" alt="helpIcon" class="helpIcon" (click)="openHelper('Sentence')">
            </div>
            <div class="sentenceSelector" *ngFor="let sentence of conceptSentenceSelected;let i=index">
                <app-button *ngIf="sentence.selectedConceptSentenceClue" content="Add Sentence above &#x2B0F;" btnClasses="white" class="addToAbove" (click)="addNewSentence(i, 'above')"></app-button>
                <app-button *ngIf="sentence.selectedConceptSentenceClue" content="Add Sentence below &#x2B0E;" btnClasses="blue" class="addToBelow" (click)="addNewSentence(i, 'below')"></app-button>
                <mat-form-field appearance="outline" class="SentenceSelectInput">
                    <mat-label>{{i + 1}}. Select Sentence</mat-label>
                    <mat-select (selectionChange)="changeConceptSentence($event.value,i)" [(ngModel)]="sentence.selectedConceptSentenceClue" (openedChange)="scrollToSelectedConcept($event, 'sentence',sentence.selectedConceptSentenceClue)">
                        <ng-container *ngFor="let group of visualSentenceClueListFiltered | keyvalue:keepOriginalOrder">
                            <mat-optgroup [label]="group.key" *ngIf="group.value.length" [id]="group.key">
                                <mat-option *ngFor="let sentenceData of group.value" [value]="sentenceData">
                                    {{sentenceData.sentence}}
                                </mat-option>
                            </mat-optgroup>
                        </ng-container>
                    </mat-select>
                    <button *ngIf="conceptSentenceSelected.length !== (i+1)" mat-icon-button color="warn" class="clear-icon" (click)="removeConceptSentence($event, i)">
                        <mat-icon>delete_outline</mat-icon>
                    </button>
                </mat-form-field>
                <div *ngIf="sentence.selectedConceptSentenceClue">
                    <div class="cupsOptions">
                        <mat-radio-group aria-label="Select an option" [(ngModel)]="sentence.cupsOptions" [color]="'primary'">
                            <mat-radio-button value="CUPS" class="typeSelector" class="cups">CUPS</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="sentenceInputsContainer">
                        <mat-radio-group aria-label="Select an option" [(ngModel)]="sentence.sentenceLineOption" [color]="'primary'" (change)="changeConceptSentenceOption($event.value,i)">
                            <mat-radio-button value="visualCue" class="typeSelector">Word Lines & Visual Cues</mat-radio-button>
                            <mat-radio-button value="noCues" class="typeSelector">Word Lines Only</mat-radio-button>
                            <mat-radio-button value="soundLine" class="typeSelector">Sentence Lines Only</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="imageHolder" *ngIf="sentence.sentenceLineOption !== 'soundLine'">
                        <div class="word" *ngFor="let word of sentence.wordsOption;let j=index">
                            <img [src]="'./assets/images/concept_images/Dictation/'+ word[word.type]" class="img">
                        </div>
                    </div>
                    <div *ngIf="sentence.sentenceLineOption === 'soundLine'">
                        <img [src]="'./assets/images/concept_images/Dictation/imgWordLineLong.png'" class="wordLineImg">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="printingContainer">
        <div class="printOptionTitle">
            Printing Options
        </div>
        <div class="printOption">
            <div class="visualCueSize">
                <mat-radio-group aria-label="Select an option" [color]="'primary'" [(ngModel)]="visualCueSize">
                    <mat-radio-button value="SMALL">Small Visual Cues</mat-radio-button>
                    <mat-radio-button value="LARGE">Large Visual Cues</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="pageOrientation">
                <mat-radio-group aria-label="Select an option" [color]="'primary'" [(ngModel)]="pageOrientation">
                    <mat-radio-button value="PORTRAIT">Portrait</mat-radio-button>
                    <mat-radio-button value="LANDSCAPE">Landscape</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

    </div>
    <div class="previewContainer" [ngClass]="{'landscape' : pageOrientation === 'LANDSCAPE'}">
        <div class="preview">
            <div class="previewTitle">
                Lesson Dictation
            </div>
            <div class="wordsPreview">
                <div class="subTitle">
                    <div class="words"> Words </div>
                    <div class="rewrite"> Rewrite </div>
                </div>
                <div class="wordList" *ngFor="let word of conceptWordSelected;let i=index">
                    <ng-container *ngIf="word.selectedConceptWordClue">
                        <div class="visualCues" [ngClass]="{'largeVisual': visualCueSize === 'LARGE'}">
                            {{ i + 1}}.
                            <ng-container *ngIf="word.wordLineOption !== 'wordLine'">
                                <ng-container *ngFor="let char of word.charsOption;let j=index">
                                    <img [src]="'./assets/images/concept_images/Dictation/'+ char[char.type]">
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="word.wordLineOption === 'wordLine'">
                                <img [src]="'./assets/images/concept_images/Dictation/imgWordLine.png'" class="onlyWordLine">
                            </ng-container>
                        </div>
                        <div class="rewritesImg">
                            {{ i + 1}}. <img src="./assets/images/concept_images/Dictation/imgWordLine.png">
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="sentencePreview">
                <div class="subTitle">
                    Sentences
                </div>
                <ng-container *ngFor="let sentence of conceptSentenceSelected;let i=index">
                    <ng-container *ngIf="sentence.selectedConceptSentenceClue">
                        <div class="visualCues" [ngClass]="{'largeVisual': visualCueSize === 'LARGE'}">
                            <ng-container *ngIf="sentence.sentenceLineOption !== 'soundLine'">
                                {{ i + 1}}.
                                <ng-container *ngFor="let word of sentence.wordsOption;let j=index">
                                    <img [src]="'./assets/images/concept_images/Dictation/'+ word[word.type]" [ngClass]="{'spacerImg': word[word.type] === 'spacer.png'}">
                                </ng-container>
                            </ng-container>
                            <!-- <ng-container *ngIf="sentence.sentenceLineOption === 'soundLine'">
                                <img [src]="'./assets/images/concept_images/Dictation/imgWordLineLong.png'" class="onlyWordLine">
                            </ng-container> -->
                        </div>
                        <div class="rewritesImg">
                            {{ i + 1}}. <img src="./assets/images/concept_images/Dictation/imgWordLineLong.png">
                        </div>
                    </ng-container>
                </ng-container>
            </div>

        </div>
        <div class="previewFooter">
            <div>Teacher Name</div>
            <div>IMSE &#169;</div>
            <div>{{ todayDate | date}}</div>
        </div>

    </div>
    <mat-progress-bar *ngIf="!(visualWordsClueList && visualSentenceClueList)" mode="query"></mat-progress-bar>
    <div class="SaveBtnContainer" *ngIf="visualWordsClueList && visualSentenceClueList">
        <app-button [content]="'Save'" [btnClasses]="'blue'" (click)="saveDictation(null, false)" [isDisabled]="selectedClass?.readOnly || isStudentPdfCreationInProgress || isTeacherPdfCreationInProgress || isSaveInProgress" [isLoading]="isSaveInProgress"></app-button>
        <app-button [content]="'Save and Print Student PDF'" (click)="saveDictation('STUDENT', false)" [isDisabled]="selectedClass?.readOnly || !savedDictations || isStudentPdfCreationInProgress" [isLoading]="isStudentPdfCreationInProgress" matTooltip="Print will be enabled after Save"
            matTooltipPosition="above"></app-button>
        <app-button [content]="'Save and Print Teacher PDF'" (click)="saveDictation('TEACHER', false)" [isDisabled]="selectedClass?.readOnly || !savedDictations || isTeacherPdfCreationInProgress" [isLoading]="isTeacherPdfCreationInProgress" matTooltip="Print will be enabled after Save"
            matTooltipPosition="above"></app-button>
        <!-- <app-button *ngIf="isLessonConcept" [content]="'Copy to Concept Material'" [btnClasses]="'blue'" (click)="saveDictation(null, true)" [isDisabled]="selectedClass?.readOnly || isStudentPdfCreationInProgress || isTeacherPdfCreationInProgress || isSaveInProgress"
            [isLoading]="isSaveInProgress"></app-button> -->
    </div>
</div>