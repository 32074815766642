import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ClassData } from "src/app/classes/classData";
import { ConceptMaterial } from "src/app/classes/concept";
import { formatedPreMade } from "src/app/classes/Concepts/premade";
import { NavbarService } from "src/app/layout/app-header/navbar.service";
import { CoreService } from "../../core.service";

@Component({
    selector: 'app-concept',
    templateUrl: 'app-concept.component.html',
    styleUrls: ['app-concept.component.scss']
})
export class ConceptComponent implements OnInit, OnDestroy {
    conceptMaterialData!: ConceptMaterial[];
    conceptMaterialDataFiltered!: ConceptMaterial[];
    _subscription: Subscription[] = [];
    isConceptSelected = false;
    selectedConcept!: ConceptMaterial;
    conceptListHeight = "calc(100% - 165px)";
    isApiLoading = false;
    leftHeaderButtons: { title: string, event: string }[] = [{
        title: 'Back to Home',
        event: 'homeButton'
    }];
    value = 'hello';
    searchConceptNameControl : UntypedFormControl = new UntypedFormControl();
    // rightHeaderButtons: { title: string, event: string }[] = [{
    //     title: 'Saved Materials',
    //     event: 'savedLessonConcept'
    // }
    // ];

    rightHeaderButtons: { title: string, event: string }[] = [];

    functonList: { [name: string]: Function } = {
        homeButton: () => this.gotoToHome(),
        goToThreePartDrill: () => this.goToThreePartDrill(),
        openClassSelection: () => this.goToClassManagement(),
        goToDictation: () => this.goToDictation(),
        goToNewConcept: () => this.goToNewConcept(),
        goToFluency: () => this.goToFluency(),
        goToRedWords: () => this.goToRedWords(),
        goToLetterFormation: () => this.goToLetterFormation(),
        goToSyllableOpenClose: () => this.goToSyllableOpenClose(),
        goToSyllableDecodingWords: () => this.goToSyllableDecodingWords()
    }
    materialCardData: concepts[] | null = [];
    materialCardDataInitial: concepts[] = [{
        imageUrl: '3part-drill-icon.png',
        title: 'Three Part Drill',
        customButton: {
            title: 'Custom',
            cssClass: 'blue conceptBtn',
            onClick: 'goToThreePartDrill'
        },
        premadeButton: {
            title: 'Digital Slides',
            cssClass: 'conceptBtn'
        },
        materialType: 'THREE_PART_DRILL'
    },
    {
        imageUrl: 'practice_reading_icon.png',
        title: 'Practice Reading',
        premadeButton: {
            title: 'Digital Slides',
            cssClass: 'conceptBtn'
        },
        premadeButton2: {
            title: 'Directions',
            cssClass: 'blue conceptBtn',
            premadeUrl: './assets/pdfs/StudentReadingPassageDirections.pdf'
        },
        materialType: 'PRACTICE_READING'
    },
    {
        imageUrl: 'new-concept-icon.png',
        title: 'New Concept',
        customButton: {
            title: 'Custom',
            cssClass: 'blue conceptBtn',
            onClick: 'goToNewConcept'
        },
        premadeButton: {
            title: 'Digital Slides',
            cssClass: 'conceptBtn'
        },
        materialType: 'MULTI_SENSORY'
    },
    {
        imageUrl: 'workbook-dictation-icon.png',
        title: 'Words and Sentences Dictation',
        customButton: {
            title: 'Custom',
            cssClass: 'blue conceptBtn',
            onClick: 'goToDictation'
        },
        premadeButton: {
            title: 'Digital Slides',
            cssClass: 'conceptBtn'
        },
        materialType: 'DICTATION'
    },
    {
        imageUrl: 'literature-connection-icon.png',
        title: 'Literature Connection',
        premadeButton: {
            title: 'Amazon Shop',
            cssClass: 'blue conceptBtn'
        },
        // premadeButton2: {
        //     title: 'IMSE Digital Resources',
        //     cssClass: 'blue conceptBtn',
        //     premadeUrl: 'https://imse.com/products'
        // },
        materialType: 'LITERACY_CONNECTIONS'
    },
    {
        imageUrl: 'ellresources-icon.png',
        title: 'ELL Resources',
        premadeButton: {
            title: 'Digital Slides',
            cssClass: 'conceptBtn'
        },
        materialType: 'ELL_RESOURCES'
    },
    {
        imageUrl: 'letter-formation-icon.png',
        title: 'Letter Formation',
        customButton: {
            title: 'Custom',
            cssClass: 'blue conceptBtn',
            onClick: 'goToLetterFormation'
        },
        premadeButton: {
            title: 'Digital Slides',
            cssClass: 'conceptBtn'
        },
        materialType: 'LETTER_FORMATION'
    },
    // {
    //     imageUrl: 'classroom-games-icon.png',
    //     title: 'Classroom Games',
    //     buttons: [{
    //         title: 'Digital Slides',
    //         cssClass: 'conceptBtn singleBtn'
    //     }]
    // },
    {
        imageUrl: 'decodable-reader.png',
        title: 'Decodable Reader',
        premadeButton: {
            title: 'Digital Slides',
            cssClass: 'conceptBtn'
        },
        premadeButton2: {
            title: 'Single Page',
            cssClass: 'blue conceptBtn',
        },
        materialType: 'DECODABLE_READER'
    },
    // {
    //     imageUrl: 'homework-icon.png',
    //     title: 'Homework',
    //     buttons: [{
    //         title: 'Custom',
    //         cssClass: 'blue conceptBtn'
    //     },
    //     {
    //         title: 'Digital Slides',
    //         cssClass: 'conceptBtn'
    //     }]
    // },
    {
        imageUrl: 'red-words-icon.png',
        title: 'Red Words',
        customButton: {
            title: 'Custom',
            cssClass: 'blue conceptBtn',
            onClick: 'goToRedWords'
        },
        premadeButton: {
            title: 'Digital Slides',
            cssClass: 'conceptBtn'
        },
        materialType: 'RED_WORDS'
    },
    {
        imageUrl: 'extension-activities-icon.png',
        title: 'Extension Activities',
        premadeButton: {
            title: 'Digital Slides',
            cssClass: 'conceptBtn'
        },
        materialType: 'EXTENSION_ACTIVITIES'
    },
    // {
    //     imageUrl: 'rapid-word.png',
    //     imageclass: 'rapidWord',
    //     title: 'Rapid Word Chart Slides',
    //     buttons: [{
    //         title: 'Digital Slides',
    //         cssClass: 'conceptBtn singleBtn'
    //     }]
    // },
    {
        imageUrl: 'fluency-icon.png',
        imageclass: 'fluencyStn',
        title: 'Fluency',
        customButton: {
            title: 'Custom',
            cssClass: 'blue conceptBtn',
            onClick: 'goToFluency'
        },
        premadeButton: {
            title: 'Digital Slides',
            cssClass: 'conceptBtn'
        },
        materialType: 'FLUENCY'
    },
    {
        imageUrl: 'word-cards-icon.png',
        imageclass: 'rapidWord',
        title: 'Word Cards',
        premadeButton: {
            title: 'Digital Slides',
            cssClass: 'conceptBtn'
        },
        materialType: 'WORD_CARD'
    },
    {
        imageUrl: 'syllable_open_close_door.png',
        imageclass: 'syllable',
        title: 'Closed/Open Door',
        customButton: {
            title: 'Custom',
            cssClass: 'blue conceptBtn',
            onClick: 'goToSyllableOpenClose'
        },
        premadeButton: {
            title: 'Digital Slides',
            cssClass: 'conceptBtn'
        },
        materialType: 'OPEN_CLOSE_DOOR'
    },
    {
        imageUrl: 'syllable_open_close_door.png',
        imageclass: 'syllable',
        title: 'Syllable Division: Decoding Words',
        customButton: {
            title: 'Custom',
            cssClass: 'blue conceptBtn',
            onClick: 'goToSyllableDecodingWords'
        },
        premadeButton: {
            title: 'Digital Slides',
            cssClass: 'conceptBtn'
        },
        materialType: 'DECODING_WORDS'
    },
    ];
    routerData: { selectedConcept: ConceptMaterial };
    selectedClass: ClassData | null;

    constructor(private coreService: CoreService,
        private router: Router,
        private navbarService: NavbarService) {
        this.selectedClass = this.coreService.selectedClass;
        if (this.selectedClass) {
            this.leftHeaderButtons.push({
                title: this.selectedClass.name,
                event: 'openClassSelection'
            });
        }
        this.navbarService.updateLeftHeaderBtn(this.leftHeaderButtons);
        this.navbarService.updateRightHeaderBtn(this.rightHeaderButtons);
        let navServiceSub = this.navbarService.headerBtnClicked$.subscribe({
            next: action => this.onFunctionCall(action)
        });
        this._subscription.push(navServiceSub);
        this.routerData = this.router.getCurrentNavigation()?.extras.state as {
            selectedConcept: ConceptMaterial
        };
    }

    ngOnInit(): void {
        this.getConceptData();
        let conceptSearch = this.searchConceptNameControl.valueChanges.subscribe({
            next: (value: string) => {
                let data = this.conceptMaterialData.filter(d => d.name.toLowerCase().indexOf(value.toLowerCase().trim()) > -1);
                this.conceptMaterialDataFiltered = data;
            }
        });
        this._subscription.push(conceptSearch);
    }

    ngOnDestroy(): void {
        this._subscription.forEach(sub => sub?.unsubscribe());
    }

    getConceptData() {
        let apiSub = this.coreService.getConceptMaterial().subscribe({
            next: (apiData) => {
                this.conceptMaterialData = apiData;
                this.conceptMaterialDataFiltered = [...this.conceptMaterialData];
                let routeSelectedConcept = this.conceptMaterialData.find(c => c.name === this.routerData?.selectedConcept.name);
                this.selectConcept(routeSelectedConcept || this.conceptMaterialData[0]);
                setTimeout(() => this.scrollToConcept());
            }
        });
        this._subscription.push(apiSub);
    }

    onFunctionCall(action: string) {
        if (this.functonList[action]) {
            this.functonList[action]();
        }
    }

    gotoToHome() {
        this.router.navigate(['/dashboard']);
    }

    clearSearchConcept() {
        this.searchConceptNameControl.setValue('');
    }

    selectConcept(selectedConcept: ConceptMaterial) {
        if (this.isApiLoading || selectedConcept === this.selectedConcept) {
            return;
        }
        this.conceptMaterialData.forEach(d => d.isSelected = false);
        selectedConcept.isSelected = true;
        this.selectedConcept = selectedConcept;
        this.isApiLoading = true;
        let premadeSub = this.coreService.getConceptPremadeMaterial(this.selectedConcept.guid).subscribe({
            next: resp => {
                let preMadeData: formatedPreMade = {};
                let preMadeData2: formatedPreMade = {};
                resp.forEach(data => {
                    if (data.url?.trim()) {
                        preMadeData[data.materialType] = data.url;
                    }
                    if (data.url2?.trim()) {
                        preMadeData2[data.materialType] = data.url2;
                    }
                });
                this.materialCardData = this.materialCardDataInitial
                    .filter(conc => !(this.selectedConcept.sort < 350 && conc.materialType === 'DECODING_WORDS'))
                    .filter(conc => !(this.selectedConcept.sort < 40 && conc.materialType === 'DICTATION'))
                    .filter(conc => conc.materialType === 'PRACTICE_READING' ? !!preMadeData['PRACTICE_READING'] : true)
                    .map(data => {
                    let tmpCardData: concepts = {
                        imageUrl: data.imageUrl,
                        title: data.title,
                        imageclass: data.imageclass,
                        materialType: data.materialType,
                        premadeButton: null
                    };
                    //add custom if present
                    if (data.customButton) {
                        tmpCardData.customButton = {
                            title: data.customButton.title,
                            cssClass: data.customButton.cssClass,
                            onClick: data.customButton.onClick
                        };
                    }
                    //Set premade Url Link
                    if (preMadeData[tmpCardData.materialType] && data.premadeButton) {
                        tmpCardData.premadeButton = {
                            title: data.premadeButton.title,
                            cssClass: data.premadeButton.cssClass,
                            premadeUrl: preMadeData[tmpCardData.materialType]
                        };
                    }
                    //Set Premade 2
                    if (data.premadeButton2 && (data.premadeButton2.premadeUrl || preMadeData2[tmpCardData.materialType])) {
                        tmpCardData.premadeButton2 = {
                            title: data.premadeButton2.title,
                            cssClass: data.premadeButton2.cssClass,
                            premadeUrl: preMadeData2[tmpCardData.materialType] || data.premadeButton2.premadeUrl
                        };
                    }
                    //Increase Btn Size if only custom or premade is present
                    if (tmpCardData.customButton && !tmpCardData.premadeButton?.premadeUrl) {
                        tmpCardData.customButton.cssClass += ' singleBtn';
                    } else if (tmpCardData.premadeButton && !tmpCardData.customButton?.onClick
                        && !tmpCardData.premadeButton2?.premadeUrl) {
                        tmpCardData.premadeButton.cssClass += ' singleBtn';
                    }
                    return tmpCardData;
                });
                this.isApiLoading = false;
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            },
            error: err => {
                console.log("Error while fetching PreMade lesson data", err);
                this.isApiLoading = false;
            }
        });
        this._subscription.push(premadeSub);
    }

    @HostListener('window:scroll', ['$event'])
    scrollEvent = (event: any): void => {
        const n = event.srcElement?.scrollingElement?.scrollTop;
        if (n > 55) {
            this.conceptListHeight = "calc(100% - 75px)";
        } else {
            this.conceptListHeight = "calc(100% - 165px)";
        }
    }

    goToThreePartDrill() {
        this.router.navigateByUrl('/3part_drill-custom', {
            state: {
                selectedConcept: this.selectedConcept
            }
        });
    }

    goToClassManagement() {
        this.router.navigate(['class']);
    }

    goToDictation() {
        this.router.navigateByUrl('/dictation-custom', {
            state: {
                selectedConcept: this.selectedConcept
            }
        });
    }

    goToNewConcept() {
        this.router.navigateByUrl('/new-concept', {
            state: {
                selectedConcept: this.selectedConcept,
                conceptMaterialList: this.conceptMaterialData
            }
        });
    }

    goToLetterFormation() {
        this.router.navigateByUrl('/letter-formation-custom', {
            state: {
                selectedConcept: this.selectedConcept,
                conceptMaterialList: this.conceptMaterialData
            }
        });
    }

    goToFluency() {
        this.router.navigateByUrl('/fluency-custom', {
            state: {
                selectedConcept: this.selectedConcept
            }
        });
    }

    goToRedWords() {
        this.router.navigateByUrl('/red-words-custom', {
            state: {
                selectedConcept: this.selectedConcept
            }
        });
    }

    goToSyllableOpenClose() {
        this.router.navigateByUrl('/syllable-open-close', {
            state: {
                selectedConcept: this.selectedConcept
            }
        });
    }

    goToSyllableDecodingWords() {
        this.router.navigateByUrl('/syllable-decoding-words', {
            state: {
                selectedConcept: this.selectedConcept
            }
        });
    }

    goToPreMadeUrl(preMadeUrl: string) {
        if (preMadeUrl) {
            window.open(preMadeUrl, '_blank', 'noopener,resizable,scrollbars,popup=false');
        }
    }

    scrollToConcept() {
        let element = document.getElementById("" + this.conceptMaterialData.indexOf(this.selectedConcept));
        element?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    }

    goToImseOg() {
        window.open('https://imse.com/interactive-og/', '_blank', 'noopener,resizable,scrollbars,popup=false');
        return;
    }
}

interface conceptBtns {
    title: string,
    cssClass: string,
    onClick?: string,
    premadeUrl?: string | undefined
}
interface concepts {
    imageUrl: string,
    title: string,
    imageclass?: string,
    customButton?: conceptBtns,
    premadeButton: conceptBtns | null,
    premadeButton2?: conceptBtns,
    materialType: string
}