import { Component, Inject, OnDestroy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { ClassData } from "src/app/classes/classData";
import { CoreService } from "src/app/core/core.service";

@Component({
    selector: 'archive-class-dialog',
    templateUrl: 'archive-class.component.html',
    styleUrls: ['archive-class.component.scss']
})
export class ArchiveClassDialog implements OnDestroy {
    private _subscription!: Subscription;
    className!: string;
    selectedClass!: ClassData | null;
    type!: string;
    loading = false;
    errorMessage = '';
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ArchiveClassDialog>,
        private coreService: CoreService,
        ) {
        this.type = this.data['type'];
        if(this.data['classData']) {
            this.selectedClass = this.data['classData'];
        } else {
            this.selectedClass = this.coreService.selectedClass;
        }
        this.className = this.selectedClass?.name || '';
    }
    ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    onCancel() {
        this.dialogRef.close(null);
    }

    onArchiveClass() {
        this.errorMessage = '';
        this.loading = true;
        this._subscription = this.coreService.archiveClass(this.selectedClass?.guid || '').subscribe({
            next: resp => {
                let dialogMessage = 'Restored';
                let dialogTheme: 'primary' | 'warn' = 'primary';
                if (this.type === 'Archive') {
                    dialogMessage = 'Archived';
                    dialogTheme = 'warn';
                }
                this.coreService.openSnackBar(`${this.className} Class ${dialogMessage}`, 3000, dialogTheme, "end", "bottom");

                this.dialogRef.close(dialogMessage);
            },
            error: err => {
                console.log(err);
                this.loading = false;
                this.errorMessage = 'Error Occured, please try later';
            }
        });
    }

}