import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ClassData } from "src/app/classes/classData";
import { ConceptMaterial } from "src/app/classes/concept";
import { SavedFluencyDto } from "src/app/classes/Concepts/fluency";
import { CoreService } from "src/app/core/core.service";
import { NavbarService } from "src/app/layout/app-header/navbar.service";
import { ConfirmDialogBox } from "../../dialog-box/confirm-dialog/confirm-dialog.component";

@Component({
    selector: 'app-fluency',
    templateUrl: 'app-fluency.component.html',
    styleUrls: ['app-fluency.component.scss']
})
export class FluencyCustomComponent implements OnInit, OnDestroy {
    selectedConcept!: ConceptMaterial;
    _subscription: Subscription[] = [];
    descriptionControl: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.maxLength(40)]);
    isSaveInProgress = false;
    isSavePrintInProgress = false;
    WORD_LIST = 5;
    WORDS_RANDOM_LIST = 25;

    fluencyFiveWordList1: string[] = Array(this.WORD_LIST).fill('');
    fluencyFiveWordList2: string[] = Array(this.WORD_LIST).fill('');
    fluencyFiveWordList3: string[] = Array(this.WORD_LIST).fill('');

    flucencyWordSplitter = [0, 5, 10, 15, 20];
    fluencyTwentyFiveWordList1: string[] = Array(this.WORDS_RANDOM_LIST).fill('');
    fluencyTwentyFiveWordList2: string[] = Array(this.WORDS_RANDOM_LIST).fill('');
    fluencyTwentyFiveWordList3: string[] = Array(this.WORDS_RANDOM_LIST).fill('');

    includeInLessonPlan1: UntypedFormControl = new UntypedFormControl(false);
    includeInLessonPlan2: UntypedFormControl = new UntypedFormControl(false);
    includeInLessonPlan3: UntypedFormControl = new UntypedFormControl(false);

    savedFluencyData!: SavedFluencyDto;
    isLessonConcept = false;
    leftHeaderButtons: { title: string, event: string }[] = [{
        title: 'Back to Home',
        event: 'homeButton'
    }];

    // rightHeaderButtons: { title: string, event: string }[] = [{
    //     title: 'Saved Materials',
    //     event: 'savedLessonConcept'
    // }];

    rightHeaderButtons: { title: string, event: string }[] = [];

    functonList: { [name: string]: Function } = {
        homeButton: () => this.gotoToHome(),
        openClassSelection: () => this.goToClassManagement()
    }
    selectedClass: ClassData | null;
    constructor(private coreService: CoreService,
        private router: Router,
        private navbarService: NavbarService,
        public dialog: MatDialog) {
        this.selectedClass = this.coreService.selectedClass;
        if (this.selectedClass) {
            this.leftHeaderButtons.push({
                title: this.selectedClass.name,
                event: 'openClassSelection'
            });
        }

        this.navbarService.updateLeftHeaderBtn(this.leftHeaderButtons);
        this.navbarService.updateRightHeaderBtn(this.rightHeaderButtons);
        let navServiceSub = this.navbarService.headerBtnClicked$.subscribe({
            next: action => this.onNavigationServiceActionCalled(action)
        });
        this._subscription.push(navServiceSub);

        let routerData = this.router.getCurrentNavigation()?.extras.state as {
            selectedConcept: ConceptMaterial,
            savedConceptMaterial?: SavedFluencyDto
        };
        this.selectedConcept = routerData.selectedConcept;

        if (routerData.savedConceptMaterial) {
            this.savedFluencyData = routerData.savedConceptMaterial;
            if (this.savedFluencyData.lessonMaterial) {
                this.isLessonConcept = true;
                this.descriptionControl = new UntypedFormControl('');
            } else {
                this.descriptionControl.setValue(this.savedFluencyData.reportTitle || '');
            }
            this.populateSaved();
        }
        let descChangeSub = this.descriptionControl.valueChanges.subscribe({
            next: (value: string) => {
                if (this.descriptionControl.errors?.['maxlength']) {
                    this.descriptionControl.setValue(value.substring(0, 40));
                }
            }
        });
        this._subscription.push(descChangeSub);
    }

    ngOnInit(): void {
    }
    ngOnDestroy(): void {
        this._subscription.forEach(sub => sub?.unsubscribe());
    }

    populateSaved() {
        //part 1
        this.fluencyFiveWordList1 = this.fetchWords(0, this.WORD_LIST, 'inputWord');
        this.fluencyTwentyFiveWordList1 = this.fetchWords(0, this.WORDS_RANDOM_LIST, 'word');
        this.includeInLessonPlan1.setValue(this.savedFluencyData.fluencyWords[0]?.includeLesson || false);
        //part 2
        this.fluencyFiveWordList2 = this.fetchWords(1, this.WORD_LIST, 'inputWord');
        this.fluencyTwentyFiveWordList2 = this.fetchWords(1, this.WORDS_RANDOM_LIST, 'word');
        this.includeInLessonPlan2.setValue(this.savedFluencyData.fluencyWords[1]?.includeLesson || false);
        //part 3
        this.fluencyFiveWordList3 = this.fetchWords(2, this.WORD_LIST, 'inputWord');
        this.fluencyTwentyFiveWordList3 = this.fetchWords(2, this.WORDS_RANDOM_LIST, 'word');
        this.includeInLessonPlan3.setValue(this.savedFluencyData.fluencyWords[2]?.includeLesson || false);
    
        setTimeout(() => window.scroll({ top: 0, left: 0, behavior: 'smooth' }), 1000);
    }
    fetchWords(index: number, length: number, name: string): string[] {
        let word = this.savedFluencyData.fluencyWords[index];
        let outWordList: string[] = [];
        if (!word) {
            return Array(length).fill('');
        }
        for (let i = 1; i <= length; i++) {
            outWordList.push(word[name + i] || '');
        }
        return outWordList;
    }
    onNavigationServiceActionCalled(action: string) {
        if (this.functonList[action]) {
            this.functonList[action]();
        }
    }

    gotoToHome() {
        this.router.navigate(['/dashboard']);
    }

    goToClassManagement() {
        this.router.navigate(['class']);
    }

    goToConcept() {
        this.router.navigateByUrl('concept', {
            state: {
                selectedConcept: this.selectedConcept
            }
        });
    }

    goToLessonBuilder() {
        this.router.navigateByUrl('lessonBuilder', {
            state: {
                lessonData: this.savedFluencyData.lessonMaterial?.lesson
            }
        });
    }

    trackByIdx(index: number, obj: any): any {
        return index;
    }

    updateRandom(fiveWords: string[], twentyFiveWords: string[], includeInLesson: UntypedFormControl) {
        if (this.isLessonConcept) {
            includeInLesson.setValue(true);
        }
        let filterFiveWords = fiveWords.filter(d => d.trim().length !== 0);
        let filteredLength = filterFiveWords.length;
        twentyFiveWords.forEach((d, i) => {
            twentyFiveWords[i] = filterFiveWords[Math.floor(Math.random() * filteredLength)] || '';
        })
    }

    clearWords(twentyFiveWords: string[]) {
        twentyFiveWords.forEach((d, i) => {
            twentyFiveWords[i] = '';
        })
    }

    saveFluency(printPdf: boolean, copyToConcept: boolean) {
        if (this.descriptionControl.errors) {
            this.descriptionControl.markAsTouched();
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            this.coreService.openSnackBar("Report Title Required", 3000, "warn", "end", "bottom");
            return;
        }
        if (printPdf) {
            this.isSavePrintInProgress = true;
        } else {
            this.isSaveInProgress = true;
        }
        let data = {
            fluencyFiveWordList1: this.fluencyFiveWordList1,
            fluencyFiveWordList2: this.fluencyFiveWordList2,
            fluencyFiveWordList3: this.fluencyFiveWordList3,
            fluencyTwentyFiveWordList1: this.fluencyTwentyFiveWordList1,
            fluencyTwentyFiveWordList2: this.fluencyTwentyFiveWordList2,
            fluencyTwentyFiveWordList3: this.fluencyTwentyFiveWordList3,
            includeInLessonPlan1: this.includeInLessonPlan1.value,
            includeInLessonPlan2: this.includeInLessonPlan2.value,
            includeInLessonPlan3: this.includeInLessonPlan3.value,
            conceptId: this.selectedConcept.guid,
            reportTitle: this.descriptionControl.value.trim()
        };
        let displayMessage = '';
        let updateById = copyToConcept ? null : this.savedFluencyData?.id;

        if (copyToConcept) {
            displayMessage = 'Copied to Concept Material';
            data.reportTitle = ('Copy From ' + this.savedFluencyData.lessonMaterial?.lesson.title).substring(0, 40);
            this.saveData(data, updateById, displayMessage, copyToConcept, printPdf);
            return;
        } else {
            displayMessage = 'Data ' + (this.savedFluencyData?.id ? 'Updated' : 'Saved');
        }


        let getSavedConceptsSub = this.coreService.getSavedConceptMaterialByClass().subscribe(
            {
                next: savedConcepts => {
                    let isDuplicateConceptName = savedConcepts.filter(d => d.materialTypeId !== updateById)
                        .some(d => d.materialTypeTitle?.toLowerCase().trim() === data.reportTitle?.toLowerCase());
                    if (isDuplicateConceptName) {
                        let confirmDialogBox = this.dialog.open(ConfirmDialogBox, {
                            data: {
                                message: `Concept material already exists with the same name. Do you want to save?`,
                                confirmBtnClass: 'blue',
                                confirmBtnLabel: 'Save'
                            },
                            disableClose: true,
                        });
                        let confirmDialogSub = confirmDialogBox.beforeClosed().subscribe({
                            next: dataConfirm => {
                                if (dataConfirm) {
                                    this.saveData(data, updateById, displayMessage, copyToConcept, printPdf);
                                } else {
                                    this.isSaveInProgress = false;
                                    this.isSavePrintInProgress = false;
                                }
                            }
                        });
                        this._subscription.push(confirmDialogSub);
                    } else {
                        this.saveData(data, updateById, displayMessage, copyToConcept, printPdf);
                    }
                }
            }
        );
        this._subscription.push(getSavedConceptsSub);
    }

    saveData(data: object, updateById: string | null, displayMessage: string, copyToConcept: boolean, printPdf: boolean) {
        let apiSub = this.coreService.saveFluency(data, updateById).subscribe({
            next: (resp: any) => {
                this.coreService.openSnackBar(displayMessage, 3000, "primary", "end", "bottom");
                if (!copyToConcept) {
                    this.savedFluencyData = resp;
                }
                this.isSaveInProgress = false;
                printPdf && this.printPdf();
            },
            error: err => {
                console.log(err);
                this.coreService.openSnackBar("Some error occurred", 3000, "warn", "end", "bottom");
                this.isSaveInProgress = false;
            }
        });
        this._subscription.push(apiSub);
    }


    printPdf() {
        let apiSub = this.coreService.getFluencyPDF(this.savedFluencyData.id).subscribe({
            next: (data: Blob) => {
                try {
                    this.isSavePrintInProgress = false;
                    this.coreService.openPdf(data, 'Fluency');
                } catch (err) {
                    console.log(err);
                    this.coreService.openSnackBar("Error occurred while opening PDF", 3000, "warn", "end", "bottom");
                }
            },
            error: err => {
                this.isSavePrintInProgress = false;
                console.log(err);
                this.coreService.openSnackBar("Some error occurred", 3000, "warn", "end", "bottom");
            }
        });
        this._subscription.push(apiSub);
    }
}
