import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConceptComponent } from './core/components/app-concept/app-concept.component';
import { AppLoginComponent } from './core/components/app-login/app-login.component';
import { DashboardComponent } from './core/components/dashboard/app-dashboard.component';
import { LoginService } from './core/login.service';
import { ClassDetailComponent } from './core/components/class-detail/class-detail.component';
import { StudentDetailComponent } from './core/components/student-detail/student-detail.component';
import { LessonBuilder } from './core/components/app-lesson-builder/app-lesson-builder.component';
import { ThreePartDrillComponent } from './core/components/app-concept-custom/app-3part-drill/app-3part-drill.component';
import { DictationWordsSentencesComponent } from './core/components/app-concept-custom/app-dictation/app-dictation.component';
import { NewConceptComponent } from './core/components/app-concept-custom/app-new-concept/app-new-concept.component';
import { FluencyCustomComponent } from './core/components/app-concept-custom/app-fluency/app-fluency.component';
import { RedWordsCustomComponent } from './core/components/app-concept-custom/app-red-words/app-red-words.component';
import { StudentAssessmentComponent } from './core/components/student-assessment/student-assessment.component';
import { LetterFormationComponent } from './core/components/app-concept-custom/app-letter-formation/app-letter-formation.component';
import { SyllableOpenCloseComponent } from './core/components/app-concept-custom/app-syllable-open-close/syllable-open-close.component';
import { SyllableDecodingWordsComponent } from './core/components/app-concept-custom/app-syllable-decoding-words/syllable-decoding-words.component';
import { LessonPrintComponent } from './core/components/app-lesson-print/lesson-print.component';
import { MigrateToDaComponent } from './core/components/app-migrate-to-district/app-migrate-to-district.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: AppLoginComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [LoginService]
  },
  {
    path: 'concept',
    component: ConceptComponent,
    canActivate: [LoginService]
  },
  {
    path: 'class',
    component: ClassDetailComponent,
    canActivate: [LoginService]
  },
  // {
  //   path: 'student',
  //   component: StudentDetailComponent,
  //   canActivate: [LoginService]
  // },
  {
    path: 'student-assessment',
    component: StudentAssessmentComponent,
    canActivate: [LoginService]
  },
  {
    path: 'lessonBuilder',
    component: LessonBuilder,
    canActivate: [LoginService]
  },
  {
    path: 'lesson-print',
    component: LessonPrintComponent,
    canActivate: [LoginService]
  },
  {
    path: '3part_drill-custom',
    component: ThreePartDrillComponent,
    canActivate: [LoginService]
  },
  {
    path: 'dictation-custom',
    component: DictationWordsSentencesComponent,
    canActivate: [LoginService]
  },
  {
    path: 'new-concept',
    component: NewConceptComponent,
    canActivate: [LoginService]
  }, {
    path: 'fluency-custom',
    component: FluencyCustomComponent,
    canActivate: [LoginService]
  }, {
    path: 'red-words-custom',
    component: RedWordsCustomComponent,
    canActivate: [LoginService]
  },
  {
    path: 'letter-formation-custom',
    component: LetterFormationComponent,
    canActivate: [LoginService]
  },
  {
    path: 'syllable-open-close',
    component: SyllableOpenCloseComponent,
    canActivate: [LoginService]
  },
  {
    path: 'syllable-decoding-words',
    component: SyllableDecodingWordsComponent,
    canActivate: [LoginService]
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin-module/admin.module').then(adm => adm.AdminModule),
    canActivate: [LoginService]
  },
  {
    path: 'migrate',
    component: MigrateToDaComponent,
    canActivate: [LoginService]
  },
  {
    path: '**',
    redirectTo: '/login'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
