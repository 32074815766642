import { Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { UntypedFormControl, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { ClassData } from "src/app/classes/classData";
import { NavbarService } from "src/app/layout/app-header/navbar.service";
import { CoreService } from "../../core.service";

import { ConceptMaterial } from 'src/app/classes/concept';
import { LessonData } from "src/app/classes/lessonData";
import { literacyComponentList, ComponentCardData, componentCards } from "./app-lesson-builder.constant";
import { formatedPreMade } from "src/app/classes/Concepts/premade";
import * as Driver from 'driver.js';
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmConceptDeleteDialogBox } from "../dialog-box/confirm-concept-delete/confirm-dialog.component";
@Component({
    selector: 'app-lesson-builder',
    templateUrl: 'app-lesson-builder.component.html',
    styleUrls: ['app-lesson-builder.component.scss']
})
export class LessonBuilder implements OnDestroy {
    selectedClass!: ClassData;
    selectedConcept!: ConceptMaterial;
    savedLessonData!: LessonData;
    descriptionControl: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.maxLength(40)]);
    orginalDescValue = '';
    showUpdateLessonBtn = false;
    isGetSavedApiInProgress = false;
    isGotoConceptInProgress = false;
    isReOrdered = false;
    isReOrderingInProgress = false;
    preMadeData: formatedPreMade = {};
    preMadeData2: formatedPreMade = {};
    private _subscription: Subscription[] = [];
    driver!: Driver;
    isTourOn = false;

    literacyComponentList!: { label: string, isAdded: boolean, materialType: string, isAdding: boolean }[];
    addedComponentList: { [key: string]: componentCards[] } = {
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: []
    };
    addedComponentDisplayList!: componentCards[];

    lessonDays: { day: string, name: string, number: number, isActive: boolean }[] = [
        {
            day: 'day1',
            name: 'Monday',
            number: 1,
            isActive: true
        },
        {
            day: 'day2',
            name: 'Tuesday',
            number: 2,
            isActive: false
        },
        {
            day: 'day3',
            name: 'Wednesday',
            number: 3,
            isActive: false
        },
        {
            day: 'day4',
            name: 'Thursday',
            number: 4,
            isActive: false
        },
        {
            day: 'day5',
            name: 'Friday',
            number: 5,
            isActive: false
        },
    ];
    selectedLessonDay = '';
    conceptUrlName: { [key: string]: string } = {
        THREE_PART_DRILL: 'lesson3partdrill',
        PHONOLOGICAL_AWARENESS: 'lessonphonological',
        MULTI_SENSORY: 'lessonmse',
        DICTATION: 'lessondictation',
        RED_WORDS: 'lessonredwords',
        DECODABLE_READER: 'lessondecodablereader',
        FLUENCY: 'lessonfluency',
        COMPREHENSION: 'lessonlanguagecomprehension',
        WRITTEN_EXPRESSION: 'lessonwrittenexpression',
        LETTER_FORMATION: 'lessonletterformation',
        PRACTICE_READING: 'lessonpracticereading',
        LITERACY_CONNECTIONS: 'lessonliteracyconnection',
        EXTENSION_ACTIVITIES: 'lessonextensionactivities',
        WORD_CARD: 'lessonwordcard',
        OPEN_CLOSE_DOOR: 'lessonsyllableopenclose',
        DECODING_WORDS: 'lessonsyllabledecodewords'
    };

    leftHeaderButtons: { title: string, event: string }[] = [{
        title: 'Back to Home',
        event: 'homeButton'
    }];

    // rightHeaderButtons: { title: string, event: string }[] = [{
    //     title: 'Saved Materials',
    //     event: 'savedLessonConcept'
    // }];

    rightHeaderButtons: { title: string, event: string }[] = [];

    functonList: { [name: string]: Function } = {
        homeButton: () => this.gotoToHome(),
        openClassSelection: () => this.goToClassManagement()
    }


    dropLessonConceptList(event: CdkDragDrop<componentCards[]>) {
        moveItemInArray(this.addedComponentDisplayList, event.previousIndex, event.currentIndex);
        this.isReOrdered = true;
    }

    constructor(
        private coreService: CoreService,
        private router: Router,
        private navbarService: NavbarService,
        public dialog: MatDialog
    ) {
        if (!this.coreService.selectedClass) {
            this.router.navigate(['dashboard']);
            return;
        }
        this.selectedClass = this.coreService.selectedClass;
        if (this.selectedClass) {
            this.leftHeaderButtons.push({
                title: this.selectedClass.name,
                event: 'openClassSelection'
            });
        }
        let state = this.router.getCurrentNavigation()?.extras.state as {
            lessonData: LessonData,
        };
        this.savedLessonData = state.lessonData;
        this.selectedConcept = this.savedLessonData.concept;
        this.literacyComponentList = literacyComponentList
            .filter(conc => !(this.selectedConcept.sort < 350 && conc.materialType === 'DECODING_WORDS'))
            .filter(conc => !(this.selectedConcept.sort < 40 && conc.materialType === 'DICTATION'))
            .filter(conc => !(this.selectedConcept.sort < 40 && conc.materialType === 'PRACTICE_READING'))
            .map(d => {
                return {
                    label: d.label,
                    isAdded: false,
                    materialType: d.materialType,
                    isAdding: false
                }
            });
        this.navbarService.updateLeftHeaderBtn(this.leftHeaderButtons);
        this.navbarService.updateRightHeaderBtn(this.rightHeaderButtons);
        const navSub = this.navbarService.headerBtnClicked$.subscribe({
            next: action => this.onNavigationServiceActionCalled(action)
        });

        this._subscription.push(navSub);

        this.descriptionControl.setValue(this.savedLessonData.title);
        this.orginalDescValue = this.savedLessonData.title;
        let descChangeSub = this.descriptionControl.valueChanges.subscribe({
            next: (value: string) => {
                this.showUpdateLessonBtn = this.orginalDescValue === value ? false : true;
                if (this.descriptionControl.errors?.['maxlength']) {
                    this.descriptionControl.setValue(value.substring(0, 40));
                }
            }
        });
        this._subscription.push(descChangeSub);
        this.isGetSavedApiInProgress = true;
        let premadeSub = this.coreService.getConceptPremadeMaterial(this.selectedConcept.guid).subscribe({
            next: resp => {
                resp.forEach(data => {
                    if (data.url?.trim()) {
                        this.preMadeData[data.materialType] = data.url;
                    }
                    if (data.url2?.trim()) {
                        this.preMadeData2[data.materialType] = data.url2;
                    }
                });
                this.getSavedLessonData();
            },
            error: err => {
                console.log("Error while fetching PreMade lesson data", err);
                this.showSnackBar('Some error occured, while fetching PreMade Data', 'warn');
                this.getSavedLessonData();
            }
        });
        this._subscription.push(premadeSub);
    }

    getSavedLessonData() {
        if (this.isTourOn) {
            return;
        }
        this.isGetSavedApiInProgress = true;
        let lessonSub = this.coreService.getSavedLessonMaterials(this.savedLessonData.id).subscribe({
            next: resp => {
                try {
                    resp.forEach(savedLessonMat => {
                        let lessonDay = savedLessonMat.lessonDay.slice(0, 1) + savedLessonMat.lessonDay.slice(1).toLowerCase();
                        let componentData = ComponentCardData[lessonDay][savedLessonMat.materialType];
                        componentData.materialId = savedLessonMat.materialId;
                        componentData.id = savedLessonMat.id;
                        componentData.preMadeUrl = this.preMadeData[savedLessonMat.materialType];
                        componentData.isEmpty = savedLessonMat.customEmpty;
                        this.addedComponentList[lessonDay].push(componentData);
                        componentData.preMadeUrl2 = this.preMadeData2[savedLessonMat.materialType];
                    });
                    this.onDayChange("Monday");
                } catch (err) {
                    console.error(err);
                    this.showSnackBar('Error occured, in reading saved data', 'warn');
                } finally {
                    this.isGetSavedApiInProgress = false;
                }

            }, error: err => {
                console.log("Error while fetching Saved lesson data", err);
                this.showSnackBar('Some error occured, while fetching Saved Data', 'warn');
                this.isGetSavedApiInProgress = false;
                this.onDayChange("Monday");
            }
        });
        this._subscription.push(lessonSub);
    }

    ngOnDestroy(): void {
        this._subscription.forEach(sub => sub?.unsubscribe());
    }

    onNavigationServiceActionCalled(action: string) {
        if (this.isTourOn) {
            return;
        }
        if (this.functonList[action]) {
            this.functonList[action]();
        }
    }

    gotoToHome() {
        if (this.isTourOn) {
            return;
        }
        this.router.navigate(['/dashboard']);
    }

    goToClassManagement() {
        if (this.isTourOn) {
            return;
        }
        this.router.navigate(['class']);
    }

    goToConcept() {
        if (this.isTourOn) {
            return;
        }
        this.router.navigateByUrl('concept', {
            state: {
                selectedConcept: this.selectedConcept
            }
        });
    }

    updateLessonName() {
        if (this.isTourOn) {
            return;
        }
        if (!this.descriptionControl.value.trim()) {
            this.showSnackBar("Please prvoide a Lesson Name", "warn")
            this.descriptionControl.setValue('');
            return;
        }
        let data = {
            classId: this.coreService.selectedClass?.guid,
            conceptId: this.selectedConcept.guid,
            title: this.descriptionControl.value,
            userId: this.coreService.userData?.id,
            id: this.savedLessonData.id
        }

        let apiSub = this.coreService.updateLessonName(this.savedLessonData.id, data).subscribe({
            next: resp => {
                this.showSnackBar("Lesson Name Updated", "primary");
                this.orginalDescValue = this.descriptionControl.value;
                this.showUpdateLessonBtn = false;
            },
            error: err => {
                console.log(err);
                this.showSnackBar("Error Occured while Updating Lesson Name", "warn");
            }
        });
        this._subscription.push(apiSub);
    }

    onDayChange(dayName: string) {
        if (this.isTourOn) {
            return;
        }
        let isAddingNewConcenpt = this.literacyComponentList.some(com => com.isAdding);
        if (isAddingNewConcenpt) {
            this.showSnackBar('Creating new Component is In-Progress', 'warn');
            return;
        }
        this.selectedLessonDay = dayName;
        this.lessonDays.forEach(days => days.isActive = (days.name === dayName ? true : false));
        //update
        this.addedComponentDisplayList = this.addedComponentList[dayName];
        this.literacyComponentList.forEach(comp => {
            comp.isAdded = this.addedComponentDisplayList.findIndex(existingComp => existingComp.materialType === comp.materialType) != -1 ? true : false;
        });
    }

    onAddComponent(index: number) {
        if (this.isTourOn) {
            return;
        }
        let newComponentData = ComponentCardData[this.selectedLessonDay][this.literacyComponentList[index].materialType];
        if (!newComponentData) {
            this.showSnackBar('Component data not found', 'warn');
            return;
        }
        // window.scrollTo(0, document.body.scrollHeight);
        // this.literacyComponentList[index].isAdded = true;
        this.literacyComponentList[index].isAdding = true;
        let materialType = this.literacyComponentList[index].materialType;
        let lessonDay = this.selectedLessonDay.toUpperCase();
        let conceptUrlName = this.conceptUrlName[materialType];
        let conceptAPiSub = this.coreService.createNewConceptForLesson(conceptUrlName, this.savedLessonData.id, lessonDay).subscribe({
            next: resp => {
                this.literacyComponentList[index].isAdding = false;
                this.literacyComponentList[index].isAdded = true;
                newComponentData.materialId = resp.id;
                newComponentData.preMadeUrl = this.preMadeData[materialType];
                newComponentData.preMadeUrl2 = this.preMadeData2[materialType];
                newComponentData.id = resp.lessonMaterial?.id;
                newComponentData.isEmpty = true;
                this.addedComponentDisplayList.push(newComponentData);
                this.showSnackBar(`${newComponentData.title} added`, 'primary');
                this.saveReOrderedConcepts(false);
            },
            error: err => {
                console.log(err);
                this.literacyComponentList[index].isAdding = false;
                this.showSnackBar('Error while saving', 'warn');
            }
        });
        this._subscription.push(conceptAPiSub);
    }

    removeComponent(selectedConcept: componentCards) {
        if (this.isTourOn) {
            return;
        }
        let conceptUrlName = this.conceptUrlName[selectedConcept.materialType];
        if (selectedConcept.materialId) {
            let confirmDialogBox = this.dialog.open(ConfirmConceptDeleteDialogBox, {
                data: {
                    message: `Are you sure you want to delete <b> ${selectedConcept.title} </b>?`,
                    confirmBtnClass: 'lightRed',
                    confirmBtnLabel: 'Delete',
                    conceptUrlName: conceptUrlName,
                    materialId: selectedConcept.materialId,
                },
                disableClose: true,
            });

            let deleteApiSub = confirmDialogBox.beforeClosed().subscribe({
                next: data => {
                    if (data) {
                        if (data === 'deleted') {
                            let index = this.addedComponentList[this.selectedLessonDay].findIndex(comp => comp.materialId === selectedConcept.materialId);
                            if (index > -1) {
                                this.addedComponentList[this.selectedLessonDay].splice(index, 1);
                            }
                            let removedConcept = this.literacyComponentList.filter(com => com.materialType === selectedConcept.materialType);
                            if(removedConcept && removedConcept.length) {
                                removedConcept[0].isAdded = false;
                            }
                            this.showSnackBar(selectedConcept.title + ' Removed', 'warn');
                            this.saveReOrderedConcepts(false);
                        } else {
                            console.log(data);
                            this.showSnackBar('Error while removing', 'warn');
                        }
                    }
                }
            });
            this._subscription.push(deleteApiSub);
        }
    }

    saveReOrderedConcepts(showMessage: boolean) {
        let sortOrderData: { [key: string]: number } = {};
        Object.values(this.addedComponentList).forEach((value) => {
            value.forEach((comp, index) => sortOrderData[comp.id || ''] = index)
        });
        this.isReOrderingInProgress = true;
        this.coreService.reorderLessonConcept(this.savedLessonData.id, sortOrderData).subscribe({
            next: resp => {
                showMessage && this.showSnackBar("Display Order Updated", "primary");
                this.isReOrdered = false;
                this.isReOrderingInProgress = false;
            },
            error: err => {
                console.log(err);
                this.showSnackBar("Error Occured while saving", "warn");
                this.isReOrdered = false;
                this.isReOrderingInProgress = false;
            }
        });
    }

    showSnackBar(message: string, colorTheme: 'warn' | 'primary') {
        this.coreService.openSnackBar(message, 3000, colorTheme, "end", "bottom");
    }

    goToCustomConcept(selectedConcept: componentCards) {
        if (this.isTourOn) {
            return;
        }
        let apiSub;
        if (!selectedConcept.materialId) {
            return;
        }
        if (selectedConcept.materialType === 'FLUENCY') {
            this.isGotoConceptInProgress = true;
            apiSub = this.coreService.getSavedFluency(selectedConcept.materialId).subscribe({
                next: resp => {
                    this.isGotoConceptInProgress = false;
                    this.router.navigateByUrl('fluency-custom', {
                        state: {
                            savedConceptMaterial: resp,
                            selectedConcept: resp.lessonMaterial?.lesson.concept
                        }
                    });
                },
                error: err => {
                    console.log(err);
                    this.isGotoConceptInProgress = false;
                    this.showSnackBar('Error while fetching Concept Data', 'warn');
                }
            });
        } else if (selectedConcept.materialType === 'THREE_PART_DRILL') {
            this.isGotoConceptInProgress = true;
            apiSub = this.coreService.getSaved3partdrillById(selectedConcept.materialId).subscribe({
                next: resp => {
                    this.isGotoConceptInProgress = false;
                    this.router.navigateByUrl('3part_drill-custom', {
                        state: {
                            savedConceptMaterial: resp
                        }
                    });
                },
                error: err => {
                    console.log(err);
                    this.isGotoConceptInProgress = false;
                    this.showSnackBar('Error while fetching Concept Data', 'warn');
                }
            });
        } else if (selectedConcept.materialType === 'DICTATION') {
            this.isGotoConceptInProgress = true;
            apiSub = this.coreService.getSavedDictation(selectedConcept.materialId).subscribe({
                next: resp => {
                    this.isGotoConceptInProgress = false;
                    this.router.navigateByUrl('dictation-custom', {
                        state: {
                            savedConceptMaterial: resp,
                            selectedConcept: resp.lessonMaterial?.lesson.concept
                        }
                    });
                },
                error: err => {
                    console.log(err);
                    this.isGotoConceptInProgress = false;
                    this.showSnackBar('Error while fetching Concept Data', 'warn');
                }
            });
        } else if (selectedConcept.materialType === 'RED_WORDS') {
            this.isGotoConceptInProgress = true;
            apiSub = this.coreService.getSavedRedWords(selectedConcept.materialId).subscribe({
                next: resp => {
                    this.isGotoConceptInProgress = false;
                    this.router.navigateByUrl('red-words-custom', {
                        state: {
                            savedConceptMaterial: resp,
                            selectedConcept: resp.lessonMaterial?.lesson.concept
                        }
                    });
                },
                error: err => {
                    console.log(err);
                    this.isGotoConceptInProgress = false;
                    this.showSnackBar('Error while fetching Concept Data', 'warn');
                }
            });
        } else if (selectedConcept.materialType === 'MULTI_SENSORY') {
            this.isGotoConceptInProgress = true;
            apiSub = this.coreService.getSavedNewConcept(selectedConcept.materialId).subscribe({
                next: resp => {
                    this.isGotoConceptInProgress = true;
                    this.router.navigateByUrl('new-concept', {
                        state: {
                            savedConceptMaterial: resp,
                            selectedConcept: resp.lessonMaterial?.lesson.concept
                        }
                    });
                },
                error: err => {
                    console.log(err);
                    this.isGotoConceptInProgress = true;
                    this.showSnackBar('Error while fetching Concept Data', 'warn');
                }
            });
        } else if (selectedConcept.materialType === 'LETTER_FORMATION') {
            apiSub = this.coreService.getLetterFormationConcept(selectedConcept.materialId).subscribe({
                next: resp => {
                    this.isGotoConceptInProgress = true;
                    this.router.navigateByUrl('letter-formation-custom', {
                        state: {
                            savedConceptMaterial: resp,
                            selectedConcept: resp.lessonMaterial?.lesson.concept
                        }
                    });
                },
                error: err => {
                    console.log(err);
                    this.isGotoConceptInProgress = false;
                    this.showSnackBar('Error while fetching Concept Data', 'warn');
                }
            });
        } else if (selectedConcept.materialType === 'OPEN_CLOSE_DOOR') {
            apiSub = this.coreService.getSavedSyllableOpenClose(selectedConcept.materialId).subscribe({
                next: resp => {
                    this.isGotoConceptInProgress = true;
                    this.router.navigateByUrl('syllable-open-close', {
                        state: {
                            savedConceptMaterial: resp,
                            selectedConcept: resp.lessonMaterial?.lesson.concept
                        }
                    });
                },
                error: err => {
                    console.log(err);
                    this.isGotoConceptInProgress = false;
                    this.showSnackBar('Error while fetching Concept Data', 'warn');
                }
            });
        } else if (selectedConcept.materialType === 'DECODING_WORDS') {
            apiSub = this.coreService.getSavedSyllableDecodeWord(selectedConcept.materialId, 'lesson').subscribe({
                next: resp => {
                    this.isGotoConceptInProgress = true;
                    this.router.navigateByUrl('syllable-decoding-words', {
                        state: {
                            savedConceptMaterial: resp,
                            selectedConcept: this.selectedConcept,
                            savedLessonData: this.savedLessonData,
                            updateById: selectedConcept.materialId
                        }
                    });
                },
                error: err => {
                    console.log(err);
                    this.isGotoConceptInProgress = false;
                    this.showSnackBar('Error while fetching Concept Data', 'warn');
                }
            });
        }
        apiSub && this._subscription.push(apiSub);
    }

    goToPreMadeUrl(selectedConcept: componentCards) {
        if (this.isTourOn) {
            return;
        }
        if (selectedConcept.preMadeUrl) {
            window.open(selectedConcept.preMadeUrl, '_blank', 'noopener,resizable,scrollbars,popup=false');
        }
    }

    goToPreMadeUrl2(selectedConcept: componentCards) {
        if (this.isTourOn) {
            return;
        }
        if (selectedConcept.preMadeUrl2) {
            window.open(selectedConcept.preMadeUrl2, '_blank', 'noopener,resizable,scrollbars,popup=false');
        }
    }

    openTourDialog(event: any) {
        event.stopPropagation();
        this.driver = new Driver({
            allowClose: false,
            onReset: (Element) => {
                this.isTourOn = false;
            }
        });
        let componentQuery = document.querySelector('.card.ng-star-inserted') ? '.card.ng-star-inserted' : '.conceptList';
        this.driver.defineSteps([
            {
                element: '#Monday',
                popover: {
                    className: 'popover-class',
                    title: 'Start here on Monday',
                    description: 'Start with lessons on Monday. Scroll down the list to see the literacy components scheduled for Monday.<br/><br/>The recommended literacy components will be automatically scheduled for that day, but you can add, remove, and rearrange them in any way you need.',
                    showButtons: true,
                    closeBtnText: 'Close',
                    nextBtnText: 'Next',
                    prevBtnText: 'Previous',
                }
            },
            {
                element: '#LiteracyComponentTitle',
                popover: {
                    className: 'popover-class',
                    title: 'Editing Literacy Components',
                    description: 'This is the literacy component window. You can add more literacy components to the day by clicking the corresponding buttons. They will be added to the bottom of the list for the day.',
                    showButtons: true,
                    closeBtnText: 'Close',
                    nextBtnText: 'Next',
                    prevBtnText: 'Previous',
                }
            },
            {
                element: componentQuery,
                popover: {
                    className: 'popover-class',
                    title: 'View and Customize',
                    description: 'Each literacy component has a few buttons to interact with.<br/>Click the Digital Slides button, if present, to open premade materials and print them.<div style="text-align:center"><img src="./assets/images/icons/digital_slide_btn_img.png" style="box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.35);height: 35px;border-radius: 3px;" alt="icon" /></div>Click the Custom Material button, if present, to view and customize the lesson materials. When finished, you can save and print them.<div style="text-align:center"><img src="./assets/images/icons/custom_mat_btn_img.png" style="box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.35);height: 35px;border-radius: 3px;" alt="icon" /></div>Click the remove button <img src="./assets/images/icons/delete_forever_icon.svg" style="vertical-align: bottom;height: 24px;" alt="icon" /> to remove the literacy component from that day’s lesson.<br/><br/>Hold and drag the move button <img src="./assets/images/icons/drag_icon.svg" style="vertical-align: sub;height: 24px;" alt="icon" /> to rearrange the literacy components for that day’s lesson.',
                    showButtons: true,
                    closeBtnText: 'Close',
                    nextBtnText: 'Next',
                    prevBtnText: 'Previous',
                    position: 'right',
                }
            },
            {
                element: '#Tuesday',
                popover: {
                    className: 'popover-class',
                    title: 'Move Through the Week',
                    description: 'When you are finished with the lessons for Monday, click on Tuesday to create Tuesday\'s lessons. Move through the week, viewing and customizing literacy components to suit your class\'s needs for each day.',
                    showButtons: true,
                    closeBtnText: 'Close',
                    nextBtnText: 'Next',
                    prevBtnText: 'Previous',
                }
            },
            {
                element: '#headerLeftopenClassSelection',
                popover: {
                    className: 'popover-class',
                    title: 'Lessons Saved to Class',
                    description: 'Your saved lessons can be found on the Class Management page whenever you need to access them.',
                    showButtons: true,
                    closeBtnText: 'Close',
                    nextBtnText: 'Next',
                    prevBtnText: 'Previous',
                }
            },
        ]);
        this.driver.start();
        this.isTourOn = true;
    }


    printLesson() {
        this.router.navigateByUrl('lesson-print', {
            state: {
                savedLessonData: this.savedLessonData,
                addedComponentList: this.addedComponentList
            }
        });
    }

}