import { Component, Inject, OnDestroy } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { ClassData } from "src/app/classes/classData";
import { User } from "src/app/classes/user";
import { CoreService } from "src/app/core/core.service";
import { Subscription } from "rxjs";


import { environment } from 'src/environments/environment';
import { Student } from "src/app/classes/student";

@Component({
    selector: 'report-selection',
    templateUrl: 'report-selection.component.html',
    styleUrls: ['report-selection.component.scss'],
})
export class ReportSelectionDialog implements OnDestroy {

    private _subscription: Subscription[] = [];
    selectedTabIndex: number = 0;
    isApiRunning = true;
    L1ReportOfControl: UntypedFormControl;
    L1ReportTypeControl: UntypedFormControl;
    L1ReportTermControl: UntypedFormControl;
    L2ReportOfControl: UntypedFormControl;
    L2ReportTypeControl: UntypedFormControl;
    L2ReportTermControl: UntypedFormControl;
    L3ReportOfControl: UntypedFormControl;
    L3ReportTypeControl: UntypedFormControl;
    L3ReportTermControl: UntypedFormControl;
    blendReportTypeControl: UntypedFormControl;

    userData!: User | null;
    selectedClass!: ClassData;
    disableReport = false;
    studentList: Student[] = [];

    // report enable
    reportEnabler = {
        BeginningSkills: false,
        Blends: false,
        Level3: false,
        Level1: {
            Initial: false,
            Midterm: false,
            Final: false,
            overall: false
        },
        Level2: {
            Initial: false,
            Midterm: false,
            Final: false,
            overall: false
        }
    };
    constructor(
        public dialogRef: MatDialogRef<ReportSelectionDialog>,
        private coreService: CoreService,
    ) {
        this.L1ReportOfControl = new UntypedFormControl('analysis');
        this.L1ReportTypeControl = new UntypedFormControl('sounds');
        this.L1ReportTermControl = new UntypedFormControl('0');
        this.L2ReportOfControl = new UntypedFormControl('analysis');
        this.L2ReportTypeControl = new UntypedFormControl('sounds');
        this.L2ReportTermControl = new UntypedFormControl('0');
        this.L3ReportOfControl = new UntypedFormControl('analysis');
        this.L3ReportTypeControl = new UntypedFormControl('spelling');
        this.L3ReportTermControl = new UntypedFormControl('3');
        this.blendReportTypeControl = new UntypedFormControl('spelling');
        this.userData = this.coreService.userData;

        if (this.coreService.selectedClass) {
            this.selectedClass = this.coreService.selectedClass;
        }
        this.getStudents();
    }


    ngOnDestroy(): void {
        this._subscription.forEach(sub => sub?.unsubscribe());
    }
    getStudents() {
        let studentSub = this.coreService.getStudents(this.userData?.ssoReadOnly ? this.userData?.id || '' : '').subscribe({
            next: resp => {
                this.studentList = resp;
                this.isApiRunning = false;
                this.reportEnable();
            },
            error: err => {
                this.coreService.openSnackBar("Error Occured while getting student data, please try later", 3000, "warn", "end", "bottom");
                this.dialogRef.close();
            }
        });
        this._subscription.push(studentSub);
    }

    reportEnable() {
        let defaultTabValue = [false,false,false,false,false,false,false,false,false,false];
        this.studentList.forEach(stu => {
            let tabEnable = stu.tabEnable?.split('^');
            //index positions BR~L1I~L1M~L1F~BL~oldDone~L2I~L2M~L2F~L3^L1Aced
            if (!(stu.assessmentLevel && tabEnable?.length)) {
                return;
            }
            let tabLevelWise = tabEnable[0].split('~').map(value => +value);
            tabLevelWise.forEach((value, index) => {
                defaultTabValue[index] = (!!value) || defaultTabValue[index];
            });
        });
        this.reportEnabler.BeginningSkills = defaultTabValue[0];
        this.reportEnabler.Level1.Initial = defaultTabValue[1];
        this.reportEnabler.Level1.Midterm = defaultTabValue[2];
        this.reportEnabler.Level1.Final = defaultTabValue[3];
        this.reportEnabler.Level1.overall = defaultTabValue[1] || defaultTabValue[2] || defaultTabValue[3];
        this.reportEnabler.Blends = defaultTabValue[4];
        this.reportEnabler.Level2.Initial = defaultTabValue[6];
        this.reportEnabler.Level2.Midterm = defaultTabValue[7];
        this.reportEnabler.Level2.Final = defaultTabValue[8];
        this.reportEnabler.Level2.overall = defaultTabValue[6] || defaultTabValue[7] || defaultTabValue[8];
        this.reportEnabler.Level3 = defaultTabValue[9];
        this.disableReport = !this.reportEnabler.BeginningSkills;
    }

    onCancel() {
        this.dialogRef.close();
    }

    onChangeTab(data: number) {
        this.selectedTabIndex = data;
        this.disableReport = !this.getReportEnableStatus(data);
    }

    getReportEnableStatus(tabIndex: number) {
        switch (this.selectedTabIndex) {
            case 0: return this.reportEnabler.BeginningSkills;
            case 1: return this.reportEnabler.Level1.overall;
            case 2: return this.reportEnabler.Level2.overall;
            case 3: return this.reportEnabler.Level3;
            case 4: return this.reportEnabler.Blends;
            default: return true;
        }
    }

    openReport() {
        let data;
        switch (this.selectedTabIndex) {
            case 0:
                data = {
                    type: 'br'
                }
                break;
            case 1:
                data = {
                    type: 'l1',
                    options: {
                        report: this.L1ReportOfControl.value,
                        type: this.L1ReportTypeControl.value,
                        term: this.L1ReportTermControl.value,
                    }
                };
                break;
            case 2:
                data = {
                    type: 'l2',
                    options: {
                        report: this.L2ReportOfControl.value,
                        type: this.L2ReportTypeControl.value,
                        term: this.L2ReportTermControl.value,
                    }
                };
                break;
            case 3:
                data = {
                    type: 'l3',
                    options: {
                        report: this.L3ReportOfControl.value,
                        type: this.L3ReportTypeControl.value,
                        term: this.L3ReportTermControl.value,
                    }
                };
                break;
            case 4: {
                data = {
                    type: 'blends',
                    options: {
                        type: this.blendReportTypeControl.value,
                    }
                }
            };
        }
        this.openAssessmentApp(data);
        this.dialogRef.close();
    }

    openAssessmentApp(data: any) {
        let assessmentBaseUrl = 'reports-class/';

        let accessmentLookup: { [key: string]: string } = {
            'analysis-sounds': 'level1-analysis/200',
            'analysis-words': 'level1-analysis/210',
            'results-sounds': 'level1-results-200-v2',
            'results-words': 'level1-results-210-v2',
            'l2-analysis-sounds': 'level2-analysis/290',
            'l2-analysis-words': 'level2-analysis/300',
            'l2-results-sounds': 'level2-results-290-v2',
            'l2-results-words': 'level2-results-300-v2',
            'l3-analysis-spelling': 'level3-analysis/330',
            'l3-results-spelling': 'level3-results-330-v2',
            'br': 'br-class-v2'
        }
        if (data) {
            let url = environment.assessmentAppUrl + '/?sessionId=' + this.userData?.ssoToken +
                '&classId=' + this.selectedClass.guid;
            let reportEncodedData;
            switch (data['type']) {
                case 'l1': {
                    let reportData = {
                        type: 'level1',
                        url: assessmentBaseUrl + accessmentLookup[data['options']['report'] + '-' + data['options']['type']],
                        term: data['options']['term']
                    };
                    reportEncodedData = btoa(JSON.stringify(reportData));
                    break;
                }
                case 'l2': {
                    let reportData = {
                        type: 'level1',
                        url: assessmentBaseUrl + accessmentLookup['l2-' + data['options']['report'] + '-' + data['options']['type']],
                        term: data['options']['term']
                    };
                    reportEncodedData = btoa(JSON.stringify(reportData));
                    break;
                }
                case 'l3': {
                    let reportData = {
                        type: 'level1',
                        url: assessmentBaseUrl + accessmentLookup['l3-' + data['options']['report'] + '-' + data['options']['type']],
                        term: data['options']['term']
                    };
                    reportEncodedData = btoa(JSON.stringify(reportData));
                    break;
                }
                case 'br': {
                    let reportData = {
                        type: 'beginReader',
                        url: assessmentBaseUrl + accessmentLookup[data['type']],
                        term: '0'
                    };
                    reportEncodedData = btoa(JSON.stringify(reportData));
                    break;
                }
                case 'blends': {
                    let reportData = {
                        type: 'blends',
                        url: assessmentBaseUrl + 'blends-' + data['options']['type']+'-v2',
                        term: '0'
                    };
                    reportEncodedData = btoa(JSON.stringify(reportData));
                    break;
                }
            };

            if (reportEncodedData) {
                url += '&reportData=' + reportEncodedData;
            }
            if (this.userData?.ssoReadOnly) {
                // by default all DA login to Assessment are Readonly
                url += "&teacherId=" + this.userData.id + "&teacherName=" + this.userData.teacherName;
            } else {
                url += this.selectedClass.readOnly ? '&readOnly=true' : '';
            }
            this.coreService.assessmentApp?.close();
            this.coreService.assessmentApp = window.open(url, "assessmentAppIog1");
        }
    }
}