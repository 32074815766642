<div id="page-resizable-container" class="login-background">
    <div id="loginContainer" class="login-content-container login" *ngIf="displayLogin">
        <app-logo></app-logo>
        <div>
            <label for="userName">
              <span>Email address</span>
              <input type="email" (focus)="clearLoginErrorMessage()" [(ngModel)]="userName" name="userName" id="userName">
            </label>
            <label for="userPass">
              <span>Password</span>
              <input type="password" (focus)="clearLoginErrorMessage()" [(ngModel)]="userPass" name="userPass" id="userPass">
            </label>
            <!-- <label class="forgot-password" (click)="forgotPasswordClicked()">
              Forgot Password
            </label> -->
        </div>

        <div class="login-error" *ngIf="loginErrorMessage && loginErrorMessage === 'InvalidCredentials'">
            Incorrect user name or password
        </div>
        <div class="login-error" *ngIf="loginErrorMessage && loginErrorMessage == 'InvalidSubscription'">
            Some error occured, Please check your Subscription here <a href="https://imse.com/account/info/" target="ImseAccount"> IMSE </a>
        </div>
        <div class="login-error" *ngIf="loginErrorMessage && loginErrorMessage !== 'InvalidCredentials' && loginErrorMessage !== 'InvalidSubscription'">
            {{loginErrorMessage}}
        </div>
        <div class="login-error" *ngIf="ssoErrorMessage">
            Look's like your IOG2 Subscription is expired or not available. <br> Please find more information about <a href="https://imse.com/account/info/" target="ImseAccount">IOG2 Subscriptions here</a>
        </div>

        <!-- <div class="input-row">
            <label class="input-row-item flex1" for="remember-me">
              <input class="input-row-item" [(ngModel)]="rememberMeSet" (click)="rememberMeChecked()" id="remember-me" type="checkbox">Remember Me
          </label>
        </div> -->

        <!-- <div class="terms-of-privacy" (click)="termsOfServiceAndPrivacy()">Terms of Service &amp; Privacy Policy</div> -->

        <app-button id="enter-app-button" (click)="handleLogin()" content="Login" btnClasses="blue" [isDisabled]="apiLoading" [isLoading]="apiLoading"></app-button>

    </div>
    <div *ngIf="!displayLogin" class="loader">
        <div class="spinner">
            <div class="cube1"></div>
            <div class="cube2"></div>
        </div>
    </div>
</div>