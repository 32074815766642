import { Component, OnDestroy } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { ClassData } from "src/app/classes/classData";
import { LessonData } from "src/app/classes/lessonData";
import { CoreService } from "src/app/core/core.service";

@Component({
    selector: 'lesson-list',
    templateUrl: './lesson-list.component.html',
    styleUrls: ['./lesson-list.component.scss']
})
export class LessonListDialog implements OnDestroy {
    private _subscription: Subscription[] = [];
    savedLessonList!: LessonData[];
    apiLoading = false;
    selectedClass!: ClassData | null;
    constructor(
        public dialogRef: MatDialogRef<LessonListDialog>,
        private coreService: CoreService,
    ) {
        this.getSavedLesson();
    }
    ngOnDestroy(): void {
        this._subscription.forEach(sub => sub?.unsubscribe());
    }

    getSavedLesson() {
        this.apiLoading = true;
        this.selectedClass = this.coreService.selectedClass;
        let getLessonSub = this.coreService.getSavedLessonByClass().subscribe({
            next: resp => {
                this.savedLessonList = resp;
                this.apiLoading = false;
            },
            error: err => console.log(err)
        });
        this._subscription.push(getLessonSub);
    }

    onSelectedClass(selectedLesson: LessonData) {
        this.dialogRef.close(selectedLesson);
    }

    onCreateNewLesson() {
        this.dialogRef.close("NewLesson");
    }

    onCancel() {
        this.dialogRef.close();
    }
}