export class User {
    id!: string;
    firstName!: string;
    lastName!: string;
    fullName!: string;
    token!: string;
    email!: string;
    username!: string;
    districtAdmin!: boolean;
    districtId!: number;
    districtName!: string;
    ssoToken!: string;
    warnDistrict!: boolean;
    termsAccepted!: boolean;
    imUserId!: number;
    recoveryEmail!: string;
    allowRecoveryUpdate!: boolean;
    verifyNeeded!: boolean;
    admin!: boolean;
    // for DA SSO
    adminId!: string; // will be generally id variable
    teacherId!: string;
    teacherName!: string;
    ssoReadOnly = false;
    districtManaged = false;
    convertDistrict = false;
    properties: Properties[] = [];
    propertiesByRule!: { [key: string]: Properties };
}

export interface Properties {
    id: number;
    createDate: Date;
    description: string;
    disabled: boolean;
    districtId: number;
    rule: string;
    updateDate: Date;
    value: string;
    value_type: string;
}

export interface StudentIdProperties {
    minLength: number;
    // maxLength: number; // use minLength as length, no min or max
    numbers: checkType;
    alphabets: checkType;
    specialChars: checkType;
}

export type checkType = "include" | "not include" |"no check";