<div class="lessonListContainer">
    <app-dialog-title [title]="'Select Saved Lesson'"></app-dialog-title>

    <div *ngIf="apiLoading">
        <mat-progress-spinner mode="indeterminate" diameter="40"></mat-progress-spinner>
    </div>

    <div class="lessonListContainer" *ngIf="!apiLoading">
        <div class="item" *ngFor="let lesson of savedLessonList">
            <div>
                <div class="itemName">
                    {{lesson.title}}
                </div>
                <div class="subItem">
                    {{lesson.concept.name}}
                </div>
            </div>
            <div class="selectBtn">
                <app-button [content]="'View/Edit'" [btnClasses]="'blue conceptBtn'" (click)="onSelectedClass(lesson)"></app-button>
            </div>
        </div>
        <div *ngIf="!savedLessonList.length" class="noData">
            No Saved Lesson found, please create one
        </div>
    </div>

    <mat-dialog-actions class="btnClass">
        <app-button [content]="'Cancel'" (click)="onCancel()"></app-button>
        <app-button [isDisabled]="selectedClass?.readOnly || false" [content]="'New Lesson'" [btnClasses]="'green'" (click)="onCreateNewLesson()"></app-button>
    </mat-dialog-actions>
</div>