import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {
  @Input() logoHeight!: number;
  dataUrl = '/assets/images/IOG2_logo.png';
  logoDataUrl: SafeResourceUrl;
  altText!: string;

  constructor(private sanitizer: DomSanitizer) {
    this.logoDataUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.dataUrl);
  }

  ngOnInit() {
    this.altText = 'Interactive OG';
  }

}
