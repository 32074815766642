<div class="reportSelection">
    <app-dialog-title [title]="'Report Selection'"></app-dialog-title>

    <mat-tab-group mat-align-tabs="center" class="tabGroup" (selectedIndexChange)="onChangeTab($event)" *ngIf="!isApiRunning">
        <mat-tab label="Beginning Reader">
            <div class="brReport" *ngIf="reportEnabler.BeginningSkills;else noData">
                No Sub Filters, Please click on <span>Open Report</span>
            </div>
        </mat-tab>
        <mat-tab label="Level 1">
            <div class="reportFilters"  *ngIf="reportEnabler.Level1.overall;else noData">
                <p>
                    Select which report:
                </p>
                <mat-button-toggle-group class="toggle-blue" name="L1ReportOf" aria-label="L1ReportOf" [formControl]="L1ReportOfControl">
                    <mat-button-toggle value="analysis">Data Analysis</mat-button-toggle>
                    <mat-button-toggle value="results">Class Results</mat-button-toggle>
                </mat-button-toggle-group>
                <p>
                    Select which type:
                </p>
                <mat-button-toggle-group class="toggle-blue" name="L1ReportType" aria-label="L1ReportType" [formControl]="L1ReportTypeControl">
                    <mat-button-toggle value="sounds">Sounds</mat-button-toggle>
                    <mat-button-toggle value="words">Words</mat-button-toggle>
                </mat-button-toggle-group>
                <p>
                    Select which term:
                </p>
                <mat-button-toggle-group class="toggle-blue" name="L1ReportTerm" aria-label="L1ReportTerm" [formControl]="L1ReportTermControl">
                    <mat-button-toggle value="0" [disabled]="!reportEnabler.Level1.Initial">Initial</mat-button-toggle>
                    <mat-button-toggle value="1" [disabled]="!reportEnabler.Level1.Midterm">Mid Term</mat-button-toggle>
                    <mat-button-toggle value="2" [disabled]="!reportEnabler.Level1.Final">Final</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </mat-tab>
        <mat-tab label="Level 2">
            <div class="reportFilters" *ngIf="reportEnabler.Level2.overall;else noData">
                <p>
                    Select which report:
                </p>
                <mat-button-toggle-group class="toggle-blue" name="L2ReportOf" aria-label="L2ReportOf" [formControl]="L2ReportOfControl">
                    <mat-button-toggle value="analysis">Data Analysis</mat-button-toggle>
                    <mat-button-toggle value="results">Class Results</mat-button-toggle>
                </mat-button-toggle-group>
                <p>
                    Select which type:
                </p>
                <mat-button-toggle-group class="toggle-blue" name="L2ReportType" aria-label="L2ReportType" [formControl]="L2ReportTypeControl">
                    <mat-button-toggle value="sounds">Sounds</mat-button-toggle>
                    <mat-button-toggle value="words">Words</mat-button-toggle>
                </mat-button-toggle-group>
                <p>
                    Select which term:
                </p>
                <mat-button-toggle-group class="toggle-blue" name="L2ReportTerm" aria-label="L2ReportTerm" [formControl]="L2ReportTermControl">
                    <mat-button-toggle value="0" [disabled]="!reportEnabler.Level2.Initial">Initial</mat-button-toggle>
                    <mat-button-toggle value="1" [disabled]="!reportEnabler.Level2.Midterm">Mid Term</mat-button-toggle>
                    <mat-button-toggle value="2" [disabled]="!reportEnabler.Level2.Final">Final</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </mat-tab>
        <mat-tab label="Level 3">
            <div class="reportFilters"  *ngIf="reportEnabler.Level3;else noData">
                <p>
                    Select which report:
                </p>
                <mat-button-toggle-group class="toggle-blue" name="L3ReportOf" aria-label="L3ReportOf" [formControl]="L3ReportOfControl">
                    <mat-button-toggle value="analysis">Data Analysis</mat-button-toggle>
                    <mat-button-toggle value="results">Class Results</mat-button-toggle>
                </mat-button-toggle-group>
                <p>
                    Select which type:
                </p>
                <mat-button-toggle-group class="toggle-blue" name="L3ReportType" aria-label="L3ReportType" [formControl]="L3ReportTypeControl">
                    <!-- <mat-button-toggle disabled value="sounds">Sounds</mat-button-toggle> -->
                    <mat-button-toggle value="spelling">Spelling</mat-button-toggle>
                </mat-button-toggle-group>
                <!-- <p>
                    Select which term:
                </p>
                <mat-button-toggle-group name="L3ReportTerm" aria-label="L3ReportTerm" [formControl]="L3ReportTermControl">
                    <mat-button-toggle value="0">Initial</mat-button-toggle>
                    <mat-button-toggle value="1">Mid Term</mat-button-toggle>
                    <mat-button-toggle value="2">Final</mat-button-toggle>
                </mat-button-toggle-group> -->
            </div>
        </mat-tab>
        <mat-tab label="Blends">
            <div class="reportFilters"  *ngIf="reportEnabler.Blends;else noData">
                <p>
                    Select which type:
                </p>
                <mat-button-toggle-group class="toggle-blue" name="blendReportType" aria-label="blendReportType" [formControl]="blendReportTypeControl">
                    <mat-button-toggle value="spelling">Spelling</mat-button-toggle>
                    <mat-button-toggle value="reading">Reading</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </mat-tab>
    </mat-tab-group>

    <mat-dialog-actions class="btnClass" *ngIf="!isApiRunning">
        <app-button [content]="'Cancel'" (click)="onCancel()"></app-button>
        <app-button [content]="'Open Report'" [btnClasses]="'blue'" (click)="openReport()" [isDisabled]="disableReport"></app-button>
    </mat-dialog-actions>

    <div *ngIf="isApiRunning" class="spinner">
        <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
    </div>
</div>

<ng-template #noData>
    <h4 class="no-data">
        No Assessment data Present
    </h4>
</ng-template>