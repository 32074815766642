<div class="classSelector">
    <app-dialog-title [title]="data.title"></app-dialog-title>
    <div mat-dialog-content *ngIf="classDataList" class="classList">
        <app-button *ngFor="let class of classDataList" [content]="class.name" [btnClasses]="'plain w100'" (click)="onSelectCLass(class)" [iconPath]="class.readOnly ? readOnlyIconPath : (class.owner ? '' : sharedIconPath)"></app-button>
    </div>

    <mat-progress-spinner mode="indeterminate" diameter="50" class="blue-spinner" *ngIf="!(classDataList || errorMessage)"></mat-progress-spinner>
    <div class="errorMessage" *ngIf="errorMessage">
        {{ errorMessage }}
    </div>
    <mat-dialog-actions class="btnClass">
        <app-button [content]="'Cancel'" (click)="onCancel()"></app-button>
        <app-button [content]="'Add New Class'" (click)="onAddCLass()" [btnClasses]="'blue'" *ngIf="canAddClass"></app-button>
    </mat-dialog-actions>
</div>