import { Component, OnInit } from '@angular/core';

const { version: appVersion } = require('../../../../package.json');

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class AppFooterComponent implements OnInit {
  appVersion: string;
  currentYear!: number;

  constructor() {
    this.appVersion = appVersion;
  }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
  }

}
