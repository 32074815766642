import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ClassData } from "src/app/classes/classData";
import { ConceptMaterial } from "src/app/classes/concept";
import { formatedRedWords, redWordRespDto, RedWords, RedWordSavedDto, RedWordSaveDto } from "src/app/classes/Concepts/redWords";
import { CoreService } from "src/app/core/core.service";
import { NavbarService } from "src/app/layout/app-header/navbar.service";
import { ConfirmDialogBox } from "../../dialog-box/confirm-dialog/confirm-dialog.component";
import { RedWordsDialogComponent } from "../../dialog-box/red-words-custom/red-word-dialog.component";

@Component({
    selector: 'app-red-words',
    templateUrl: 'app-red-words.component.html',
    styleUrls: ['app-red-words.component.scss']
})
export class RedWordsCustomComponent implements OnInit, OnDestroy {
    selectedConcept!: ConceptMaterial;
    _subscription: Subscription[] = [];
    descriptionControl: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.maxLength(40)]);
    flashCardsPdfList: string[][] = [
        ['Stage K', 'RWStageK', 'stageK'],
        ['Stage 1A', 'RWStage1A', 'stage1A'],
        ['Stage 1B', 'RWStage1B', 'stage1B'],
        ['Stage 2', 'RWStage2', 'stage2'],
        ['Stage 3', 'RWStage3', 'stage3'],
        ['Stage 4', 'RWStage4', 'stage4'],
        ['Stage 5', 'RWStage5', 'stage5'],
        ['Color and Number', 'RWColorNumber', 'colorNumber']
    ]; //[UI DisplayName, PDF name, flashcardKeyName]

    redwordApiLoading = true;
    isSaveInProgress = false;
    isSavePrintInProgress = false;
    redWordsList!: RedWords;
    redNewWordsListFiltered!: RedWords;
    redReviewWordsListFiltered!: RedWords;
    wordSearchNewWordControl: UntypedFormControl = new UntypedFormControl('');
    wordSearchReviewWordControl: UntypedFormControl = new UntypedFormControl('');
    selectedNewWord!: formatedRedWords | null;
    selectedReviewWord!: formatedRedWords | null;
    isCustomWordAdded = false;
    flashcardsInputs: flashcardsInputs = {
        solidLettersZanerBloser: false,
        solidLettersDNealian: false,
        startingPointsZanerBloser: false,
        startingPointsDNealian: false,
        stageK: false,
        stage1A: false,
        stage1B: false,
        stage2: false,
        stage3: false,
        stage4: false,
        stage5: false,
        colorNumber: false
    };
    flashCardsData1: string[][] = [
        ['Level K Red Word Paper', 'with dotted letters', 'k_red_word'],
        ['Level K Flash Cards', '(Sequence Order)', 'Level_k_Sequence'],
        ['Level K Flash Cards', '(Alphabetical Order)', 'Level_k_Alphabetical'],
        ['Color and Number', 'Flash cards', 'colors_numbers'],
    ];
    flashCardsData2: string[][] = [
        ['Level 1 Flash Cards', '(Sequence Order)', 'Level_1_Sequence'],
        ['Level 1 Flash Cards', '(Alphabetical Order)', 'Level_1_Alphabetical'],
        ['Level 2 Flash Cards', '(Sequence Order)', 'Level_2_Sequence'],
        ['Level 2 Flash Cards', '(Alphabetical Order)', 'Level_2_Alphabetical'],
    ];
    toBeSaved!: RedWordSaveDto;
    savedRedWordsData!: RedWordSavedDto;
    isLessonConcept = false;

    savedNewWordsList: formatedRedWords[] = [];
    dropNewWordList(event: CdkDragDrop<formatedRedWords[]>) {
        moveItemInArray(this.savedNewWordsList, event.previousIndex, event.currentIndex);
    }
    savedReviewWordsList: formatedRedWords[] = [];
    dropReviewWordList(event: CdkDragDrop<formatedRedWords[]>) {
        moveItemInArray(this.savedReviewWordsList, event.previousIndex, event.currentIndex);
    }
    leftHeaderButtons: { title: string, event: string }[] = [{
        title: 'Back to Home',
        event: 'homeButton'
    }];

    // rightHeaderButtons: { title: string, event: string }[] = [{
    //     title: 'Saved Materials',
    //     event: 'savedLessonConcept'
    // }];

    rightHeaderButtons: { title: string, event: string }[] = [];

    functonList: { [name: string]: Function } = {
        homeButton: () => this.gotoToHome(),
        openClassSelection: () => this.goToClassManagement()
    }
    selectedClass: ClassData | null;
    constructor(private coreService: CoreService,
        private router: Router,
        private navbarService: NavbarService,
        public dialog: MatDialog) {
        this.selectedClass = this.coreService.selectedClass;
        if (this.selectedClass) {
            this.leftHeaderButtons.push({
                title: this.selectedClass.name,
                event: 'openClassSelection'
            });
        }

        this.navbarService.updateLeftHeaderBtn(this.leftHeaderButtons);
        this.navbarService.updateRightHeaderBtn(this.rightHeaderButtons);
        let navServiceSub = this.navbarService.headerBtnClicked$.subscribe({
            next: action => this.onNavigationServiceActionCalled(action)
        });
        this._subscription.push(navServiceSub);

        let routerData = this.router.getCurrentNavigation()?.extras.state as {
            selectedConcept: ConceptMaterial,
            savedConceptMaterial: RedWordSavedDto
        };

        this.selectedConcept = routerData.selectedConcept;
        let descChangeSub = this.descriptionControl.valueChanges.subscribe({
            next: (value: string) => {
                if (this.descriptionControl.errors?.['maxlength']) {
                    this.descriptionControl.setValue(value.substring(0, 40));
                }
            }
        });
        this._subscription.push(descChangeSub);
        this.getRedWords(true);

        if (routerData.savedConceptMaterial) {
            this.savedRedWordsData = routerData.savedConceptMaterial;
            this.updateSavedData();
            if (this.savedRedWordsData.lessonMaterial) {
                this.isLessonConcept = true;
                this.descriptionControl = new UntypedFormControl('');
            }
        }

        //sub for word search
        let formControlSub = this.wordSearchNewWordControl.valueChanges.subscribe({
            next: value => {
                if (value && (typeof value === 'object')) {
                    this.selectedNewWord = value;
                    this.wordSearchNewWordControl.setValue(this.selectedNewWord?.fullword, { emitEvent: false });
                } else if (value) {
                    this.selectedNewWord = null;
                    this.redNewWordsListFiltered.IMSE = this.redWordsList.IMSE?.filter(d => {
                        return (d.fullword?.toLowerCase().indexOf(value.trim().toLowerCase()) ?? -1) >= 0;
                    });
                    this.redNewWordsListFiltered.custom = this.redWordsList.custom?.filter(d => {
                        return (d.fullword?.toLowerCase().indexOf(value.trim().toLowerCase()) ?? -1) >= 0;
                    });
                } else {
                    this.selectedNewWord = null;
                    this.redNewWordsListFiltered = { ...this.redWordsList };
                }
            }
        });
        this._subscription.push(formControlSub);

        //sub for word search
        let formReviewControlSub = this.wordSearchReviewWordControl.valueChanges.subscribe({
            next: value => {
                if (value && (typeof value === 'object')) {
                    this.selectedReviewWord = value;
                    this.wordSearchReviewWordControl.setValue(this.selectedReviewWord?.fullword, { emitEvent: false });
                } else if (value) {
                    this.selectedReviewWord = null;
                    this.redReviewWordsListFiltered.IMSE = this.redWordsList.IMSE?.filter(d => {
                        return (d.fullword?.toLowerCase().indexOf(value.trim().toLowerCase()) ?? -1) >= 0;
                    });
                    this.redReviewWordsListFiltered.custom = this.redWordsList.custom?.filter(d => {
                        return (d.fullword?.toLowerCase().indexOf(value.trim().toLowerCase()) ?? -1) >= 0;
                    });
                } else {
                    this.selectedReviewWord = null;
                    this.redReviewWordsListFiltered = { ...this.redWordsList };
                }
            }
        });
        this._subscription.push(formReviewControlSub);

    }

    ngOnInit(): void {
        setTimeout(() => window.scroll({ top: 0, left: 0, behavior: 'smooth' }), 300);
    }

    ngOnDestroy(): void {
        this._subscription.forEach(sub => sub?.unsubscribe());
    }
    onNavigationServiceActionCalled(action: string) {
        if (this.functonList[action]) {
            this.functonList[action]();
        }
    }

    gotoToHome() {
        this.router.navigate(['/dashboard']);
    }

    goToClassManagement() {
        this.router.navigate(['class']);
    }

    goToConcept() {
        this.router.navigateByUrl('concept', {
            state: {
                selectedConcept: this.selectedConcept
            }
        });
    }

    goToLessonBuilder() {
        this.router.navigateByUrl('lessonBuilder', {
            state: {
                lessonData: this.savedRedWordsData.lessonMaterial?.lesson
            }
        });
    }

    trackByIdx(index: number, obj: any): any {
        return index;
    }

    getRedWords(setDefault: boolean) {
        this.redwordApiLoading = true;
        let getWordApiSub = this.coreService.getRedWords().subscribe({
            next: data => {
                let yourRedWords: formatedRedWords[] = data.filter(rd => !rd.concept?.guid)
                    .map(d => {
                        return {
                            guid: d.guid,
                            fullword: d.word.trim() + ' - custom',
                            conceptName: 'custom',
                            words: d.word.trim()
                        }
                    });
                let imseRedWords: formatedRedWords[] = data.filter(rd => rd.concept?.guid)
                    .sort((a, b) => a.concept?.sort - b.concept?.sort).map(d => {
                        return {
                            guid: d.guid,
                            fullword: d.word.trim() + ' - ' + d.concept.name,
                            conceptName: d.concept.name,
                            words: d.word.trim(),
                            conceptId: d.concept.guid
                        }
                    });
                this.redwordApiLoading = false;
                this.redWordsList = {
                    IMSE: imseRedWords,
                    custom: yourRedWords
                };
                this.redNewWordsListFiltered = {
                    IMSE: imseRedWords,
                    custom: yourRedWords
                };
                this.redReviewWordsListFiltered = {
                    IMSE: imseRedWords,
                    custom: yourRedWords
                };
                let selectedConceptWord = imseRedWords.filter(wrd => wrd.conceptId === this.selectedConcept.guid);
                if (setDefault && selectedConceptWord.length) {
                    this.selectedReviewWord = selectedConceptWord[0];
                    this.wordSearchReviewWordControl.setValue(this.selectedReviewWord?.fullword, { emitEvent: false });
                    this.selectedNewWord = selectedConceptWord[0];
                    this.wordSearchNewWordControl.setValue(this.selectedNewWord?.fullword, { emitEvent: false });
                }
            }
        });
        this._subscription.push(getWordApiSub);
    }

    updateSavedData() {
        this.flashcardsInputs = {
            solidLettersZanerBloser: this.savedRedWordsData.solidZB,
            solidLettersDNealian: this.savedRedWordsData.solidDN,
            startingPointsZanerBloser: this.savedRedWordsData.dotZB,
            startingPointsDNealian: this.savedRedWordsData.dotDN,
            stageK: this.savedRedWordsData.stageK,
            stage1A: this.savedRedWordsData.stage1A,
            stage1B: this.savedRedWordsData.stage1B,
            stage2: this.savedRedWordsData.stage2,
            stage3: this.savedRedWordsData.stage3,
            stage4: this.savedRedWordsData.stage4,
            stage5: this.savedRedWordsData.stage5,
            colorNumber: this.savedRedWordsData.color
        };
        this.descriptionControl.setValue(this.savedRedWordsData.reportTitle);
        this.savedNewWordsList = this.savedRedWordsData.newRedwordList.map(w => {
            return {
                guid: w.redWord.guid,
                fullword: w.redWord.word.trim() + ' - ' + (w.redWord.concept?.name || 'Custom'),
                conceptName: w.redWord.concept?.name || 'Custom',
                words: w.redWord.word.trim()
            }
        });
        this.savedReviewWordsList = this.savedRedWordsData.reviewRedwordList.map(w => {
            return {
                guid: w.redWord.guid,
                fullword: w.redWord.word.trim() + ' - ' + (w.redWord.concept?.name || 'Custom'),
                conceptName: w.redWord.concept?.name || 'Custom',
                words: w.redWord.word.trim()
            }
        });
    }
    addWordList(type: string) {
        let duplicateWordAdd = false;
        if (type === 'new') {
            duplicateWordAdd = this.savedNewWordsList.some(w => w.guid === this.selectedNewWord?.guid);
            if (!duplicateWordAdd && this.selectedNewWord) {
                this.savedNewWordsList.push(this.selectedNewWord);
            }
        } else {
            duplicateWordAdd = this.savedReviewWordsList.some(w => w.guid === this.selectedReviewWord?.guid);
            if (!duplicateWordAdd && this.selectedReviewWord) {
                this.savedReviewWordsList.push(this.selectedReviewWord);
            }
        }

        if (duplicateWordAdd) {
            this.coreService.openSnackBar("Duplicate word", 3000, "warn", "end", "bottom");
        }
    }

    deleteWordFromSaved(type: string, index: number) {
        if (type === 'new') {
            this.savedNewWordsList.splice(index, 1);
        } else {
            this.savedReviewWordsList.splice(index, 1);
        }
        this.coreService.openSnackBar("Word Removed", 3000, "accent", "end", "bottom");
    }

    saveRedWords(printPdf: boolean, copyToConcept: boolean) {
        if (this.descriptionControl.errors) {
            this.descriptionControl.markAsTouched();
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            this.coreService.openSnackBar("Report Title Required", 3000, "warn", "end", "bottom");
            return;
        }
        if (printPdf) {
            this.isSavePrintInProgress = true;
        } else {
            this.isSaveInProgress = true;
        }
        this.toBeSaved = {
            ...this.flashcardsInputs,
            conceptId: this.selectedConcept.guid,
            reportTitle: this.descriptionControl.value.trim(),
            newRedWordGuidList: [...this.savedNewWordsList.map(w => w.guid)],
            newRedWordList: [...this.savedNewWordsList.map(w => w.words)],
            reviewRedWordGuidList: [...this.savedReviewWordsList.map(w => w.guid)],
            reviewRedWordList: [...this.savedReviewWordsList.map(w => w.words)]
        }
        let displayMessage = '';
        let updateById = copyToConcept ? null : this.savedRedWordsData?.id;
        if (copyToConcept) {
            displayMessage = 'Copied to Concept Material';
            this.toBeSaved.reportTitle = ('Copy From ' + this.savedRedWordsData.lessonMaterial?.lesson.title).substring(0, 40);
            this.saveData(updateById, displayMessage, copyToConcept, printPdf);
            return;
        } else {
            displayMessage = 'Data ' + (this.savedRedWordsData?.id ? 'Updated' : 'Saved');
        }

        let getSavedConceptsSub = this.coreService.getSavedConceptMaterialByClass().subscribe(
            {
                next: savedConcepts => {
                    let isDuplicateConceptName = savedConcepts.filter(d => d.materialTypeId !== updateById)
                        .some(d => d.materialTypeTitle?.toLowerCase().trim() === this.toBeSaved.reportTitle?.toLowerCase());
                    if (isDuplicateConceptName) {
                        let confirmDialogBox = this.dialog.open(ConfirmDialogBox, {
                            data: {
                                message: `Concept material already exists with the same name. Do you want to save?`,
                                confirmBtnClass: 'blue',
                                confirmBtnLabel: 'Save'
                            },
                            disableClose: true,
                        });
                        let confirmDialogSub = confirmDialogBox.beforeClosed().subscribe({
                            next: data => {
                                if (data) {
                                    this.saveData(updateById, displayMessage, copyToConcept, printPdf);
                                } else {
                                    this.isSaveInProgress = false;
                                    this.isSavePrintInProgress = false;
                                }
                            }
                        });
                        this._subscription.push(confirmDialogSub);
                    } else {
                        this.saveData(updateById, displayMessage, copyToConcept, printPdf);
                    }
                }
            }
        );
        this._subscription.push(getSavedConceptsSub);

    }

    saveData(updateById: string | null, displayMessage: string, copyToConcept: boolean, printPdf: boolean) {
        let apiSub = this.coreService.saveRedWords(this.toBeSaved, updateById).subscribe({
            next: resp => {
                this.coreService.openSnackBar(displayMessage, 3000, "primary", "end", "bottom");
                if (!copyToConcept) {
                    this.savedRedWordsData = resp;
                }
                this.isSaveInProgress = false;
                printPdf && this.printPdf();

            },
            error: err => {
                console.log(err);
                this.coreService.openSnackBar("Some error occurred'", 3000, "warn", "end", "bottom");
                this.isSaveInProgress = false;
            }
        });
        this._subscription.push(apiSub);
    }
    printPdf() {
        let apiSub = this.coreService.getRedWordsPDF(this.savedRedWordsData.id).subscribe({
            next: (data: Blob) => {
                try {
                    this.isSavePrintInProgress = false;
                    this.coreService.openPdf(data, 'RedWords');
                } catch (err) {
                    console.log(err);
                    this.coreService.openSnackBar("Error occurred while opening PDF", 3000, "warn", "end", "bottom");
                }
            },
            error: err => {
                this.isSavePrintInProgress = false;
                console.log(err);
                this.coreService.openSnackBar("Some error occurred", 3000, "warn", "end", "bottom");
            }
        });
        this._subscription.push(apiSub);
    }

    addNewRedWord(type: string) {
        let newWord = type === 'new' ? this.wordSearchNewWordControl.value : this.wordSearchReviewWordControl.value;
        let redWordDialog = this.dialog.open(RedWordsDialogComponent, {
            data: {
                newWord: newWord
            }
        });
        let redWordSub = redWordDialog.afterClosed().subscribe(
            {
                next: (d: redWordRespDto) => {
                    if (d) {
                        let newCustomerWord: formatedRedWords = {
                            guid: d.guid,
                            fullword: d.word.trim() + ' - custom',
                            conceptName: 'custom',
                            words: d.word.trim()
                        };
                        if (type === 'new') {
                            this.selectedNewWord = newCustomerWord;
                            this.wordSearchNewWordControl.setValue("", { emitEvent: false });
                            this.addWordList(type);
                        } else {
                            this.selectedReviewWord = newCustomerWord;
                            this.wordSearchReviewWordControl.setValue("", { emitEvent: false });
                            this.addWordList(type);
                        }
                        this.getRedWords(false);
                    }
                }
            }
        );
        this._subscription.push(redWordSub);
    }

    softDeleteCustomWord(e: any, words: formatedRedWords) {
        if (e && e.stopPropagation) {
            e.stopPropagation();
        } else if (window.event) {
            window.event.cancelBubble = true;
        }
        let confirmDialogBox = this.dialog.open(ConfirmDialogBox, {
            data: {
                message: `Are you sure you want to delete <b> ${words.words} </b>?`,
                confirmBtnClass: 'lightRed',
                confirmBtnLabel: 'Delete'
            },
            disableClose: true,
        });
        let confirmDialogSub = confirmDialogBox.beforeClosed().subscribe({
            next: data => {
                if (data) {
                    let wordDeleteSub = this.coreService.deleteCustomRedWord(words.guid).subscribe({
                        next: resp => {
                            this.getRedWords(true);
                            this.coreService.openSnackBar(`RedWord: ${words.words} deleted`, 3000, "warn", "end", "bottom");
                        },
                        error: err => {
                            console.log(err);
                            this.coreService.openSnackBar("Error Occurrd while deleting", 3000, "warn", "end", "bottom");
                        }
                    });
                    this._subscription.push(wordDeleteSub);
                }
            }
        });

        this._subscription.push(confirmDialogSub);
    }
}

interface flashcardsInputs {
    [index: string]: any,
    solidLettersZanerBloser: boolean,
    solidLettersDNealian: boolean,
    startingPointsZanerBloser: boolean,
    startingPointsDNealian: boolean,
    stageK: boolean,
    stage1A: boolean,
    stage1B: boolean,
    stage2: boolean,
    stage3: boolean,
    stage4: boolean,
    stage5: boolean,
    colorNumber: boolean
}