import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { filter, Subscription } from 'rxjs';
import { Grades, Levels } from 'src/app/classes/enums';

import * as _moment from 'moment';
import { CoreService } from 'src/app/core/core.service';


const moment = _moment;
@Component({
    selector: 'edit-class-migrate',
    templateUrl: './edit-class-migrate.component.html',
    styleUrls: ['./edit-class-migrate.component.scss'],
})
export class EditClassMigrateComponent implements OnInit, OnDestroy {
    private _subscription: Subscription[] = [];
    gradeList = Grades;
    levelList = Levels;
    classForm!: FormGroup;
    classNameControl!: FormControl;
    classGardeControl!: FormControl;
    classBaseLineControl!: FormControl;
    classData!: {
        name: string;
        level: string;
        grade: string;
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<EditClassMigrateComponent>,
        private coreService: CoreService) {

        this.classData = data['classData'];
        this.classNameControl = new FormControl(this.classData.name || '', [Validators.required, Validators.maxLength(30)]);
        this.classGardeControl = new FormControl(this.gradeList[this.classData.grade || 'KINDERGARTEN']?.key, Validators.required);
        this.classBaseLineControl = new FormControl(this.levelList[this.classData.level || 'BR']?.key, Validators.required);
        this.classForm = new FormGroup({
            name: this.classNameControl,
            level: this.classBaseLineControl,
            grade: this.classGardeControl,
        });
    }
    ngOnDestroy(): void {
        this._subscription.forEach(sub => sub?.unsubscribe());
    }

    ngOnInit(): void {
    }

    onCancel() {
        this.dialogRef.close(null);
    }

    onUpdate() {
        if (!this.classForm.valid) {
            this.coreService.openSnackBar("Please fill required values", 3000, "error", "right", "top");
            return;
        }
        this.dialogRef.close(this.classForm.value);
    }
    keepOriginalOrder = (a: any, b: any) => 1;

}