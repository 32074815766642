import { Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { User } from 'src/app/classes/user';
import { LoginService } from 'src/app/core/login.service';
@Component({
    selector: 'app-mfa-verify',
    templateUrl: 'app-mfa-verify.component.html',
    styleUrls: ['app-mfa-verify.component.scss']
})
export class MfaVerifyDialog implements OnDestroy {
    private _subscription: Subscription[];
    userData: User;
    errorMessage!: string;
    codeInputControl: FormControl<string>;
    isApiVerifyRunning = false;
    isApiResendRunning = false;
    isApiResendRecoveryRunning = false;
    showRecoveryEmail = false;
    showResendNote = false;
    showResendCodeBtn = true;
    showResendCodeRecoveryBtn = false;
    canAddRecoveryEmail = false;
    recoveryEmailControl: FormControl<string>;
    showAddRecoveryEmail = false;
    isUpdateEmailApiRunning = false;
    isEmailUpdated = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<MfaVerifyDialog>,
        private loginService: LoginService) {
        this._subscription = [];
        this.userData = data['userData'];
        this.codeInputControl = new FormControl();
        this.codeInputControl.setValidators([Validators.required, Validators.maxLength(6), Validators.minLength(6)]);
        this.recoveryEmailControl = new FormControl();
        this.recoveryEmailControl.setValidators([Validators.required, Validators.email]);
        this.canAddRecoveryEmail = !this.userData.recoveryEmail && this.userData.allowRecoveryUpdate;
        this.showResendCodeRecoveryBtn = !!this.userData.recoveryEmail;
    }


    ngOnDestroy(): void {
        this._subscription?.forEach(sub => sub && sub.unsubscribe());
    }

    checkMfaCode() {
        if (!this.codeInputControl.valid) {
            return;
        }
        this.isApiVerifyRunning = true;
        this.errorMessage = '';
        this.showResendNote = false;
        let apiSub = this.loginService.verifyCode(this.userData.imUserId, this.codeInputControl.value, this.userData.username).subscribe({
            next: resp => {
                if (resp.verified) {
                    this.userData.token = resp.token;
                    this.userData.ssoToken = resp.ssoToken;
                    this.dialogRef.close(true);
                } else {
                    this.errorMessage = 'WrongCode';
                }
                this.isApiVerifyRunning = false;
            },
            error: err => {
                console.log(err);
                this.errorMessage = 'Error while performing Code check, please try again';
                this.isApiVerifyRunning = false;
            }
        });
        this._subscription.push(apiSub);
    }

    loginAgain() {
        this.dialogRef.close(null);
    }

    reSendCode() {
        this.isApiResendRunning = true;
        let apiSub = this.loginService.resendCode(this.userData.imUserId).subscribe({
            next: resp => {
                this.errorMessage = '';
                this.showResendNote = true;
                this.showResendCodeBtn = false;
                this.showRecoveryEmail = false;
                this.isApiResendRunning = false;
                this.codeInputControl.setValue('');
            },
            error: err => {
                console.log(err);
                this.errorMessage = "Resend Call failed, please try again";
                this.isApiResendRunning = false;
            }
        });
        this._subscription.push(apiSub);
    }

    resendCodeToRecovery() {
        this.isApiResendRecoveryRunning = true;
        let apiSub = this.loginService.resendCodeToRecovery(this.userData.imUserId).subscribe({
            next: resp => {
                this.errorMessage = '';
                this.showResendNote = true;
                this.showResendCodeRecoveryBtn = false;
                this.showRecoveryEmail = true;
                this.isApiResendRecoveryRunning = false;
                this.codeInputControl.setValue('');
            },
            error: err => {
                console.log(err);
                this.errorMessage = "Resend Call failed, please try again";
                this.isApiResendRecoveryRunning = false;
            }
        });
        this._subscription.push(apiSub);
    }

    showHideAddRecoveryBlock() {
        this.showAddRecoveryEmail = !this.showAddRecoveryEmail;
    }

    updateRecoveryEmail() {
        if (!this.recoveryEmailControl.valid) {
            return;
        }
        this.isUpdateEmailApiRunning = true;
        let apiSub = this.loginService.updateRecoveryEmail(this.userData.imUserId, this.recoveryEmailControl.value).subscribe({
            next: resp => {
                this.isUpdateEmailApiRunning = false;
                this.isEmailUpdated = true;
                this.recoveryEmailControl.disable();
            },
            error: err => {
                console.log(err);
                this.isUpdateEmailApiRunning = false;
                this.errorMessage = "Update Recovery Email failed, please try later";
            }
        });
        this._subscription.push(apiSub);
    }
}