<div class="convertDistrictContainer">
    <app-dialog-title title="Convert to District Managed"></app-dialog-title>
    <div mat-dialog-content class="content">
        <div>
            <p>Your district is requesting this account be converted to a District Managed Account.</p>

            <p>When converted, all existing IMSE training, products, and IOG information linked to this district email address will become owned and managed by the district.</p>

            <p>In the event you ever leave this district and cannot access this email address, you will no longer be able to retrieve historical information in this IMSE account.</p>
        </div>
        <hr/>
        <div>
            <div class="btnStepperClass">
                <p>
                    I understand that all previous purchases, trainings, certificates, etc on this account will remain, but be managed by my district. If I leave this district, I will not be able to access any information from this account.
                </p>
                <div class="btn">
                    <app-button content="Continue" btnClasses="blue" [isDisabled]="apiRunning" (onClick)="acceptMigrate()"></app-button>
                    <app-button content="Not now" btnClasses="grey" [isDisabled]="apiRunning" (onClick)="onNotNow()"></app-button>
                </div>
            </div>
            <hr/>
            <div class="btnStepperClass">
                <p>Contact IMSE if you have any concerns regarding District Managed Accounts </p>
                <p class="contactBtn"><app-button content="Contact" btnClasses="whiteRed conceptBtn" (onClick)="onCancel()"></app-button></p>
            </div>
        </div>
    </div>

    <!-- <mat-dialog-actions class="btnClass">
        <app-button [content]="'Cancel'" (click)="onCancel()"></app-button>
    </mat-dialog-actions> -->
</div>