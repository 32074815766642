import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoginService } from 'src/app/core/login.service';
import { NavbarService } from '../navbar.service';

@Component({
  selector: 'app-header-left',
  templateUrl: './app-header-left.component.html',
  styleUrls: ['./app-header-left.component.scss']
})
export class HeaderLeftComponent implements OnDestroy {
  buttonList!: { title: string, event: string }[] | null;
  _subscription!: Subscription;

  constructor(private router: Router,
    private loginService: LoginService,
    private navbarService: NavbarService) {
    this._subscription = this.navbarService.headerLeftBtns$.subscribe({
      next: data => {
        if(loginService.userData?.ssoReadOnly) {
          this.buttonList = data?.filter(btn => btn.title.toLowerCase() !== 'back to home') || [];
        } else {
          this.buttonList = data;
        }
      }
    });
  }
  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  logoutUser() {
    this.loginService.logoutUser();
  }

  onBtnClicked(action: string) {
    this.navbarService.onHeaderBtnClicked(action);
  }
}
