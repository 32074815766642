<div class="syllable-container">
    <div class="titelCotainer">
        <div class="homeBtn">
            <app-button [content]="'&#x2B05; Concept Materials'" (click)="goToConcept()" *ngIf="!isLessonConcept"></app-button>
            <app-button [content]="'&#x2B05; Lesson Builder'" (click)="goToLessonBuilder()" *ngIf="isLessonConcept"></app-button>
        </div>
        <div class="title">
            Syllable Division: Decoding Words - Create Your Own
        </div>
    </div>
    <div class="content">
        <div class="subTitle">
            Concept:
            <span class="conceptName">
                {{selectedConcept.name}}
            </span>
        </div>
        <div class="reportTitleCotainer" *ngIf="!isLessonConcept">
            <div class="titleCotainer">
                <mat-form-field appearance="outline" class="titleForm">
                    <mat-label>Title for Syllable Decoding Words (Max 40 Chars)</mat-label>
                    <input matInput placeholder="Short title for your custom concept" [formControl]="descriptionControl" id="descriptionControl">
                    <mat-hint class="hintMessage">
                        <div *ngIf="descriptionControl.value?.length === 40" class="maxCharReached">
                            Max limit reached
                        </div>
                        <div *ngIf="descriptionControl.value?.length != 40" class="charLeft">
                            {{ 40 - (descriptionControl.value?.length || 0) }} Chars remaining
                        </div>
                    </mat-hint>
                </mat-form-field>
            </div>
        </div>
        <div>
            <mat-progress-bar *ngIf="isApiLoading" mode="query"></mat-progress-bar>
        </div>
        <main class="mat-elevation-z4 decode-section" *ngIf="!isApiLoading">
            <div class="searchBar" *ngIf="wordsList?.length">
                <mat-form-field appearance="outline" floatLabel="auto">
                    <mat-label>Search for decode word</mat-label>
                    <button mat-icon-button matPrefix>
                        <mat-icon>search</mat-icon>
                    </button>
                    <input matInput [formControl]="wordSearchControl" id="wordSearchControl" placeholder="by word name or concept name">
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="auto">
                    <mat-label>New Custom Decode Word</mat-label>
                    <input matInput placeholder="" [formControl]="newDecodeWordControl" id="newDecodeWordControl">
                    <button mat-icon-button matSuffix class="greenBtn" (click)="addCustomDecodeWord(newDecodeWordControl.value)">
                        <mat-icon>add</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div class="decode-words">
                <div class="word-selector">
                    <h3>
                        IMSE Decoding Words
                    </h3>
                    <ul class="word-list">
                        <li *ngFor="let word of wordsListFiltered" class="previewLi">
                            <button mat-mini-fab color="primary" class="addIcon" (click)="addToSavedWord(word)">
                                    <mat-icon>add</mat-icon>
                            </button>
                            <div class="decodeWord">
                                <div class="word">{{word.word}}</div>
                                <div class="type">{{word.concept?.name || 'Custom'}}</div>
                            </div>
                            <button mat-icon-button color="warn" class="deleteIcon" (click)="deleteCustomWord(word)" *ngIf="!(word.concept)">
                                <mat-icon>delete_forever</mat-icon>
                            </button>
                        </li>
                    </ul>
                </div>
                <div class="word-preview">
                    <h3>
                        Selected Decoding Words
                    </h3>
                    <ul class="word-list" cdkDropList (cdkDropListDropped)="dropSavedWordList($event)" id="SavedWordsList">
                        <li *ngFor="let word of savedWordsList;let i=index" class="previewLi" cdkDrag cdkDragBoundary=".word-preview">
                            <div class="drag-handle" cdkDragHandle>
                                <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                                  <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                                  <path d="M0 0h24v24H0z" fill="none"></path>
                                </svg>
                            </div>
                            <div class="decodeWord">
                                <div class="word">{{word.word}}</div>
                                <div class="type">{{word.concept?.name || 'Custom'}}</div>
                            </div>
                            <button mat-icon-button color="accent" class="removeIcon" (click)="removeFromSavedWord(i)">
                                <mat-icon>close</mat-icon>
                            </button>
                            <span *cdkDragPreview>
                                {{word.word}}
                            </span>
                        </li>
                    </ul>
                    <div>&nbsp;</div>
                </div>
            </div>
        </main>
        <div class="wordPerPage" *ngIf="!isApiLoading">
            Words Per Page:
            <mat-button-toggle-group class="toggle-blue" name="wordsPerPage" aria-label="wordsPerPage" [formControl]="wordsPerPage">
                <mat-button-toggle value="1">1</mat-button-toggle>
                <mat-button-toggle value="2">2</mat-button-toggle>
                <mat-button-toggle value="3">3</mat-button-toggle>
                <mat-button-toggle value="4">4</mat-button-toggle>
                <mat-button-toggle value="5">5</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
    <div class="SaveBtnContainer">
        <app-button [content]="'Save'" [btnClasses]="'blue'" (click)="saveDecodeWord(false,false)" [isDisabled]="selectedClass?.readOnly || isSaveInProgress  || isSavePrintInProgress " [isLoading]="isSaveInProgress"></app-button>
        <app-button [content]="'Save and Print PDF'" (click)="saveDecodeWord(true,false)" [isDisabled]="selectedClass?.readOnly || isSaveInProgress  || isSavePrintInProgress" [isLoading]="isSavePrintInProgress"></app-button>
        <!-- <app-button *ngIf="isLessonConcept" [content]="'Copy to Concept Material'" [btnClasses]="'blue'" (click)="saveDecodeWord(false,true)" [isDisabled]="selectedClass?.readOnly || isSaveInProgress || isSavePrintInProgress"></app-button> -->
    </div>
</div>