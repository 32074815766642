<div class="warn-container">
    <app-dialog-title [title]="'Need Account Update!'"></app-dialog-title>
    <h3>
        Your district and school are not set!
    </h3>
    <h4>
        If you need to use sharing features within a school district, you must update the information in your account <a href="https://imse.com/account/info/" target="ImseAccount">here</a>. To create classes and students that are not associated with a
        district, continue to class creation.
    </h4>
    <mat-dialog-actions class="btnClass">
        <app-button [content]="'Close'" (click)="onClose()"></app-button>
    </mat-dialog-actions>
</div>