import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ClassData, ClassDto } from 'src/app/classes/classData';
import { ConceptMaterial, ConceptType, SavedConceptDetail } from 'src/app/classes/concept';
import { invitedUserData } from 'src/app/classes/invitedUserData';
import { deleteLessonDto, LessonData, SavedLessonData } from 'src/app/classes/lessonData';
import { User } from 'src/app/classes/user';

import { CoreService } from 'src/app/core/core.service';
import { NavbarService } from 'src/app/layout/app-header/navbar.service';
import { AddEditClassComponent } from '../dialog-box/add-edit-class/add-edit-class.component';
import { AddEditInvitedUser } from '../dialog-box/add-edit-invited-user/add-edit-invited-user.component';
import { ArchiveClassDialog } from '../dialog-box/archive-class/archive-class.component';
import { ConceptDisplay } from '../dialog-box/concept-display/concept-display.component';
import { ConfirmDialogBox } from '../dialog-box/confirm-dialog/confirm-dialog.component';
import { SavedConceptDialog } from '../dialog-box/saved-concept-dialog/saved-concept-dialog.component';
@Component({
  selector: 'class-detail',
  templateUrl: './class-detail.component.html',
  styleUrls: ['./class-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClassDetailComponent implements OnDestroy {
  selectedClass!: ClassData;
  invitedUser: invitedUserData[] = [];
  invitedStudentUser: invitedUserData[] = [];
  savedLessonList: LessonData[] = [];
  userData!: User | null;
  isGetLessonLoading = true;
  savedConceptList: SavedConceptDetail[] = [];
  isGetConceptSavedLoading = true;
  savedConceptNames: { name: string, id: string, isSelected: boolean }[] = [];
  days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
  customComptents = ['THREE_PART_DRILL', 'DICTATION', 'MULTI_SENSORY', 'LETTER_FORMATION', 'FLUENCY', 'RED_WORDS', 'OPEN_CLOSE_DOOR', 'DECODING_WORDS'];
  private _subscription: Subscription[] = [];
  leftHeaderButtons: { title: string, event: string }[] = [{
    title: 'Back to Home',
    event: 'homeButton'
  }];

  canShareClass = false;
  // rightHeaderButtons: { title: string, event: string }[] = [{
  //   title: 'Class Reports',
  //   event: 'classReportBtn'
  // }];

  functonList: { [name: string]: Function } = {
    homeButton: () => this.gotoToHome()
  }

  constructor(
    private coreService: CoreService,
    private router: Router,
    private navbarService: NavbarService,
    public dialog: MatDialog,
    ) {
    if (!this.coreService.selectedClass) {
      this.router.navigate(['dashboard']);
      return;
    }
    this.selectedClass = this.coreService.selectedClass;
    this.userData = this.coreService.userData;
    let shareClassRule = this.userData?.propertiesByRule && this.userData.propertiesByRule['TEACHER_SHARE'];
    this.canShareClass = shareClassRule ? JSON.parse(shareClassRule.value) : false;
  
    this.navbarService.updateLeftHeaderBtn(this.leftHeaderButtons);
    this.navbarService.updateRightHeaderBtn(null);
    // this.navbarService.updateRightHeaderBtn(this.rightHeaderButtons);
    const navSub = this.navbarService.headerBtnClicked$.subscribe({
      next: action => this.onNavigationServiceActionCalled(action)
    });

    this._subscription.push(navSub);
    this.getInvitedUserData();
    this.getSavedLesson();
    this.getSavedConceptMaterial(null);
  }

  ngOnDestroy(): void {
    this._subscription.forEach(sub => sub?.unsubscribe());
  }

  onNavigationServiceActionCalled(action: string) {
    if (this.functonList[action]) {
      this.functonList[action]();
    }
  }

  gotoToHome() {
    this.router.navigate(['/dashboard']);
  }

  gotoStudent() {
    // this.router.navigate(['student']);
    this.router.navigate(['student-assessment']);
  }

  getClass(classId: string) {
    let getClassSub = this.coreService.getClassData(classId).subscribe({
      next: updatedClass => {
        this.selectedClass = updatedClass;
        this.coreService.selectedClass = updatedClass;
      },
      error: err => {
        console.log(err);
        this.coreService.openSnackBar("Error Occured, please try later", 3000, "warn", "end", "bottom");
      }
    });
    this._subscription.push(getClassSub);
  }

  getSavedLesson() {
    let getLessonSub = this.coreService.getSavedLessonByClass().subscribe({
      next: resp => {
        this.savedLessonList = resp;
        this.savedLessonList.forEach(lesson => {
          lesson.selected = false;
          lesson.isGetSavedConceptLoading = true;
          lesson.addedComponentList = {
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: []
          };
          let getLessonSavedDataSub = this.coreService.getSavedLessonMaterials(lesson.id).subscribe({
            next: lessonSavedData => {
              lesson.isGetSavedConceptLoading = false;
              lessonSavedData.filter(conc => this.customComptents.indexOf(conc.materialType) > -1).forEach(savedConcept => {
                let lessonDay = savedConcept.lessonDay.slice(0, 1) + savedConcept.lessonDay.slice(1).toLowerCase();
                savedConcept.materialUiName = ConceptType[savedConcept.materialType];
                lesson.addedComponentList[lessonDay].push(savedConcept);
              });
            },
            error: err => {
              console.log(err);
              lesson.isGetSavedConceptLoading = false;
            }
          });
          this._subscription.push(getLessonSavedDataSub);
        });
        this.isGetLessonLoading = false;
      },
      error: err => console.log(err)
    });
    this._subscription.push(getLessonSub);
  }

  getSavedConceptMaterial(reloadSavedConcept: string | null) {
    let getSavedConceptSub = this.coreService.getSavedConceptMaterialByClass().subscribe({
      next: resp => {
        this.savedConceptList = resp;
        this.isGetConceptSavedLoading = false;
        let conceptNameSet: { [key: string]: string } = {};
        this.savedConceptList.forEach(data => {
          conceptNameSet[data.concept.name] = data.concept.guid;
        })
        this.savedConceptNames = Object.entries(conceptNameSet).map(([name, id]) => {
          return {
            name: name,
            id: id,
            isSelected: false
          };
        });
        if (reloadSavedConcept) {
          this.openSavedConceptMaterial(reloadSavedConcept);
        }
      },
      error: err => console.log(err)
    });
    this._subscription.push(getSavedConceptSub);
  }

  addNewClass() {
    const newClassDialogef = this.dialog.open(AddEditClassComponent, {
      data: {
        title: 'Create a New Class',
        type: 'new'
      },
      disableClose: true,
      panelClass: ['dialogOverflow']
    });

    let newClassSub = newClassDialogef.afterClosed().subscribe({
      next: (data: ClassDto) => {
        if (data) {
          let addClassSub = this.coreService.addNewClass(data).subscribe({
            next: newClassData => {
              this.coreService.selectedClass = newClassData;
              this.router.navigate(['student-assessment']);
              this.coreService.openSnackBar(`New Class ${data.className} Added`, 3000, "primary", "end", "bottom");
            },
            error: err => {
              console.log(err);
              this.coreService.openSnackBar("Error Occured, please try later", 3000, "warn", "end", "bottom");
            }
          });
          this._subscription.push(addClassSub);
        }
      }
    });
    this._subscription.push(newClassSub);
  }

  editClass() {
    const editClassDialogef = this.dialog.open(AddEditClassComponent, {
      data: {
        title: 'Edit Class',
        type: 'edit',
        classData: this.selectedClass
      },
      disableClose: true,
      panelClass: ['dialogOverflow']
    });

    let editClassSub = editClassDialogef.afterClosed().subscribe({
      next: (data: any) => {
        if (data) {
          if(data === 'archive') {
            this.confirmArchiveClass();
            return;
          }
          let updateClassSub = this.coreService.saveUpdateClass(data).subscribe({
            next: res => {
              this.getClass(data.classId);
              this.coreService.openSnackBar(`Class Updated - ${data.className}`, 3000, "primary", "end", "bottom");
            },
            error: err => {
              console.log(err);
              this.coreService.openSnackBar("Error Occured, please try later", 3000, "warn", "end", "bottom");
            }
          });
          this._subscription.push(updateClassSub);
        }
      }
    });
    this._subscription.push(editClassSub);
  }

  getInvitedUserData() {
    let getInvitedUserSub = this.coreService.getSharedClass().subscribe({
      next: res => {
        this.invitedUser = res.filter(shr => !shr.studentSharedInfo);
        this.invitedStudentUser = res.filter(shr => shr.studentSharedInfo);
      },
      error: err => console.log(err)
    });
    this._subscription.push(getInvitedUserSub);
  }

  confirmArchiveClass() {
    const archiveClassDialogef = this.dialog.open(ArchiveClassDialog, {
      data: {
        type: 'Archive'
      },
      disableClose: true,
      panelClass: ['dialogOverflow']
    });
    let archivesub = archiveClassDialogef.afterClosed().subscribe({
      next: data => {
        if (data && data === 'Archived') {
          this.coreService.selectedClass = null;
          this.router.navigate(['dashboard']);
        } else {
          this.editClass();
        }
      }
    });
    this._subscription.push(archivesub);
  }

  editInvitedUser(editUser: invitedUserData) {
    const editInviteDialog = this.dialog.open(AddEditInvitedUser, {
      data: {
        type: 'edit',
        invitedUserData: editUser
      },
      disableClose: true,
      panelClass: ['dialogOverflow']
    });
    let editInviteDialogSub = editInviteDialog.afterClosed().subscribe({
      next: data => {
        if (data === 'userRemoved' || data === 'userUpdated') {
          this.getInvitedUserData();
        }
      }
    });
    this._subscription.push(editInviteDialogSub);
  }

  shareClassInvite() {
    const addInviteDialog = this.dialog.open(AddEditInvitedUser, {
      data: {
        type: 'add',
      },
      disableClose: true,
      panelClass: ['dialogOverflow']
    });

    let addInviteDialogSub = addInviteDialog.afterClosed().subscribe({
      next: data => {
        if (data === 'userUpdated') {
          this.getInvitedUserData();
        }
      }
    });
    this._subscription.push(addInviteDialogSub);
  }

  goToLessonBuilder(lesson: LessonData) {
    this.router.navigateByUrl('lessonBuilder', {
      state: {
        lessonData: lesson,
      }
    });
  }
  // For Lesson creation
  openConceptDialog() {
    let openConceptSelect = this.dialog.open(ConceptDisplay);
    let openConceptSeectSub = openConceptSelect.afterClosed().subscribe({
      next: (lessonData: LessonData) => {
        if (lessonData) {
          this.router.navigateByUrl('lessonBuilder', {
            state: {
              lessonData: lessonData
            }
          });
        }
      }
    });
    this._subscription.push(openConceptSeectSub);
  }

  deleteSelectedLesson() {
    let seletedLesson = this.savedLessonList.filter(l => l.selected);
    if (!seletedLesson.length) {
      this.NoSelectionSnackBar("lesson");
      return;
    }
    let confirmDialogBox = this.dialog.open(ConfirmDialogBox, {
      data: {
        message: 'Are you sure you want to delete selected lessons?',
        confirmBtnClass: 'lightRed',
        confirmBtnLabel: 'Delete'
      },
      disableClose: true,
    });
    let confirmDialogSub = confirmDialogBox.afterClosed().subscribe({
      next: resp => {
        if (resp && seletedLesson.length > 0) {
          let lessonToBeDeleted: deleteLessonDto[] = seletedLesson.map(les => {
            return {
              classId: this.selectedClass.guid,
              conceptId: les.concept.guid,
              id: les.id,
              userId: this.userData?.id
            }
          });
          let deleteApiSub = this.coreService.deleteLesson(lessonToBeDeleted).subscribe({
            next: resp => {
              this.coreService.openSnackBar("Lesson Deleted", 3000, "warn", "end", "bottom");
              this.getSavedLesson();
            },
            error: err => {
              console.log(err);
              this.coreService.openSnackBar("Error occured, try again later", 3000, "warn", "end", "bottom");
            }
          });
          this._subscription.push(deleteApiSub);
        }
      }
    });
    this._subscription.push(confirmDialogSub);
  }

  goToConcept() {
    this.router.navigateByUrl('concept');
  }

  openSavedConceptMaterial(conceptName: string) {

    let selectedConcept = this.savedConceptList.filter(conc => conc.concept.name === conceptName);

    if (!selectedConcept.length) {
      this.coreService.openSnackBar(`No Materials present for ${conceptName}`, 3000, "warn", "end", "bottom");
      return;
    }

    const savedConceptDialog = this.dialog.open(SavedConceptDialog, {
      data: {
        conceptName,
        conceptMaterial: selectedConcept,
        guid: selectedConcept[0].concept.guid
      },
      disableClose: true,
      panelClass: ['dialogOverflow']
    });

    let savedConceptSub = savedConceptDialog.afterClosed().subscribe({
      next: (reloadSavedConcept: string) => {
        if (reloadSavedConcept) {
          this.getSavedConceptMaterial(reloadSavedConcept);
        }
      }
    });

    this._subscription.push(savedConceptSub);
  }

  NoSelectionSnackBar(type: string) {
    this.coreService.openSnackBar(`Please select atleast a ${type}`, 3000, "warn", "end", "bottom");
  }

  deleteConcept() {
    let selectedConcept = this.savedConceptNames.filter(conc => conc.isSelected).map(d => d.id);
    if (!selectedConcept.length) {
      this.NoSelectionSnackBar("Concept");
      return;
    }

    let confirmDialogBox = this.dialog.open(ConfirmDialogBox, {
      data: {
        message: 'Are you sure you want to delete selected concept materials?',
        confirmBtnClass: 'lightRed',
        confirmBtnLabel: 'Delete'
      },
      disableClose: true,
    });

    let confirmDialogSub = confirmDialogBox.afterClosed().subscribe({
      next: resp => {
        if (resp && selectedConcept.length > 0) {
          let apiSub = this.coreService.deleteConceptByConceptId(selectedConcept).subscribe({
            next: resp => {
              this.coreService.openSnackBar("Concepts Deleted", 3000, "warn", "end", "bottom");
              this.getSavedConceptMaterial(null);
            },
            error: err => {
              console.log(err);
              this.coreService.openSnackBar("Error occured, try again later", 3000, "warn", "end", "bottom");
            }
          });
          this._subscription.push(apiSub);
        }
      }
    });
    this._subscription.push(confirmDialogSub);
  }


  goToLessonConcept(selectedConcept: SavedLessonData, selectedLesson: LessonData) {
    let apiSub;

    if (selectedConcept.materialType === 'FLUENCY') {
      apiSub = this.coreService.getSavedFluency(selectedConcept.materialId).subscribe({
        next: resp => {
          this.router.navigateByUrl('fluency-custom', {
            state: {
              savedConceptMaterial: resp,
              selectedConcept: resp.lessonMaterial?.lesson.concept
            }
          });
        },
        error: err => {
          console.log(err);
          this.showConceptErrorSnackBar();
        }
      });
    } else if (selectedConcept.materialType === 'THREE_PART_DRILL') {
      apiSub = this.coreService.getSaved3partdrillById(selectedConcept.materialId).subscribe({
        next: resp => {
          this.router.navigateByUrl('3part_drill-custom', {
            state: {
              savedConceptMaterial: resp
            }
          });
        },
        error: err => {
          console.log(err);
          this.showConceptErrorSnackBar();
        }
      });
    } else if (selectedConcept.materialType === 'DICTATION') {
      apiSub = this.coreService.getSavedDictation(selectedConcept.materialId).subscribe({
        next: resp => {
          this.router.navigateByUrl('dictation-custom', {
            state: {
              savedConceptMaterial: resp,
              selectedConcept: resp.lessonMaterial?.lesson.concept
            }
          });
        },
        error: err => {
          console.log(err);
          this.showConceptErrorSnackBar();
        }
      });
    } else if (selectedConcept.materialType === 'RED_WORDS') {
      apiSub = this.coreService.getSavedRedWords(selectedConcept.materialId).subscribe({
        next: resp => {
          this.router.navigateByUrl('red-words-custom', {
            state: {
              savedConceptMaterial: resp,
              selectedConcept: resp.lessonMaterial?.lesson.concept
            }
          });
        },
        error: err => {
          console.log(err);
          this.showConceptErrorSnackBar();
        }
      });
    } else if (selectedConcept.materialType === 'MULTI_SENSORY') {
      apiSub = this.coreService.getSavedNewConcept(selectedConcept.materialId).subscribe({
        next: resp => {
          this.router.navigateByUrl('new-concept', {
            state: {
              savedConceptMaterial: resp,
              selectedConcept: resp.lessonMaterial?.lesson.concept
            }
          });
        },
        error: err => {
          console.log(err);
          this.showConceptErrorSnackBar();
        }
      });
    } else if (selectedConcept.materialType === 'LETTER_FORMATION') {
      apiSub = this.coreService.getLetterFormationConcept(selectedConcept.materialId).subscribe({
        next: resp => {
          this.router.navigateByUrl('letter-formation-custom', {
            state: {
              savedConceptMaterial: resp,
              selectedConcept: resp.lessonMaterial?.lesson.concept
            }
          });
        },
        error: err => {
          console.log(err);
          this.showConceptErrorSnackBar();
        }
      });
    } else if (selectedConcept.materialType === 'OPEN_CLOSE_DOOR') {
      apiSub = this.coreService.getSavedSyllableOpenClose(selectedConcept.materialId).subscribe({
        next: resp => {
          this.router.navigateByUrl('syllable-open-close', {
            state: {
              savedConceptMaterial: resp,
              selectedConcept: resp.lessonMaterial?.lesson.concept
            }
          });
        },
        error: err => {
          console.log(err);
          this.showConceptErrorSnackBar();
        }
      });
    } else if (selectedConcept.materialType === 'DECODING_WORDS') {
      apiSub = this.coreService.getSavedSyllableDecodeWord(selectedConcept.materialId, 'lesson').subscribe({
        next: resp => {
          this.router.navigateByUrl('syllable-decoding-words', {
            state: {
              savedConceptMaterial: resp,
              selectedConcept: selectedLesson.concept,
              savedLessonData: selectedLesson,
              updateById: selectedConcept.materialId
            }
          });
        },
        error: err => {
          console.log(err);
          this.showConceptErrorSnackBar();
        }
      });
    }
    apiSub && this._subscription.push(apiSub);
  }

  showConceptErrorSnackBar() {
    this.coreService.openSnackBar("Error while fetching Concept Data", 3000, "warn", "end", "bottom");
  }
}