<!-- {{ conceptMaterialData }} -->
<div class="conceptPanel" (scroll)="scrollEvent($event)">
    <div class="leftPanel">
        <p>
            Which concept do you need? Choose from the list below to see the content.
        </p>
        <mat-form-field class="search-form-field" appearance="fill" floatLabel="auto">
            <mat-label>Search by concept</mat-label>
            <input matInput type="text" [formControl]="searchConceptNameControl">
            <button *ngIf="searchConceptNameControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchConcept()">
              <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <div class="conceptList" [style.height]="conceptListHeight">
            <button class="conceptBtn" *ngFor="let conceptData of conceptMaterialDataFiltered;let i=index" (click)="selectConcept(conceptData)" [id]="i" [ngClass]="conceptData.isSelected ?'isSelectedConcept' :''">
                {{conceptData.name}}
            </button>
        </div>

        <app-button *ngIf="!isApiLoading" [content]="'< Back to Home'" [btnClasses]="'blue w100'" (click)="gotoToHome()"></app-button>

    </div>
    <div class="rightPanel" *ngIf="selectedConcept">
        <mat-progress-bar *ngIf="isApiLoading" mode="query"></mat-progress-bar>
        <h3>{{selectedConcept.name}}</h3>
        <p>
            Create your own lesson content or access materials made by IMSE experts.
        </p>

        <div class="cardContainer" *ngIf="!isApiLoading">
            <ng-container *ngFor="let matData of materialCardData">
                <div class="card" *ngIf="matData['customButton'] || matData['premadeButton'] || matData['premadeButton2']">
                    <div class="title">
                        <img [src]="'./assets/images/concept_icons/' + matData['imageUrl']" [ngClass]="'icon ' + (matData['imageclass'] || '')">
                        <p>{{matData['title']}}</p>
                    </div>
                    <div class="btnAlign">
                        <app-button *ngIf="matData['customButton']" [isDisabled]="selectedClass?.readOnly || false" [content]="matData['customButton']['title']" [btnClasses]="matData['customButton']['cssClass']" (click)="matData['customButton']['onClick'] && onFunctionCall(matData['customButton']['onClick'])"></app-button>
                        <app-button *ngIf="matData['premadeButton2']" [content]="matData['premadeButton2']['title']" [btnClasses]="matData['premadeButton2']['cssClass']" (click)="matData['premadeButton2']['premadeUrl'] && goToPreMadeUrl(matData['premadeButton2']['premadeUrl'])"></app-button>
                        <app-button *ngIf="matData['premadeButton']" [content]="matData['premadeButton']['title']" [btnClasses]="matData['premadeButton']['cssClass']" (click)="matData['premadeButton']['premadeUrl'] && goToPreMadeUrl(matData['premadeButton']['premadeUrl'])"></app-button>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>