import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, retry } from 'rxjs';
import { environment } from 'src/environments/environment';

import { User } from '../classes/user';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
@Injectable({
    providedIn: 'root',
})
export class LoginService implements CanActivate {
    public userData!: User | null;
    backListedRoutes = ['dashboard','login'];

    constructor(private httpClient: HttpClient, private router: Router) {
        let sessionId = sessionStorage.getItem('sessionId');
        if (sessionId) {
            //login by token handled in Login component
            return;
        }
        let sessionUserData = localStorage.getItem('userData');
        if (sessionUserData) {
            this.userData = <User>JSON.parse(sessionUserData || '{}');
            this.router.navigate(['dashboard']);
        }
    }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (this.userData?.token) {
            if(this.userData?.ssoReadOnly) {
                if(this.backListedRoutes.includes(route.routeConfig?.path || '')) {
                    this.logoutUser();
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }

    authenticateUser(userName: string, password: string): Observable<User> {
        const url = environment.apiUrl + '/login/v1/authenticate';
        const body = new HttpParams().set('username', userName).set('password', password);
        const httpHeader = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded');
        return this.httpClient.post<User>(url, body.toString(), { headers: httpHeader });
    }

    logoutUser() {
        localStorage.removeItem('userData');
        let closeWindow = this.userData?.ssoReadOnly;
        this.userData = null;
        // this.router.navigate(['/login']);
        if (closeWindow) {
            window.close();
        } else {
            window.location.reload();
        }
    }

    loginUsingSessionId(sessionId: string, sessionId2: string): Observable<User> {
        sessionStorage.removeItem('sessionId');
        sessionStorage.removeItem('sessionId2');
        localStorage.removeItem('userData');
        let url = environment.apiUrl + '/login/v1/session?'
            + 'sessionId=' + sessionId;
        if (sessionId2) {
            url += '&sessionId2=' + sessionId2;
        }
        return this.httpClient.post<User>(url, {});
    }

    verifyCode(userId: number, code: String, username: string): Observable<{ verified: boolean, token: string, ssoToken: string }> {
        const url = environment.apiUrl + '/login/v1/verifyCode?'
        const data = { 
            userId,
            code,
            username
        };
        return this.httpClient.post<{ verified: boolean, token: string, ssoToken: string }>(url, data);
    }

    resendCode(userId: number) {
        const url = environment.apiUrl + '/login/v1/resendCode?userId=' + userId ;
        return this.httpClient.post(url, {});
    }

    resendCodeToRecovery(userId: number) {
        const url = environment.apiUrl + '/login/v1/resendCodeToRecovery?userId=' + userId;
        return this.httpClient.post(url, {});
    }

    updateRecoveryEmail(userId: number, email: string) {
        const url = environment.apiUrl + '/login/v1/updateRecoveryEmail?userId=' + userId
            + '&email=' + email;
        return this.httpClient.post(url, {});
    }

    acceptDaInvite(email: string) {
        const url = environment.apiUrl + '/login/v1/acceptInvite';
        return this.httpClient.post(url, { email });
    }
}