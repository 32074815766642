import { Component, Inject, OnDestroy } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ClassData } from "src/app/classes/classData";
import { ConceptMaterial, SavedConceptDetail, ConceptType } from "src/app/classes/concept";
import { formatedPreMade } from "src/app/classes/Concepts/premade";
import { LessonPrintDto, LessonPrintMaterialDto } from "src/app/classes/lessonData";
import { CoreService } from "src/app/core/core.service";
import { ConfirmDialogBox } from "../confirm-dialog/confirm-dialog.component";

@Component({
    selector: 'saved-concept-dialog',
    templateUrl: 'saved-concept-dialog.component.html',
    styleUrls: ['saved-concept-dialog.component.scss']
})
export class SavedConceptDialog implements OnDestroy {
    savedConceptList: SavedConceptDetail[] = [];
    savedConceptContainer: { [key: string]: SavedConceptDetail[] } = {};
    conceptGuid: string;
    conceptName: string = '';
    isloading = true;
    _subscription: Subscription[] = [];
    preMadeData: formatedPreMade = {};
    selectedClass: ClassData | null;
    isPrintInProgress = false;
    constructor(
        public dialogRef: MatDialogRef<SavedConceptDialog>,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private coreService: CoreService,
        public dialog: MatDialog
    ) {
        this.savedConceptList = this.data['conceptMaterial'];
        this.conceptName = this.data['conceptName'];
        this.conceptGuid = this.data['guid'];
        this.selectedClass = this.coreService.selectedClass;
        this.savedConceptList.forEach(data => {
            let conceptType = ConceptType[data.materialType];
            if (this.savedConceptContainer[conceptType]) {
                this.savedConceptContainer[conceptType].push(data);
            } else {
                this.savedConceptContainer[conceptType] = [];
                this.savedConceptContainer[conceptType].push(data);
            }
        });

        let premadeSub = this.coreService.getConceptPremadeMaterial(this.conceptGuid).subscribe({
            next: resp => {
                resp.forEach(data => {
                    if (data.url.trim()) {
                        let conceptType = ConceptType[data.materialType];
                        this.preMadeData[conceptType] = data.url;
                    }
                });
                this.isloading = false;
            },
            error: err => {
                console.log("Error while fetching PreMade lesson data", err);
                this.isloading = false;
            }
        });
        this._subscription.push(premadeSub);
    }
    ngOnDestroy(): void {
        this._subscription.forEach(sub => sub?.unsubscribe());
    }

    onCancel() {
        this.dialogRef.close(null);
    }

    onSelectMaterial(selected: SavedConceptDetail) {
        this.isloading = true;
        let apiSub!: Subscription;
        if (selected.materialType === 'THREE_PART_DRILL') {
            apiSub = this.coreService.getSaved3partdrillById(selected.materialTypeId).subscribe({
                next: resp => {
                    this.dialogRef.close();
                    this.isloading = false;
                    this.router.navigateByUrl('3part_drill-custom', {
                        state: {
                            savedConceptMaterial: resp
                        }
                    });
                },
                error: err => {
                    console.log(err);
                    this.isloading = false;
                }
            });
        } else if (selected.materialType === 'DICTATION') {
            apiSub = this.coreService.getSavedDictation(selected.materialTypeId).subscribe({
                next: resp => {
                    this.dialogRef.close();
                    this.isloading = false;
                    this.router.navigateByUrl('dictation-custom', {
                        state: {
                            savedConceptMaterial: resp,
                            selectedConcept: resp.classConceptMaterial.concept
                        }
                    });
                },
                error: err => {
                    console.log(err);
                    this.isloading = false;
                }
            });
        } else if (selected.materialType === 'MULTI_SENSORY') {
            apiSub = this.coreService.getSavedNewConcept(selected.materialTypeId).subscribe({
                next: resp => {
                    this.dialogRef.close();
                    this.isloading = false;
                    this.router.navigateByUrl('new-concept', {
                        state: {
                            savedConceptMaterial: resp,
                            selectedConcept: resp.classConceptMaterial?.concept
                        }
                    });
                },
                error: err => {
                    console.log(err);
                    this.isloading = false;
                }
            });
        } else if (selected.materialType === 'LETTER_FORMATION') {
            apiSub = this.coreService.getLetterFormationConcept(selected.materialTypeId).subscribe({
                next: resp => {
                    this.dialogRef.close();
                    this.isloading = false;
                    this.router.navigateByUrl('letter-formation-custom', {
                        state: {
                            savedConceptMaterial: resp,
                            selectedConcept: resp.classConceptMaterial?.concept
                        }
                    });
                },
                error: err => {
                    console.log(err);
                    this.isloading = false;
                }
            });
        } else if (selected.materialType === 'FLUENCY') {
            apiSub = this.coreService.getSavedFluency(selected.materialTypeId).subscribe({
                next: resp => {
                    this.dialogRef.close();
                    this.isloading = false;
                    this.router.navigateByUrl('fluency-custom', {
                        state: {
                            savedConceptMaterial: resp,
                            selectedConcept: resp.classConceptMaterial?.concept
                        }
                    });
                },
                error: err => {
                    console.log(err);
                    this.isloading = false;
                }
            });
        } else if (selected.materialType === 'RED_WORDS') {
            apiSub = this.coreService.getSavedRedWords(selected.materialTypeId).subscribe({
                next: resp => {
                    this.dialogRef.close();
                    this.isloading = false;
                    this.router.navigateByUrl('red-words-custom', {
                        state: {
                            savedConceptMaterial: resp,
                            selectedConcept: resp.classConceptMaterial.concept
                        }
                    });
                },
                error: err => {
                    console.log(err);
                    this.isloading = false;
                }
            });
        } else if (selected.materialType === 'OPEN_CLOSE_DOOR') {
            apiSub = this.coreService.getSavedSyllableOpenClose(selected.materialTypeId).subscribe({
                next: resp => {
                    this.dialogRef.close();
                    this.isloading = false;
                    this.router.navigateByUrl('syllable-open-close', {
                        state: {
                            savedConceptMaterial: resp,
                            selectedConcept: resp.classConceptMaterial?.concept
                        }
                    });
                },
                error: err => {
                    console.log(err);
                    this.isloading = false;
                }
            });
        } else if (selected.materialType === 'DECODING_WORDS') {
            apiSub = this.coreService.getSavedSyllableDecodeWord(selected.materialTypeId, 'class').subscribe({
                next: resp => {
                    this.dialogRef.close();
                    this.isloading = false;
                    this.router.navigateByUrl('syllable-decoding-words', {
                        state: {
                            savedConceptMaterial: resp,
                            selectedConcept: selected.concept,
                            updateById: selected.materialTypeId
                        }
                    });
                },
                error: err => {
                    console.log(err);
                    this.isloading = false;
                }
            });

        }
        apiSub && this._subscription.push(apiSub);
    }

    openPreMade(event: any, url: string) {
        event.stopPropagation();
        if (url) {
            window.open(url, '_blank', 'noopener,resizable,scrollbars,popup=false');
        }
    }

    deleteSelected() {
        let selectedConcept = this.savedConceptList.filter(conc => conc.isSelected).map(d => d.id);

        if (!selectedConcept.length) {
            this.coreService.openSnackBar("Please select atleast a concept", 3000, "warn", "end", "bottom");
            return;
        }

        let confirmDialogBox = this.dialog.open(ConfirmDialogBox, {
            data: {
                message: 'Are you sure you want to delete Selected Concept Materials ?',
                confirmBtnClass: 'lightRed',
                confirmBtnLabel: 'Delete'
            },
            disableClose: true,
        });

        let confirmDialogSub = confirmDialogBox.afterClosed().subscribe({
            next: resp => {
                if (resp && selectedConcept.length > 0) {
                    let apiSub = this.coreService.deleteConceptBySavedIds(selectedConcept).subscribe({
                        next: resp => {
                            this.coreService.openSnackBar(`Material Deleted, reloading ${this.conceptName}`, 3000, "warn", "end", "bottom");
                            this.dialogRef.close(this.conceptName);;
                        },
                        error: err => {
                            console.log(err);
                            this.coreService.openSnackBar("Error occured, try again later", 3000, "warn", "end", "bottom");
                        }
                    });
                    this._subscription.push(apiSub);
                }
            }
        });
        this._subscription.push(confirmDialogSub);
    }

    printSelected()  {
        let selectedConcept = this.savedConceptList.filter(conc => conc.isSelected).map( comp => {
            return {
                id: comp.materialTypeId || '',
                materialType: comp.materialType
            }
        });
        console.log(selectedConcept);

        if (!selectedConcept.length) {
            this.coreService.openSnackBar("Please select atleast a concept", 3000, "warn", "end", "bottom");
            return;
        }
        this.isPrintInProgress = true;
        let conceptPrint: LessonPrintDto;
        let printList: LessonPrintMaterialDto[] = selectedConcept;

        conceptPrint = {
            materialData: printList
        };
        console.log(conceptPrint);
        let apiSub = this.coreService.printConceptMaterialPdf(conceptPrint).subscribe({
            next: (data: Blob) => {
                try {
                    this.isPrintInProgress = false;
                    this.coreService.openPdf(data, 'Concept Material Print');
                } catch (err) {
                    console.log(err);
                    this.coreService.openSnackBar("Error occurred while opening PDF", 3000, "warn", "end", "bottom");
                }
            },
            error: err => {
                this.isPrintInProgress = false;
                console.log(err);
                this.coreService.openSnackBar("Some error occurred'", 3000, "warn", "end", "bottom");
            }
        });
        this._subscription.push(apiSub);
    }
}