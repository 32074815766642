import { Component, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from "@angular/material/paginator";
import { Subscription, timeout } from 'rxjs';
import { ClassData, ClassDto } from 'src/app/classes/classData';
import { StudentIdProperties, User } from 'src/app/classes/user';

import { CoreService } from 'src/app/core/core.service';
import { NavbarService } from 'src/app/layout/app-header/navbar.service';
import { LoginService } from '../../login.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { Grades, Levels } from 'src/app/classes/enums';
import { Student } from 'src/app/classes/student';
import { EditClassMigrateComponent } from '../dialog-box/edit-class-migrate/edit-class-migrate.component';
import { DataSource } from '@angular/cdk/collections';
import { EditStudentMigrateComponent } from '../dialog-box/edit-student-migrate/edit-student-migrate.component';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-migrate-to-district.component',
    templateUrl: './app-migrate-to-district.component.html',
    styleUrls: ['./app-migrate-to-district.component.scss']
})
export class MigrateToDaComponent implements OnDestroy {
    classDatas !: ClassData[];
    userData!: User | null;
    private _subscription: Subscription[] = [];
    currentIndex = 0;
    isGetApiRunning = false;
    gradeList = Grades;
    levelList = Levels;

    infoStepCompleted = false;
    classStepCompleted = false;
    studentStepCompleted = false;
    previewStepCompleted = false;
    // leftHeaderButtons: { title: string, event: string }[] = [{
    //     title: 'Cancel',
    //     event: 'cancelButton'
    // }];

    rightHeaderButtons: { title: string, event: string }[] = [{
        title: 'Migrate Later',
        event: 'notNow'
    }];

    functonList: { [name: string]: Function } = {
        cancelButton: () => this.logoutUser(),
        notNow: () => this.notNow()
    }

    classMigrateData: ClassMigrateDto[] = [];
    classDataSource!: MatTableDataSource<ClassMigrateDto>;
    @ViewChild("classSort", { static: true }) classSort!: MatSort;
    @ViewChild("classPaginator", { static: true }) classPaginator!: MatPaginator;
    classDisplayColumns = ["name", "level", "grade", "studentCount", "conceptCount", "lessonCount", "edit", "status", "isChanged"];

    studentMigrateData: StudentMigrateDto[] = [];
    studentIdMapCount = new Map<string, number>();
    duplicateStudentIdPresent = false;
    invalidStudentIdPresent = false;
    daStudentIdAllPresent: boolean | null = null;
    isConflictStudentCheckApiRunning = false;
    studentMapEditedData = new Map<string, StudentMigrateDto>(); // this is to hold the edited students data
    studentDataSource!: MatTableDataSource<StudentMigrateDto>;
    studentIdFormatProperties!: StudentIdProperties;
    // userTypeableSpecialChars = /[!@#$%^&*(),.?":{}|<>+\-]/; // Define user-typeable special characters
    specialCharsAllowed = /[\-]/;
    allowedChars = /^[0-9a-zA-Z\-]+$/;
    @ViewChild("studentSort", { static: true }) studentSort!: MatSort;
    @ViewChild("studentPaginator", { static: true }) studentPaginator!: MatPaginator;
    studentDisplayColumns = ["statusIcons", "studentID", "firstName", "lastName", "level", "class.name", "edit", "status", "changed"];

    // preview tab
    classCounts!: {
        import: number;
        archive: number;
        delete: number;
        edited: number;
    };
    studentCounts!: {
        import: number;
        delete: number;
        edited: number;
    };

    confirmToggle: FormControl<boolean> = new FormControl(false, { nonNullable: true });
    constructor(
        private coreService: CoreService,
        private loginService: LoginService,
        private router: Router,
        private navbarService: NavbarService,
        public dialog: MatDialog,
    ) {
        // this.navbarService.updateLeftHeaderBtn(this.leftHeaderButtons);
        this.navbarService.updateRightHeaderBtn(this.rightHeaderButtons);
        const navSub = this.navbarService.headerBtnClicked$.subscribe({
            next: action => this.onNavigationServiceActionCalled(action)
        });

        let routerData = this.router.getCurrentNavigation()?.extras.state as {
            userData: User
        };

        this.userData = routerData.userData;
        this.getClassMetricsData();
    }

    ngOnDestroy(): void {
        this._subscription.forEach(sub => sub?.unsubscribe());
    }

    onNavigationServiceActionCalled(action: string) {
        if (this.functonList[action]) {
            this.functonList[action]();
        }
    }

    logoutUser() {
        this.loginService.logoutUser();
    }

    notNow() {
        // call notNowIncreament api then
        this.userData && this.coreService.setUserData(this.userData);
        this.router.navigate(['/dashboard']);
    }

    onStepChange(event: StepperSelectionEvent, stepper: MatStepper) {
        setTimeout(() => {
            stepper.selectedIndex = this.currentIndex;
        });
    }

    markInfoStep(stepper: MatStepper) {
        this.infoStepCompleted = true;
        this.currentIndex = 1;
        this.stepperNext(stepper);
    }

    markClassStep(stepper: MatStepper) {
        this.classStepCompleted = true;
        this.currentIndex = 2;
        this.stepperNext(stepper);
        this.formatStudentData();
    }

    backToClassStep(stepper: MatStepper) {
        this.currentIndex = 1;
        this.classStepCompleted = false;
        this.stepperPrevious(stepper);
    }

    markStudentStep(stepper: MatStepper) {
        this.formatPreviewData();
        this.studentStepCompleted = true;
        this.currentIndex = 3;
        this.stepperNext(stepper);
    }

    backToStudentStep(stepper: MatStepper) {
        this.currentIndex = 2;
        this.studentStepCompleted = false;
        this.stepperPrevious(stepper);
    }

    markPreviewStep(stepper: MatStepper) {
        this.previewStepCompleted = true;
        this.currentIndex = 4;
        this.stepperNext(stepper);
    }

    stepperNext(stepper: MatStepper) {
        setTimeout(() => { stepper.next() });
    }

    stepperPrevious(stepper: MatStepper) {
        setTimeout(() => { stepper.previous() });
    }

    getClassMetricsData() {
        this.isGetApiRunning = true;
        let apiSub = this.coreService.getClassMetricsData().subscribe({
            next: resp => {
                this.classDatas = resp;
                // get properties data for distristManaged
                this.getDaProperties();
            },
            error: err => {
                console.log(err);
                this.isGetApiRunning = false;
                this.coreService.openSnackBar("Error while getting Class Data", 3000, "error", "right", "top");
                this.coreService.softLogoutUser();
                this.router.navigateByUrl("/login");
            }
        });
        this._subscription.push(apiSub);
    }

    getDaProperties() {
        let prosApi = this.coreService.getDistrictAdminProperties(true).subscribe({
            next: resp => {
                let studentRules = resp.filter(p => p.rule === 'STUDENT_ID_VALIDATION');
                if (!studentRules?.length) {
                    this.coreService.openSnackBar("No DA properties found, Contact DA", 3000, "warn", "right", "top");
                    this.coreService.softLogoutUser();
                    this.router.navigateByUrl("/login");
                    return;
                }
                this.studentIdFormatProperties = JSON.parse(studentRules[0].value);
                this.isGetApiRunning = false;
                this.formatClassData();
            },
            error: err => {
                console.log(err);
                this.coreService.openSnackBar("Error while getting Student ID Format", 3000, "warn", "right", "top");
                this.coreService.softLogoutUser();
                this.router.navigateByUrl("/login");
            }
        });
        this._subscription.push(prosApi);
    }

    formatClassData() {
        this.classMigrateData = [];
        this.classDatas.forEach(cls => {
            let tmpCls: ClassMigrateDto = {
                guid: cls.guid,
                name: cls.name,
                level: cls.level,
                grade: cls.grade,
                metrics: {
                    studentCount: cls.metrics?.studentCount || 0,
                    conceptCount: cls.metrics?.conceptCount || 0,
                    lessonCount: cls.metrics?.lessonCount || 0,
                    assessmentCount: cls.metrics?.assessmentCount || 0,
                },
                status: 'Import',
                updated: {},
                isChanged: false,
                student: cls.student,
                toolTipData: '',
            }
            this.classMigrateData.push(tmpCls);
        });
        this.classMigrateData.sort((a, b) => a.name.localeCompare(b.name));
        this.classDataSource = new MatTableDataSource(this.classMigrateData);
        setTimeout(() => {
            // this.classDataSource.sort = this.classSort;
            this.classDataSource.paginator = this.classPaginator;
        });
    }

    onEditClassData(classToEdit: ClassMigrateDto) {
        let editDialog = this.dialog.open(EditClassMigrateComponent, {
            data: {
                classData: {
                    name: classToEdit.updated.name || classToEdit.name,
                    level: classToEdit.updated.level || classToEdit.level,
                    grade: classToEdit.updated.grade || classToEdit.grade
                }
            },
            disableClose: true
        });
        let diaSub = editDialog.afterClosed().subscribe({
            next: resp => {
                if (!resp) {
                    return;
                }
                classToEdit.toolTipData = "";
                let changeLog: string[] = [];

                if (resp['name'].trim() !== classToEdit.name) {
                    classToEdit.updated.name = resp['name'].trim();
                    changeLog.push(`Name: ${classToEdit.name} → ${classToEdit.updated.name}`);
                } else {
                    delete classToEdit.updated.name;
                }

                if (resp['level'].trim() !== classToEdit.level) {
                    classToEdit.updated.level = resp['level'];
                    changeLog.push(`Level: ${this.levelList[classToEdit.level]?.uiName} → ${this.levelList[classToEdit.updated.level || '']?.uiName}`);
                } else {
                    delete classToEdit.updated.level;
                }

                if (resp['grade'].trim() !== classToEdit.grade) {
                    classToEdit.updated.grade = resp['grade'];
                    changeLog.push(`Grade: ${this.gradeList[classToEdit.grade]?.name} → ${this.gradeList[classToEdit.updated.grade || '']?.name}`);
                } else {
                    delete classToEdit.updated.grade;
                }
                classToEdit.toolTipData = changeLog.join("\n");
                classToEdit.isChanged = !!classToEdit.toolTipData;
                this.onClassSortChange();
            }
        });
        this._subscription.push(diaSub);
    }

    formatStudentData() {
        this.studentMigrateData = [];
        this.classMigrateData.filter(cls => cls.status === 'Import').forEach(cls => {
            cls.student?.forEach(stu => {
                let editedStudentData = this.studentMapEditedData.get(stu.guid);
                if (editedStudentData) {
                    this.studentMigrateData.push(editedStudentData);
                    return;
                }
                let tmpStu: StudentMigrateDto = {
                    guid: stu.guid,
                    studentID: stu.studentID?.trim(),
                    firstName: stu.firstName,
                    lastName: stu.lastName,
                    level: stu.level,
                    class: cls,
                    classLinked: true,
                    dulipcateStudentId: false,
                    daStudentIdExists: null,
                    formatInValid: false,
                    formatErrorsValues: "",
                    updated: {},
                    status: 'Import',
                    isChanged: false,
                    toolTipData: '',
                    statusValidCount: 0
                }
                this.studentMigrateData.push(tmpStu);
                this.studentMapEditedData.set(stu.guid, tmpStu);
            });
        });
        // check for dulipcate student id
        this.checkForStudentDuplicate();
        this.studentMigrateData.sort((a, b) => a.studentID.localeCompare(b.studentID));
        this.studentDataSource = new MatTableDataSource(this.studentMigrateData);
        // this.studentDataSource.sort = this.studentSort;
        this.studentDataSource.paginator = this.studentPaginator;
        this.onStudentSortChange();
    }

    onStudentStatusToggle() {
        this.checkForStudentDuplicate();
    }

    checkForStudentDuplicate() {
        this.studentIdMapCount.clear();
        this.duplicateStudentIdPresent = false;
        this.invalidStudentIdPresent = false;
        this.studentMigrateData.filter(stu => stu.status === 'Import').forEach(stu => {
            // check format
            this.studentIdFormatProperties && this.validateStudentFormat(stu);
            if (!this.invalidStudentIdPresent && stu.formatInValid) {
                this.invalidStudentIdPresent = true;
            }
            let tmpStuId = stu.updated.studentID || stu.studentID;
            this.studentIdMapCount.set(tmpStuId, (this.studentIdMapCount.get(tmpStuId) || 0) + 1);
        });
        this.studentMigrateData.filter(stu => stu.status === 'Import').forEach(stu => {
            stu.dulipcateStudentId = (this.studentIdMapCount.get(stu.updated.studentID || stu.studentID) || 0) > 1;
            if (!this.duplicateStudentIdPresent && stu.dulipcateStudentId) {
                this.duplicateStudentIdPresent = true;
            }
        });
        this.daStudentIdAllPresent = null;
    }

    onEditStudent(studentEdit: StudentMigrateDto) {
        let editDia = this.dialog.open(EditStudentMigrateComponent, {
            disableClose: true,
            data: {
                studentData: {
                    firstName: studentEdit.updated.firstName || studentEdit.firstName,
                    lastName: studentEdit.updated.lastName || studentEdit.lastName,
                    studentID: studentEdit.updated.studentID || studentEdit.studentID,
                    level: studentEdit.updated.level || studentEdit.level,
                    class: studentEdit.class.updated.name || studentEdit.class.name,
                    linked: studentEdit.classLinked
                }
            }
        });

        editDia.afterClosed().subscribe({
            next: resp => {
                if (!resp) {
                    return;
                }
                if (resp) {
                    studentEdit.toolTipData = "";
                    let changeLog: string[] = [];
                    if (resp['firstName'].trim() !== studentEdit.firstName) {
                        studentEdit.updated.firstName = resp['firstName'].trim();
                        changeLog.push(`First Name: ${studentEdit.firstName} → ${studentEdit.updated.firstName}`);
                    } else {
                        delete studentEdit.updated.firstName;
                    }

                    if (resp['lastName'].trim() !== studentEdit.lastName) {
                        studentEdit.updated.lastName = resp['lastName'].trim();
                        changeLog.push(`Last Name: ${studentEdit.lastName} → ${studentEdit.updated.lastName}`);
                    } else {
                        delete studentEdit.updated.lastName;
                    }

                    if (resp['level'] !== studentEdit.level) {
                        studentEdit.updated.level = resp['level'];
                        changeLog.push(`Level: ${this.levelList[studentEdit.level]?.uiName} → ${this.levelList[studentEdit.updated.level || '']?.uiName}`);
                    } else {
                        delete studentEdit.updated.level;
                    }

                    if (resp['studentID'].trim() !== studentEdit.studentID) {
                        studentEdit.updated.studentID = resp['studentID'].trim();
                        changeLog.push(`Student Id: ${studentEdit.studentID} → ${studentEdit.updated.studentID}`);
                    } else {
                        delete studentEdit.updated.studentID;
                    }
                    studentEdit.classLinked = resp['linked'];
                    if (!resp['linked']) {
                        changeLog.push('Class unlinked');
                    }

                    studentEdit.toolTipData = changeLog.join("\n");
                    // !studentEdit.classLinked ||
                    //     'firstName' in studentEdit.updated ||
                    //     'lastName' in studentEdit.updated ||
                    //     'level' in studentEdit.updated ||
                    //     'studentID' in studentEdit.updated
                    if (studentEdit.toolTipData) {
                        studentEdit.isChanged = true;
                        studentEdit.daStudentIdExists = null;
                    } else {
                        studentEdit.isChanged = false;
                    }
                }
                this.checkForStudentDuplicate();
                this.onStudentSortChange();
            }
        });

    }

    onClassSortChange() {
        let tmpData = this.classDataSource.data.slice();
        if (!this.classSort.direction || this.classSort.active === 'name') {
            tmpData.sort((a, b) => (a.updated.name || a.name).localeCompare(b.updated.name || b.name));
        }
        if (this.classSort.direction === 'desc') {
            tmpData.reverse();
        }
        this.classDataSource.data = tmpData;
    }

    onStudentSortChange() {
        let tmpData = this.studentDataSource.data.slice();
        if (!this.studentSort.direction || this.studentSort.active === 'studentID') {
            tmpData.sort((a, b) => (a.updated.studentID || a.studentID).localeCompare(b.updated.studentID || b.studentID));
        } else if (this.studentSort.active === 'firstName') {
            tmpData.sort((a, b) => (a.updated.firstName || a.firstName).localeCompare(b.updated.firstName || b.firstName));
        } else if (this.studentSort.active === 'lastName') {
            tmpData.sort((a, b) => (a.updated.lastName || a.lastName).localeCompare(b.updated.lastName || b.lastName));
        } else if (this.studentSort.active === 'statusIcons') {
            tmpData.forEach(s => {
                s.statusValidCount = 0;
                if (s.status !== 'Import') {
                    s.statusValidCount = -1;
                    return;
                }
                if (s.formatInValid) {
                    s.statusValidCount += 1;
                }
                if (s.dulipcateStudentId) {
                    s.statusValidCount += 2;
                }
                if (s.daStudentIdExists === false) {
                    s.statusValidCount += 3;
                }
            });
            tmpData.sort((a, b) => a.statusValidCount - b.statusValidCount);
        }
        if (this.studentSort.direction === 'desc') {
            tmpData.reverse();
        }
        this.studentDataSource.data = tmpData;
    }

    checkStudentIdConflicts(stepper: MatStepper) {
        // if (!this.duplicateStudentIdPresent && this.conflictStudentIdPresent === false) {
        //     this.markStudentStep(stepper);
        //     return;
        // }

        if (this.duplicateStudentIdPresent) {
            this.coreService.openSnackBar("Please resolve duplicate student id", 5000, "error", "right", "top");
            this.daStudentIdAllPresent = null;
            return;
        }
        if (this.invalidStudentIdPresent) {
            this.coreService.openSnackBar("Please resolve student id format errors", 5000, "error", "right", "top");
            this.daStudentIdAllPresent = null;
            return;
        }
        let studentIdsReq = this.studentMigrateData.filter(stu => stu.status === 'Import').map(stu => stu.updated.studentID || stu.studentID);
        if (!studentIdsReq.length) {
            this.daStudentIdAllPresent = true;
            this.markStudentStep(stepper);
            return;
        }
        this.coreService.openSnackBar("Checking for District student match", 3000, "primary", "right", "top");
        this.isConflictStudentCheckApiRunning = true;
        this.coreService.checkDaStudentConflict(studentIdsReq).subscribe(resp => {
            if (resp?.length) {
                this.isConflictStudentCheckApiRunning = false;
                let dastudentID = new Set<string>(...[resp]);
                this.studentMigrateData.filter(stu => stu.status === 'Import').forEach(stu => {
                    stu.daStudentIdExists = dastudentID.has(stu.updated.studentID || stu.studentID);
                });
                this.daStudentIdAllPresent = this.studentMigrateData.filter(stu => stu.status === 'Import').every(stu => stu.daStudentIdExists);
                if (this.daStudentIdAllPresent) {
                    this.markStudentStep(stepper);
                } else {
                    this.coreService.openSnackBar("Student IDs do not exist in this District. Please update these entries.", 5000, "error", "right", "top");
                }
                this.studentSort?.active === 'statusIcons' && this.onStudentSortChange();
            } else {
                this.coreService.openSnackBar("Student IDs do not exist in this District. Please update these entries.", 5000, "error", "right", "top");
                this.daStudentIdAllPresent = false;
                this.studentMigrateData.filter(stu => stu.status === 'Import').forEach(stu => stu.daStudentIdExists = false);
                this.isConflictStudentCheckApiRunning = false;
            }
        });
    }

    formatPreviewData() {
        //class - import/archive/delete - counts
        //student - import/delete - counts

        this.classCounts = {
            archive: 0,
            delete: 0,
            edited: 0,
            import: 0
        };
        this.classMigrateData.forEach(cls => {
            if (cls.status === 'Import') {
                this.classCounts.import++;
                cls.isChanged && this.classCounts.edited++;
            } else if (cls.status === 'Archive') {
                this.classCounts.archive++;
            } else {
                this.classCounts.delete++;
            }
        });
        this.studentCounts = {
            delete: 0,
            edited: 0,
            import: 0
        };
        this.studentMigrateData.forEach(stu => {
            if (stu.status === 'Import') {
                this.studentCounts.import++;
                stu.isChanged && this.studentCounts.edited++;
            } else {
                this.studentCounts.delete++;
            }
        });
    }

    validateStudentFormat(student: StudentMigrateDto) {
        let errors: string[] = [];
        let studentId = student.updated.studentID || student.studentID;
        if (studentId.length !== this.studentIdFormatProperties.minLength) {
            errors.push(`Incorrect Length, required - ${this.studentIdFormatProperties.minLength}`);
        }

        // if (studentId.length > this.studentIdFormatProperties.maxLength) {
        //     errors.push(`Incorrect Length, Maximum - ${this.studentIdFormatProperties.maxLength}`);
        // }

        if (this.studentIdFormatProperties.numbers === 'include' && !/\d/.test(studentId)) {
            errors.push("Should include numbers");
        } else if (this.studentIdFormatProperties.numbers === 'not include' && /\d/.test(studentId)) {
            errors.push("Should not include numbers");
        }

        if (this.studentIdFormatProperties.alphabets === 'include' && !/[a-zA-Z]/.test(studentId)) {
            errors.push("Should include letters");
        } else if (this.studentIdFormatProperties.alphabets === 'not include' && /[a-zA-Z]/.test(studentId)) {
            errors.push("Should not include letters");
        }

        if (this.studentIdFormatProperties.specialChars === 'include' && !this.specialCharsAllowed.test(studentId)) {
            errors.push("Should include character '-'");
        } else if (this.studentIdFormatProperties.specialChars === 'not include' && this.specialCharsAllowed.test(studentId)) {
            errors.push("Should not include character '-'");
        }

        // It should not contains any other chars than 0-9a-zA-Z and '-'
        if (!this.allowedChars.test(studentId)) {
            errors.push("Special Chars are not allowed" + (this.studentIdFormatProperties.specialChars === 'include' ? ", other than '-'" : ''));
        }

        student.formatInValid = !!errors.length;
        student.formatErrorsValues = errors.join("\n");
    }
}

interface ClassMigrateDto {
    guid: string;
    name: string;
    level: string;
    grade: string;
    metrics: {
        studentCount: number;
        conceptCount: number;
        lessonCount: number;
        assessmentCount: number;
    };
    isChanged: boolean;
    updated: {
        name?: string;
        level?: string;
        grade?: string;
    };
    status: 'Import' | 'Archive' | 'Delete';
    toolTipData: string;
    student: Student[];
}

interface StudentMigrateDto {
    guid: string;
    studentID: string;
    firstName: string;
    lastName: string;
    level: string;
    class: ClassMigrateDto;
    classLinked: boolean;
    dulipcateStudentId: boolean;
    daStudentIdExists: boolean | null;
    formatInValid: boolean;
    formatErrorsValues: string;
    isChanged: boolean;
    updated: {
        studentID?: string;
        firstName?: string;
        lastName?: string;
        level?: string;
    };
    toolTipData: string;
    status: 'Import' | 'Delete';
    statusValidCount: number;
}