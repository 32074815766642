import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { debounceTime, map, Observable, Subject, Subscription } from 'rxjs';
import { ClassData } from 'src/app/classes/classData';
import { invitedUserData } from 'src/app/classes/invitedUserData';
import { User } from 'src/app/classes/user';
import { CoreService } from 'src/app/core/core.service';
import { ConfirmDialogBox } from '../confirm-dialog/confirm-dialog.component';
import { TeacherDto } from 'src/app/classes/teachersDto';


@Component({
    selector: 'add-edit-invited-user',
    templateUrl: 'add-edit-invited-user.component.html',
    styleUrls: ['add-edit-invited-user.component.scss'],
})
export class AddEditInvitedUser implements OnInit, OnDestroy {
    private _subscription: Subscription[] = [];
    selectedClass!: ClassData;
    errorMessage = '';
    invitedUserData!: invitedUserData;
    userData!: User | null;
    type = ''; //add or edit
    title = '';
    inviteFormGroup!: UntypedFormGroup;
    roleControl!: UntypedFormControl;
    notesControl!: UntypedFormControl;
    readOnlyControl!: UntypedFormControl;

    hintMessage = '';
    isUpdateLoading = false;
    isRemoveLoading = false;
    teachersList!: TeacherDto[];
    teachersListFiltered!: TeacherDto[];
    isApiLoading = true;
    teacherFormControl!: FormControl<TeacherDto | string | null>;
    selectedTeacher!: TeacherDto | null;
    changeValueFlag = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<AddEditInvitedUser>,
        private coreService: CoreService,
        public dialog: MatDialog,
        private router: Router,
    ) {
        if (!this.coreService.selectedClass) {
            this.router.navigate(['dashboard']);
            return;
        }
        this.selectedClass = this.coreService.selectedClass;
        this.userData = this.coreService.userData;
        this.hintMessage = '';
        if (this.data['type'] === 'edit') {
            this.type = 'edit';
            this.invitedUserData = this.data['invitedUserData'];
            this.title = 'Edit User for ' + this.selectedClass.name;
        } else {
            this.title = 'Share ' + this.selectedClass.name;
            this.type = 'add';
        }
    }

    ngOnInit(): void {
        //removing Name search, user can be shared by direct email
        this.teacherFormControl = new FormControl<TeacherDto | string | null>(null, Validators.required);
        this.teacherFormControl.valueChanges.subscribe({
            next: value => {
                if (value && (typeof value === 'object')) {
                    this.selectedTeacher = value;
                    this.teacherFormControl.setValue(this.selectedTeacher?.teacherName, { emitEvent: false });
                    this.hintMessage = `School: ${this.selectedTeacher.school?.schoolName}`;
                } else if (value) {
                    let trimedValue: string = <string>value.trim();
                    this.selectedTeacher = null;
                    this.teachersListFiltered = this.teachersList.filter(tch => {
                        return tch.teacherName.toLowerCase().trim().indexOf(trimedValue.toLowerCase()) > -1 ||
                            tch.teacherEmail.toLowerCase().trim().indexOf(trimedValue.toLowerCase()) > -1;
                    });
                } else {
                    this.selectedTeacher = null;
                    this.teachersListFiltered = [...this.teachersList];
                }
            }
        });

        this.roleControl = new UntypedFormControl('', Validators.required);
        this.notesControl = new UntypedFormControl('');
        this.readOnlyControl = new UntypedFormControl(false, Validators.required);
        this.inviteFormGroup = new UntypedFormGroup({
            teacherFormControl: this.teacherFormControl,
            roleControl: this.roleControl,
            notesControl: this.notesControl,
            readOnlyControl: this.readOnlyControl
        });
        // Set Form values for Edit
        if (this.type === 'edit') {
            if (!this.invitedUserData.user) {
                this.teacherFormControl.setValue(this.invitedUserData.email, { emitEvent: false });
                this.hintMessage = 'IOG2 subscription not found';
            } else {
                this.teacherFormControl.setValue(this.invitedUserData.user.fullName, { emitEvent: false });
            }
            this.teacherFormControl.disable({ emitEvent: false });

            this.roleControl.setValue(this.invitedUserData.userRoleType);
            this.notesControl.setValue(this.invitedUserData.note);
            this.readOnlyControl.setValue(this.invitedUserData.readOnly);

            if (!this.selectedClass.owner) {
                this.roleControl.disable();
                this.readOnlyControl.disable();
            }
        }
        this.inviteFormGroup.valueChanges.subscribe({
            next: value => {
                this.changeValueFlag = true;
            }
        });
        this.getTeacherList();
    }
    ngOnDestroy(): void {
        this._subscription.forEach(sub => sub?.unsubscribe());
    }

    onCancel() {
        if (this.changeValueFlag) {
            const confirmDialog = this.dialog.open(ConfirmDialogBox, {
                data: {
                    message: `This will discard all changes. Continue?`,
                    confirmBtnLabel: "Yes",
                    confirmBtnClass: "lightRed",
                    cancelBtnLabel: "No",
                },
                disableClose: true,
            });
            let dialogSub = confirmDialog.afterClosed().subscribe({
                next: isConfirmed => {
                    if (isConfirmed) {
                        this.dialogRef.close(null);
                    }
                }
            });
            this._subscription.push(dialogSub);
        } else {
            this.dialogRef.close(null);
        }
    }

    getTeacherList() {
        this.isApiLoading = true;
        let apiSub = this.coreService.getTeachersListUnderDa().subscribe({
            next: resp => {
                this.teachersList = resp.filter(tch => tch.teacherId !== this.userData?.id);
                this.teachersListFiltered = [...this.teachersList];
                this.isApiLoading = false;
                if (this.type === 'edit' && this.invitedUserData.user) {
                    this.selectedTeacher = this.teachersList.find(tch => tch.teacherId === this.invitedUserData.user.id) || null;
                }
                if (this.selectedTeacher) {
                    this.hintMessage += 'School: ' + this.selectedTeacher.school?.schoolName;
                }
            },
            error: err => {
                console.log("Error while getting Teachers List", err);
                this.coreService.openSnackBar("Erroe occured while getting teachers list", 3000, "warn", "end", "bottom");
                this.onCancel();
            }
        });
        this._subscription.push(apiSub);
    }

    onAddUpdate() {
        if (this.inviteFormGroup.invalid) {
            this.inviteFormGroup.markAllAsTouched();
            return;
        }
        this.errorMessage = '';
        let shareClassData: SharedClassDto = {
            classId: this.selectedClass.guid,
            role: this.roleControl.value,
            readOnly: this.readOnlyControl.value
        };
        if (this.invitedUserData?.id) {
            shareClassData.id = this.invitedUserData?.id
        }
        if (this.type === 'add' && !this.selectedTeacher) {
            // clear teacher search if not selected
            this.teacherFormControl.setValue('', { emitEvent: false });
            this.teachersListFiltered  = [...this.teachersList];
            return;
        }
        shareClassData.email = this.selectedTeacher?.teacherEmail;

        if (this.notesControl.value) {
            shareClassData.note = this.notesControl.value;
        }
        this.isUpdateLoading = true;
        let updateShareClassApiSub = this.coreService.addUpdateSharedClassInvite(shareClassData).subscribe({
            next: resp => {
                let snackMessage = 'Updated Successfully';
                let messageTheme: 'primary' | 'accent' = 'primary';
                let duration = 3000;
                if (this.type !== 'edit') {
                    if (resp.user) {
                        snackMessage = 'Shared Successfully';
                    } else {
                        snackMessage = 'No active IOG2 subscription found for User, Email Invitation sent';
                        messageTheme = 'accent';
                        duration = 8000;
                    }
                }
                this.coreService.openSnackBar(snackMessage, duration, messageTheme, "end", "bottom");
                this.isUpdateLoading = false;
                this.dialogRef.close('userUpdated');
            },
            error: (err) => {
                console.log(err);
                this.isUpdateLoading = false;
                if (err?.message) {
                    let msg;
                    try {
                        msg = JSON.parse(err.message);
                    } catch (ex) {
                        console.log(ex);
                    }
                    this.errorMessage = msg?.error || 'Error Occured, Please try later';
                } else {
                    this.errorMessage = 'Error Occured, Please try later';
                }
            },
        });
        this._subscription.push(updateShareClassApiSub);
    }


    removeInvitedUser() {
        this.errorMessage = '';
        let unlinkClass = !this.selectedClass.owner;
        let userName = this.invitedUserData.user?.fullName || this.invitedUserData.name || this.invitedUserData.email;
        let confirmDialogBox = this.dialog.open(ConfirmDialogBox, {
            data: {
                message: `Are you sure you want to ${unlinkClass ? 'unlink' : 'remove'} <b> ${unlinkClass ? 'current class' : userName} </b> ?`,
                confirmBtnClass: 'lightRed',
                confirmBtnLabel: unlinkClass ? 'Unlink' : 'Remove'
            },
            disableClose: true,
        });
        let confirmSub = confirmDialogBox.afterClosed().subscribe({
            next: data => {
                if (data) {
                    this.isRemoveLoading = true;
                    let deleteInviteApiSub = this.coreService
                        .deleteSharedClass(this.invitedUserData.id)
                        .subscribe({
                            next: (res) => {
                                this.coreService.openSnackBar(unlinkClass ? 'Class unlinked' : `${userName} user removed`, 3000, "warn", "end", "bottom");
                                this.isRemoveLoading = false;
                                if (unlinkClass) {
                                    this.coreService.selectedClass = null;
                                    this.dialogRef.close();
                                    this.router.navigate(['dashboard']);
                                } else {
                                    this.dialogRef.close('userRemoved');
                                }
                            },
                            error: (err) => {
                                console.log(err);
                                this.isRemoveLoading = false;
                                this.errorMessage = 'Error Occured, Please try later';
                            },
                        });
                    this._subscription.push(deleteInviteApiSub);
                }
            }
        });
        this._subscription.push(confirmSub);


    }
}

export interface SharedClassDto {
    id?: string;
    classId: string;
    userId?: string;
    email?: string;
    name?: string;
    role: string;
    note?: string;
    readOnly: boolean;
}