import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { debounceTime, map, Subject, Subscription } from "rxjs";
import { Student } from "src/app/classes/student";
import { TeacherDto } from "src/app/classes/teachersDto";
import { User } from "src/app/classes/user";
import { CoreService } from "src/app/core/core.service";
import { ConfirmDialogBox } from "../confirm-dialog/confirm-dialog.component";

@Component({
    selector: 'add-invite-student',
    templateUrl: 'add-invite-student.component.html',
    styleUrls: ['add-invite-student.component.scss']
})
export class AddInviteStudentDialog implements OnInit, OnDestroy {
    studentData!: Student[];
    editStudentData!: Student;
    title = "";
    userData!: User | null;
    errorMessage = '';
    userSeaching = false;
    hintMessage = '';

    inviteFormGroup!: UntypedFormGroup;
    roleControl!: UntypedFormControl;
    notesControl!: UntypedFormControl;
    readOnlyControl!: UntypedFormControl;
    private _subscription: Subscription[] = [];
    isSharing: boolean = false;
    isDeleting: boolean = false;

    teachersList!: TeacherDto[];
    teachersListFiltered!: TeacherDto[];
    isApiLoading = true;
    teacherFormControl!: FormControl<TeacherDto | string | null>;
    selectedTeacher!: TeacherDto | null;
    changeValueFlag = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<AddInviteStudentDialog>,
        private coreService: CoreService,
        public dialog: MatDialog,
    ) {
        if(data['type'] === 'edit' && data['editStudentData']) {
            this.editStudentData = data['editStudentData'];
            this.title = 'Edit Share for ' + (this.editStudentData.firstName || '') + ' ' + (this.editStudentData.lastName || '') + ' - ' + this.editStudentData.studentID;
            this.isApiLoading = false;
        } else if (data['studentList']?.length) {
            this.studentData = data['studentList'];
            this.title = this.studentData.length === 1 ? 'Share Student ' + (this.studentData[0].firstName || '') + ' ' + (this.studentData[0].lastName || '') + ' - ' + this.studentData[0].studentID : `Share Students - ${this.studentData.length} selected`;
        } else {
            this.dialogRef.close();
        }
        this.hintMessage = '';
        this.userData = this.coreService.userData;
    }

    ngOnInit(): void {
        if(this.editStudentData) {
            // Flow for Edit Student
            let sharedTeacherInfo = this.editStudentData.sharedUsers[0];
            this.teacherFormControl = new FormControl<string | null>(sharedTeacherInfo.user?.fullName || sharedTeacherInfo.email || '', Validators.required);
            this.teacherFormControl.disable();
            this.hintMessage = `Email: ${sharedTeacherInfo.email}`;
            this.roleControl = new UntypedFormControl(sharedTeacherInfo.userRoleType, Validators.required);
            this.readOnlyControl = new UntypedFormControl(sharedTeacherInfo.readOnly, Validators.required);
            this.inviteFormGroup = new UntypedFormGroup({
                teacherFormControl: this.teacherFormControl,
                roleControl: this.roleControl,
                readOnlyControl: this.readOnlyControl
            });
            this.inviteFormGroup.valueChanges.subscribe({
                next: value => {
                    this.changeValueFlag = true;
                }
            });
            return;
        }
        this.teacherFormControl = new FormControl<TeacherDto | string | null>(null, Validators.required);
        this.teacherFormControl.valueChanges.subscribe({
            next: value => {
                if (value && (typeof value === 'object')) {
                    this.selectedTeacher = value;
                    this.teacherFormControl.setValue(this.selectedTeacher?.teacherName, { emitEvent: false });
                    this.hintMessage = `School: ${this.selectedTeacher.school?.schoolName}, Email: ${this.selectedTeacher?.teacherEmail}`;
                } else if (value) {
                    let trimedValue: string = <string>value.trim();
                    this.selectedTeacher = null;
                    this.teachersListFiltered = this.teachersList.filter(tch => {
                        return tch.teacherName.toLowerCase().trim().indexOf(trimedValue.toLowerCase()) > -1 ||
                            tch.teacherEmail.toLowerCase().trim().indexOf(trimedValue.toLowerCase()) > -1;
                    });
                } else {
                    this.selectedTeacher = null;
                    this.teachersListFiltered = [...this.teachersList];
                }
            }
        });

        this.roleControl = new UntypedFormControl('', Validators.required);
        this.notesControl = new UntypedFormControl('');
        this.readOnlyControl = new UntypedFormControl(false, Validators.required);
        this.inviteFormGroup = new UntypedFormGroup({
            teacherFormControl: this.teacherFormControl,
            roleControl: this.roleControl,
            notesControl: this.notesControl,
            readOnlyControl: this.readOnlyControl
        });
        this.inviteFormGroup.valueChanges.subscribe({
            next: value => {
                this.changeValueFlag = true;
            }
        });
        this.getTeacherList();
    }


    ngOnDestroy(): void {
        this._subscription.forEach(sub => sub?.unsubscribe());
    }

    onCancel() {
        if (this.changeValueFlag) {
            const confirmDialog = this.dialog.open(ConfirmDialogBox, {
                data: {
                    message: `This will discard all changes. Continue?`,
                    confirmBtnLabel: "Yes",
                    confirmBtnClass: "lightRed",
                    cancelBtnLabel: "No",
                },
                disableClose: true,
            });
            let dialogSub = confirmDialog.afterClosed().subscribe({
                next: isConfirmed => {
                    if (isConfirmed) {
                        this.dialogRef.close(null);
                    }
                }
            });
            this._subscription.push(dialogSub);
        } else {
            this.dialogRef.close(null);

        }
    }

    getTeacherList() {
        this.isApiLoading = true;
        let apiSub = this.coreService.getTeachersListUnderDa().subscribe({
            next: resp => {
                this.teachersList = resp.filter(tch => tch.teacherId !== this.userData?.id);
                this.teachersListFiltered = [...this.teachersList];
                this.isApiLoading = false;
            },
            error: err => {
                console.log("Error while getting Teachers List", err);
                this.coreService.openSnackBar("Erroe occured while getting teachers list", 3000, "warn", "end", "bottom");
                this.onCancel();
            }
        });
        this._subscription.push(apiSub);
    }

    onShare() {
        if (this.inviteFormGroup.invalid) {
            this.inviteFormGroup.markAllAsTouched();
            return;
        }
        if (!this.selectedTeacher) {
            this.teacherFormControl.setValue('', { emitEvent: false });
            this.teachersListFiltered = [...this.teachersList];
            return;
        }
        this.errorMessage = '';
        let shareDtoList = this.studentData.
            filter(stu => !stu.sharedUsers || !stu.sharedUsers?.find(shr => shr.user?.email === this.selectedTeacher?.teacherEmail)).
            map(stu => {
                let shareStudenDto: shareStudenDto = {
                    classId: this.coreService.selectedClass?.guid || '',
                    note: this.notesControl.value,
                    readOnly: this.readOnlyControl.value,
                    role: this.roleControl.value,
                    studentId: stu.guid,
                }
                shareStudenDto.email = this.selectedTeacher?.teacherEmail;
                return shareStudenDto;
        });
        if(!shareDtoList?.length) {
            this.coreService.openSnackBar("Student already shared with this teacher", 3000, "warn", "end", "bottom");
            return;
        }
        this.isSharing = true;
        let apiCallSub = this.coreService.shareStudent(shareDtoList).subscribe({
            next: resp => {
                this.coreService.openSnackBar("Successfully Shared", 3000, "primary", "end", "bottom");
                this.dialogRef.close('Shared');
            },
            error: (err) => {
                console.log(err);
                this.errorMessage = 'Error Occured, Please try later';
                this.isSharing = false;
            }
        });
        this._subscription.push(apiCallSub);
    }

    onUpdateShare() {
        this.isSharing = true;
        let sharedTeacherInfo = this.editStudentData.sharedUsers[0];
        let updateShareDto = {
            id: sharedTeacherInfo.id,
            role: this.roleControl.value,
            readOnly: !!this.readOnlyControl.value,
            type: 'updated'
        };
        let apiSub = this.coreService.updateSharedStudent(updateShareDto).subscribe({
            next: resp => {
                this.coreService.openSnackBar("Updated Successfully", 3000, "primary", "end", "bottom");
                this.dialogRef.close(updateShareDto);
            },
            error: (err) => {
                console.log(err);
                this.errorMessage = 'Error Occured, Please try later';
                this.isSharing = false;
            }
        });
        this._subscription.push(apiSub);
    }

    confirmDeleteShare() {
        let sharedTeacherInfo = this.editStudentData.sharedUsers[0];
        let confirmDialogBox = this.dialog.open(ConfirmDialogBox, {
            data: {
                message: 'Are you sure you want remove student access from <b>' + (sharedTeacherInfo.user?.fullName || sharedTeacherInfo.name || sharedTeacherInfo.email) + ' </b> ?',
                confirmBtnClass: 'lightRed',
                confirmBtnLabel: 'Remove Access'
            },
            disableClose: true,
        });
        let confirmDialogSub = confirmDialogBox.afterClosed().subscribe({
            next: data => {
                if (data) {
                    this.deleteStudentShared();
                }
            }
        });
        this._subscription.push(confirmDialogSub);
    }

    deleteStudentShared() {
        this.isDeleting = true;
        let sharedTeacherInfo = this.editStudentData.sharedUsers[0];

        let updateShareDto = {
            id: sharedTeacherInfo.id,
            type: 'deleted'
        };

        let deleteSharedStudentApiSub = this.coreService.deleteSharedStudent(sharedTeacherInfo.id).subscribe({
            next: resp => {
                this.isDeleting = false;
                this.coreService.openSnackBar("Access removed", 3000, "accent", "end", "bottom");
                this.dialogRef.close(updateShareDto);
            },
            error: err => {
                console.log(err);
                this.coreService.openSnackBar("Error Occured, please try later", 3000, "warn", "end", "bottom");
            }
        });
        this._subscription.push(deleteSharedStudentApiSub);
    }
}

export interface shareStudenDto {
    classId: string,
    note?: string,
    readOnly: boolean,
    role: string,
    studentId: string,
    userId?: string,
    email?: string,
    name?: string
}