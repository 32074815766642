import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription } from 'rxjs';
import { ClassData } from 'src/app/classes/classData';
import { User } from 'src/app/classes/user';
import { CoreService } from 'src/app/core/core.service';
@Component({
  selector: 'class-display',
  templateUrl: './class-display.component.html',
  styleUrls: ['./class-display.component.scss']
})
export class ClassDisplayComponent implements OnDestroy {
  classDataList!: ClassData[];
  userData: User | null;
  private _subscription: Subscription;
  errorMessage = '';
  readOnlyIconPath = "./assets/images/icons/lock_icon.svg";
  sharedIconPath = "./assets/images/icons/share_icon.svg";
  canAddClass = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ClassDisplayComponent>,
    private coreService: CoreService) {

    this.userData = this.coreService.userData;
    let addClassRule = this.userData?.propertiesByRule && this.userData.propertiesByRule['TEACHER_CLASS_CREATE'];
    this.canAddClass = (data.type === 'classManagement') ? (addClassRule ? JSON.parse(addClassRule.value) : true) : false;

    this._subscription = this.coreService.getAllClassData().subscribe({
      next: classData => {
        this.classDataList = classData.sort((a, b) => a.name.localeCompare(b.name));
      },
      error: err => {
        this.errorMessage = 'Some error occured. Please try later';
        console.log(err);
      }
    });
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  onCancel() {
    this.dialogRef.close(null);
  }

  onAddCLass() {
    if (!this.canAddClass) {
      this.onCancel();
      return;
    }
    this.dialogRef.close("AddNewClass");
  }

  onSelectCLass(selectedClass: ClassData) {
    this.dialogRef.close(selectedClass);
  }
}