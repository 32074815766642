<div class="transferStudent">
    <app-dialog-title [title]="'Transfer Student'"></app-dialog-title>

    <div class="transferContainer" *ngIf="!isTransferComplete">
        <div class="studentContainer">
            <h3>Selected Student(s)</h3>
            <div class="studentList">
                <div *ngFor="let stu of transferStudents">
                    <span class="studentId"> {{stu.studentID}} </span> - {{ stu.firstName || ''}} {{stu.lastName || ''}}
                </div>
            </div>
        </div>
        <div class="classList">
            <mat-progress-bar mode="query" *ngIf="isGetClassApiRunning"></mat-progress-bar>
            <h3>Transfer to Class <span> (please select a class)</span></h3>
            <mat-selection-list #classSelecion [multiple]="false">
                <mat-list-option *ngFor="let class of classList" [value]="class">
                    {{class.name}}
                </mat-list-option>
            </mat-selection-list>
        </div>

    </div>
    <div class="transferResultContainer" *ngIf="isTransferComplete">
        <table>
            <thead>
                <tr>
                    <th>Student Id</th>
                    <th>Result</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let stu of resultData" [ngClass]="stu[1] === 'Transferred' ? '' : 'error'">
                    <td>{{stu[2]}}</td>
                    <td>{{stu[1]}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <mat-dialog-actions class="btnClass">
        <app-button [content]="isTransferComplete ? 'Close' : 'Cancel'" [isDisabled]="isTransfering" (click)="onCancel()"></app-button>
        <app-button [content]="'Transfer'" [btnClasses]="'blue'" *ngIf="!isTransferComplete" [isDisabled]="isTransfering" [isLoading]="isTransfering" (click)="transferStudent()"></app-button>
    </mat-dialog-actions>
</div>