import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CoreService } from "src/app/core/core.service";

@Component({
    selector: 'confirm-concept-delete',
    templateUrl: 'confirm-concept-delete.component.html',
    styleUrls: ['confirm-concept-delete.component.scss']
})
export class ConfirmConceptDeleteDialogBox {
    cancelBtnLabel = 'Cancel';
    cancelBtnClass = '';
    confirmBtnLabel = 'Confirm';
    confirmBtnClass = '';
    message = 'are you sure?';
    isLoading = false;
    conceptUrlName = '';
    materialId = '';
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ConfirmConceptDeleteDialogBox>,
        private coreService: CoreService
    ) {
        this.message = this.data['message'] || this.message;
        this.confirmBtnClass = this.data['confirmBtnClass'];
        this.confirmBtnLabel = this.data['confirmBtnLabel'];
        this.conceptUrlName = this.data['conceptUrlName'];
        this.materialId = this.data['materialId'];
    }

    onCancel() {
        this.dialogRef.close(false);
    }

    onConfirm() {
        this.isLoading = true;
        this.coreService.deleteConceptInLesson(this.conceptUrlName, this.materialId).subscribe({
            next: resp => {
                this.dialogRef.close('deleted');
                this.isLoading = false;
            },
            error: err => {
                this.dialogRef.close(err);
                this.isLoading = false;
            }
        });
    }
}