import { Component } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { CoreService } from "src/app/core/core.service";

@Component({
    selector: 'terms-and-services',
    templateUrl: 'terms_and_services.component.html',
    styleUrls: ['terms_and_services.component.scss']
})
export class TermsAndServicesComponent {
    acceptTermsControl = new UntypedFormControl(false, Validators.required);
    acceptPrivacyControl = new UntypedFormControl(false, Validators.required);
    isApiLoading = false;
    constructor(
        public dialogRef: MatDialogRef<TermsAndServicesComponent>,
        private coreService: CoreService
    ) {
        localStorage.removeItem("userData");
    }

    onAcceptTerms() {
        this.acceptTermsControl.markAsTouched();
        this.acceptPrivacyControl.markAsTouched();

        if(!this.acceptTermsControl.value) {
            let acceptTermsCheckbox = document.getElementById("acceptTerms");
            try {
                acceptTermsCheckbox?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
            } catch (e) {
                console.log(e);
                alert("Please Accept Terms of Service");
            }
            return;
        }
        if(!this.acceptPrivacyControl.value) {
            let acceptPrivacyCheckbox = document.getElementById("acceptPrivacy");
            try {
                acceptPrivacyCheckbox?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
            } catch (e) {
                console.log(e);
                alert("Please Accept Privacy Policy");
            }
            return;
        }
        this.isApiLoading =  true;
        this.coreService.acceptTemrsOfService().subscribe({
            next: resp => {
                this.isApiLoading = false;
                if(this.coreService.userData) {
                    this.coreService.userData.termsAccepted = true;
                }
                localStorage.setItem("userData", JSON.stringify(this.coreService.userData));
                this.onClose();
            }, 
            error: err => {
                this.isApiLoading = false;
                alert("Some error occured, please try again later");
                this.onClose();
            }
        });
    }

    onClose() {
        this.dialogRef.close();
    }
}