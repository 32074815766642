import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ClassData } from 'src/app/classes/classData';
import { CoreService } from 'src/app/core/core.service';
import { ArchiveClassDialog } from '../archive-class/archive-class.component';
@Component({
    selector: 'class-archived',
    templateUrl: './class-archived.component.html',
    styleUrls: ['./class-archived.component.scss']
})
export class ClassArchivedDisplayComponent implements OnDestroy {
    classDataList!: ClassData[] | null;
    title = 'Restore Archived Class';
    private _subscription: Subscription[] = [];
    errorMessage = '';
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ClassArchivedDisplayComponent>,
        public dialog: MatDialog,
        private coreService: CoreService) {
            this.getUnArchivedClass();
    }
    getUnArchivedClass() {
        this.classDataList = null;
        let apiSub = this.coreService.getAllArchivedClassData().subscribe({
            next: classData => {
                this.classDataList = classData.sort((a, b) => a.name.localeCompare(b.name));
            },
            error: err => {
                this.errorMessage = 'Some error occured. Please try later';
                console.log(err)
            }
        });
        this._subscription.push(apiSub);
    }
    ngOnDestroy(): void {
        this._subscription?.forEach(sub => sub && sub.unsubscribe());
    }

    confirmUnArchiveClass(selectedClass: ClassData) {
        const archiveClassDialogef = this.dialog.open(ArchiveClassDialog, {
            data: {
                type: 'Restore',
                classData: selectedClass
            },
            disableClose: true,
            panelClass: ['dialogOverflow']
        });
        let archivesub = archiveClassDialogef.afterClosed().subscribe({
            next: data => {
                if(data) {
                    this.getUnArchivedClass();
                }
            }
        });
        this._subscription.push(archivesub);
    }

    onCancel() {
        this.dialogRef.close(null);
    }
}