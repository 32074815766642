import { Component, OnDestroy } from "@angular/core";
import { UntypedFormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { ConceptMaterial } from "src/app/classes/concept";
import { CoreService } from "src/app/core/core.service";
import { ConfirmDialogBox } from "../confirm-dialog/confirm-dialog.component";

@Component({
    selector: 'concept-dialog',
    templateUrl: 'concept-display.component.html',
    styleUrls: ['concept-display.component.scss']
})
export class ConceptDisplay implements OnDestroy {
    conceptList!: ConceptMaterial[];
    selectedConceptControl = new UntypedFormControl('', Validators.required);
    descriptionControl: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.maxLength(40)]);
    private _subscription: Subscription[] = [];
    isSaveInProgress = false;
    formGroup!: FormGroup;
    changeValueFlag = false;
    constructor(
        public dialogRef: MatDialogRef<ConceptDisplay>,
        private coreService: CoreService,
        private dialog: MatDialog,
    ) {
        this.getConcepts();
        let descChangeSub = this.descriptionControl.valueChanges.subscribe({
            next: (value: string) => {
                if (this.descriptionControl.errors?.['maxlength']) {
                    this.descriptionControl.setValue(value.substring(0, 40));
                }
            }
        });
        this._subscription.push(descChangeSub);
        this.formGroup =  new FormGroup({
            concept: this.selectedConceptControl,
            description: this.descriptionControl
        });
        this.formGroup.valueChanges.subscribe({
            next: value => {
                this.changeValueFlag = true;
            }
        });
    }

    ngOnDestroy(): void {
        this._subscription?.forEach(sub => sub?.unsubscribe());
    }

    getConcepts() {
        let apiSub = this.coreService.getConceptMaterial().subscribe({
            next: resp => this.conceptList = resp
        });
        this._subscription.push(apiSub);
    }
    onCancel() {
        if (this.changeValueFlag) {
            const confirmDialog = this.dialog.open(ConfirmDialogBox, {
                data: {
                    message: `This will discard all changes. Continue?`,
                    confirmBtnLabel: "Yes",
                    confirmBtnClass: "lightRed",
                    cancelBtnLabel: "No",
                },
                disableClose: true,
            });
            let dialogSub = confirmDialog.afterClosed().subscribe({
                next: isConfirmed => {
                    if (isConfirmed) {
                        this.dialogRef.close(null);
                    }
                }
            });
            this._subscription.push(dialogSub);
        } else {
            this.dialogRef.close(null);
        }
    }

    onAddLesson() {
        if (this.descriptionControl.errors || this.selectedConceptControl.errors) {
            this.descriptionControl.markAsTouched();
            this.selectedConceptControl.markAsTouched();
            this.coreService.openSnackBar("Report Title & Concept Required", 3000, "warn", "end", "bottom");
            return;
        }
        if(!this.descriptionControl.value.trim()) {
            this.descriptionControl.setValue('');
            this.coreService.openSnackBar("Report Title should not be empty", 3000, "warn", "end", "bottom");
            return;
        }
        this.isSaveInProgress = true;
        let data = {
            classId: this.coreService.selectedClass?.guid,
            conceptId: this.selectedConceptControl.value.guid,
            title: this.descriptionControl.value,
            userId: this.coreService.userData?.id
        }
        this.coreService.createNewLessonBuilder(data).subscribe({
            next: resp => {
                this.coreService.openSnackBar("Lesson Created", 3000, "primary", "end", "bottom");
                this.dialogRef.close(resp);
                this.isSaveInProgress = false;
            },
            error: err => {
                console.log(err);
                this.coreService.openSnackBar("Some error occurred", 3000, "warn", "end", "bottom");
                this.isSaveInProgress = false;
            }
        })
    }
}
