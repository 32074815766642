import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'app-button',
  templateUrl: './app-button.component.html',
  styleUrls: ['./app-button.component.scss']
})
export class AppButtonComponent {

  @Input() content!: string;
  @Input() btnClasses: string = '';
  @Input() isDisabled: boolean = false;
  @Input() iconPath!: string;
  @Input() isLoading: boolean = false;

  @Output() onClick: EventEmitter<any> = new EventEmitter();
  constructor() { }

  onImageClicked(event: any) {
    if (this.isDisabled && event && event.stopPropagation) {
      event.stopPropagation();
    }
  }

  btnClick(event: any) {
    if (this.isDisabled && event && event.stopPropagation) {
      event.stopPropagation();
      return;
    }
    this.onClick.emit(null);
  }
}