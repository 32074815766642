import { Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'src/app/classes/user';
import { CoreService } from 'src/app/core/core.service';
import { LoginService } from 'src/app/core/login.service';
@Component({
    selector: 'app-convert-district',
    templateUrl: 'app-convert-district.component.html',
    styleUrls: ['app-convert-district.component.scss']
})
export class ConvertToDistrict implements OnDestroy {
    private _subscription: Subscription[];
    asanaFormLink = "https://form.asana.com/?k=PZwPl7IN0qFqkvUjsJaL1g&d=489609068781169";
    userData: User;
    errorMessage!: string;
    apiRunning = false;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ConvertToDistrict>,
        private loginService: LoginService,
        private coreService: CoreService,
        private router: Router,) {
        this._subscription = [];
        this.userData = data['userData'];
    }

    ngOnDestroy(): void {
        this._subscription?.forEach(sub => sub && sub.unsubscribe());
    }

    onCancel() {
        window.open(this.asanaFormLink, '_blank', 'noopener,resizable,scrollbars,popup=false');
        this.dialogRef.close(null);
    }

    onNotNow() {
        // do notNowIncreament api call - which increament the counter
        //then close the dialog with value so user can login as usual
        this.dialogRef.close('notNow');
    }

    acceptMigrate() {
        let tmpUser: User = new User();
        tmpUser.token = this.userData.token;
        this.coreService.userData = tmpUser;
        this.loginService.userData = tmpUser;
        this.dialogRef.close('migrate');
        this.router.navigateByUrl('/migrate', {
            state: {
                userData: this.userData
            }
        });
    }
}
