import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AngularMaterialModule } from '../angular-material.module';
import { LogoComponent } from './components/logo/logo.component';
import { DashboardComponent } from './components/dashboard/app-dashboard.component';
import { NgxTeacherstudentModule } from 'ngx-teacher-student';
import { ClassDisplayComponent } from './components/dialog-box/class-display/class-display.component';
import { AppButtonComponent } from './components/app-button/app-button.component';
import { ConceptComponent } from './components/app-concept/app-concept.component';
import { ClassDetailComponent } from './components/class-detail/class-detail.component';
import { AddEditClassComponent } from './components/dialog-box/add-edit-class/add-edit-class.component';
import { StudentDetailComponent } from './components/student-detail/student-detail.component';
import { ArchiveClassDialog } from './components/dialog-box/archive-class/archive-class.component';
import { AddEditInvitedUser } from './components/dialog-box/add-edit-invited-user/add-edit-invited-user.component';
import { ConfirmDialogBox } from './components/dialog-box/confirm-dialog/confirm-dialog.component';
import { LessonBuilder } from './components/app-lesson-builder/app-lesson-builder.component';
import { ConceptDisplay } from './components/dialog-box/concept-display/concept-display.component';
import { EditStudentDialog } from './components/dialog-box/edit-student/edit-student.component';
import { AddInviteStudentDialog } from './components/dialog-box/add-edit-invite-student/add-invite-student.component';
import { TransferStudentDialog } from './components/dialog-box/transfer-student/transfer-student.component';
import { ThreePartDrillComponent } from './components/app-concept-custom/app-3part-drill/app-3part-drill.component';
import { HttpInterceptor } from './httpInterceptor.service';
import { DictationWordsSentencesComponent } from './components/app-concept-custom/app-dictation/app-dictation.component';
import { SavedConceptDialog } from './components/dialog-box/saved-concept-dialog/saved-concept-dialog.component';
import { NewConceptComponent } from './components/app-concept-custom/app-new-concept/app-new-concept.component';
import { FluencyCustomComponent } from './components/app-concept-custom/app-fluency/app-fluency.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { RedWordsCustomComponent } from './components/app-concept-custom/app-red-words/app-red-words.component';
import { StudentAssessmentComponent } from './components/student-assessment/student-assessment.component';
import { ReportSelectionDialog } from './components/dialog-box/report-selection/report-selection.component';
import { LetterFormationComponent } from './components/app-concept-custom/app-letter-formation/app-letter-formation.component';
import { DistrictWarningComponent } from './components/dialog-box/district-warning/district-warning.component';
import { DictationHelper } from './components/dialog-box/dictation-helper/dictation-helper.component';
import { SyllableOpenCloseComponent } from './components/app-concept-custom/app-syllable-open-close/syllable-open-close.component';
import { SyllableDecodingWordsComponent } from './components/app-concept-custom/app-syllable-decoding-words/syllable-decoding-words.component';
import { RedWordsDialogComponent } from './components/dialog-box/red-words-custom/red-word-dialog.component';
import { LessonPrintComponent } from './components/app-lesson-print/lesson-print.component';
import { ConfirmConceptDeleteDialogBox } from './components/dialog-box/confirm-concept-delete/confirm-dialog.component';
import { ClassArchivedDisplayComponent } from './components/dialog-box/class-display-archived/class-archived.component';
import { LessonListDialog } from './components/dialog-box/lesson-list/lesson-list.component';
import { ShowSharedNotification } from './components/dialog-box/show-shared-notification/show-shared-notification.component';
import { TermsAndServicesComponent } from './components/dialog-box/terms_and_services/terms_and_services.component';
import { MfaVerifyDialog } from './components/dialog-box/mfa-code-verify/app-mfa-verify.component';
import { ConvertToDistrict } from './components/dialog-box/app-convert-district/app-convert-district.component';
import { MigrateToDaComponent } from './components/app-migrate-to-district/app-migrate-to-district.component';
import { EditClassMigrateComponent } from './components/dialog-box/edit-class-migrate/edit-class-migrate.component';
import { EditStudentMigrateComponent } from './components/dialog-box/edit-student-migrate/edit-student-migrate.component';

@NgModule({
  declarations: [
    LogoComponent,
    DashboardComponent,
    ClassDisplayComponent,
    AppButtonComponent,
    ConceptComponent,
    ClassDetailComponent,
    LessonBuilder,
    ThreePartDrillComponent,
    DictationWordsSentencesComponent,
    NewConceptComponent,
    FluencyCustomComponent,
    RedWordsCustomComponent,
    StudentAssessmentComponent,
    LetterFormationComponent,
    SyllableOpenCloseComponent,
    SyllableDecodingWordsComponent,
    LessonPrintComponent,
    MigrateToDaComponent,
    //dialogs
    ConceptDisplay,
    EditStudentDialog,
    AddInviteStudentDialog,
    TransferStudentDialog,
    AddEditClassComponent,
    StudentDetailComponent,
    ArchiveClassDialog,
    AddEditInvitedUser,
    ConfirmDialogBox,
    ReportSelectionDialog,
    SavedConceptDialog,
    DictationHelper,
    DistrictWarningComponent,
    RedWordsDialogComponent,
    ConvertToDistrict,
    MfaVerifyDialog,
    TermsAndServicesComponent,
    ConfirmConceptDeleteDialogBox,
    ClassArchivedDisplayComponent,
    LessonListDialog,
    ShowSharedNotification,
    EditClassMigrateComponent,
    EditStudentMigrateComponent
  ],
  exports: [
    LogoComponent,
    DashboardComponent,
    AppButtonComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxTeacherstudentModule,
    AngularMaterialModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInterceptor,
    multi: true
  },
  { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } }]

})
export class CoreModule { }