import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ClassData } from "src/app/classes/classData";
import { invitedUserData } from "src/app/classes/invitedUserData";
import { CoreService } from "src/app/core/core.service";
import { Subscription } from 'rxjs';
import * as _moment from 'moment';

const moment = _moment;

@Component({
    templateUrl: 'show-shared-notification.component.html',
    styleUrls: ['show-shared-notification.component.scss']
})
export class ShowSharedNotification {
    classSharedUserList: invitedUserData[] = [];
    studentSharedUserList: invitedUserData[] = [];
    classWithAssessments: ClassData[] = [];
    classAssessmentDetails!: classAssessmentDates[];
    currentDay = moment();
    private _subcription: Subscription[] = [];
    loader = true;
    markAllLoading = false;
    constructor(
        public dialogRef: MatDialogRef<ShowSharedNotification>,
        private coreService: CoreService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        let getClassApiSub = this.coreService.getAllClassData().subscribe({
            next: classData => {
                let classDataList = classData.sort((a, b) => a.name.localeCompare(b.name));
                this.classWithAssessments = classDataList.filter(a => a.initialAssessmentDate || a.midAssessmentDate || a.finalAssessmentDate);
                this.getNewSharedClass();
            },
            error: err => {
                console.log(err);
                this.coreService.openSnackBar("Error Occured while getting Class", 3000, "warn", "end", "bottom");
                this.loader = false;
                this.onCancel();
            }
        });
        this._subcription.push(getClassApiSub);
    }

    getNewSharedClass() {
        let getNotificationSub = this.coreService.getSharedClassForNotify().subscribe({
            next: newSharedClass => {
                this.classSharedUserList = newSharedClass;
                this.getNewSharedStudent();
            },
            error: err => {
                console.log(err);
                this.coreService.openSnackBar("Error Occured while getting Class Shared details", 3000, "warn", "end", "bottom");
                this.loader = false;
                this.onCancel();
            }
        });
        this._subcription.push(getNotificationSub);
    }

    getNewSharedStudent() {
        let getNotificationSub = this.coreService.getSharedStudentForNotify().subscribe({
            next: resp => {
                this.studentSharedUserList = resp;
                this.loader = false;
                this.setUpData();
            },
            error: err => {
                console.log(err);
                this.coreService.openSnackBar("Error Occured while getting Student Shared details", 3000, "warn", "end", "bottom");
                this.loader = false;
                this.onCancel();
            }
        });
        this._subcription.push(getNotificationSub);
    }

    setUpData() {
        this.classSharedUserList.every((shr: invitedUserData) => {
            shr.isLoading = false;
        });

        this.studentSharedUserList.every((shr: invitedUserData) => {
            shr.isLoading = false;
        });
        let initialAssessments: classAssessmentDates[] = this.classWithAssessments.filter(a => a.initialAssessmentDate).map(a => {
            return {
                guid: a.guid,
                name: a.name,
                type: 'Initial',
                assessmentDate: a.initialAssessmentDate,
                assessmentInDay: moment(a.initialAssessmentDate).diff(this.currentDay, 'days'),
                classData: a
            }
        }).filter(a => a.assessmentInDay < 7 && a.assessmentInDay >= 0);
        let midAssessments: classAssessmentDates[] = this.classWithAssessments.filter(a => a.midAssessmentDate).map(a => {
            return {
                guid: a.guid,
                name: a.name,
                type: 'Mid',
                assessmentDate: a.midAssessmentDate,
                assessmentInDay: moment(a.midAssessmentDate).diff(this.currentDay, 'days'),
                classData: a
            }
        }).filter(a => a.assessmentInDay < 7 && a.assessmentInDay >= 0);
        let finalAssessments: classAssessmentDates[] = this.classWithAssessments.filter(a => a.finalAssessmentDate).map(a => {
            return {
                guid: a.guid,
                name: a.name,
                type: 'Final',
                assessmentDate: a.finalAssessmentDate,
                assessmentInDay: moment(a.finalAssessmentDate).diff(this.currentDay, 'days'),
                classData: a
            }
        }).filter(a => a.assessmentInDay < 7 && a.assessmentInDay >= 0);
        this.classAssessmentDetails = [...initialAssessments, ...midAssessments, ...finalAssessments]
            .sort((a, b) => a.assessmentInDay - b.assessmentInDay);
        this.coreService.isAssessmentDateNotified = true;
        this.data['closeOnEmpty'] && this.canCloseDialog('new');
    }

    onMarkReadClass(markRead: invitedUserData) {
        markRead.isLoading = true;
        this.coreService.markClassShareNotification(markRead.id).subscribe({
            next: resp => {
                this.classSharedUserList = this.classSharedUserList.filter(shr => shr.id != markRead.id);
                // this.canCloseDialog('old');
            },
            error: err => markRead.isLoading = false
        });
    }

    onMarkReadStudent(markRead: invitedUserData) {
        markRead.isLoading = true;
        this.coreService.markStudentShareNotification(markRead.id).subscribe({
            next: resp => {
                this.studentSharedUserList = this.studentSharedUserList.filter(shr => shr.id != markRead.id);
                // this.canCloseDialog('old');
            },
            error: err => markRead.isLoading = false
        });
    }

    markAllread() {
        this.loader = true;
        this.coreService.markAllNotificationRead().subscribe({
            next: resp => {
                this.classSharedUserList = [];
                this.studentSharedUserList = [];
                this.coreService.openSnackBar("Marked all as read", 3000, "primary", "end", "bottom");
                this.loader = false;
                // this.canCloseDialog('old');
            }, 
            error: err => {
                console.log(err);
                this.coreService.openSnackBar("Error Occurred, please try later", 3000, "warn", "end", "bottom");
                this.loader = false;
                this.onCancel();
            }
        });

    }

    onCancel() {
        this.dialogRef.close(false);
    }

    canCloseDialog(type: 'new' | 'old') {
        if (!(this.classSharedUserList.length || this.studentSharedUserList.length || this.classAssessmentDetails.length)) {
            this.dialogRef.close(false);
            if (type === "new") {
                this.coreService.openSnackBar("No New Notifications", 3000, "primary", "end", "bottom");
            }
        }
    }

    classSelect(selectedClass: ClassData, goToStudent: boolean) {
        let data: { selectedClass: ClassData, goToStudent?: boolean } = {
            selectedClass: selectedClass,
            goToStudent: goToStudent
        };
        this.dialogRef.close(data);
    }

}

interface classAssessmentDates {
    guid: string,
    name: string,
    type: string,
    assessmentDate: string,
    assessmentInDay: number,
    classData: ClassData
}