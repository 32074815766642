import { Component, Inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'district',
    templateUrl: 'district-warning.component.html',
    styleUrls: ['district-warning.component.scss']
})
export class DistrictWarningComponent {
    constructor(
        public dialogRef: MatDialogRef<DistrictWarningComponent>
    ) {
    }

    onClose() {
        this.dialogRef.close(false);
    }
}