<div class="classSelector">
    <app-dialog-title [title]="'New Custom Red Word'"></app-dialog-title>

    <mat-form-field appearance="fill" class="titleForm">
        <mat-label>Enter New Red Word</mat-label>
        <input matInput placeholder="like given, forgot" [formControl]="customWord" id="customWord">
    </mat-form-field>

    <mat-dialog-actions class="btnClass">
        <app-button [content]="'Cancel'" (click)="onCancel()"></app-button>
        <app-button [content]="'Add Word'" [btnClasses]="'blue'" (click)="onAdd()" [isDisabled]="isAddingNewWord" [isLoading]="isAddingNewWord"></app-button>
    </mat-dialog-actions>
</div>