<div class="mfa-container">
    <div class="verifyBlock" *ngIf="!showAddRecoveryEmail">
        <h3>
            <mat-icon>lock</mat-icon>
            Enter your verification code
        </h3>
        <div>
            <p class="notes">
                A 6 digit verification code has been sent to your {{showRecoveryEmail ? 'Recovery' : '' }} email <b>{{showRecoveryEmail ? userData.recoveryEmail : userData.email}}</b>. Please enter the code below.
                <!-- <br/> The code expires in one hour. -->
            </p>
            <mat-form-field appearance="fill" floatLabel="auto" class="mfaCode">
                <mat-label>Verification Code</mat-label>
                <input matInput placeholder="******" [formControl]="codeInputControl" maxlength="6">
            </mat-form-field>
        </div>
        <div class="action-btn">
            <app-button *ngIf="showResendCodeBtn" content="Resend Code" btnClasses="blue" [isDisabled]="isApiResendRunning || isApiVerifyRunning || isApiResendRecoveryRunning" [isLoading]="isApiResendRunning" (click)="reSendCode()"></app-button>
            <app-button content="Verify" btnClasses="green" [isDisabled]="!codeInputControl.valid || isApiVerifyRunning || isApiResendRunning || isApiResendRecoveryRunning" [isLoading]="isApiVerifyRunning" (click)="checkMfaCode()"></app-button>
            <!-- <app-button content="Cancel" btnClasses="white" (click)="loginAgain()"></app-button> -->
            <app-button (click)="showHideAddRecoveryBlock()" content="Add recovery email" btnClasses="blue" *ngIf="canAddRecoveryEmail"></app-button>
        </div>

        <div *ngIf="errorMessage === 'WrongCode'">
            <p>
                <span class="errorMsg">Verification failed, Please try again</span>
            </p>
        </div>
        <div class="resendNote" *ngIf="showResendNote">
            {{showRecoveryEmail ? 'Verification code has been sent to your recovery email' : 'Verification code has been resent to your email'}}
        </div>
        <div *ngIf="errorMessage && errorMessage !== 'WrongCode'" class="errorMsg">
            {{errorMessage}}
        </div>
        <div class="action-btn recoveryCodeBtn">
            <app-button (click)="resendCodeToRecovery()" content="Didn't receive the code? Send a code to your recovery email." btnClasses="plain" *ngIf="showResendCodeRecoveryBtn" [isDisabled]="isApiResendRunning || isApiVerifyRunning || isApiResendRecoveryRunning"
                [isLoading]="isApiResendRecoveryRunning"></app-button>
            <!-- <app-button (click)="showHideAddRecoveryBlock()" class="recoveryEmail" content="Add recovery email" btnClasses="blue" *ngIf="canAddRecoveryEmail"></app-button> -->
        </div>
        <button mat-mini-fab class="closeBtn" color="accent" (click)="loginAgain()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="addRecoveryBlock" *ngIf="showAddRecoveryEmail">
        <h3>
            <mat-icon>mail</mat-icon>
            Add Recovery Email
        </h3>
        <div>
            <mat-form-field appearance="fill" floatLabel="auto">
                <mat-label>Recovery Email:</mat-label>
                <input matInput placeholder="" [formControl]="recoveryEmailControl">
                <mat-hint *ngIf="recoveryEmailControl.dirty && recoveryEmailControl.errors" class="errorMsg">Please enter Valid Email</mat-hint>
            </mat-form-field>
        </div>
        <div class="action-btn" *ngIf="!isEmailUpdated">
            <app-button (click)="showHideAddRecoveryBlock()" content="Cancel" btnClasses="white" [isDisabled]="isUpdateEmailApiRunning"></app-button>
            <app-button (click)="updateRecoveryEmail()" content="Update" btnClasses="blue" [isDisabled]="!recoveryEmailControl.valid || isUpdateEmailApiRunning" [isLoading]="isUpdateEmailApiRunning"></app-button>
        </div>
        <div *ngIf="errorMessage && errorMessage !== 'WrongCode'" class="errorMsg">
            {{errorMessage}}
        </div>
        <div *ngIf="isEmailUpdated">
            <h2 class="recoveryUpdated">Recovery Email updated, Please Login Again</h2>
            <app-button (click)="loginAgain()" content="Close" btnClasses="white"></app-button>
        </div>
    </div>
</div>