<div class="letter-formation-container">
    <div class="titelCotainer">
        <div class="homeBtn">
            <app-button [content]="'&#x2B05; Concept Materials'" (click)="goToConcept()" *ngIf="!isLessonConcept"></app-button>
            <app-button [content]="'&#x2B05; Lesson Builder'" (click)="goToLessonBuilder()" *ngIf="isLessonConcept"></app-button>
        </div>
        <div class="title">
            Letter Formation - Create Your Own
        </div>
    </div>
    <div class="content">
        <div class="subTitle">
            Concept:
            <span class="conceptName">
                {{selectedConcept.name}}
            </span>
        </div>
        <div class="reportTitleCotainer" *ngIf="!isLessonConcept">
            <div class="titleCotainer">
                <mat-form-field appearance="outline" class="titleForm">
                    <mat-label>Title for Letter Formation (Max 40 Chars)</mat-label>
                    <input matInput placeholder="Short title for your custom concept" [formControl]="descriptionControl" id="descriptionControl">
                    <mat-hint class="hintMessage">
                        <div *ngIf="descriptionControl.value?.length === 40" class="maxCharReached">
                            Max limit reached
                        </div>
                        <div *ngIf="descriptionControl.value?.length != 40" class="charLeft">
                            {{ 40 - (descriptionControl.value?.length || 0) }} Chars remaining
                        </div>
                    </mat-hint>
                </mat-form-field>
            </div>
        </div>
        <div class="inputsForm1">
            <div>
                <mat-button-toggle-group [formControl]="letterFormationType" (change)="onLetterFormationTypeChange($event.value)" name="letterFormation" aria-label="letterFormation" class="toggle-blue">
                    <mat-button-toggle value="largeSmallHouse">Large / Small House</mat-button-toggle>
                    <mat-button-toggle value="3SmallHouse">3 Small House</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
        <div class="letterSelection" *ngIf="conceptMaterialList">
            <div *ngIf="letterFormationType.value === 'largeSmallHouse'" class="largeSmallHouseContainer">
                <div class="input">
                    <h3>Top Letter</h3>
                    <mat-form-field appearance="outline">
                        <mat-label>Letter</mat-label>
                        <mat-select [formControl]="largeSmallLetterConcept" (selectionChange)="updateLargeSmallImg()">
                            <mat-option *ngFor="let concept of conceptMaterialList" [value]="concept">{{concept.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-button-toggle-group name="largeSmallCaseType" [(ngModel)]="largeSmallLetterCaseType" (change)="updateLargeSmallImg()" aria-label="largeSmallCaseType" class="toggle-blue">
                        <mat-button-toggle value="lower">Lower Case</mat-button-toggle>
                        <mat-button-toggle value="capital">Capital Case</mat-button-toggle>
                    </mat-button-toggle-group>
                    <br/>
                    <mat-button-toggle-group class="letterType toggle-blue" [formControl]="largeSmallLetterType" (change)="updateLargeSmallImg()">
                        <mat-button-toggle value="dn">D'Nealian</mat-button-toggle>
                        <mat-button-toggle value="zb">Zaner-Bloser</mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
                <div class="preview">
                    <div class="container">
                        <div class="title">
                            <div>
                                Letter Formation
                            </div>
                            <div>Name: _______________</div>
                        </div>
                        <div class="topLetter" *ngIf="largeSmallLetterConcept.value">
                            <img *ngIf="largeSmallTopLetterImgSrc !== 'NotAllowed'" [src]="largeSmallTopLetterImgSrc" alt="image not available">
                            <p *ngIf="largeSmallTopLetterImgSrc === 'NotAllowed'">Visual cues are not required for capital digraphs.</p>
                        </div>
                        <div class="bottomLetter" *ngIf="largeSmallLetterConcept.value">
                            <img *ngIf="largeSmallBottomLetterImgSrc !== 'NotAllowed'" [src]="largeSmallBottomLetterImgSrc" alt="image not available">
                            <p *ngIf="largeSmallBottomLetterImgSrc === 'NotAllowed'">Visual cues are not required for capital digraphs.</p>
                        </div>
                        <div class="previewFooter">
                            <div>Teacher Name</div>
                            <div>IMSE &#169;</div>
                            <div>{{ todayDate | date}}</div>
                        </div>
                    </div>

                </div>
            </div>
            <div *ngIf="letterFormationType.value === '3SmallHouse'" class="threeSmallHouseContainer">
                <div class="input">
                    <!-- Top Letter -->
                    <h3>Top Letter</h3>
                    <mat-form-field appearance="outline">
                        <mat-label>Letter</mat-label>
                        <mat-select [formControl]="topLetterConcept" (selectionChange)="update3HouseTopImg()">
                            <mat-option *ngFor="let concept of conceptMaterialList" [value]="concept">{{concept.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div>
                        <mat-button-toggle-group name="topLetterCaseType" [(ngModel)]="topLetterCaseType" (change)="update3HouseTopImg()" aria-label="topLetterCaseType" class="toggle-blue">
                            <mat-button-toggle value="lower">Lower Case</mat-button-toggle>
                            <mat-button-toggle value="capital">Capital Case</mat-button-toggle>
                        </mat-button-toggle-group>
                        <br/>
                        <mat-button-toggle-group class="letterType toggle-blue" [formControl]="topLetterLetterType" (change)="update3HouseTopImg()">
                            <mat-button-toggle value="dn">D'Nealian</mat-button-toggle>
                            <mat-button-toggle value="zb">Zaner-Bloser</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <p>Select a Visual Cue</p>
                    <div class="visualCue">
                        <mat-radio-group [color]="'primary'" [(ngModel)]="topLetterVisualType" (change)="update3HouseTopImg()" aria-label="visualCue">
                            <mat-radio-button value="BlankHouse">
                                <img src="./assets/images/concept_images/NewCustomConcept/VisualCueBlankHouse.png" alt="blankCue" matTooltip="No Cue" matTooltipPosition="above">
                            </mat-radio-button>
                            <mat-radio-button value="Letter">
                                <img src="./assets/images/concept_images/NewCustomConcept/VisualCueLetter.png" alt="visualCue" matTooltip="Visual Cue" matTooltipPosition="above">
                            </mat-radio-button>
                            <mat-radio-button value="LetterDot">
                                <img src="./assets/images/concept_images/NewCustomConcept/VisualCueLetterDot.png" alt="dotCue" matTooltip="Visual Dot Cue" matTooltipPosition="above">
                            </mat-radio-button>
                            <mat-radio-button value="LetterDotArrow">
                                <img src="./assets/images/concept_images/NewCustomConcept/VisualCueLetterDotArrow.png" alt="arrowCue" matTooltip="Visual Arrow Cue" matTooltipPosition="above">
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <hr/>
                    <!-- Middle Letter -->
                    <h3>Middle Letter</h3>
                    <mat-form-field appearance="outline">
                        <mat-label>Letter</mat-label>
                        <mat-select [formControl]="middleLetterConcept" (selectionChange)="update3HouseMiddleImg()">
                            <mat-option *ngFor="let concept of conceptMaterialList" [value]="concept">{{concept.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div>
                        <mat-button-toggle-group name="middleLetterCaseType" [(ngModel)]="middleLetterCaseType" (change)="update3HouseMiddleImg()" aria-label="middleLetterCaseType" class="toggle-blue">
                            <mat-button-toggle value="lower">Lower Case</mat-button-toggle>
                            <mat-button-toggle value="capital">Capital Case</mat-button-toggle>
                        </mat-button-toggle-group>
                        <br/>
                        <mat-button-toggle-group class="letterType toggle-blue" [formControl]="middleLetterLetterType" (change)="update3HouseMiddleImg()">
                            <mat-button-toggle value="dn">D'Nealian</mat-button-toggle>
                            <mat-button-toggle value="zb">Zaner-Bloser</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <p>Select a Visual Cue</p>
                    <div class="visualCue">
                        <mat-radio-group [color]="'primary'" [(ngModel)]="middleLetterVisualType" (change)="update3HouseMiddleImg()" aria-label="visualCue">
                            <mat-radio-button value="BlankHouse">
                                <img src="./assets/images/concept_images/NewCustomConcept/VisualCueBlankHouse.png" alt="blankCue" matTooltip="No Cue" matTooltipPosition="above">
                            </mat-radio-button>
                            <mat-radio-button value="Letter">
                                <img src="./assets/images/concept_images/NewCustomConcept/VisualCueLetter.png" alt="visualCue" matTooltip="Visual Cue" matTooltipPosition="above">
                            </mat-radio-button>
                            <mat-radio-button value="LetterDot">
                                <img src="./assets/images/concept_images/NewCustomConcept/VisualCueLetterDot.png" alt="dotCue" matTooltip="Visual Dot Cue" matTooltipPosition="above">
                            </mat-radio-button>
                            <mat-radio-button value="LetterDotArrow">
                                <img src="./assets/images/concept_images/NewCustomConcept/VisualCueLetterDotArrow.png" alt="arrowCue" matTooltip="Visual Arrow Cue" matTooltipPosition="above">
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <hr/>
                    <!-- Bottom Letter -->
                    <h3>Bottom Letter</h3>
                    <mat-form-field appearance="outline">
                        <mat-label>Letter</mat-label>
                        <mat-select [formControl]="bottomLetterConcept" (selectionChange)="update3HouseBottomImg()">
                            <mat-option *ngFor="let concept of conceptMaterialList" [value]="concept">{{concept.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div>
                        <mat-button-toggle-group name="bottomLetterCaseType" [(ngModel)]="bottomLetterCaseType" (change)="update3HouseBottomImg()" aria-label="bottomLetterCaseType" class="toggle-blue">
                            <mat-button-toggle value="lower">Lower Case</mat-button-toggle>
                            <mat-button-toggle value="capital">Capital Case</mat-button-toggle>
                        </mat-button-toggle-group>
                        <br/>
                        <mat-button-toggle-group class="letterType toggle-blue" [formControl]="bottomLetterLetterType" (change)="update3HouseBottomImg()">
                            <mat-button-toggle value="dn">D'Nealian</mat-button-toggle>
                            <mat-button-toggle value="zb">Zaner-Bloser</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <p>Select a Visual Cue</p>
                    <div class="visualCue">
                        <mat-radio-group [color]="'primary'" [(ngModel)]="bottomLetterVisualType" (change)="update3HouseBottomImg()" aria-label="visualCue">
                            <mat-radio-button value="BlankHouse">
                                <img src="./assets/images/concept_images/NewCustomConcept/VisualCueBlankHouse.png" alt="blankCue" matTooltip="No Cue" matTooltipPosition="above">
                            </mat-radio-button>
                            <mat-radio-button value="Letter">
                                <img src="./assets/images/concept_images/NewCustomConcept/VisualCueLetter.png" alt="visualCue" matTooltip="Visual Cue" matTooltipPosition="above">
                            </mat-radio-button>
                            <mat-radio-button value="LetterDot">
                                <img src="./assets/images/concept_images/NewCustomConcept/VisualCueLetterDot.png" alt="dotCue" matTooltip="Visual Dot Cue" matTooltipPosition="above">
                            </mat-radio-button>
                            <mat-radio-button value="LetterDotArrow">
                                <img src="./assets/images/concept_images/NewCustomConcept/VisualCueLetterDotArrow.png" alt="arrowCue" matTooltip="Visual Arrow Cue" matTooltipPosition="above">
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>

                </div>
                <div class="preview">
                    <div class="container">
                        <div class="title">
                            <div>
                                Letter Formation
                            </div>
                            <div>Name: _______________</div>
                        </div>
                        <div class="topLetter" *ngIf="topLetterConcept.value">
                            <img *ngIf="topLetterImgSrc !== 'NotAllowed'" [src]="topLetterImgSrc" alt="image not available">
                            <p *ngIf="topLetterImgSrc === 'NotAllowed'">Visual cues are not required for capital digraphs.</p>
                        </div>
                        <div class="middleLetter" *ngIf="middleLetterConcept.value">
                            <img *ngIf="middleLetterImgSrc !== 'NotAllowed'" [src]="middleLetterImgSrc" alt="image not available">
                            <p *ngIf="middleLetterImgSrc === 'NotAllowed'">Visual cues are not required for capital digraphs.</p>
                        </div>
                        <div class="bottomLetter" *ngIf="bottomLetterConcept.value">
                            <img *ngIf="bottomLetterImgSrc !== 'NotAllowed'" [src]="bottomLetterImgSrc" alt="image not available">
                            <p *ngIf="bottomLetterImgSrc === 'NotAllowed'">Visual cues are not required for capital digraphs.</p>
                        </div>
                        <div class="previewFooter">
                            <div>Teacher Name</div>
                            <div>IMSE &#169;</div>
                            <div>{{ todayDate | date}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="SaveBtnContainer">
        <app-button [content]="'Save'" [btnClasses]="'blue'" (click)="saveNewConcept(false, false)" [isDisabled]="selectedClass?.readOnly || isSaveInProgress || isSavePrintInProgress" [isLoading]="isSaveInProgress"></app-button>
        <app-button [content]="'Save and Print Teacher PDF'" (click)="saveNewConcept(true, false)" [isDisabled]="selectedClass?.readOnly || isSaveInProgress || isSavePrintInProgress" [isLoading]="isSavePrintInProgress"></app-button>
        <!-- <app-button *ngIf="isLessonConcept" [content]="'Copy to Concept Material'" [btnClasses]="'blue'" (click)="saveNewConcept(false, true)" [isDisabled]="selectedClass?.readOnly || isSaveInProgress || isSavePrintInProgress" [isLoading]="isSaveInProgress"></app-button> -->
    </div>
</div>