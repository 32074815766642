import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
// import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatDialog } from '@angular/material/dialog';
import { ClassDisplayComponent } from '../dialog-box/class-display/class-display.component';
import { Properties, User } from 'src/app/classes/user';
import { Router } from '@angular/router';
import { NavbarService } from 'src/app/layout/app-header/navbar.service';
import { CoreService } from '../../core.service';
import { AddEditClassComponent } from '../dialog-box/add-edit-class/add-edit-class.component';
import { ClassData, ClassDto } from 'src/app/classes/classData';
import { Subscription } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ReportSelectionDialog } from '../dialog-box/report-selection/report-selection.component';
import { ConceptDisplay } from '../dialog-box/concept-display/concept-display.component';
import { ConceptMaterial } from 'src/app/classes/concept';
import { LessonData } from 'src/app/classes/lessonData';
import { DistrictWarningComponent } from '../dialog-box/district-warning/district-warning.component';
import * as Driver from 'driver.js';
import { ClassArchivedDisplayComponent } from '../dialog-box/class-display-archived/class-archived.component';
import { LessonListDialog } from '../dialog-box/lesson-list/lesson-list.component';
import { ShowSharedNotification } from '../dialog-box/show-shared-notification/show-shared-notification.component';
import { invitedUserData } from 'src/app/classes/invitedUserData';
import { TermsAndServicesComponent } from '../dialog-box/terms_and_services/terms_and_services.component';
@Component({
  selector: 'app-dashboard',
  templateUrl: './app-dashboard.component.html',
  styleUrls: ['./app-dashboard.component.scss']
})
export class DashboardComponent implements OnDestroy, OnInit {
  userData!: User | null;
  selectedClass!: ClassData;
  classDataList!: ClassData[];
  isGetClassLoading = false;
  canAddClass = true;
  private _subcription: Subscription[] = [];
  rightHeaderButtons: { title: string, event: string }[] = [{
    title: 'Notifications',
    event: 'openNotificationDialog'
  }];
  leftHeaderButtons: { title: string, event: string }[] | null = null;
  classSelectControl!: UntypedFormControl;
  driver!: Driver;
  isTourOn: boolean = false;
  constructor(
    public dialog: MatDialog,
    private coreService: CoreService,
    private router: Router,
    private navbarService: NavbarService) {
    // if (coreService.userData?.districtAdmin) {
    //   this.leftHeaderButtons = [{
    //     title: 'District Admin',
    //     event: 'goToDistrictAdmin'
    //   }];
    // }
    this.userData = this.coreService.userData;
    if (this.userData?.admin && environment.name !== 'staging') {
      this.rightHeaderButtons.unshift({ title: 'Insights', event: 'goToAdminReporting' });
    }
    this.navbarService.updateLeftHeaderBtn(this.leftHeaderButtons);
    this.navbarService.updateRightHeaderBtn(this.rightHeaderButtons);
    this.classSelectControl = new UntypedFormControl('');
    if (this.coreService.selectedClass) {
      this.selectedClass = this.coreService.selectedClass;
      this.classSelectControl = new UntypedFormControl(this.selectedClass.guid);
    }
    let districtId = this.coreService.userData?.districtId;
    let isDistrictWarned = window.sessionStorage.getItem('isDistrictWarned'); //to show warning only first time when app loads
    if (!isDistrictWarned && (!districtId || districtId <= 0) && !this.coreService.userData?.warnDistrict) {
      window.sessionStorage.setItem('isDistrictWarned', "warned");
      setTimeout(() => {
        this.dialog.open(DistrictWarningComponent, {
          disableClose: true,
          panelClass: ['dialogOverflow']
        });
      }, 1000);
    }


    const navSub = this.navbarService.headerBtnClicked$.subscribe({
      next: action => this.onNavigationServiceActionCalled(action)
    });
    this._subcription.push(navSub);

  }

  ngOnInit(): void {
    if (!this.coreService.userData?.termsAccepted) {
      this.openTermsDialog();
    } else {
      this.getAllClassList();
    }
    this.driver = new Driver({
      allowClose: false,
      onReset: (Element) => {
        this.isTourOn = false;
      }
    });

  }

  ngOnDestroy(): void {
    this._subcription.forEach(sub => sub?.unsubscribe());
  }


  functonList: { [name: string]: Function } = {
    goToDistrictAdmin: () => this.goToDistrictAdmin(),
    goToAdminReporting: () => this.goToAdminReporting(),
    openNotificationDialog: () => this.openNotificationDialog('onClick')
  }

  onNavigationServiceActionCalled(action: string) {
    if (this.functonList[action]) {
      this.functonList[action]();
    }
  }


  selectClass() {
    this.openClassSelectDialog(false, false);
  }

  openTermsDialog() {
    const dialogef = this.dialog.open(TermsAndServicesComponent, {
      disableClose: true,
      panelClass: ['dialogOverflow'],
      autoFocus: 'first-heading'
    });
    let dialogSub = dialogef.afterClosed().subscribe({
      next: resp => this.getAllClassList()
    });
    this._subcription.push(dialogSub);
  }

  getAllClassList() {
    !this.coreService.isAssessmentDateNotified && this.openNotificationDialog('auto');
    this.isGetClassLoading = true;
    let getClassApiSub = this.coreService.getAllClassData().subscribe({
      next: classData => {
        this.classDataList = classData.sort((a, b) => a.name.localeCompare(b.name));
        if (this.userData?.districtManaged) {
          this.getDistrictAdminProperties();
        } else {
          this.isGetClassLoading = false;
        }
      },
      error: err => {
        console.log(err);
        this.coreService.openSnackBar("Error Occured while getting Class", 3000, "warn", "end", "bottom");
        this.isGetClassLoading = false;
      }
    });
    this._subcription.push(getClassApiSub);
  }

  getDistrictAdminProperties() {
    let prosApi = this.coreService.getDistrictAdminProperties().subscribe({
      next: resp => {
        this.isGetClassLoading = false;
        if (this.userData) {
          this.userData.properties = resp;
          let propertiesByRule: { [key: string]: Properties } = {};
          resp.forEach(pros => {
            propertiesByRule[pros.rule] = pros;
          });
          this.userData.propertiesByRule = propertiesByRule;
          let addClassRule = propertiesByRule && propertiesByRule['TEACHER_CLASS_CREATE'];
          this.canAddClass = addClassRule ? JSON.parse(addClassRule.value) : true;
        }
      },
      error: err => {
        console.log(err);
        this.coreService.openSnackBar("Error Occured while getting Properties", 3000, "warn", "end", "bottom");
        this.isGetClassLoading = false;
      }
    });
    this._subcription.push(prosApi);
  }

  openNotificationDialog(type : 'auto' | 'onClick') {
    const dialogef = this.dialog.open(ShowSharedNotification, {
      disableClose: true,
      panelClass: ['dialogOverflow'],
      data : {
        closeOnEmpty : type === 'auto' ? true : false
      }
    });
    let dialogSub = dialogef.afterClosed().subscribe({
      next: (data: { selectedClass: ClassData, goToStudent?: boolean }) => {
        if (data) {
          const sharedSelectedClass = this.classDataList.find(cls => cls.guid === data.selectedClass.guid);
          if (!sharedSelectedClass) {
            return;
          }
          this.coreService.selectedClass = sharedSelectedClass;
          this.selectedClass = sharedSelectedClass;
          this.classSelectControl.setValue(this.selectedClass.guid);
          data.goToStudent ? this.router.navigate(['student-assessment']) : this.router.navigate(['class']);
        }
      }
    });
    this._subcription.push(dialogSub);
  }

  openClassSelectDialog(forStudents: boolean = false, doRedirect: boolean = true) {
    if (this.isTourOn) {
      return;
    }
    if (this.selectedClass && doRedirect) {
      forStudents ? this.router.navigate(['student-assessment']) : this.router.navigate(['class']);
      return;
    }

    const dialogef = this.dialog.open(ClassDisplayComponent, {
      data: {
        title: 'Select Your Class',
        type: forStudents ? 'studentManagement' : 'classManagement',
      },
      disableClose: true,
      panelClass: ['dialogOverflow']
    });

    let classDialogSub = dialogef.afterClosed().subscribe({
      next: data => {
        if (data) {
          if (data === 'AddNewClass') {
            this.AddNewClass(doRedirect);
          } else {
            this.coreService.selectedClass = data;
            this.selectedClass = data;
            this.classSelectControl.setValue(this.selectedClass.guid);
            if (doRedirect) {
              forStudents ? this.router.navigate(['student-assessment']) : this.router.navigate(['class']);
            }
          }
        }
      }
    });

    this._subcription.push(classDialogSub);
  }

  goToAssessmentApp() {
    if (this.isTourOn) {
      return;
    }
    if (!this.selectedClass?.guid) {
      this.coreService.openSnackBar("Please Select a class", 3000, "warn", "end", "bottom");
      return;
    }
    this.dialog.open(ReportSelectionDialog);

  }

  @HostListener('window:message', ['$event'])
  onAssessmentAppClose(event: any) {
    if (event.origin === environment.assessmentAppUrl && event.data === 'tabClose') {
      //This api call is to check is sessiontoken stil valid
      this.coreService.getAllClassData().subscribe({
        next: resp => { }
      });
    }
  }

  openConcept() {
    if (this.isTourOn) {
      return;
    }
    if (this.selectedClass) {
      this.router.navigate(['concept']);
      return;
    }
    const dialogef = this.dialog.open(ClassDisplayComponent, {
      data: {
        title: 'Select Your Class',
        type: 'studentManagement',
      },
      disableClose: true,
      panelClass: ['dialogOverflow']
    });

    let classDialogSub = dialogef.afterClosed().subscribe({
      next: data => {
        if (data) {
          this.coreService.selectedClass = data;
          this.router.navigate(['concept']);
        }
      }
    });
    this._subcription.push(classDialogSub);
  }

  AddNewClass(redirectStudent: boolean) {
    if (this.isTourOn) {
      return;
    }
    const newClassDialogef = this.dialog.open(AddEditClassComponent, {
      data: {
        title: 'Create a New Class',
        type: 'new',
        redirectStudent: redirectStudent
      },
      disableClose: true,
      panelClass: ['dialogOverflow']
    });
    let newClassSub = newClassDialogef.afterClosed().subscribe({
      next: (data: ClassDto) => {
        if (data) {
          let addClassSub = this.coreService.addNewClass(data).subscribe({
            next: newClassData => {
              this.coreService.selectedClass = newClassData;
              this.selectedClass = newClassData;
              this.classSelectControl.setValue(newClassData.guid);
              if (redirectStudent) {
                this.router.navigate(['student-assessment']);
              }
              this.coreService.openSnackBar(`New Class ${data.className} Added`, 3000, "primary", "end", "bottom");
            },
            error: err => {
              console.log(err);
              this.coreService.openSnackBar("Error Occured, please try later", 3000, "warn", "end", "bottom");
            }
          });
          this._subcription.push(addClassSub);
        } else {
          this.selectedClass?.guid && this.classSelectControl.setValue(this.selectedClass.guid);
        }
      }
    });
    this._subcription.push(newClassSub);
  }

  toGoLessonBuilder() {
    if (this.isTourOn) {
      return;
    }
    if (!this.selectedClass?.guid) {
      const dialogef = this.dialog.open(ClassDisplayComponent, {
        data: {
          title: 'Select Your Class',
          type: 'studentManagement',
        },
        disableClose: true,
        panelClass: ['dialogOverflow']
      });
      let classDialogSub = dialogef.afterClosed().subscribe({
        next: data => {
          if (data) {
            this.coreService.selectedClass = data;
            this.selectedClass = data;
            this.classSelectControl.setValue(this.selectedClass.guid);
            this.openLessonBuilder();
          }
        }
      });
      this._subcription.push(classDialogSub);
      return;
    } else {
      this.openLessonBuilder();
    }
  }

  openLessonBuilder() {
    let lessonSelector = this.dialog.open(LessonListDialog, {
      disableClose: true,
      panelClass: ['dialogOverflow']
    });
    let lessonSelectorSub = lessonSelector.afterClosed().subscribe({
      next: (data: any) => {
        if (data) {
          if (data === 'NewLesson') {
            this.openCreateLessonBuilder();
          } else {
            this.router.navigateByUrl('lessonBuilder', {
              state: {
                lessonData: data
              }
            });
          }
        }
      }
    });
    this._subcription.push(lessonSelectorSub);
  }

  openCreateLessonBuilder() {
    let openConceptSelect = this.dialog.open(ConceptDisplay);
    let openConceptSeectSub = openConceptSelect.afterClosed().subscribe({
      next: (lessonData: LessonData) => {
        if (lessonData) {
          this.router.navigateByUrl('lessonBuilder', {
            state: {
              lessonData: lessonData
            }
          });
        }
      }
    });
    this._subcription.push(openConceptSeectSub);
  }

  openTourDialog(event: any) {
    event.stopPropagation();
    this.driver.defineSteps([
      {
        element: '#ClassManagement',
        popover: {
          className: 'popover-class',
          title: 'Step 1 - Create a Class/Student',
          description: 'Start with Classroom Materials where you can create a class and add students. If you only have 1 student, you can create a 1 student class.',
          showButtons: true,
          closeBtnText: 'Close',
          nextBtnText: 'Next',
          prevBtnText: 'Previous',
        }
      },
      {
        element: '#StudentManagement',
        popover: {
          className: 'popover-class',
          title: 'Step 2 - Assessment',
          description: 'After creating your class, Student Assessment will allow you to assess your students. ',
          showButtons: true,
          closeBtnText: 'Close',
          nextBtnText: 'Next',
          prevBtnText: 'Previous',
        }
      },
      {
        element: '#ReportsCard',
        popover: {
          className: 'popover-class',
          title: 'Step 3 - Analyze and Metrics',
          description: 'Click here to review the performance of your classes and students. <br/> If your students have varying skill levels, you can group them together based on their reading level.',
          showButtons: true,
          closeBtnText: 'Close',
          nextBtnText: 'Next',
          prevBtnText: 'Previous',
        }
      },
      {
        element: '#LessonBuilder',
        popover: {
          className: 'popover-class',
          title: 'Step 4 - Lesson Builder',
          description: 'Next, click here to create your own lessons that are customized for your students.',
          showButtons: true,
          closeBtnText: 'Close',
          nextBtnText: 'Next',
          prevBtnText: 'Previous',
        }
      },
      {
        element: '#ConceptMaterial',
        popover: {
          className: 'popover-class',
          title: 'Step 5 - Quick Access Materials',
          description: 'If you know exactly what you want you can skip assessment and lesson creation to go straight to quick access concept materials.',
          showButtons: true,
          closeBtnText: 'Close',
          nextBtnText: 'Next',
          prevBtnText: 'Previous',
        }
      }
    ]);
    this.driver.start();
    this.isTourOn = true;
  }

  openArchivedClassDialog() {
    let archiveClassDialog = this.dialog.open(ClassArchivedDisplayComponent);
    let unArchiveSub = archiveClassDialog.afterClosed().subscribe({
      next: resp => {
        this.getAllClassList();
      }
    });
  }

  goToDistrictAdmin() {
    window.open(environment.districtAdminUrl + "/?token=" + this.userData?.ssoToken, "_blank");
  }

  goToAdminReporting() {
    this.isGetClassLoading = true;
    this.router.navigateByUrl('admin/dashboard');
  }
}
