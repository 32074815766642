import { Component, Inject, OnDestroy } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { sharedUsers, Student } from "src/app/classes/student";
import { CoreService } from "src/app/core/core.service";
import { ConfirmDialogBox } from "../confirm-dialog/confirm-dialog.component";

import { LevelForUI } from "src/app/classes/enums";
import { AddInviteStudentDialog } from "../add-edit-invite-student/add-invite-student.component";

@Component({
    selector: 'edit-student',
    templateUrl: 'edit-student.component.html',
    styleUrls: ['edit-student.component.scss'],
})
export class EditStudentDialog implements OnDestroy {
    private _subscription: Subscription[] = [];
    editStudent: Student;
    isUpdateApiRunning = false;
    isDeleteApiRunning = false;

    studentIdControl: UntypedFormControl;
    studentFirstNameControl: UntypedFormControl;
    studentLastNameControl: UntypedFormControl;
    studentLevelControl: UntypedFormControl;
    uiLevelName = '';
    editStudentFormGroup: UntypedFormGroup;
    refreshOnClose = false;
    changeValueFlag = false;

    constructor(
        public dialogRef: MatDialogRef<EditStudentDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private coreService: CoreService,
        public dialog: MatDialog
    ) {
        this.editStudent = data['student'];
        this.studentIdControl = new UntypedFormControl(this.editStudent.studentID.trim(), Validators.required);
        this.studentFirstNameControl = new UntypedFormControl(this.editStudent.firstName);
        this.studentLastNameControl = new UntypedFormControl(this.editStudent.lastName);
        this.uiLevelName = LevelForUI(this.editStudent.level);
        this.studentLevelControl = new UntypedFormControl(this.uiLevelName, Validators.required);
        this.editStudentFormGroup = new UntypedFormGroup({
            studentIdControl: this.studentIdControl,
            studentFirstNameControl: this.studentFirstNameControl,
            studentLastNameControl: this.studentLastNameControl,
            studentLevelControl: this.studentLevelControl
        });
        this.editStudentFormGroup.valueChanges.subscribe({
            next: value => {
                this.changeValueFlag = true;
            }
        });
    }
    ngOnDestroy(): void {
        this._subscription.forEach(sub => sub?.unsubscribe());
    }

    onCancel() {
        if (this.changeValueFlag) {
            const confirmDialog = this.dialog.open(ConfirmDialogBox, {
                data: {
                    message: `This will discard all changes. Continue?`,
                    confirmBtnLabel: "Yes",
                    confirmBtnClass: "lightRed",
                    cancelBtnLabel: "No",
                },
                disableClose: true,
            });
            let dialogSub = confirmDialog.afterClosed().subscribe({
                next: isConfirmed => {
                    if (isConfirmed) {
                        this.dialogRef.close(this.refreshOnClose);
                    }
                }
            });
            this._subscription.push(dialogSub);
        } else {
            this.dialogRef.close(this.refreshOnClose);
        }
    }

    deleteStudent() {
        this.isDeleteApiRunning = true;
        let deleteApiSub = this.coreService.deleteStudent([this.editStudent.guid]).subscribe({
            next: resp => {
                this.isDeleteApiRunning = false;
                this.coreService.openSnackBar('Removed ' + (this.editStudent.firstName || '') + ' ' + (this.editStudent.lastName || '') + ' from class', 3000, "warn", "end", "bottom");
                this.dialogRef.close('student deleted');
            },
            error: err => {
                console.log(err);
                this.coreService.openSnackBar("Error Occured, please try later", 3000, "warn", "end", "bottom");
                this.isDeleteApiRunning = false;
            }
        });
        this._subscription.push(deleteApiSub);
    }

    onDelete() {
        let confirmDialogBox = this.dialog.open(ConfirmDialogBox, {
            data: {
                message: 'Are you sure you want to remove <b>' + ((this.editStudent.firstName || '') + ' ' + (this.editStudent.lastName || '')) + ' </b> ?',
                confirmBtnClass: 'lightRed',
                confirmBtnLabel: 'Remove'
            },
            disableClose: true,
        });
        let confirmDialogSub = confirmDialogBox.afterClosed().subscribe({
            next: data => {
                if (data) {
                    this.deleteStudent();
                }
            }
        });
        this._subscription.push(confirmDialogSub);
    }

    onEditStudent() {
        this.editStudentFormGroup.markAllAsTouched();
        if (this.editStudentFormGroup.invalid) {
            return;
        }
        this.isUpdateApiRunning = true;
        this.studentIdControl.setValue(this.studentIdControl.value.trim(), { emitEvent: false });
        let levelData = this.uiLevelName === this.studentLevelControl.value ? this.editStudent.level : this.studentLevelControl.value;
        let newStudent = new Student(this.studentIdControl.value, this.studentFirstNameControl.value, this.studentLastNameControl.value, levelData, this.editStudent.guid);
        let editApiSub = this.coreService.saveStudent(newStudent.studentDto(this.coreService.userData?.id || '')).subscribe({
            next: resp => {
                this.isUpdateApiRunning = false;
                this.coreService.openSnackBar("Student Updated", 3000, "primary", "end", "bottom");
                this.dialogRef.close('student updated');
            },
            error: err => {
                this.isUpdateApiRunning = false;
                console.log(err);
                let errorMessage = 'Some Error Occured, please try later';
                if(err.message) {
                    try {
                        let errorResp = JSON.parse(err.message);
                        if(errorResp['message'] === 'StudentId exists') {
                            errorMessage = 'Student ID already exists, please update with different ID';
                        }
                    } catch (ex) {
                        console.log(ex);
                    }
                }
                this.coreService.openSnackBar(errorMessage, 3000, "warn", "end", "bottom");
            }
        });
        this._subscription.push(editApiSub);
    }

    onEditshare(user: sharedUsers) {
        let editShareStudent = new Student(this.editStudent.studentID, this.editStudent.firstName, this.editStudent.lastName, this.editStudent.level, this.editStudent.guid);
        editShareStudent.sharedUsers = [];
        editShareStudent.sharedUsers.push(user);

        let editShareDialog = this.dialog.open(AddInviteStudentDialog, {
            data: {
                editStudentData: editShareStudent,
                type: 'edit'
            },
            disableClose: true,
        });
        let updateShrSub = editShareDialog.afterClosed().subscribe({
            next: (resp: { role: string, readOnly: boolean, id: string, type: string }) => {
                if (resp) {
                    this.refreshOnClose = true;
                    if (resp.type === 'deleted') {
                        // this.deleteStudentSharedInvite(user.id);
                        let newSharedUser = this.editStudent.sharedUsers.filter(shrUser => shrUser.id !== user.id);
                        this.editStudent.sharedUsers = newSharedUser;
                    } else {
                        let updatedSharedData = resp as { role: string, readOnly: boolean };
                        user.readOnly = updatedSharedData.readOnly;
                        user.userRoleType = updatedSharedData.role;
                    }
                }
            }
        });

        this._subscription.push(updateShrSub);
    }

}