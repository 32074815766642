import { AfterViewInit, Component, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  hideHeader = true;

  constructor(private router: Router,
    private elRef: ElementRef) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe({
      next: (event) => {
        let url = (<NavigationEnd>event).urlAfterRedirects;
        this.hideHeader = url.includes('/login') || url === '/';
      }
    });
  }
  ngAfterViewInit(): void {
    // let loader = this.elRef.nativeElement.querySelector('#loader');
    let loader = document.getElementById('loader');
    if (loader) {
      loader.style.display = "none"; //hide loader
    }
  }

}
