<div class="container">
    <app-dialog-title [title]="'Adding a Dictation ' + type"></app-dialog-title>

    <div mat-dialog-content>
        <div *ngIf="type === 'Word'">
            <p>
                1. Select the concept word or review word.
            </p>
            <p>
                2. After selecting a word, three options display for how to display the visual cues.
            </p>
            <img src="./assets/images/concept_images/Dictation_word_help_1.jpg" alt="helpIcon" class="wordHelper1">
            <div class="subPara">
                Select the appropriate option based on your student(s):
                <p>
                    a. The "Visual Cues" option is recommended for group 3 students and gives the most assistance.
                </p>
                <p>
                    b. The "Sound Lines" option is recommended for group 2 students and gives some assistance.
                </p>
                <p>
                    c. The "Word Line" option is recommended for group 1 students and gives the least assistance.
                </p>
            </div>
            <p>
                3. Beneath the visual cues is the ability to switch between the sound line and the visual cue. This can be used to focus on specific blends, such as "wham" in the preview.
            </p>
            <img src="./assets/images/concept_images/Dictation_word_help_2.jpg" alt="helpIcon" class="wordHelper2">
        </div>
        <div *ngIf="type === 'Sentence'">
            <p>
                1. Select the concept sentence or review sentence.
            </p>
            <p>
                2. After selecting a sentence, three options display for how to display the visual cues.
            </p>
            <img src="./assets/images/concept_images/Dictation_Sentence_help_1.jpg" alt="helpIcon" class="sentenceHelper">
            <div class="subPara">
                Select the appropriate option based on your student(s):
                <p>
                    a. The "Word Lines & Visual Cues" option is recommended for group 3 students and gives the most assistance.
                </p>
                <p>
                    b. The "Word Lines" option is recommended for group 2 students and gives some assistance.
                </p>
                <p>
                    c. The "Sentence Line Only" option is recommended for group 1 students and gives the least assistance.
                </p>
            </div>
        </div>
    </div>
    <mat-dialog-actions class="btnClass">
        <app-button content="Close" (click)="onClose()" btnClasses="blue"></app-button>
    </mat-dialog-actions>
</div>