<div class="classSelector">
    <app-dialog-title [title]="title"></app-dialog-title>
    <div mat-dialog-content *ngIf="classDataList" class="classList">
        <div *ngFor="let class of classDataList" class="classUnArchiveList">
            <div class="className">
                {{class.name}}
                <img *ngIf="!class.owner" src="./assets/images/icons/share_icon.svg" class="shareIcon">
            </div>
            <div class="unArchiveBtn">
                <app-button [content]="'Restore'" [btnClasses]="'whiteRed conceptBtn'" (click)="confirmUnArchiveClass(class)"></app-button>
            </div>
        </div>
    </div>

    <mat-progress-spinner mode="indeterminate" diameter="50" class="blue-spinner" *ngIf="!(classDataList || errorMessage)"></mat-progress-spinner>
    <div class="errorMessage" *ngIf="errorMessage">
        {{ errorMessage }}
    </div>
    <mat-dialog-actions class="btnClass">
        <app-button [content]="'Cancel'" (click)="onCancel()"></app-button>
    </mat-dialog-actions>
</div>