import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UntypedFormControl, Validators } from "@angular/forms";
import { Subscription } from "rxjs";

import { CoreService } from "src/app/core/core.service";
import { NavbarService } from "src/app/layout/app-header/navbar.service";
import { ConceptMaterial, SavedConceptDetail } from "src/app/classes/concept";
import { savedThreeParttDrill, saveThreePartDrillDto } from "src/app/classes/Concepts/threePartDrill";
import { ClassData } from "src/app/classes/classData";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogBox } from "../../dialog-box/confirm-dialog/confirm-dialog.component";

@Component({
    selector: 'app-3part-drill',
    templateUrl: 'app-3part-drill.component.html',
    styleUrls: ['app-3part-drill.component.scss']
})
export class ThreePartDrillComponent implements OnInit, OnDestroy {
    todayDate = new Date();
    selectedConcept!: ConceptMaterial;
    savedConceptMaterial!: savedThreeParttDrill;
    _subscription: Subscription[] = [];
    userName: string;
    isSaveInProgress = false;
    isPdfCreationInProgress = false;
    leftHeaderButtons: { title: string, event: string }[] = [{
        title: 'Back to Home',
        event: 'homeButton'
    }];

    // rightHeaderButtons: { title: string, event: string }[] = [{
    //     title: 'Saved Materials',
    //     event: 'savedLessonConcept'
    // }];

    rightHeaderButtons: { title: string, event: string }[] = [];

    gridSelectionControl: UntypedFormControl;
    gridOptionsImgSrc: { [key: string]: string } = {
        GRID_4: './assets/images/concept_images/4grid.png',
        GRID_6: './assets/images/concept_images/6grid.png',
        GRID_8: './assets/images/concept_images/8grid.png'
    };
    gridPreviewImgSrc = this.gridOptionsImgSrc['GRID_4'];
    charSelectionList !: ConceptMaterial[];
    charControl1!: UntypedFormControl;
    charControl2!: UntypedFormControl;
    charControl3!: UntypedFormControl;
    charControl4!: UntypedFormControl;
    charControl5!: UntypedFormControl;
    charControl6!: UntypedFormControl;
    charControl7!: UntypedFormControl;
    charControl8!: UntypedFormControl;
    descriptionControl: UntypedFormControl;
    letterTypeControl: UntypedFormControl;

    charImgsSrc: { [key: string]: string } = {
        img1: '',
        img2: '',
        img3: '',
        img4: '',
        img5: '',
        img6: '',
        img7: '',
        img8: ''
    }
    isLessonConcept = false;

    functonList: { [name: string]: Function } = {
        homeButton: () => this.gotoToHome(),
        openClassSelection: () => this.goToClassManagement()
    }
    selectedClass: ClassData | null;

    constructor(private coreService: CoreService,
        private router: Router,
        private navbarService: NavbarService,
        public dialog: MatDialog) {
        this.selectedClass = this.coreService.selectedClass;
        if (this.selectedClass) {
            this.leftHeaderButtons.push({
                title: this.selectedClass.name,
                event: 'openClassSelection'
            });
        }

        this.navbarService.updateLeftHeaderBtn(this.leftHeaderButtons);
        this.navbarService.updateRightHeaderBtn(this.rightHeaderButtons);
        let navServiceSub = this.navbarService.headerBtnClicked$.subscribe({
            next: action => this.onNavigationServiceActionCalled(action)
        });
        this._subscription.push(navServiceSub);

        let routerData = this.router.getCurrentNavigation()?.extras.state as {
            selectedConcept: ConceptMaterial,
            savedConceptMaterial: savedThreeParttDrill

        };
        this.userName = this.coreService.userData?.firstName + ' ' + this.coreService.userData?.lastName;
        if (routerData.savedConceptMaterial) {
            this.savedConceptMaterial = routerData.savedConceptMaterial;
            if (this.savedConceptMaterial.lessonMaterial) {
                this.isLessonConcept = true;
                this.selectedConcept = routerData.savedConceptMaterial.lessonMaterial.lesson.concept;
            } else {
                this.selectedConcept = routerData.savedConceptMaterial.classConceptMaterial.concept;
            }
        } else {
            this.selectedConcept = routerData.selectedConcept;
        }

        this.charControl1 = new UntypedFormControl();
        this.charControl2 = new UntypedFormControl();
        this.charControl3 = new UntypedFormControl();
        this.charControl4 = new UntypedFormControl();
        this.charControl5 = new UntypedFormControl();
        this.charControl6 = new UntypedFormControl();
        this.charControl7 = new UntypedFormControl();
        this.charControl8 = new UntypedFormControl();
        this.letterTypeControl = new UntypedFormControl('zb');
        this.descriptionControl = new UntypedFormControl('', [Validators.required, Validators.maxLength(40)]);
        if (this.savedConceptMaterial) {
            if (this.isLessonConcept) {
                this.descriptionControl = new UntypedFormControl('');
            } else {
                this.descriptionControl.setValue(this.savedConceptMaterial.title);
            }
            this.gridSelectionControl = new UntypedFormControl(this.savedConceptMaterial.letterFormation?.letterFormationType || 'GRID_4');
            this.gridPreviewImgSrc = this.gridOptionsImgSrc[this.gridSelectionControl.value];

        } else {
            this.gridSelectionControl = new UntypedFormControl('GRID_4');
        }
        let descChangeSub = this.descriptionControl.valueChanges.subscribe({
            next: (value: string) => {
                if (this.descriptionControl.errors?.['maxlength']) {
                    this.descriptionControl.setValue(value.substring(0, 40));
                }
            }
        });
        this._subscription.push(descChangeSub);
        let getConceptSub = this.coreService.getConceptMaterial().subscribe({
            next: resp => {
                this.charSelectionList = resp.filter(data => data.letter).map(data => {
                    return {
                        ...data,
                        char: this.fetchCharFromConceptName(data.name)
                    }
                });
                this.populatedSelectedChar();
            }
        });
        this._subscription.push(getConceptSub);

        // setTimeout(() => window.scroll({ top: 0, left: 0, behavior: 'smooth'}),0);
    }

    fetchCharFromConceptName(name: string) {
        let ch = name.trim().split(/\s+/)[1];
        if (!ch) {
            return ''
        } else if (ch.length == 1) {
            return ch;
        } else if (ch.length == 2) {
            if (ch[0].toLowerCase() == ch[1]) {
                return ch[1];
            } else {
                return ch;
            }
        }
        return '';
    }

    populatedSelectedChar() {
        if (this.savedConceptMaterial) {
            let letterFormation = this.savedConceptMaterial.letterFormation;
            if (letterFormation) {
                let fontDatas = [letterFormation.font1, letterFormation.font2, letterFormation.font3, letterFormation.font4, letterFormation.font5, letterFormation.font6, letterFormation.font7, letterFormation.font8];
                this.letterTypeControl.setValue(fontDatas.includes(0) ? 'dn' : 'zb');
            }
            this.charControl1 = new UntypedFormControl(this.getConceptByIdAndUpdateImg(letterFormation?.letterID1, 'img1'));
            this.charControl2 = new UntypedFormControl(this.getConceptByIdAndUpdateImg(letterFormation?.letterID2, 'img2'));
            this.charControl3 = new UntypedFormControl(this.getConceptByIdAndUpdateImg(letterFormation?.letterID3, 'img3'));
            this.charControl4 = new UntypedFormControl(this.getConceptByIdAndUpdateImg(letterFormation?.letterID4, 'img4'));

            if (this.gridSelectionControl.value === 'GRID_6' || this.gridSelectionControl.value === 'GRID_8') {
                this.charControl5 = new UntypedFormControl(this.getConceptByIdAndUpdateImg(letterFormation?.letterID5, 'img5'));
                this.charControl6 = new UntypedFormControl(this.getConceptByIdAndUpdateImg(letterFormation?.letterID6, 'img6'));
            }
            if (this.gridSelectionControl.value === 'GRID_8') {
                this.charControl7 = new UntypedFormControl(this.getConceptByIdAndUpdateImg(letterFormation?.letterID7, 'img7'));
                this.charControl8 = new UntypedFormControl(this.getConceptByIdAndUpdateImg(letterFormation?.letterID8, 'img8'));
            }
        }
        setTimeout(() => window.scroll({ top: 0, left: 0, behavior: 'smooth' }), 300);
    }
    getConceptByIdAndUpdateImg = (id: string, imgIndex: string) => {
        if (id) {
            let selectedConceptChar = this.charSelectionList.filter(char => char.guid === id)[0];
            this.charSelectionChange(selectedConceptChar.char, imgIndex);
            return selectedConceptChar;
        }
        return null;
    };

    ngOnDestroy(): void {
        this._subscription.forEach(sub => sub?.unsubscribe());
    }

    ngOnInit(): void {

    }

    onNavigationServiceActionCalled(action: string) {
        if (this.functonList[action]) {
            this.functonList[action]();
        }
    }

    gotoToHome() {
        this.router.navigate(['/dashboard']);
    }

    goToClassManagement() {
        this.router.navigate(['class']);
    }

    gridChanged(value: any) {
        this.gridPreviewImgSrc = this.gridOptionsImgSrc[this.gridSelectionControl.value];
    }

    charSelectionChange(selectedValue: any, charControl: string) {
        this.charImgsSrc[charControl] = `./assets/images/concept_images/3part-drill/${selectedValue}-${this.letterTypeControl.value}-lower.png`;
    }

    letterTypeChanged(value: any) {
        this.charControl1.value && this.charSelectionChange(this.charControl1.value?.char, 'img1');
        this.charControl2.value && this.charSelectionChange(this.charControl2.value?.char, 'img2');
        this.charControl3.value && this.charSelectionChange(this.charControl3.value?.char, 'img3');
        this.charControl4.value && this.charSelectionChange(this.charControl4.value?.char, 'img4');
        this.charControl5.value && this.charSelectionChange(this.charControl5.value?.char, 'img5');
        this.charControl6.value && this.charSelectionChange(this.charControl6.value?.char, 'img6');
        this.charControl7.value && this.charSelectionChange(this.charControl7.value?.char, 'img7');
        this.charControl8.value && this.charSelectionChange(this.charControl8.value?.char, 'img8');
    }

    goToConcept() {
        this.router.navigateByUrl('concept', {
            state: {
                selectedConcept: this.selectedConcept
            }
        });
    }

    goToLessonBuilder() {
        this.router.navigateByUrl('lessonBuilder', {
            state: {
                lessonData: this.savedConceptMaterial.lessonMaterial?.lesson
            }
        });
    }

    printPdf() {
        this.isPdfCreationInProgress = true;
        let type = ''
        if (this.gridSelectionControl.value === 'GRID_8') {
            type = 'LETTER_FORMATION_8_GRID'
        } else if (this.gridSelectionControl.value === 'GRID_6') {
            type = 'LETTER_FORMATION_6_GRID'
        } else {
            type = 'LETTER_FORMATION_4_GRID'
        }
        let pdfSub = this.coreService.get3PartDrillPDF(this.savedConceptMaterial.id, type).subscribe({
            next: (data: Blob) => {
                try {
                    this.isPdfCreationInProgress = false;
                    this.coreService.openPdf(data, '3partdrill');
                } catch (err) {
                    console.log(err);
                    this.coreService.openSnackBar("Error occurred while opening PDF", 3000, "warn", "end", "bottom");
                }
            },
            error: err => {
                alert(err);
                console.log(err);
                this.isPdfCreationInProgress = false;
                this.coreService.openSnackBar("Some error occurred", 3000, "warn", "end", "bottom");
            }
        });
        this._subscription.push(pdfSub);
    }

    saveThreePartDrill(copyToConcept: boolean, printPdf: boolean) {
        if (this.descriptionControl.errors) {
            this.descriptionControl.markAsTouched();
            this.coreService.openSnackBar("Report Title Required", 3000, "warn", "end", "bottom");
            let titleBox = document.getElementById("descriptionControl");
            // window.scrollBy({ top: 0, left: 0, behavior: 'smooth' });
            titleBox?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            return;
        }
        this.isSaveInProgress = true;
        let dataToBeSaved: saveThreePartDrillDto = {
            conceptId: this.selectedConcept.guid,
            l1font: this.letterTypeControl.value === 'zb' ? 'zanerbloser' : 'dnealian',
            letterFormationType: 'LETTER_FORMATION_4_GRID',
            title: this.descriptionControl.value.trim()
        }
        dataToBeSaved.l1letter = this.charControl1.value?.guid || null;
        dataToBeSaved.l2letter = this.charControl2.value?.guid || null;
        dataToBeSaved.l3letter = this.charControl3.value?.guid || null;
        dataToBeSaved.l4letter = this.charControl4.value?.guid || null;

        if (this.gridSelectionControl.value == 'GRID_6' || this.gridSelectionControl.value == 'GRID_8') {
            dataToBeSaved.l5letter = this.charControl5.value?.guid || null;
            dataToBeSaved.l6letter = this.charControl6.value?.guid || null;
            dataToBeSaved.letterFormationType = 'LETTER_FORMATION_6_GRID';
        }
        if (this.gridSelectionControl.value == 'GRID_8') {
            dataToBeSaved.l7letter = this.charControl7.value?.guid || null;
            dataToBeSaved.l8letter = this.charControl8.value?.guid || null;
            dataToBeSaved.letterFormationType = 'LETTER_FORMATION_8_GRID';

        }

        if (copyToConcept) {
            dataToBeSaved.title = ('Copy From ' + this.savedConceptMaterial.lessonMaterial.lesson.title).substring(0, 40);
            let saveSub = this.coreService.saveThreePartDrill(dataToBeSaved, null).subscribe({
                next: resp => {
                    this.coreService.openSnackBar("Copied to Concept Material", 3000, "primary", "end", "bottom");
                    this.isSaveInProgress = false;
                },
                error: err => {
                    console.log(err);
                    this.isSaveInProgress = false;
                    this.coreService.openSnackBar("Some error occurred", 3000, "warn", "end", "bottom");
                }
            });
            this._subscription.push(saveSub);

        } else {
            let getSavedConceptsSub = this.coreService.getSavedConceptMaterialByClass().subscribe(
                {
                    next: savedConcepts => {
                        let isDuplicateConceptName = savedConcepts.filter(d => d.materialTypeId !== this.savedConceptMaterial?.id)
                            .some(d => d.materialTypeTitle?.toLowerCase().trim() === dataToBeSaved.title?.toLowerCase());
                        if (isDuplicateConceptName) {
                            let confirmDialogBox = this.dialog.open(ConfirmDialogBox, {
                                data: {
                                    message: `Concept material already exists with the same name. Do you want to save?`,
                                    confirmBtnClass: 'blue',
                                    confirmBtnLabel: 'Save'
                                },
                                disableClose: true,
                            });
                            let confirmDialogSub = confirmDialogBox.beforeClosed().subscribe({
                                next: data => {
                                    if (data) {
                                        this.saveData(dataToBeSaved, printPdf);
                                    } else {
                                        this.isSaveInProgress = false;
                                    }
                                }
                            });
                            this._subscription.push(confirmDialogSub);
                        } else {
                            this.saveData(dataToBeSaved, printPdf);
                        }
                    }
                }
            );
            this._subscription.push(getSavedConceptsSub);
        }
    }

    saveData(dataToBeSaved: saveThreePartDrillDto, printPdf: boolean) {
        let saveSub = this.coreService.saveThreePartDrill(dataToBeSaved, this.savedConceptMaterial?.id).subscribe({
            next: resp => {
                this.coreService.openSnackBar("Data Saved", 3000, "primary", "end", "bottom");
                this.savedConceptMaterial = resp;
                this.isSaveInProgress = false;
                printPdf && this.printPdf();
            },
            error: err => {
                console.log(err);
                this.isSaveInProgress = false;
                this.coreService.openSnackBar("Some error occurred", 3000, "warn", "end", "bottom");
            }
        });
        this._subscription.push(saveSub);
    }

    demo() {
        console.log(this.descriptionControl);
        return this.descriptionControl.valid;
    }

}