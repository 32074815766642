<mat-progress-bar *ngIf="isloading" mode="query"></mat-progress-bar>
<div class="savedConceptContainer">
    <app-dialog-title [title]="'Saved Concept '+ conceptName +' Materials'"></app-dialog-title>

    <div class="savedConceptListContainer"  mat-dialog-content>
        <mat-expansion-panel *ngFor="let item of savedConceptContainer | keyvalue" expanded="true">
            <mat-expansion-panel-header class="expansionHeader">
                <mat-panel-title class="expansionTitle">
                    {{item.key}}
                </mat-panel-title>
                <app-button class="expansionPreMade" *ngIf="preMadeData[item.key]" [content]="'Digital Slides'" [btnClasses]="'conceptBtn singleBtn'" (click)="openPreMade($event, preMadeData[item.key])"></app-button>
            </mat-expansion-panel-header>

            <div class="savedConceptItem" *ngFor="let concept of item.value">
                <div class="savedConceptItemName">
                    {{concept.materialTypeTitle}}
                </div>
                <div class="savedConceptListBtns">
                    <app-button [content]="'View/Edit'" [btnClasses]="'blue conceptBtn'" (click)="onSelectMaterial(concept)"></app-button>
                    <mat-checkbox [color]="'primary'" class="savedConceptCheckbox" [(ngModel)]="concept.isSelected"></mat-checkbox>
                </div>
            </div>
        </mat-expansion-panel>
    </div>
    <mat-dialog-actions class="savedConceptActionBtns">
        <app-button [content]="'Cancel'" (click)="onCancel()"></app-button>
        <app-button *ngIf="savedConceptList.length" [isDisabled]="selectedClass?.readOnly || isPrintInProgress || false" [content]="'Delete Selected'" [btnClasses]="'lightRed'" (click)="deleteSelected()"></app-button>
        <app-button *ngIf="savedConceptList.length" [content]="'Print Selected'" [btnClasses]="'blue'" (click)="printSelected()" [isLoading]="isPrintInProgress" [isDisabled]="isPrintInProgress"></app-button>
    </mat-dialog-actions>
</div>